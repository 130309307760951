import { StripeApi } from 'api/Stripe'
import getHostDomain from 'utils/GetHost'
import { Alert, Button } from '@mui/material'

const AccountOnboard = ({ account }) => {
  const { data } = StripeApi.useGetAccountOnboardingLinkQuery({
    accountId: account,
    refreshUrl: `${getHostDomain()}/account`,
    returnUrl: `${getHostDomain()}/account`
  })
  return (
    <div>
      <div className="aside-header aside-account-onboard">
        <span className="bounty">Get Paid by Fence.gg</span>
      </div>
      <div className="content-panel">
        <span className="paragraph">
          Want to earn extra money through Fence.gg? To unlock features like
          bounties, instant payouts from leagues, and more - you must fill out
          the payments onboarding form. This is required by our payment
          processor Stripe - We do not store this information.
        </span>
        <Alert sx={{ marginBottom: 3 }} severity="error">
          You haven't finished setting up FenceGG payments
        </Alert>
        <Button className="btn btn-primary" href={data?.url}>
          <span>Continue Onboarding</span>
        </Button>
      </div>
    </div>
  )
}

export default AccountOnboard
