import { useCallback, useEffect, useState } from 'react'
import LeagueService from 'services/LeagueService'

export const useGetLeagueRunsSidebar = () => {
  const [leagueRuns, setLeagueRuns] = useState([])
  const [rankings, setRankings] = useState([])

  const getPendingRuns = useCallback(async () => {
    const response = await LeagueService.getPendingRunsByMax(async runs =>
      setLeagueRuns(runs)
    )
    return response
  }, [])

  useEffect(() => {
    const callPendingRuns = getPendingRuns()
    return () => {
      callPendingRuns.then(response =>
        response ? response.unsubscribe() : null
      )
    }
  }, [getPendingRuns])

  const getSeasonRanks = useCallback(async () => {
    await Promise.all(
      leagueRuns.map(async run => {
        return await LeagueService.getLeagueSeasonRankings(
          run?.league,
          run?.season,
          run?.tier
        ).then(
          ranks =>
            ranks.filter(rank => {
              if (run.league === 'duo') {
                return rank?.members?.includes(run.id)
              }
              return rank.uid === run.id
            })[0]
        )
      })
    ).then(userRanks => setRankings(userRanks))
  }, [leagueRuns])

  useEffect(() => {
    if (leagueRuns.length > 0) {
      getSeasonRanks()
    }
  }, [getSeasonRanks, leagueRuns.length])

  const getRank = (uid, league, tier) => {
    if (rankings.length > 0 && leagueRuns.length > 0) {
      if (league === 'duo') {
        return (
          rankings.filter(rank =>
            rank
              ? rank?.members?.includes(uid) &&
                rank.league === league &&
                rank.tier === tier
              : false
          )[0]?.rank || '--'
        )
      }
      return (
        rankings.filter(rank =>
          rank
            ? rank.uid === uid && rank.league === league && rank.tier === tier
            : false
        )[0]?.rank || '--'
      )
    }
    return '--'
  }

  return { getRank, leagueRuns }
}
