import React, { useCallback, useEffect, useState } from 'react'
import moment from 'moment'
import Countdown from 'react-countdown'
import TimeAgo from 'react-timeago'
import Username from './Username'
import LoginButton from './LoginButton'
import {
  Button,
  CircularProgress,
  FormControl,
  InputLabel,
  Modal,
  Select
} from '@mui/material'
import { useAppCommunityStreams, useAppIsLoggedIn, useUserData } from 'hooks'
import BountyService from 'services/BountyService'
import { UserServiceApi } from 'api/UserServiceApi'
import DisplayEntry from './Bounties/DisplayEntry'
import SelectWinner from './Bounties/SelectWinner'
import UsernameAvatar from './UsernameDisplay/UsernameAvatar'
import UsernameStreamLink from './UsernameDisplay/UsernameStreamLink'

const BountyDetails = ({
  bounty,
  availableBounties,
  isEligible,
  setSelectedBounty,
  submitModalOpen,
  setSubmitModalOpen
}) => {
  const user = useUserData()
  const communityStreams = useAppCommunityStreams()
  const isLoggedIn = useAppIsLoggedIn()
  const [bountyEntriesExpanded, setBountyEntriesExpanded] = useState(false)
  const [loadedEntries, setLoadedEntries] = useState([])
  const [openReviewModal, setOpenReviewModal] = useState(false)
  const [submitLoading, setSubmitLoading] = useState(false)
  const [selectedWinner, setSelectedWinner] = useState('')
  const [getUsername, { data: username }] =
    UserServiceApi.useLazyGetUsernameByIdCacheQuery()

  useEffect(() => {
    if (bounty?.createdBy) {
      getUsername({ id: bounty?.createdBy }, true)
    }
  }, [getUsername, bounty.createdBy])

  const getBounties = useCallback(async () => {
    const response = BountyService.getEntriesForBounty(bounty.id, entries => {
      setLoadedEntries(entries?.length > 0 ? entries : [])
    })
    return response
  }, [bounty.id])

  useEffect(() => {
    const bounties = getBounties()
    return () => {
      bounties.then(response => (response ? response.unsubscribe() : null))
    }
  }, [getBounties])

  const getEntryCount = () => {
    return loadedEntries.length
  }

  const handleClose = () => {
    setOpenReviewModal(false)
  }

  const handleWinnerChange = e => {
    setSelectedWinner(e.target.value)
  }

  const submitBountyWinner = (id, winner) => {
    BountyService.submitBountyWinner(id, winner, data => {
      setSelectedWinner('')
      setOpenReviewModal(false)
      setSubmitLoading(false)
    })
  }

  const checkIsLive = uid => {
    if (uid?.length > 0) {
      let streamInfo = communityStreams?.filter(s => s.id === uid)
      if (streamInfo?.length > 0) {
        return streamInfo[0]
      }
    }
  }

  const userEnteredBounty = () => {
    return loadedEntries.filter(e => e.submittedBy === `twitch:${user.id}`)
      ?.length > 0
      ? true
      : false
  }

  const renderer = ({ days, hours, minutes, seconds }) => {
    const needsPad = value => {
      if (value > 9) {
        return false
      }
      return true
    }
    return (
      <span>
        {needsPad(days) ? '0' + days : days}
        <span className="timer-label">:</span>
        {needsPad(hours) ? '0' + hours : hours}
        <span className="timer-label">:</span>
        {needsPad(minutes) ? '0' + minutes : minutes}
        <span className="timer-label">:</span>
        {needsPad(seconds) ? '0' + seconds : seconds}
      </span>
    )
  }

  return (
    <div key={bounty.id} className="bounty">
      <div className="bounty-task">
        <span className="bounty-name">
          {availableBounties.filter(b => b.id === bounty.bounty)?.length > 0
            ? availableBounties.filter(b => b.id === bounty.bounty)[0]?.desc
            : ''}
        </span>
        <span
          className={`bounty-status ${
            bounty.status === 'active'
              ? getEntryCount() > 0
                ? 'is-pending'
                : 'is-active'
              : ''
          }`}>
          {bounty.status === 'active'
            ? getEntryCount() > 0
              ? 'Pending'
              : 'Available'
            : ''}
        </span>
      </div>
      <div className="bounty-details">
        <div className="bounty-posted">
          {/* {bounty.status === 'completed' && (
            <div className="bounty-username" style={{ paddingBottom: 10 }}>
              <span className="bounty-username-label">Won By</span>
              <UserStreamLink
                uid={
                  loadedEntries.filter(e => e.winner === true)[0]?.submittedBy
                }>
                <Avatar
                  uid={
                    loadedEntries.filter(e => e.winner === true)[0]?.submittedBy
                  }
                />
                <span
                  className={
                    checkIsLive(
                      loadedEntries.filter(e => e.winner === true)[0]
                        ?.submittedBy
                    )
                      ? 'is-live'
                      : ''
                  }>
                  <Username
                    uid={
                      loadedEntries.filter(e => e.winner === true)[0]
                        ?.submittedBy
                    }
                  />
                </span>
              </UserStreamLink>
            </div>
          )} */}
          <div className="bounty-username">
            <span className="bounty-username-label">Posted By</span>
            <UsernameStreamLink username={username}>
              <UsernameAvatar username={username} />
              <span className={checkIsLive(bounty.createdBy) ? 'is-live' : ''}>
                <Username username={username} />
              </span>
            </UsernameStreamLink>
          </div>
          <div className="bounty-created">
            <span className="bounty-created-label">
              {bounty.status === 'completed' ? 'Ended' : 'Active For'}
            </span>{' '}
            {bounty.status === 'completed' ? (
              <TimeAgo date={bounty?.endedAt?.seconds * 1000} />
            ) : (
              <Countdown
                renderer={renderer}
                overtime={true}
                date={moment(bounty?.opened?.seconds * 1000).toDate()}
              />
            )}
          </div>
        </div>
        <div className="bounty-prize">
          <span className="bounty-prize">
            <span className="bounty-prize-amount">
              {(bounty.amount / 100).toLocaleString('en-US', {
                style: 'currency',
                currency: 'USD'
              })}
            </span>
            <span className="bounty-prize-label">Prize</span>
          </span>
        </div>
      </div>
      <div className="bounty-entries">
        <div className="bounty-entries-inner">
          <span
            onClick={() => {
              if (getEntryCount() > 0) {
                setBountyEntriesExpanded(!bountyEntriesExpanded)
              }
            }}
            className={`bounty-entries-count ${
              getEntryCount() > 0 ? 'entries-clickable' : ''
            } ${bountyEntriesExpanded === true ? 'entries-expanded' : ''}`}>
            {getEntryCount()} submitted{' '}
            {getEntryCount() === 1 ? 'entry' : 'entries'}
          </span>
          {isLoggedIn ? (
            <Button
              style={{
                backgroundColor:
                  user?.usingKick === true ||
                  userEnteredBounty() ||
                  !isEligible()
                    ? '#707070'
                    : '#837654'
              }}
              disabled={
                user?.usingKick === true || userEnteredBounty() || !isEligible()
              }
              onClick={() => {
                if (submitModalOpen !== true && bounty.status !== 'completed') {
                  setSubmitModalOpen(true)
                  setSelectedBounty({
                    ...bounty,
                    desc: availableBounties.filter(
                      b => b.id === bounty.bounty
                    )[0].desc
                  })
                }
              }}
              className={`btn btn-primary ${
                bounty.status === 'completed'
                  ? loadedEntries.filter(
                      e => e.submittedBy === `twitch:${user.id}`
                    )[0]?.winner === true
                    ? 'btn-won'
                    : loadedEntries.filter(
                        e => e.submittedBy === `twitch:${user.id}`
                      )?.length > 0
                    ? 'btn-lost'
                    : 'btn-ended'
                  : ''
              }`}>
              {userEnteredBounty()
                ? bounty.status === 'completed'
                  ? loadedEntries.filter(
                      e => e.submittedBy === `twitch:${user.id}`
                    )[0]?.winner === true
                    ? 'You Won'
                    : 'You Lost'
                  : "You're Entered"
                : user?.usingKick === true || !isEligible()
                ? 'Not Eligible'
                : bounty.status === 'completed'
                ? 'Bounty Ended'
                : 'Submit Entry'}
            </Button>
          ) : (
            <LoginButton />
          )}
        </div>
        <div
          className={`entries-list ${
            bountyEntriesExpanded === true ? 'entries-list-expanded' : ''
          }`}>
          {loadedEntries
            .sort((a, b) => a.createdAt - b.createdAt)
            .map((entry, i) => (
              <DisplayEntry
                key={i}
                entry={entry}
                bounty={bounty}
                setOpenReviewModal={setOpenReviewModal}
              />
            ))}
        </div>
      </div>
      <Modal
        className="start-run-modal"
        disableEscapeKeyDown={false}
        open={openReviewModal}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description">
        <div className="run-modal">
          <div className="aside-header aside-run">
            <span className="bounty">Select the Winner for the Bounty</span>
          </div>
          <div className="modal-content">
            <span className="paragraph" style={{ paddingTop: 10 }}>
              Select the person that was the first one to submit proof of
              completing the task.
            </span>
            <span className="subhead green">Bounty Task:</span>
            <span className="bounty-modal-task">
              {availableBounties.filter(b => b.id === bounty.bounty)?.length > 0
                ? availableBounties.filter(b => b.id === bounty.bounty)[0]?.desc
                : ''}
            </span>
            <div>
              <span className="subhead green">
                First Person to Submit Proof of Task Completion
              </span>
              <FormControl sx={{ paddingBottom: 3 }}>
                <InputLabel id="select-winner-label">
                  Select the winner...
                </InputLabel>
                <Select
                  labelId="select-winner-label"
                  id="select-winner"
                  value={selectedWinner}
                  label="Select the winner..."
                  onChange={handleWinnerChange}>
                  {loadedEntries
                    .sort((a, b) => a.createdAt - b.createdAt)
                    .map((user, i) => (
                      <SelectWinner key={i} user={user} />
                    ))}
                </Select>
              </FormControl>
            </div>
            <div className="modal-controls">
              <Button
                className="btn btn-primary btn-cancel"
                disabled={submitLoading}
                onClick={e => {
                  e.preventDefault()
                  setOpenReviewModal(false)
                }}>
                Cancel
              </Button>
              <Button
                className="btn btn-primary"
                disabled={submitLoading}
                onClick={e => {
                  e.preventDefault()
                  setSubmitLoading(true)
                  submitBountyWinner(bounty.id, selectedWinner)
                }}>
                {submitLoading && (
                  <CircularProgress
                    style={{
                      color: '#000',
                      height: 15,
                      width: 15,
                      marginRight: 10
                    }}
                  />
                )}
                Submit Winner
              </Button>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  )
}

export default BountyDetails
