import { useState } from 'react'
import { Modal, Box, Typography, Button, Link } from '@mui/material'
import { AddCard as AddCardIcon } from '@mui/icons-material'
import { useUserIsStripeTest } from 'hooks/use-user-isStripeTest'
import { Elements } from '@stripe/react-stripe-js'
import AddCardForm from 'components/Stripe/cards/AddCardForm'
import { loadStripe } from '@stripe/stripe-js'
import { useCreateCard } from 'components/Stripe/hooks/Cards'

const AddCardModal = ({ route, customerId, button = true }) => {
  const [isOpen, setIsOpen] = useState(false)
  const handleClose = () => setIsOpen(false)
  const isStripeTest = useUserIsStripeTest()
  const stripeKey = isStripeTest
    ? process.env.REACT_APP_STRIPE_TEST_KEY
    : process.env.REACT_APP_STRIPE_KEY
  const stripePromise = loadStripe(stripeKey)
  const { clientSecret = '' } = useCreateCard(customerId)

  const appearance = {
    theme: 'night'
  }

  return (
    <Box mt="10px">
      {button ? (
        <Button variant={'contained'} onClick={() => setIsOpen(true)}>
          Add Payment Method
        </Button>
      ) : (
        <Link href="#" onClick={() => setIsOpen(true)}>
          Add Payment Method
        </Link>
      )}
      <Modal open={isOpen} onClose={handleClose}>
        <Box className="run-modal">
          <Box className="aside-header">
            <Typography
              className="aside-header"
              id="card-modal-title"
              variant="h6"
              component="span">
              <AddCardIcon /> Add a new Card
            </Typography>
          </Box>
          <Box className="modal-content">
            {stripePromise && clientSecret && (
              <Box paddingBottom="15px">
                <Elements
                  stripe={stripePromise}
                  options={{ clientSecret, appearance, loader: 'always' }}>
                  <AddCardForm route={route} />
                </Elements>
              </Box>
            )}
          </Box>
        </Box>
      </Modal>
    </Box>
  )
}

export default AddCardModal
