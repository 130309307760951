import React, { useCallback, useEffect, useState } from 'react'
import { useUserIsOwner, useUserIsAdmin, useUserIsEditor } from 'hooks'
import { Link } from 'react-router-dom'
import ArticleItem from './News/ArticleItem'
import { StoryServiceApi } from 'api/StoryServiceApi'
import { Box, Button, CircularProgress } from '@mui/material'

const LatestNews = () => {
  const isOwner = useUserIsOwner()
  const isAdmin = useUserIsAdmin()
  const isEditor = useUserIsEditor()
  const [newsStories, setNewsStories] = useState([])
  const [lastStory, setLastStory] = useState()
  const [hasNext, setHasNext] = useState(false)
  const [getInitialStories, { data: initialStories, isLoading }] =
    StoryServiceApi.useLazyGetInitialStoriesQuery()
  const [getStories] = StoryServiceApi.useLazyGetMoreStoriesQuery()

  const getInitialNews = useCallback(async () => {
    getInitialStories()
  }, [getInitialStories])

  useEffect(() => {
    if (newsStories.length === 0) {
      getInitialNews()
    }
  }, [getInitialNews, newsStories.length])

  useEffect(() => {
    if (initialStories?.data?.length > 0) {
      setNewsStories(initialStories?.data)
      setLastStory(initialStories?.last)
      setHasNext(initialStories?.hasNext)
    }
  }, [initialStories?.data, initialStories?.last, initialStories?.hasNext])

  const loadMore = async () => {
    getStories({
      last: lastStory
    })
      .unwrap()
      .then(stories => {
        const currentStories = newsStories
        setNewsStories([...currentStories, ...stories.data])
        setLastStory(stories?.last)
        setHasNext(stories?.hasNext)
      })
  }

  if (isLoading) {
    return (
      <div>
        <div className="aside-header aside-competitors">
          <span className="news">Latest News</span>
        </div>
        <nav className="news-nav news-nav-loading">
          <Box textAlign={'center'}>
            <CircularProgress size={'5rem'} />
          </Box>
        </nav>
      </div>
    )
  }

  return (
    <div>
      <div className="aside-header aside-competitors">
        <span className="news">Latest News</span>
      </div>
      <nav className="news-nav">
        {(isAdmin || isOwner || isEditor) && (
          <div className="news-controls">
            <Link className="btn btn-primary" to="/news/post">
              New Story
            </Link>
          </div>
        )}
        <ul>
          {newsStories.length > 0 ? (
            newsStories.map(story => (
              <ArticleItem key={story.id} story={story} />
            ))
          ) : (
            <span className="no-competitors">
              There are no news stories at this time.
            </span>
          )}

          {hasNext && (
            <li>
              <Button
                variant={'contained'}
                className="all-link"
                onClick={loadMore}
                fullWidth>
                Load More
              </Button>
            </li>
          )}
        </ul>
      </nav>
    </div>
  )
}

export default LatestNews
