import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { getHostFunctions } from 'utils/GetHost'
import { auth } from 'configs/firebaseConfig'

const base = getHostFunctions()

const getUserToken = async () => {
  const token = await auth.currentUser?.getIdToken()
  return token
}

export const StoryServiceApi = createApi({
  reducerPath: 'StoryService',
  baseQuery: fetchBaseQuery({
    baseUrl: `${base}`,
    prepareHeaders: async headers => {
      headers.set('Content-Type', 'application/json')
      const token = await getUserToken()
      if (token) headers.set('Authorization', `Bearer ${token}`)
      return headers
    }
  }),
  tagTypes: ['Stories', 'Story'],
  endpoints: build => ({
    getInitialStories: build.query({
      query: () => ({
        url: `/getStories`
      }),
      transformResponse: response => response.stories,
      providesTags: result =>
        result
          ? [...result?.data?.map(({ id }) => ({ type: 'Story', id })), 'Story']
          : ['Story']
    }),
    getMoreStories: build.query({
      query: ({ last }) => ({
        url: `/getStoriesPage?last=${JSON.stringify(last)}`
      }),
      transformResponse: response => response.stories,
      providesTags: result =>
        result
          ? [...result?.data?.map(({ id }) => ({ type: 'Story', id })), 'Story']
          : ['Story']
    })
  })
})
