export const stripeAmountFormat = amount => {
  const number = parseFloat(amount)
  const convert = Math.round(number * 100)
  return convert
}

export const stripeDisplayAmount = amount => {
  if (amount < 0) {
    const posAmount = amount * -1
    return `$${(posAmount / 100).toFixed(2)}`
  } else {
    return `$${(amount / 100).toFixed(2)}`
  }
}
