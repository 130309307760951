import { Grid } from '@mui/material'
import Username from 'components/Username'
import supplant from 'utils/Supplant'
import moment from 'moment'
import Countdown from 'react-countdown'
import UsernameAvatar from 'components/UsernameDisplay/UsernameAvatar'
import UsernameStreamLink from 'components/UsernameDisplay/UsernameStreamLink'

const StreamDisplay = ({ stream }) => {
  const renderer = ({ hours, minutes, seconds }) => {
    const needsPad = value => {
      if (value > 9) {
        return false
      }
      return true
    }
    return (
      <span className="timer">
        {needsPad(hours) ? '0' + hours : hours}
        <span className="label">H</span>
        {needsPad(minutes) ? '0' + minutes : minutes}
        <span className="label">M</span>
        {needsPad(seconds) ? '0' + seconds : seconds}
        <span className="label">S</span>
      </span>
    )
  }
  return (
    <Grid item xs={12} md={6}>
      <div className="streamer-box" key={stream.id}>
        <UsernameStreamLink
          style={{
            backgroundImage: `url(${supplant(stream.thumbnail_url, {
              width: 600,
              height: 338
            })})`,
            backgroundSize: '100% auto',
            backgroundRepeat: 'no-repeat'
          }}
          username={stream?.username}>
          <div className="streamer-box-details">
            <div className="streamer-box-user">
              <div className="streamer-box-username">
                <UsernameAvatar
                  username={stream?.username}
                  className={'avatar'}
                />
                <span
                  className={`username ${
                    stream?.username?.premium === true ? 'premium-badge' : ''
                  }`}>
                  <Username username={stream.username} />
                </span>
              </div>
              <div className="streamer-box-viewers">{stream.viewer_count}</div>
            </div>
          </div>
          <div className="streamer-box-timer">
            <Countdown
              renderer={renderer}
              overtime={true}
              date={moment(stream.started_at).toDate()}
            />
          </div>
        </UsernameStreamLink>
      </div>
    </Grid>
  )
}

export default StreamDisplay
