import React, { useEffect } from 'react'
import { useUser } from 'hooks'
import Alert from '@mui/material/Alert'
import FormControl from '@mui/material/FormControl'
import Button from '@mui/material/Button'
import ChatbotService from 'services/ChatbotService'
import CircularProgress from '@mui/material/CircularProgress'
import ChatModded2 from '../assets/img/chatbot-modded-2.jpg'

const ChatbotSettings = () => {
  const [isLoading, setIsLoading] = React.useState(false)
  const [isLoadingDisconnect, setIsLoadingDisconnect] = React.useState(false)
  const [attemptedSetup, setAttemptedSetup] = React.useState(false)
  const [chatbotSettings, setChatbotSettings] = React.useState({
    modded: false,
    connected: false
  })
  const user = useUser()
  const chatbotUsername = 'fencegg'

  useEffect(() => {
    if (user && user.uid) {
      ChatbotService.getChatbotSettings(user.uid, data => {
        if (typeof data.data() !== 'undefined') {
          setChatbotSettings(data.data())
        }
      })
    }
  }, [user])

  useEffect(() => {
    if (chatbotSettings.modded === true && chatbotSettings.connected === true) {
      setIsLoading(false)
    }
    if (
      chatbotSettings.modded === false &&
      chatbotSettings.connected === false
    ) {
      setIsLoading(false)
    }
    if (
      chatbotSettings.modded === true &&
      chatbotSettings.connected === false
    ) {
      setIsLoading(false)
      setIsLoadingDisconnect(false)
    }
  }, [chatbotSettings])

  const testSetup = () => {
    if (!isLoading) {
      setAttemptedSetup(true)
      setIsLoading(true)
      ChatbotService.connectChatbot(user.uid, () => {})
    }
  }

  const disconnect = () => {
    if (!isLoadingDisconnect) {
      setIsLoadingDisconnect(true)
      ChatbotService.disconnectChatbot(user.uid, () => {})
    }
  }
  const reconnect = () => {
    if (!isLoading) {
      setIsLoading(true)
      ChatbotService.connectChatbot(user.uid, () => {})
      setTimeout(() => {
        setIsLoading(false)
      }, 5000)
    }
  }

  const getIframe = () => {
    const url = `https://www.twitch.tv/embed/${
      user.displayName
    }/chat?darkpopout&parent=${
      window.location.href.indexOf('localhost') > -1 ? 'localhost' : 'fence.gg'
    }`
    return (
      <div className="twitch-chat-wrapper">
        <span>{user.displayName}'s Embed Twitch Chat</span>
        <iframe
          className="twitch-chat"
          src={url}
          title={'Twitch Chat'}></iframe>
      </div>
    )
  }

  return (
    <div className="chatbot-settings">
      <div className="aside-header aside-dashboard">
        <span className="dashboard">Chatbot Settings</span>
      </div>
      <div className="content-panel">
        <span className="paragraph">
          The Fence.gg chatbot allows you to take advantage of our Twitch chat
          integrated features. This will make your content more interactive for
          your viewers as you can allow them to trigger the Fence.gg overlays in
          your livestream.
        </span>
        {chatbotSettings.modded === true &&
          chatbotSettings.connected === true && (
            <span className="paragraph">
              The Fencegg chatbot is ready. If you have any issues, disconnect
              or reconnect it using the buttons below.
            </span>
          )}
        {chatbotSettings.modded === true &&
          chatbotSettings.connected === false && (
            <Alert className="content-alert" severity="error">
              Action Required: Reconnect the Chatbot!
            </Alert>
          )}
        {chatbotSettings && chatbotSettings.modded !== true ? (
          <div>
            <Alert className="content-alert" severity="error">
              Action Required: Setup the Chatbot!
            </Alert>
            <span className="heading-text">Step 1. Mod the chatbot.</span>
            <span className="paragraph">
              You need to add the {chatbotUsername} chatbot as a moderator to
              your Twitch channel to take advantage of our Twitch chat
              integrated features.
            </span>
            <span className="paragraph">
              Type <span className="green">/mod {chatbotUsername}</span> in your
              Twitch channel's chat below, and click OK when prompted:
            </span>
            {getIframe()}
            <span className="heading-text">
              Step 2. Verify the chatbot is modded.
            </span>
            <span className="paragraph">
              In the box above, type <span className="green">/mods</span> to
              check if the {chatbotUsername} chatbot is listed in your channel's
              moderators.
            </span>
            <div className="helper-message">
              <img src={ChatModded2} alt={'Chat Mod'} />
            </div>
            <span className="paragraph">
              If {chatbotUsername} is listed in your moderators, continue to the
              next step.
            </span>
            <FormControl className="form-control">
              <Button
                onClick={testSetup}
                variant="contained"
                type="submit"
                disabled={isLoading}>
                {isLoading && (
                  <CircularProgress
                    style={{
                      color: '#7ab903',
                      height: 20,
                      width: 20,
                      marginRight: 10
                    }}
                  />
                )}
                {isLoading ? (
                  <span>Testing Chatbot...</span>
                ) : attemptedSetup ? (
                  <span>Try again</span>
                ) : (
                  <span>Continue to Next Step</span>
                )}
              </Button>
            </FormControl>
          </div>
        ) : (
          <div>
            <FormControl className="form-control chatbot-controls">
              {chatbotSettings.modded === true &&
                chatbotSettings.connected === true && (
                  <div>
                    <Button
                      className="btn-disconnect"
                      onClick={disconnect}
                      variant="contained"
                      disabled={isLoading || isLoadingDisconnect}
                      type="submit">
                      {isLoadingDisconnect && (
                        <CircularProgress
                          style={{
                            color: '#000',
                            height: 20,
                            width: 20,
                            marginRight: 10
                          }}
                        />
                      )}
                      {isLoadingDisconnect ? (
                        <span>Disconnecting Chatbot...</span>
                      ) : (
                        <span>Disconnect</span>
                      )}
                    </Button>
                    <Button
                      className="btn-connect"
                      onClick={reconnect}
                      variant="contained"
                      disabled={isLoading || isLoadingDisconnect}
                      type="submit">
                      {isLoading && (
                        <CircularProgress
                          style={{
                            color: '#7ab903',
                            height: 20,
                            width: 20,
                            marginRight: 10
                          }}
                        />
                      )}
                      {isLoading ? (
                        <span>Reconnecting Chatbot...</span>
                      ) : (
                        <span>Reconnect</span>
                      )}
                    </Button>
                  </div>
                )}
              {chatbotSettings.modded === true &&
                chatbotSettings.connected === false && (
                  <div>
                    <Button
                      className="btn-connect"
                      onClick={reconnect}
                      variant="contained"
                      disabled={isLoading || isLoadingDisconnect}
                      type="submit">
                      {isLoading && (
                        <CircularProgress
                          style={{
                            color: '#7ab903',
                            height: 20,
                            width: 20,
                            marginRight: 10
                          }}
                        />
                      )}
                      {isLoading ? (
                        <span>Connecting Chatbot...</span>
                      ) : (
                        <span>Reconnect Chatbot</span>
                      )}
                    </Button>
                  </div>
                )}
            </FormControl>
          </div>
        )}
      </div>
    </div>
  )
}

export default ChatbotSettings
