import React, { useRef } from 'react'
import Box from '@mui/material/Box'
import { useAppIsLoggedIn } from 'hooks'
import SocialLinks from 'components/SocialLinks'
import SiteTagline from 'components/SiteTagline'
import Footer from 'components/Footer'
import MenuMobile from 'components/MenuMobile'
import moment from 'moment'
import Countdown from 'react-countdown'
import { CSSTransition } from 'react-transition-group'
import { useState } from 'react'
import { Grid } from '@mui/material'
import LoginButton from 'components/LoginButton'
import FenceLogo from '../assets/img/fence-logo.png'
import GoonsMapLighthouse from '../assets/img/goons-map-lighthouse.jpg'
import TrophyGreenIcon from '../assets/img/icon-trophy-green.png'
import AdminGreenIcon from '../assets/img/icon-admin-green.png'
import CashGreenIcon from '../assets/img/icon-cash-green.png'

const ClosedBeta = () => {
  const isLoggedIn = useAppIsLoggedIn()
  const overlayLeagueRunRef = useRef(null)
  const [overlayAnim, setOverlayAnim] = useState(false)

  const renderer = ({ hours, minutes, seconds }) => {
    const needsPad = value => {
      if (value > 9) {
        return false
      }
      return true
    }
    return (
      <span className="timer">
        {needsPad(hours) ? '0' + hours : hours}
        <span className="label">H</span>
        {needsPad(minutes) ? '0' + minutes : minutes}
        <span className="label">M</span>
        {needsPad(seconds) ? '0' + seconds : seconds}
        <span className="label">S</span>
      </span>
    )
  }

  setTimeout(() => {
    setOverlayAnim(true)
  }, 1000)

  return (
    <Box>
      <div className="main-container">
        <div className="wrapper tagline-social">
          <SiteTagline />
          <SocialLinks />
        </div>
        <div className="main wrapper clearfix">
          <div className="main-col main-col-full middle-content">
            <div>
              <div className="aside-header aside-beta">
                <span className="leagues">Closed Beta</span>
              </div>
              <div className="content-panel">
                <div className="overlay-banner">
                  {isLoggedIn ? (
                    <div className="overlay-banner-text">
                      <h1>You're on the waitlist</h1>
                      <h2>
                        We will notify when our services are available to you.
                        Please follow us on social media for the latest news and
                        feature updates.
                      </h2>
                    </div>
                  ) : (
                    <div className="overlay-banner-text">
                      <h1>Fence.gg Closed Beta Testing</h1>
                      <h2>
                        We are currently in closed beta, testing and preparing
                        for our imminent launch. <br />
                        Sign in to join the waitlist and check us out on social
                        media.
                      </h2>
                      <div className="overlay-banner-login">
                        <LoginButton />
                      </div>
                    </div>
                  )}
                  <CSSTransition
                    nodeRef={overlayLeagueRunRef}
                    in={overlayAnim}
                    timeout={0}
                    classNames="animate-overlay-goons">
                    <div
                      ref={overlayLeagueRunRef}
                      className={
                        `overlay overlay-goons overlay-league-run overlay-align-right` +
                        (overlayAnim ? ' animate-overlay-goons-enter-done' : '')
                      }
                      style={{ bottom: '20%', top: 'auto' }}>
                      <div className="overlay-badge">
                        <img src={FenceLogo} />
                      </div>
                      <div
                        className="overlay-league-map"
                        style={{
                          backgroundImage: `url(${GoonsMapLighthouse})`
                        }}>
                        <div className="overlay-league-map-inner"></div>
                        <div className="overlay-league-map-content">
                          <div className="rank-badge">
                            <div className={'badge-outer badge-red badge-32'}>
                              <div className="badge-inner"></div>
                              <div className="badge-rank">
                                <span>1</span>
                              </div>
                            </div>
                          </div>
                          <span className="overlay-league-text">
                            <span className="overlay-league-username">
                              {/* <img
                                className="avatar"
                                src="https://static-cdn.jtvnw.net/jtv_user_pictures/2e89ad3f-2bed-4d51-bf71-bf9202879ee4-profile_image-300x300.png"
                              /> */}
                              <span>{'<Your Name Here>'}</span>
                            </span>
                            <span className={`overlay-league-tier league-red`}>
                              Red Solos League
                            </span>
                          </span>
                        </div>
                      </div>
                      <div className="overlay-goons-timeago">
                        <span className="overlay-league-points">
                          37.94{' '}
                          <span className="overlay-league-points-label">
                            PTS
                          </span>
                        </span>
                      </div>
                      <div className="overlay-goons-textbox">
                        <Countdown
                          renderer={renderer}
                          overtime={true}
                          date={moment().toDate()}
                        />
                      </div>
                    </div>
                  </CSSTransition>
                </div>
                <Grid className="value-props" container spacing={2}>
                  <Grid className="value-prop" item xs={12} md={4} lg={4}>
                    <div className="value-prop-inner">
                      <div className="value-prop-icon">
                        <img src={TrophyGreenIcon} />
                      </div>
                      <div className="value-prop-text">
                        <span className="subhead">Competitive Leagues</span>
                        <span className="paragraph">
                          Join and compete in Escape From Tarkov leagues. Unlike
                          other tournaments, you can compete on your own time -
                          during your own stream schedule.
                        </span>
                      </div>
                    </div>
                  </Grid>
                  <Grid className="value-prop" item xs={12} md={4} lg={4}>
                    <div className="value-prop-inner">
                      <div className="value-prop-icon">
                        <img src={AdminGreenIcon} />
                      </div>
                      <div className="value-prop-text">
                        <span className="subhead">Streaming Tools</span>
                        <span className="paragraph">
                          Boost your viewer interactivity with our custom
                          overlays and chatbot. We have many robust features
                          planned to help all Tarkov content creators.
                        </span>
                      </div>
                    </div>
                  </Grid>
                  <Grid className="value-prop" item xs={12} md={4} lg={4}>
                    <div className="value-prop-inner">
                      <div className="value-prop-icon">
                        <img src={CashGreenIcon} />
                      </div>
                      <div className="value-prop-text">
                        <span className="subhead">Cash Prizes</span>
                        <span className="paragraph">
                          Win cash prizes every month by competing in leagues
                          and custom events along with other special features
                          yet to be announced.
                        </span>
                      </div>
                    </div>
                  </Grid>
                </Grid>
                <div className="comparison-chart">
                  <div className="comparison-desc">
                    <span className="subhead">Fence.gg Plans</span>
                    <span className="paragraph">
                      Sign up for FREE and compete in the free leagues for cash
                      prizes while using the basic streaming overlays and
                      chatbot to elevate your Tarkov content. Premium
                      subscribers will gain access to exclusive leagues with
                      larger prize pools while also utilizing premium overlays
                      and chatbot commands. Any fees collected will help
                      increase the prize pools and fund development for the
                      agressive roadmap of new features that we have planned.
                    </span>
                  </div>

                  <Grid
                    className="comparison-chart-inner"
                    container
                    spacing={2}>
                    <Grid className="plan-free" item xs={12} md={6} lg={6}>
                      <div className="plan-inner">
                        <span className="subhead">Standard</span>
                        <div className="plan-price">
                          <span>FREE</span>
                        </div>
                        <span className="plan-item">Compete in Leagues</span>
                        <span className="plan-item">Smaller Prize Pool</span>
                        <span className="plan-item">Basic Overlays</span>
                        <span className="plan-item">
                          Basic Chatbot Commands
                        </span>
                        <span className="plan-item">Get Exposure</span>
                      </div>
                    </Grid>
                    <Grid className="plan-premium" item xs={12} md={6} lg={6}>
                      <div className="plan-inner">
                        <span className="subhead">Fence Premium</span>
                        <div className="plan-price">
                          <span>Coming Soon</span>
                        </div>
                        <span className="plan-item">Join Premium Leagues</span>
                        <span className="plan-item">Larger Prize Pools</span>
                        <span className="plan-item">Premium Overlays</span>
                        <span className="plan-item">
                          Premium Chatbot Commands
                        </span>
                        <span className="plan-item">Prize Bounties</span>
                        <span className="plan-item">Special Events</span>
                        <span className="plan-item">+ More</span>
                      </div>
                    </Grid>
                  </Grid>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
      <MenuMobile />
    </Box>
  )
}

export default ClosedBeta
