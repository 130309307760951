import { Grid } from '@mui/material'
import React, { useEffect, useRef } from 'react'
import { CSSTransition } from 'react-transition-group'
import CircularProgress from '@mui/material/CircularProgress'
import LeagueService from 'services/LeagueService'
import FenceLogo from '../../src/assets/img/fence-logo.png'
import { useAppLeagues } from 'hooks'
import { LeagueServiceApi } from 'api/LeagueServiceApi'

const OverlayRank = ({ settings, username }) => {
  const leagues = useAppLeagues()
  const [rankings, setRankings] = React.useState([])
  const [rankData, setRankData] = React.useState({})
  const [rank, setRank] = React.useState({
    rank: '--'
  })
  const [detailsLoading, setDetailsLoading] = React.useState(true)
  const [overlayLoading, setOverlayLoading] = React.useState(true)
  const overlayRankingsRef = useRef(null)
  const [lastLeague, setLastLeague] = React.useState('')
  const [leagueSeason, setLeagueSeason] = React.useState(0)
  const [getRunsByIdsCall] = LeagueServiceApi.useGetRunsByIdsMutation()

  useEffect(() => {
    let listenerRankings = null
    if (
      (overlayLoading === true || settings.ranks.league !== lastLeague) &&
      settings &&
      settings.owner &&
      settings.ranks &&
      settings.ranks.league &&
      leagues.length > 0
    ) {
      setOverlayLoading(false)
      setLastLeague(settings.ranks.league)
      setLeagueSeason(
        leagues.filter(l => l.id === settings.ranks.league)[0].currentSeason
      )
      if (typeof listenerRankings === 'function') {
        listenerRankings()
      }
      listenerRankings = LeagueService.getLeagueSeasonRankings(
        settings.ranks.league,
        leagues.filter(l => l.id === settings.ranks.league)[0].currentSeason,
        'yellow',
        data => {
          let rankData =
            data.data() && data.data().rankings ? data.data().rankings : []
          setRankings(rankData)
          let userRank = {}
          if (rankData.filter(r => r.uid === settings.owner).length > 0) {
            userRank = rankData.filter(r => r.uid === settings.owner)[0]
            setRank(rankData.filter(r => r.uid === settings.owner)[0])
          }
          let runString = ''
          if (userRank && userRank.weeks && userRank.weeks.length > 0) {
            userRank.weeks.forEach((week, wi) => {
              userRank.weeks[wi].runs.forEach(run => {
                if (runString === '') {
                  runString = runString + run.id
                } else {
                  runString = runString + ',' + run.id
                }
              })
            })
            if (runString !== '') {
              getRunsByIdsCall({ runs: runString })
                .unwrap()
                .then(data => {
                  if (data && data.success && data.success.length > 0) {
                    let runData = { ...userRank }
                    runData.weeks.forEach((week, wi) => {
                      runData.weeks[wi].runs.forEach((run, ri) => {
                        if (
                          data.success.filter(
                            r => r.id === runData.weeks[wi].runs[ri].id
                          ).length > 0
                        ) {
                          runData.weeks[wi].runs[ri] = data.success.filter(
                            r => r.id === runData.weeks[wi].runs[ri].id
                          )[0]
                        }
                      })
                    })
                    setRankData(runData)
                    setDetailsLoading(false)
                  }
                })
            } else {
              setDetailsLoading(false)
            }
          }
        }
      )
    }

    return () => {
      if (typeof listenerRankings === 'function') {
        listenerRankings()
      }
    }
  }, [settings, overlayLoading, leagues])

  const getStat = param => {
    let total = 0
    rankData.weeks.forEach((week, wi) => {
      rankData.weeks[wi].runs.forEach((run, ri) => {
        total = total + run[param]
      })
    })
    return `${total}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  }

  const capitalizeFirstLetter = string => {
    return string.charAt(0).toUpperCase() + string.slice(1)
  }

  return settings && settings.ranks ? (
    <CSSTransition
      nodeRef={overlayRankingsRef}
      in={settings.ranks.show}
      timeout={0}
      classNames="animate-overlay-rank">
      <div
        ref={overlayRankingsRef}
        className={
          'overlay-rank' +
          (settings && settings.ranks && settings.ranks.align === 'left'
            ? ' overlay-rank-left'
            : ' overlay-rank-right') +
          (settings.ranks.show ? ' animate-overlay-rank-enter-done' : '')
        }
        style={{
          top:
            settings.ranks && typeof settings.ranks.offset !== 'undefined'
              ? settings.ranks.offset
              : 200
        }}>
        <div className="overlay-rank-inner">
          <div
            className="overlay-rank-top"
            style={{
              backgroundImage: `url(${username.profile_image_url})`
            }}>
            <div className="overlay-rank-top-inner">
              <div className="overlay-rank-top-inner-inner">
                <span>{username.display_name}</span>
                <img src={FenceLogo} />
              </div>
            </div>
          </div>
          <div className="overlay-league-rank">
            <div className="overlay-league-rank-content">
              <div className="rank-badge">
                <div className="badge-outer badge-yellow badge-48">
                  <div className="badge-inner"></div>
                  <div className="badge-rank">
                    <span>{rank.rank}</span>
                  </div>
                </div>
              </div>
              <span className="overlay-league-text">
                <span className="overlay-league-username">
                  {capitalizeFirstLetter(
                    lastLeague === 'scav' ? lastLeague : `${lastLeague}s`
                  )}{' '}
                  League
                </span>
                <span className="overlay-league-tier league-yellow">
                  Yellow Tier
                </span>
              </span>
            </div>
          </div>
          <div className="overlay-league-rank-season">
            <div className="overlay-league-rank-season-inner">
              <span className="season-title">Season {leagueSeason}</span>
              <div className="season-points">
                <span className="season-points-label">Points</span>
                <span className="season-points-value">{rank.points}</span>
              </div>
              <div className="season-data">
                {detailsLoading ? (
                  <div className="loader-centered">
                    <CircularProgress
                      style={{
                        color: '#7ab903',
                        height: 75,
                        width: 75
                      }}
                    />
                  </div>
                ) : (
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <span className="season-data-label">Roubles</span>
                      <span className="season-data-value">
                        {getStat('costItems')}
                      </span>
                    </Grid>
                    <Grid item xs={6}>
                      <span className="season-data-label">PMCs</span>
                      <span className="season-data-value">
                        {getStat('killsPmc')}
                      </span>
                    </Grid>
                    <Grid item xs={6}>
                      <span className="season-data-label">Bonus Items</span>
                      <span className="season-data-value">
                        {getStat('bonusItems')}
                      </span>
                    </Grid>
                    <Grid item xs={6}>
                      <span className="season-data-label">Raiders</span>
                      <span className="season-data-value">
                        {getStat('killsRogue')}
                      </span>
                    </Grid>
                    <Grid item xs={6}>
                      <span className="season-data-label">XP</span>
                      <span className="season-data-value">{getStat('xp')}</span>
                    </Grid>
                    <Grid item xs={6}>
                      <span className="season-data-label">Damage</span>
                      <span className="season-data-value">
                        {getStat('damage')}
                      </span>
                    </Grid>
                  </Grid>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </CSSTransition>
  ) : (
    <div></div>
  )
}

export default OverlayRank
