import React from 'react'
import GoonsSettings from 'components/GoonsSettings'
import ChatbotSettings from 'components/ChatbotSettings'
import OverlaySettings from 'components/OverlaySettings'
import LeagueSettings from 'components/LeagueSettings'
import RankSettings from 'components/RankSettings'
import ReferralSettings from 'components/ReferralSettings'
import KickSettings from 'components/KickSettings'
import StreamerChallengeSettings from 'components/StreamerChallengeSettings'

const Dashboard = ({ content }) => {
  return (
    <>
      <OverlaySettings />
      <ChatbotSettings />
      <ReferralSettings />
      <KickSettings />
      {/* <StreamerChallengeSettings /> */}
      <GoonsSettings />
      <LeagueSettings />
      <RankSettings />
    </>
  )
}

export default Dashboard
