import React, { useState, useEffect, useCallback } from 'react'
import {
  EditorState,
  ContentState,
  convertFromHTML,
  convertToRaw,
  convertFromRaw
} from 'draft-js'
import { Editor } from 'react-draft-wysiwyg'
import { useParams } from 'react-router-dom'
import {
  useUserIsOwner,
  useUserIsAdmin,
  useUserIsEditor,
  useAppIsLoggedIn
} from 'hooks'
import { Button, CircularProgress } from '@mui/material'
import NewsService from 'services/NewsService'
import TimeAgo from 'react-timeago'
import FormControl from '@mui/material/FormControl'
import TextField from '@mui/material/TextField'
import NewsThumb from '../assets/img/news-thumb.jpg'
import Username from 'components/Username'
import CommentDisplay from 'components/Comments/CommentDisplay'
import { UserServiceApi } from 'api/UserServiceApi'
import SubmitComment from 'components/Comments/SubmitComment'
import UsernameAvatar from 'components/UsernameDisplay/UsernameAvatar'
import UsernameStreamLink from 'components/UsernameDisplay/UsernameStreamLink'

const NewsStory = () => {
  const isLoggedIn = useAppIsLoggedIn()
  const isOwner = useUserIsOwner()
  const isAdmin = useUserIsAdmin()
  const isEditor = useUserIsEditor()
  const match = useParams()
  const [storyLoading, setStoryLoading] = useState(true)
  const [isEditing, setIsEditing] = useState(false)
  const [titleState, setTitleState] = useState(
    EditorState.createWithContent(
      ContentState.createFromBlockArray(
        convertFromHTML('<h1>This is my title</h1>')
      )
    )
  )
  const [contentState, setContentState] = useState(
    EditorState.createWithContent(
      ContentState.createFromBlockArray(
        convertFromHTML('<p>This is my story content.</p>')
      )
    )
  )
  const [storyProps, setStoryProps] = useState({})
  const [postLoading, setPostLoading] = React.useState(false)
  const [postCommentsLoading, setPostCommentsLoading] = React.useState(true)
  const [newsImage, setNewsImage] = React.useState(NewsThumb)
  const [storyComments, setStoryComments] = React.useState('')
  const [getUsername, { data: username }] =
    UserServiceApi.useLazyGetUsernameByIdCacheQuery()

  useEffect(() => {
    if (storyProps?.postedBy) {
      getUsername({ id: storyProps.postedBy }, true)
    }
  }, [getUsername, storyProps.postedBy])

  const onContentStateChange = contentState => {
    setContentState(contentState)
  }

  const onTitleStateChange = contentState => {
    setTitleState(contentState)
  }

  const handleImageChange = (e, v) => {
    setNewsImage(e.target.value)
  }

  const getNewsStory = useCallback(async () => {
    const response = await NewsService.getNewsStoryBySlug(match.id, result => {
      if (result && result.id) {
        setStoryProps({
          ...result,
          data: {}
        })
        JSON.parse(decodeURIComponent(result.content))
        setContentState(
          EditorState.createWithContent(
            convertFromRaw(JSON.parse(decodeURIComponent(result.content)))
          )
        )
        JSON.parse(decodeURIComponent(result.title))
        setTitleState(
          EditorState.createWithContent(
            convertFromRaw(JSON.parse(decodeURIComponent(result.title)))
          )
        )
        setNewsImage(result.image)
        setStoryLoading(false)
      }
    })
    return response
  }, [match.id])

  const getComments = useCallback(async () => {
    const response = await NewsService.getCommentsByPostId(
      storyProps.id,
      comments => {
        setStoryComments(comments)
        setPostCommentsLoading(false)
      }
    )
    return response
  }, [storyProps.id])

  useEffect(() => {
    if (match && match.id) {
      const story = getNewsStory()
      return () => {
        story.then(response => (response ? response.unsubscribe() : null))
      }
    }
  }, [getNewsStory, match])

  useEffect(() => {
    if (storyProps?.id) {
      const comments = getComments()
      return () => {
        comments.then(response => (response ? response.unsubscribe() : null))
      }
    }
  }, [getComments, storyProps.id])

  const updateStory = () => {
    setPostLoading(true)
    NewsService.updateNewsStory(
      storyProps.id,
      JSON.stringify(convertToRaw(titleState.getCurrentContent())),
      JSON.stringify(convertToRaw(contentState.getCurrentContent())),
      newsImage,
      result => {
        setIsEditing(false)
        setPostLoading(false)
      }
    )
  }

  const unpublishStory = () => {
    setPostLoading(true)
    NewsService.unpublishNewsStory(storyProps.id, result => {
      setPostLoading(false)
      //alert success
    })
  }

  const publishStory = () => {
    setPostLoading(true)
    NewsService.publishNewsStory(storyProps.id, result => {
      setPostLoading(false)
      //alert success
    })
  }

  return (
    <>
      <div className="aside-header aside-news">
        <span className="news">Latest News</span>
      </div>
      {storyLoading ? (
        <div className="loader-centered news-loader">
          <CircularProgress
            style={{
              color: '#7ab903',
              height: 75,
              width: 75
            }}
          />
        </div>
      ) : (
        <div>
          {(isAdmin || isOwner || isEditor) && !isEditing && (
            <div className="news-controls">
              <Button
                className="btn btn-primary"
                onClick={e => {
                  e.preventDefault()
                  setIsEditing(true)
                }}>
                Edit Story
              </Button>
              {storyProps.published !== true ? (
                <Button
                  className="btn btn-primary"
                  onClick={e => {
                    e.preventDefault()
                    publishStory()
                  }}>
                  Publish
                </Button>
              ) : (
                <Button
                  className="btn btn-primary btn-cancel"
                  onClick={e => {
                    e.preventDefault()
                    unpublishStory()
                  }}>
                  Unpublish
                </Button>
              )}
            </div>
          )}
          <div className="content-panel">
            <div>
              {isEditing ? (
                <div>
                  <span className="subhead">News Story Title</span>
                  <Editor
                    wrapperClassName="news-wrapper"
                    editorClassName="news-editor title-editor"
                    toolbarClassName="hide-toolbar"
                    editorState={titleState}
                    onEditorStateChange={onTitleStateChange}
                  />
                  <span className="subhead">News Story Image</span>
                  <FormControl className="input-news-image">
                    <TextField
                      id="news-story-image"
                      onChange={(e, v) => {
                        e.persist()
                        handleImageChange(e, v)
                      }}
                      value={newsImage}
                      placeholder="ex. 0"
                      variant="outlined"
                    />
                  </FormControl>
                  <span className="subhead">News Story Content</span>
                  <Editor
                    wrapperClassName="news-wrapper"
                    editorClassName="news-editor"
                    editorState={contentState}
                    onEditorStateChange={onContentStateChange}
                  />
                </div>
              ) : (
                <div className="news-nav news-story">
                  <div className="story">
                    <div className="news-text">
                      <Editor
                        editorClassName="news-viewer title-viewer"
                        toolbarClassName="hide-toolbar"
                        editorState={titleState}
                        readOnly={true}
                      />
                      <div className="news-published">
                        <span className="date">
                          {storyProps.published === true ? (
                            <span>
                              <TimeAgo
                                date={storyProps.createdAt.seconds * 1000}
                              />
                            </span>
                          ) : (
                            <span>Not published</span>
                          )}
                        </span>
                        <div className="news-user">
                          <UsernameStreamLink username={username}>
                            <UsernameAvatar
                              username={username}
                              className={'avatar'}
                            />

                            <span>
                              <Username username={username} />
                            </span>
                          </UsernameStreamLink>
                        </div>
                      </div>
                      <div className="news-image">
                        <div
                          className="news-image-inner"
                          style={{
                            backgroundImage: `url(${storyProps.image})`
                          }}></div>
                      </div>
                      <Editor
                        editorClassName="news-viewer"
                        toolbarClassName="hide-toolbar"
                        editorState={contentState}
                        readOnly={true}
                      />
                      <div className="news-comments">
                        {postCommentsLoading ? (
                          <div className="loader-centered">
                            <CircularProgress
                              style={{
                                color: '#7ab903',
                                height: 55,
                                width: 55,
                                marginRight: 10
                              }}
                            />
                          </div>
                        ) : storyComments.length === 0 ? (
                          <div className="no-competitors">
                            Be the first to post a commment.
                          </div>
                        ) : (
                          <div className="story-comments">
                            {storyComments.map((comment, i) => (
                              <CommentDisplay comment={comment} i={i} key={i} />
                            ))}
                          </div>
                        )}

                        <div className="post-comment">
                          {isLoggedIn ? (
                            <SubmitComment storyProps={storyProps} />
                          ) : (
                            <div className="no-competitors">
                              Please login to post a comment.
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
            {isEditing && (
              <div className="news-buttons">
                <Button
                  className="btn btn-primary btn-cancel"
                  disabled={postLoading}
                  onClick={e => {
                    e.preventDefault()
                    setIsEditing(false)
                  }}>
                  {postLoading && (
                    <CircularProgress
                      style={{
                        color: '#000',
                        height: 15,
                        width: 15,
                        marginRight: 10
                      }}
                    />
                  )}
                  Cancel
                </Button>
                <Button
                  className="btn btn-primary"
                  disabled={false}
                  onClick={e => {
                    e.preventDefault()
                    updateStory()
                  }}>
                  {postLoading && (
                    <CircularProgress
                      style={{
                        color: '#000',
                        height: 15,
                        width: 15,
                        marginRight: 10
                      }}
                    />
                  )}
                  Save
                </Button>
              </div>
            )}
          </div>
        </div>
      )}
    </>
  )
}

export default NewsStory
