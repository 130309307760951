import { CircularProgress } from '@mui/material'

const TeamName = ({ team }) => {
  return team?.display_name ? (
    <span>{team?.display_name}</span>
  ) : (
    <div>
      <CircularProgress
        style={{
          color: '#7ab903',
          height: 20,
          width: 20
        }}
      />
    </div>
  )
}
export default TeamName
