import React, { useEffect } from 'react'
import Card from './Card'

import Grid from '@mui/material/Grid'
import CashApp from './CashApp'

const CardsList = ({
  cards = [],
  paymentMethod,
  paymentOnChange,
  defaultMethod,
  options = {},
  actions,
  grid,
  subscribed
}) => {
  useEffect(() => {
    if (cards.length > 0 && typeof paymentOnChange === 'function') {
      let payments = [...cards]
      paymentOnChange(payments.sort((a, b) => b.created - a.created)[0].id)
    }
  }, [cards])

  return (
    <React.Fragment>
      {cards.length === 0 && (
        <span className="disclaimer">
          You have no payment options available, please add a new payment
          method.
        </span>
      )}
      <Grid container spacing={2} mt={'10px'}>
        {cards.map((card, key) => (
          <React.Fragment key={key}>
            {card.type === 'card' && (
              <Card
                card={card}
                paymentMethod={paymentMethod}
                paymentOnChange={paymentOnChange}
                defaultMethod={defaultMethod}
                options={options}
                actions={actions}
                grid={grid}
                subscribed={subscribed}
              />
            )}
            {card.type === 'cashapp' && (
              <CashApp
                card={card}
                paymentMethod={paymentMethod}
                paymentOnChange={paymentOnChange}
                defaultMethod={defaultMethod}
                options={options}
                actions={actions}
                grid={grid}
                subscribed={subscribed}
              />
            )}
          </React.Fragment>
        ))}
      </Grid>
    </React.Fragment>
  )
}

export default CardsList
