import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { auth } from 'configs/firebaseConfig'
import { getHostFunctions } from 'utils/GetHost'

const base = getHostFunctions()

const getUserToken = async () => {
  const token = await auth.currentUser?.getIdToken()
  return token
}

export const UserServiceApi = createApi({
  reducerPath: 'UserService',
  baseQuery: fetchBaseQuery({
    baseUrl: `${base}`,
    prepareHeaders: async headers => {
      headers.set('Content-Type', 'application/json')
      const token = await getUserToken()
      if (token) headers.set('Authorization', `Bearer ${token}`)
      return headers
    }
  }),
  tagTypes: ['Usernames', 'Username'],
  endpoints: build => ({
    getMultipleUsers: build.mutation({
      query: ({ usernames }) => ({
        url: `/getMultipleUsers?query=${JSON.stringify(usernames)}`
      }),
      transformResponse: response => response.users
    }),
    getMultipleUsersById: build.mutation({
      query: ({ usernames }) => ({
        url: `/getMultipleUsersById?query=${JSON.stringify(usernames)}`
      }),
      transformResponse: response => response.users
    }),
    getUsernameByUsername: build.mutation({
      query: ({ username }) => ({
        url: `/getUsername?query=${username}}`
      }),
      transformResponse: response => response.users
    }),
    getUsernameById: build.mutation({
      query: ({ id }) => ({
        url: `/getUsernameById?query=${id}`
      }),
      transformResponse: response => response.users
    }),
    getUsernameByIdCache: build.query({
      query: ({ id }) => ({
        url: `/getUsernameById?query=${id}`
      }),
      transformResponse: response => response.users,
      providesTags: result =>
        result
          ? [{ type: 'Username', id: result?.login }, 'Username']
          : ['Username']
    }),
    getMultipleUsersByIdCache: build.query({
      query: ({ usernames }) => ({
        url: `/getMultipleUsersById?query=${JSON.stringify(usernames)}`
      }),
      transformResponse: response => response.users,
      providesTags: result =>
        result
          ? [
              ...result.map(user => ({ type: 'Usernames', id: user?.login })),
              'Usernames'
            ]
          : ['Usernames']
    }),
    searchUsername: build.mutation({
      query: ({ username, exclude }) => ({
        url: `/searchUsername`,
        params: { query: username }
      }),
      transformResponse: response => response.users
    }),
    getUserList: build.query({
      query: ({ last, exclude }) => ({
        url: `/getUserList`,
        params: { last, exclude }
      }),
      transformResponse: response => response.users,
      providesTags: result =>
        result
          ? [
              ...result?.data?.map(user => ({
                type: 'Usernames',
                id: user?.login
              })),
              'Usernames'
            ]
          : ['Usernames']
    })
  })
})
