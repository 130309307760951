import React from 'react'
import { useAppIsLoggedIn } from 'hooks'
import Banner from 'components/Banner'
import { Link } from 'react-router-dom'

const Careers = () => {
  const isLoggedIn = useAppIsLoggedIn()
  return (
    <>
      <Banner />
      <div className="aside-header aside-leagues">
        <span className="admin">Careers and Volunteer Opportunities</span>
      </div>
      <div className="content-panel">
        <span className="paragraph">
          We do not have any paid positions open at this time. However, if you
          are looking to get involved we have the following volunteer
          opportunities available. These positions are great ways to give back
          to the Tarkov community while promoting yourself and your skills.
          Please <Link to="/contact">contact us</Link> if you would like to
          learn more.
        </span>
        <ul>
          <li>Volunteer Writer</li>
          <li>Volunteer League Admin</li>
          <li>Volunteer Event Caster</li>
          <li>Volunteer Social Media Manager</li>
          <li>Volunteer Discord Moderators</li>
        </ul>
        <span className="paragraph">Updated December 2022</span>
        <span className="paragraph">&mdash; Management Team</span>
      </div>
    </>
  )
}

export default Careers
