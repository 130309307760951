import React from 'react'
import PlayerLink from './PlayerLink'

const EventMVP = () => {
  return (
    <div className="events-box events-mvp">
      <div className="events-box-header">
        <span className="events-title">Fence MVP Award</span>
      </div>
      <div className="events-mvp-content">
        <div
          className="events-mvp-image"
          style={{
            background:
              'url(/assets/img/events/2023/oct/twitchcon-streamer-tournament/emm3d-mvp.jpg) no-repeat center center'
          }}></div>
        <div className="events-mvp-info">
          <div className="events-mvp-user">
            <PlayerLink player={33850} />
          </div>
          <div className="events-mvp-chart">
            <div className="events-mvp-candles">
              <div className="events-mvp-avg">
                <span>Avg.</span>
              </div>
              <div className="events-mvp-candle">
                <div
                  className="events-mvp-candle-inner"
                  style={{ height: '100%' }}>
                  <span className="events-mvp-candle-score">1.29</span>
                </div>
                <span className="events-mvp-candle-label">KPR</span>
              </div>
              <div className="events-mvp-candle">
                <div
                  className="events-mvp-candle-inner"
                  style={{ height: '100%' }}>
                  <span className="events-mvp-candle-score">84%</span>
                </div>
                <span className="events-mvp-candle-label">KAS</span>
              </div>
              <div className="events-mvp-candle">
                <div
                  className="events-mvp-candle-inner"
                  style={{ height: '91%' }}>
                  <span className="events-mvp-candle-score">33%</span>
                </div>
                <span className="events-mvp-candle-label">SR</span>
              </div>
              <div className="events-mvp-candle">
                <div
                  className="events-mvp-candle-inner"
                  style={{ height: '88.5%' }}>
                  <span className="events-mvp-candle-score">11</span>
                </div>
                <span className="events-mvp-candle-label">ENT</span>
              </div>
              <div className="events-mvp-candle">
                <div
                  className="events-mvp-candle-inner"
                  style={{ height: '75%' }}>
                  <span className="events-mvp-candle-score">0</span>
                </div>
                <span className="events-mvp-candle-label">CAP</span>
              </div>
              <div className="events-mvp-candle events-mvp-candle-rating">
                <div
                  className="events-mvp-candle-inner"
                  style={{ height: '100%' }}>
                  <span className="events-mvp-candle-score">1.26</span>
                </div>
                <span className="events-mvp-candle-label">RATING</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default EventMVP
