import { useState } from 'react'
import { StripeApi } from 'api/Stripe'
import {
  Grid,
  TextField,
  Button,
  Typography,
  Box,
  Modal,
  CircularProgress
} from '@mui/material'
import { AddCard as AddCardIcon } from '@mui/icons-material'
import { stripeAmountFormat } from 'utils/Stripe'

const TopUp = () => {
  const [isConfirm, setIsConfirm] = useState(false)
  const [isSubmit, setIsSubmit] = useState(false)
  const [amount, setAmount] = useState('')
  const [description, setDescription] = useState('')
  const [stripeAmount, setStripeAmount] = useState(0)
  const [topUp] = StripeApi.useCreateTopUpMutation()

  const submitHandler = e => {
    e.preventDefault()
    setIsConfirm(true)
  }

  const handleClose = () => {
    setIsConfirm(false)
  }

  const setAmountField = e => {
    const { value } = e.target
    setAmount(value)
    setStripeAmount(stripeAmountFormat(value))
  }

  const setDescriptionField = e => {
    const { value } = e.target
    setDescription(value)
  }

  const confirmTopUp = async () => {
    setIsSubmit(true)
    await topUp({ amount: stripeAmount, description })
    setIsSubmit(false)
    setIsConfirm(false)
    setAmount('')
    setDescription('')
    setStripeAmount(0)
  }

  return (
    <Box mt={'20px'} mb={'20px'}>
      <form onSubmit={submitHandler}>
        <Typography mb={'10px'}>Top Up Balance</Typography>
        <Grid container spacing={2} alignItems={'center'}>
          <Grid item md={4}>
            <TextField
              name={'amount'}
              value={amount}
              label={'Amount'}
              onChange={setAmountField}
            />
          </Grid>
          <Grid item md={8}>
            <TextField
              name={'description'}
              value={description}
              label={'Description'}
              onChange={setDescriptionField}
            />
          </Grid>
          <Grid item md={12}>
            <Button variant="contained" type="submit">
              Top Up
            </Button>
          </Grid>
        </Grid>
      </form>
      <Modal open={isConfirm} onClose={handleClose}>
        <Box className="run-modal">
          <Box className="aside-header">
            <Typography
              className="aside-header"
              id="card-modal-title"
              variant="h6"
              component="span">
              <AddCardIcon /> Confirm Top Up
            </Typography>
          </Box>
          <Box className="modal-content">
            <Typography>
              Are you sure you want to add ${amount} to stripe?
            </Typography>
            <Box textAlign={'right'} paddingBottom={'10px'}>
              <Button
                variant="contained"
                color="error"
                onClick={handleClose}
                sx={{ marginRight: '5px' }}>
                Cancel
              </Button>
              <Button
                variant="contained"
                onClick={confirmTopUp}
                disabled={isSubmit}>
                {isSubmit && (
                  <CircularProgress
                    style={{
                      color: '#000',
                      height: 20,
                      width: 20,
                      marginRight: 10
                    }}
                  />
                )}
                {isSubmit ? <span>Loading...</span> : <span>Confirm</span>}
              </Button>
            </Box>
          </Box>
        </Box>
      </Modal>
    </Box>
  )
}

export default TopUp
