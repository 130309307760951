import React, { useState, useEffect } from 'react'
import SubscribeForm from './Stripe/subscribe/SubscribeForm'
import { NavLink, useNavigate } from 'react-router-dom'

import { useUserData } from 'hooks'
import { useUserStripe, useUserSubscription } from 'hooks/use-user-data'
import { Alert } from '@mui/material'
import { StripeApi } from 'api/Stripe'

const SubscribeComponent = ({ newCard }) => {
  const user = useUserData()
  const subscription = useUserSubscription()
  const stripe = useUserStripe()
  const { data } = StripeApi.useGetSubscriptionStatusQuery({
    customerId: stripe?.customer,
    subscriptionId: subscription?.id
  })
  const navigate = useNavigate()
  const [showingAlert, setShowingAlert] = useState(true)

  useEffect(() => {
    if (subscription?.status === 'active') {
      navigate('/account')
    }
  }, [subscription, navigate])

  useEffect(() => {
    if (newCard === true) {
      setTimeout(() => {
        setShowingAlert(false)
      }, 3000)
    }
  }, [newCard])

  return (
    <div>
      <span className="paragraph">
        Subscribe using the form below to get Fence.gg Premium for $8.99 per
        month. You can cancel your subscription at any time. View your
        transaction history and manage your payment options on the{' '}
        <NavLink to={'/account'}>account page</NavLink>.
      </span>
      {(typeof subscription === 'undefined' ||
        (subscription && subscription.status === 'canceled')) && (
        <div>
          {newCard && showingAlert && (
            <Alert severity="success" sx={{ marginBottom: '10px' }}>
              Your payment method was successfully added.
            </Alert>
          )}
          <SubscribeForm user={user} />
        </div>
      )}
      {data?.subscription?.id &&
        data?.subscription.status !== 'active' &&
        data?.paymentStatus.status !== 'succeeded' && (
          <Alert color={'error'}>
            There was an issue completing the payment for your subscription.
            Please visit your <NavLink to={'/account'}>account page</NavLink>{' '}
            and review your{' '}
            <a
              href={data?.invoice?.hosted_invoice_url}
              rel="noreferrer"
              target="_blank">
              latest invoice
            </a>
            .
          </Alert>
        )}
    </div>
  )
}

export default SubscribeComponent
