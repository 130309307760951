import { useDispatch } from 'react-redux'

import IconInfo from '../../../assets/img/icon-info.png'
import { setRankingsModal, setRankingsModalLeague } from 'store/slice/appSlice'
import RankDisplay from './RankDisplay'

const DuoRank = ({ rank, league }) => {
  const dispatch = useDispatch()

  return (
    <div className="duo-partners">
      {rank?.members.map((member, mi) => (
        <div className="duo-partner" key={mi}>
          <RankDisplay rank={rank} uid={member} />
          {rank[member] > 0 && (
            <div
              className="duo-partner-points"
              onClick={() => {
                dispatch(setRankingsModal(member))
                dispatch(setRankingsModalLeague(league.id))
              }}>
              {rank[member]} <span>PTS</span>{' '}
              <img src={IconInfo} alt="Info Icon" />
            </div>
          )}
        </div>
      ))}
    </div>
  )
}

export default DuoRank
