import moment from 'moment'
import React from 'react'
import { Link } from 'react-router-dom'
import TeamAvatar from './TeamAvatar'
import TeamName from './TeamName'
import CenteredLoader from './CenteredLoader'

const EventsResults = ({ tournament, matches, teams }) => {
  const getMatchDays = () => {
    let days = []
    let m = [...matches]
    m.sort((a, b) => b.starts._seconds - a.starts._seconds).forEach(match => {
      let day = moment(match.starts._seconds * 1000).format('MMMM DD, YYYY')
      if (!days.includes(day)) {
        days.push(day)
      }
    })
    return days
  }

  return (
    <div className="events-box events-standings">
      <div className="events-box-header">
        <span className="events-title">Event Results</span>
      </div>

      {matches?.length > 0 ? (
        <div>
          {getMatchDays().map(day => {
            return (
              <div key={day}>
                <div className="event-results-header">
                  <span className="event-results-date">{day}</span>
                </div>
                {[...matches]
                  .sort((a, b) => b.starts._seconds - a.starts._seconds)
                  .filter(
                    m =>
                      moment(m.starts._seconds * 1000).format(
                        'MMMM DD, YYYY'
                      ) === day
                  )
                  .map(match => {
                    return (
                      <div key={match?.id} className="event-results-row">
                        <Link
                          to={`/match/${teams
                            ?.filter(t => t.id === match?.teams[0]?.id)[0]
                            ?.display_name?.replace(/ /g, '-')
                            .toLowerCase()}-vs-${teams
                            ?.filter(t => t.id === match?.teams[1]?.id)[0]
                            ?.display_name?.replace(/ /g, '-')
                            .toLowerCase()}-${tournament?.display_name
                            ?.replace(/ /g, '-')
                            .toLowerCase()}/${match?.id}`}>
                          <span
                            className={`event-results-label-team-one ${
                              match?.teams[0]?.winner === true
                                ? ''
                                : 'event-team-loser'
                            }`}>
                            <TeamAvatar
                              team={
                                teams?.filter(
                                  t => t.id === match?.teams[0]?.id
                                )[0]
                              }
                            />
                            <TeamName
                              team={
                                teams?.filter(
                                  t => t.id === match?.teams[0]?.id
                                )[0]
                              }
                            />
                          </span>
                          <span className="event-results-label">
                            <span
                              className={
                                match?.teams[0]?.winner === true
                                  ? 'event-results-winner'
                                  : 'event-results-loser'
                              }>
                              {match?.teams[0]?.score}
                            </span>
                            -
                            <span
                              className={
                                match?.teams[1]?.winner === true
                                  ? 'event-results-winner'
                                  : 'event-results-loser'
                              }>
                              {match?.teams[1]?.score}
                            </span>
                          </span>
                          <span
                            className={`event-results-label-team-two ${
                              match?.teams[1]?.winner === true
                                ? ''
                                : 'event-team-loser'
                            }`}>
                            <TeamName
                              team={
                                teams?.filter(
                                  t => t.id === match?.teams[1]?.id
                                )[0]
                              }
                            />
                            <TeamAvatar
                              team={
                                teams?.filter(
                                  t => t.id === match?.teams[1]?.id
                                )[0]
                              }
                            />
                          </span>
                          <span className="event-results-label">
                            BO{match?.bestOf}
                          </span>
                        </Link>
                      </div>
                    )
                  })}
              </div>
            )
          })}
        </div>
      ) : (
        <CenteredLoader />
      )}
    </div>
  )
}

export default EventsResults
