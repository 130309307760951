import React, { useState } from 'react'
import { Modal, Box, Typography, Button, CircularProgress } from '@mui/material'
import CreditCardIcon from '@mui/icons-material/CreditCard'
import CardsList from 'components/Stripe/cards/CardsList'
import { StripeApi } from 'api/Stripe'

const SubscriptionChangeCard = ({
  isCardChangeOpen,
  handleCardChangeClose,
  customerId,
  subscriptionId,
  currentDefault = ''
}) => {
  const { data: cards = [] } = StripeApi.useGetCustomerCardsQuery({
    customerId
  })
  const [updateSubscription] = StripeApi.useUpdateSubscriptionMutation()
  const [paymentMethod, setPaymentMethod] = useState(currentDefault)
  const [isLoading, setIsLoading] = useState(false)

  const paymentOnChange = paymentId => {
    setPaymentMethod(paymentId)
  }

  const updateSubscriptionDefaultPayment = event => {
    event.preventDefault()
    setIsLoading(true)
    const subscriptionData = {
      default_payment_method: paymentMethod
    }
    updateSubscription({ subscriptionId, subscriptionData }).then(() => {
      setIsLoading(false)
      handleCardChangeClose()
    })
  }

  const options = {
    hideNew: true
  }

  return (
    <Modal open={isCardChangeOpen} onClose={handleCardChangeClose}>
      <Box className="run-modal">
        <Box className="aside-header">
          <Typography
            className="aside-header"
            id="default-card-modal-title"
            variant="h6"
            component="span">
            <CreditCardIcon /> Change Subscription Payment Method
          </Typography>
        </Box>
        <Box className="modal-content">
          <CardsList
            cards={cards}
            paymentMethod={paymentMethod}
            paymentOnChange={paymentOnChange}
            options={options}
          />
          <Box className="modal-controls" sx={{ marginTop: '8px' }}>
            <Button
              variant="contained"
              color="info"
              onClick={handleCardChangeClose}>
              Close
            </Button>
            <Button
              variant="contained"
              disabled={isLoading}
              onClick={updateSubscriptionDefaultPayment}
              sx={{ marginLeft: '10px' }}>
              {isLoading && (
                <CircularProgress
                  style={{
                    color: '#000',
                    height: 20,
                    width: 20,
                    marginRight: 10
                  }}
                />
              )}
              {isLoading ? <span>Saving...</span> : <span>Save</span>}
            </Button>
          </Box>
        </Box>
      </Box>
    </Modal>
  )
}

export default SubscriptionChangeCard
