import React, { useEffect, useState, useRef, createRef } from 'react'
import { Link } from 'react-router-dom'
import { styled } from '@mui/material/styles'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import Box from '@mui/material/Box'
import ListItemText from '@mui/material/ListItemText'
import ListItemAvatar from '@mui/material/ListItemAvatar'
import Avatar from '@mui/material/Avatar'
import Typography from '@mui/material/Typography'
import Paper from '@mui/material/Paper'
import FormControl from '@mui/material/FormControl'
import FormControlLabel from '@mui/material/FormControlLabel'
import Switch from '@mui/material/Switch'
import TextField from '@mui/material/TextField'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import CircularProgress from '@mui/material/CircularProgress'
import Tooltip from '@mui/material/Tooltip'
import Slider from '@mui/material/Slider'
import Grid from '@mui/material/Grid'
import MuiInput from '@mui/material/Input'
import Button from '@mui/material/Button'
import VolumeUp from '@mui/icons-material/VolumeUp'

import ModuleService from 'services/ModuleService'
import QuestionService from 'services/QuestionService'
import { useAppModules, useAppSettings, useUser } from 'hooks'
import { getHostDomain } from 'utils/GetHost'
import SettingsService from 'services/SettingsService'
import { LeagueServiceApi } from 'api/LeagueServiceApi'

const Input = styled(MuiInput)`
  width: 60px;
`

const StyledButton = styled(Button)(({ theme }) => ({
  border: '1px solid',
  padding: '10px 20px'
}))

const serviceKey = {
  interview: {
    service: QuestionService,
    playNext: QuestionService.nextQuestion,
    reset: QuestionService.resetShown
  }
}

const Modules = () => {
  const mods = useAppModules()
  const user = useUser()
  const appSettings = useAppSettings()
  const [channelUsername, setChannelUsername] = useState('')
  const [usernameError, setUsernameError] = useState(false)
  const [reCalcLoading, setReCalcLoading] = useState(false)
  const [testDuoRanksLoading, setTestDuoRanksLoading] = useState(false)
  const [eventStatsLoading, setEventStatsLoading] = useState(false)
  const [badgesLoading, setBadgesLoading] = useState(false)
  const [maintenanceLoading, setMaintenanceLoading] = useState(false)
  const [copyOpened, setCopyOpened] = useState(false)
  const modsOverlayRef = useRef(mods.map(() => createRef()))
  const [reCalcScavRanksCall] = LeagueServiceApi.useReCalcScavRanksMutation()
  const [reCalcDuosRanksCall] = LeagueServiceApi.useReCalcDuosRanksMutation()
  const [issueBadgesCall] = LeagueServiceApi.useIssueBadgesMutation()
  const [testRanksCall] = LeagueServiceApi.useTestRanksMutation()
  const [testDuoRanksCall] = LeagueServiceApi.useTestDuoRanksMutation()
  const [volume, setVolume] = useState(() => {
    let volObj = {}
    mods.forEach(mod => {
      volObj[mod.id] = mod.volume
    })
    return volObj
  })

  useEffect(() => {
    let timer = null
    if (copyOpened) {
      timer = setTimeout(() => {
        setCopyOpened(false)
      }, 3000)
    }

    return () => clearTimeout(timer)
  }, [copyOpened])

  const reCalcScavRanks = async () => {
    setReCalcLoading(true)
    await reCalcScavRanksCall()
      .unwrap()
      .then(result => {
        setReCalcLoading(false)
      })
  }

  const reCalcDuosRanks = async () => {
    setReCalcLoading(true)
    await reCalcDuosRanksCall()
      .unwrap()
      .then(result => {
        setReCalcLoading(false)
      })
  }

  const testDuoRanks = async () => {
    setTestDuoRanksLoading(true)
    await testDuoRanksCall()
      .unwrap()
      .then(result => {
        setTestDuoRanksLoading(false)
      })
  }

  const issueBadges = async league => {
    setBadgesLoading(true)
    await issueBadgesCall({ league })
      .unwrap()
      .then(result => {
        setBadgesLoading(false)
      })
  }

  const testRanks = async () => {
    setReCalcLoading(true)
    await testRanksCall()
      .unwrap()
      .then(result => {
        setReCalcLoading(false)
      })
  }

  const toggleMaintenanceMode = () => {
    setMaintenanceLoading(true)
    SettingsService.toggleMaintenanceMode(result => {
      setMaintenanceLoading(false)
    })
  }

  const overwriteMatchStats = () => {
    setEventStatsLoading(true)
    SettingsService.overwriteMatchStats(result => {
      setEventStatsLoading(false)
    })
  }

  const overwriteEventStats = () => {
    setEventStatsLoading(true)
    SettingsService.overwriteEventStats(result => {
      setEventStatsLoading(false)
    })
  }

  return (
    <div>
      <div className="aside-header aside-modules">
        <span className="admin">Misc. Controls</span>
      </div>
      <div className="content-panel">
        <span className="heading-text">Maintenance Mode</span>
        <span className="paragraph">
          Turning this on will lock out everyone except for admins if there is a
          site issue.
        </span>
        <div className="ranking-controls">
          <Button
            className={
              'btn btn-primary' +
              (appSettings.maintenance === true ? ' btn-cancel' : '')
            }
            onClick={e => {
              e.preventDefault()
              toggleMaintenanceMode()
            }}>
            {maintenanceLoading && (
              <CircularProgress
                style={{
                  color: '#000',
                  height: 15,
                  width: 15,
                  marginRight: 10
                }}
              />
            )}
            {appSettings.maintenance === true ? 'Turn Off' : 'Turn On'}
          </Button>
        </div>
        <span className="heading-text">Event Stats</span>
        <span className="paragraph">Calculate Match Ratings.</span>
        <div className="ranking-controls">
          <Button
            className="btn btn-primary"
            onClick={e => {
              e.preventDefault()
              overwriteMatchStats()
            }}>
            {eventStatsLoading && (
              <CircularProgress
                style={{
                  color: '#000',
                  height: 15,
                  width: 15,
                  marginRight: 10
                }}
              />
            )}
            Calculate Match Ratings
          </Button>
          <Button
            style={{ marginLeft: 15 }}
            className="btn btn-primary"
            onClick={e => {
              e.preventDefault()
              overwriteEventStats()
            }}>
            {eventStatsLoading && (
              <CircularProgress
                style={{
                  color: '#000',
                  height: 15,
                  width: 15,
                  marginRight: 10
                }}
              />
            )}
            Calculate Event Ratings
          </Button>
        </div>
        <span className="heading-text">Scav Run Controls</span>
        <span className="paragraph">
          Recaculate the points for all approved scav runs for the current
          season. Issue badges for the currrent season. Test rankings script.
        </span>
        <div className="ranking-controls">
          <Button
            className="btn btn-primary"
            onClick={e => {
              e.preventDefault()
              reCalcScavRanks()
            }}>
            {reCalcLoading && (
              <CircularProgress
                style={{
                  color: '#000',
                  height: 15,
                  width: 15,
                  marginRight: 10
                }}
              />
            )}
            Recaculate Points
          </Button>
          <Button
            style={{ marginLeft: 15 }}
            className="btn btn-primary"
            onClick={e => {
              e.preventDefault()
              issueBadges('scav')
            }}>
            {badgesLoading && (
              <CircularProgress
                style={{
                  color: '#000',
                  height: 15,
                  width: 15,
                  marginRight: 10
                }}
              />
            )}
            Issue Badges
          </Button>
          <Button
            style={{ marginLeft: 15 }}
            className="btn btn-primary"
            onClick={e => {
              e.preventDefault()
              testRanks()
            }}>
            {reCalcLoading && (
              <CircularProgress
                style={{
                  color: '#000',
                  height: 15,
                  width: 15,
                  marginRight: 10
                }}
              />
            )}
            Test Ranks
          </Button>
        </div>
        <span className="heading-text">Solos League Controls</span>
        <span className="paragraph">Issue badges for the current season.</span>
        <div className="ranking-controls">
          <Button
            className="btn btn-primary"
            onClick={e => {
              e.preventDefault()
              issueBadges('solo')
            }}>
            {badgesLoading && (
              <CircularProgress
                style={{
                  color: '#000',
                  height: 15,
                  width: 15,
                  marginRight: 10
                }}
              />
            )}
            Issue Badges
          </Button>
        </div>
        <span className="heading-text">Duo Run Controls</span>
        <span className="paragraph">Test rankings forumla</span>
        <div className="ranking-controls">
          <Button
            className="btn btn-primary"
            style={{ marginRight: 15 }}
            onClick={e => {
              e.preventDefault()
              testDuoRanks()
            }}>
            {testDuoRanksLoading && (
              <CircularProgress
                style={{
                  color: '#000',
                  height: 15,
                  width: 15,
                  marginRight: 10
                }}
              />
            )}
            Test Ranks
          </Button>
          <Button
            className="btn btn-primary"
            onClick={e => {
              e.preventDefault()
              reCalcDuosRanks()
            }}>
            {reCalcLoading && (
              <CircularProgress
                style={{
                  color: '#000',
                  height: 15,
                  width: 15,
                  marginRight: 10
                }}
              />
            )}
            Recaculate Points
          </Button>
        </div>
        {/* <span className="heading-text">Legacy Site Modules</span>
        <span className="paragraph">
          Enable and disable site modules and configure these modules.
        </span> */}
        {/* <Paper variant="outlined">
          <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
            {mods.map((m, i) => {
              return (
                <ListItem
                  key={m.slug}
                  sx={{
                    display: 'block',
                    ['@media (max-width:768px)']: {
                      'padding': '20px',
                      'display': 'block',
                      'textAlign': 'center',
                      '& .MuiAvatar-circular': {
                        margin: '0 auto'
                      }
                    }
                  }}>
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <ListItemAvatar>
                      <Avatar alt={m.name} src="/static/images/avatar/1.jpg" />
                    </ListItemAvatar>
                    <ListItemText
                      primary={m.name}
                      secondary={<React.Fragment>{m.desc}</React.Fragment>}
                    />
                    <FormControlLabel
                      control={
                        <Switch
                          checked={m.enabled}
                          onChange={() => {
                            ModuleService.toggleMod(m.id, !m.enabled)
                          }}
                          inputProps={{ 'aria-label': 'controlled' }}
                        />
                      }
                      label={m.enabled ? 'Enabled' : 'Disabled'}
                    />
                  </Box>
                  <Box sx={{ padding: '30px 0 0' }}>
                    <FormControl
                      fullWidth
                      component="form"
                      sx={{ m: 1, position: 'relative' }}
                      variant="standard">
                      <Tooltip
                        placement="top"
                        arrow
                        open={copyOpened}
                        title="Copied!">
                        <ContentCopyIcon
                          sx={{
                            position: 'absolute',
                            top: 16,
                            right: 17,
                            cursor: 'pointer',
                            zIndex: 99
                          }}
                          onClick={() => {
                            setCopyOpened(true)
                            navigator.clipboard.writeText(
                              modsOverlayRef.current[i].current.value
                            )
                          }}
                        />
                      </Tooltip>
                      <TextField
                        id="outlined-read-only-input"
                        name={`${m.slug}Overlay`}
                        label={`${m.name} Overlay URL`}
                        inputRef={modsOverlayRef.current[i]}
                        value={`${getHostDomain()}/overlay/${m.overlayKey}`}
                        InputProps={{
                          readOnly: true
                        }}
                        onFocus={event => {
                          event.target.select()
                        }}
                      />
                    </FormControl>
                    <Box sx={{ textAlign: 'center' }}>
                      <Typography
                        variant="body2"
                        gutterBottom
                        component="div"
                        onClick={() => {
                          regenOverlayURL(m.id)
                        }}
                        sx={{
                          color: '#b72824',
                          textDecoration: 'underline',
                          cursor: 'pointer'
                        }}>
                        Regenerate Overlay URL
                      </Typography>
                    </Box>
                  </Box>
                  {m.slug === 'interview' && (
                    <Box>
                      <Box>
                        <Typography
                          variant="body2"
                          gutterBottom
                          component="div"
                          onClick={() => {
                            testShoutout()
                          }}
                          sx={{
                            color: '#b72824',
                            textDecoration: 'underline',
                            cursor: 'pointer',
                            margin: '10px 0 20px',
                            textAlign: 'center'
                          }}>
                          Test Shoutout
                        </Typography>
                      </Box>
                      <Box
                        component="form"
                        noValidate
                        autoComplete="off"
                        onSubmit={handleSubmit}>
                        <Box sx={{ mb: 2 }}>
                          <TextField
                            fullWidth
                            id="outlined-basic"
                            error={usernameError}
                            required
                            onChange={handleUsernameChange}
                            value={channelUsername}
                            label="Switch Chatbot Channels"
                            variant="outlined"
                          />
                        </Box>
                        <Box>
                          <StyledButton type="submit" style={{ width: '100%' }}>
                            <Typography
                              variant="body2"
                              component="span"
                              display="flex">
                              Switch Channel
                            </Typography>
                          </StyledButton>
                        </Box>
                      </Box>
                    </Box>
                  )}
                  <Box sx={{ maxWidth: 400, padding: '10px', display: 'none' }}>
                    <Typography id="input-slider" gutterBottom>
                      {m.name} TTS Volume
                    </Typography>
                    <Grid container spacing={2} alignItems="center">
                      <Grid item>
                        <VolumeUp />
                      </Grid>
                      <Grid item xs>
                        <Slider
                          value={
                            typeof volume[m.id] === 'number' ? volume[m.id] : 0
                          }
                          step={10}
                          marks
                          min={0}
                          max={100}
                          onChange={(e, v) => {
                            handleSliderChange(e, m.id, v)
                          }}
                          aria-labelledby="input-slider"
                        />
                      </Grid>
                      <Grid item>
                        <Input
                          value={volume[m.id]}
                          size="small"
                          onChange={e => {
                            handleInputChange(e, m.id)
                          }}
                          onBlur={handleBlur}
                          inputProps={{
                            'step': 10,
                            'min': 0,
                            'max': 100,
                            'type': 'number',
                            'aria-labelledby': 'input-slider'
                          }}
                        />
                      </Grid>
                    </Grid>
                  </Box>
                  {m.type === 'submissions' && (
                    <Box sx={{ textAlign: 'center' }}>
                      <Typography
                        variant="body2"
                        gutterBottom
                        component="div"
                        onClick={() => {
                          serviceKey[m.slug].playNext()
                        }}
                        sx={{
                          color: '#b72824',
                          textDecoration: 'underline',
                          cursor: 'pointer'
                        }}>
                        Play Next
                      </Typography>
                      <Typography
                        variant="body2"
                        gutterBottom
                        component="div"
                        onClick={() => {
                          serviceKey[m.slug].reset(m.showID)
                        }}
                        sx={{
                          color: '#b72824',
                          textDecoration: 'underline',
                          cursor: 'pointer'
                        }}>
                        Reset
                      </Typography>
                    </Box>
                  )}
                  {m.type === 'hideshow' && (
                    <Box sx={{ textAlign: 'center' }}>
                      <Typography
                        variant="body2"
                        gutterBottom
                        component="div"
                        onClick={() => {
                          serviceKey[m.slug].hideShow()
                        }}
                        sx={{
                          color: '#b72824',
                          textDecoration: 'underline',
                          cursor: 'pointer'
                        }}>
                        Hide/Show
                      </Typography>
                    </Box>
                  )}
                </ListItem>
              )
            })}
          </List>
        </Paper> */}
      </div>
    </div>
  )
}

export default Modules
