import React from 'react'

const CurrentLeagues = () => {
  return (
    <div>
      <div className="aside-header aside-leagues">
        <span className="leagues">Leagues</span>
      </div>
    </div>
  )
}

export default CurrentLeagues
