import { TableRow, TableCell } from '@mui/material'
import TrophyGold from '../../../assets/img/icon-trophy-gold.png'
import TrophySilver from '../../../assets/img/icon-trophy-silver.png'
import TrophyBronze from '../../../assets/img/icon-trophy-bronze.png'
import IconInfo from '../../../assets/img/icon-info.png'
import { useDispatch } from 'react-redux'
import Countdown from 'react-countdown'
import moment from 'moment'
import { setRankingsModal, setRankingsModalLeague } from 'store/slice/appSlice'
import DuoRank from './DuoRank'
import RankDisplay from './RankDisplay'

const LeagueUserRanked = ({ rank, league, tier, pendingRuns }) => {
  const dispatch = useDispatch()

  const rendererQualify = ({ hours, minutes, seconds }) => {
    const needsPad = value => {
      if (value > 9) {
        return false
      }
      return true
    }
    return (
      <span className="timer">
        {needsPad(hours) ? '0' + hours : hours}
        <span className="label">H</span>
        {needsPad(minutes) ? '0' + minutes : minutes}
        <span className="label">M</span>
        {needsPad(seconds) ? '0' + seconds : seconds}
        <span className="label">S</span>
      </span>
    )
  }

  const isQualifying = (uid, league) => {
    let result = '--'
    pendingRuns.forEach(run => {
      if (run.id === uid && run.league === league) {
        if (run.ended === true) {
          result = 'Ended...'
        } else {
          result = (
            <Countdown
              renderer={rendererQualify}
              overtime={true}
              date={moment(run.started.seconds * 1000).toDate()}
            />
          )
        }
      }
    })
    return result
  }

  return (
    <TableRow
      className="ranks-row"
      sx={{
        '&:last-child td, &:last-child th': { border: 0 }
      }}>
      <TableCell component="td" scope="row" align="left" width={18}>
        <div className="rankings-rank">
          <div className="rankings-trophy">
            {rank.rank === 1 ? (
              <img
                className="rankings-placement-trophy"
                src={TrophyGold}
                alt="Gold Trophy"
              />
            ) : rank.rank === 2 ? (
              <img
                className="rankings-placement-trophy"
                src={TrophySilver}
                alt="Silver Trophy"
              />
            ) : (
              rank.rank === 3 && (
                <img
                  className="rankings-placement-trophy"
                  src={TrophyBronze}
                  alt="Bronze Trophy"
                />
              )
            )}
          </div>
          <div className="rankings-badge">
            <div className="rank-badge">
              <div className={`badge-outer badge-${tier.id} badge-32`}>
                <div className="badge-inner"></div>
                <div className="badge-rank">
                  <span>
                    {rank.rank ? rank.rank : '--'}
                    {/* <sup>th</sup> */}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </TableCell>
      <TableCell>
        {league.id !== 'duo' ? (
          <RankDisplay rank={rank} uid={rank.uid} />
        ) : (
          <DuoRank rank={rank} league={league} />
        )}
      </TableCell>
      <TableCell align="center" width={90}>
        {isQualifying(rank.uid, league.id)}
      </TableCell>
      <TableCell align="right" className="rankings-points" width={110}>
        <div
          className="rankings-points-inner"
          style={{
            cursor: league.id !== 'duo' ? 'pointer' : 'default'
          }}
          onClick={() => {
            if (league.id !== 'duo') {
              dispatch(setRankingsModal(rank.uid))
              dispatch(setRankingsModalLeague(league.id))
            }
          }}>
          {parseFloat(rank.points).toFixed(2)} <span>PTS</span>
          {league.id !== 'duo' && <img src={IconInfo} alt="Info Icon" />}
        </div>
      </TableCell>
    </TableRow>
  )
}

export default LeagueUserRanked
