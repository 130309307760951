import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { getAuth } from 'firebase/auth'
import { getHostFunctions } from 'utils/GetHost'

const base = getHostFunctions()
const auth = getAuth()

const getUserToken = async () => {
  const token = await auth.currentUser?.getIdToken()
  return token
}

export const TournamentServiceApi = createApi({
  reducerPath: 'TournamentService',
  baseQuery: fetchBaseQuery({
    baseUrl: `${base}/`,
    prepareHeaders: async headers => {
      headers.set('Content-Type', 'application/json')
      const token = await getUserToken()
      if (token) headers.set('Authorization', `Bearer ${token}`)
      return headers
    }
  }),
  keepUnusedDataFor: 600,
  refetchOnFocus: false,
  tagTypes: [
    'Events',
    'Teams',
    'Matches',
    'Players',
    'Organizers',
    'ParticipantCount'
  ],
  endpoints: build => ({
    getEvents: build.query({
      query: () => ({
        url: `/tournaments-getTournamentEvents`
      }),
      transformResponse: result => result.events,
      providesTags: result =>
        result
          ? [
              ...result?.map(({ id }) => ({
                type: 'Events',
                id
              })),
              'Events'
            ]
          : ['Events']
    }),
    getTeamsByIds: build.query({
      query: ({ ids }) => ({
        url: `/tournaments-getTeamsByIds?ids=${ids}`
      }),
      transformResponse: result =>
        result?.teams?.length > 0 ? result.teams[0] : {},
      providesTags: result =>
        result
          ? [
              {
                type: 'Teams',
                id: result?.id
              },
              'Teams'
            ]
          : ['Teams']
    }),
    getOrganizersByIds: build.query({
      query: ({ ids }) => ({
        url: `/tournaments-getOrganizersByIds?ids=${ids}`
      }),
      transformResponse: result =>
        result?.organizers?.length > 0 ? result.organizers[0] : {},
      providesTags: result =>
        result
          ? [
              {
                type: 'Organizers',
                id: result?.id
              },
              'Organizers'
            ]
          : ['Organizers']
    }),
    getMatchesByIds: build.query({
      query: ({ ids }) => ({
        url: `/tournaments-getMatchesByIds?ids=${ids}`
      }),
      transformResponse: result =>
        result?.matches?.length > 0 ? result.matches[0] : {},
      providesTags: result =>
        result
          ? [
              {
                type: 'Matches',
                id: result?.id
              },
              'Matches'
            ]
          : ['Matches']
    }),
    getPlayersByIds: build.query({
      query: ({ ids }) => ({
        url: `/tournaments-getPlayersByIds?ids=${ids}`
      }),
      transformResponse: result =>
        result?.players?.length > 0 ? result.players[0] : {},
      providesTags: result =>
        result
          ? [
              {
                type: 'Players',
                id: result?.id
              },
              'Players'
            ]
          : ['Players']
    }),
    getEventsByIds: build.query({
      query: ({ ids }) => ({
        url: `/tournaments-getEventsByIds?ids=${ids}`
      }),
      transformResponse: result =>
        result?.events?.length > 0 ? result.events[0] : {},
      providesTags: result =>
        result
          ? [
              {
                type: 'Events',
                id: result?.id
              },
              'Events'
            ]
          : ['Events']
    }),
    getTournamentById: build.query({
      query: ({ id }) => ({
        url: `/tournaments-getTournamentById`,
        params: { id }
      }),
      providesTags: result =>
        result?.tournament
          ? [{ type: 'Events', id: result?.tournament?.id }, 'Events']
          : ['Events']
    }),
    getTournamentDay: build.query({
      query: ({ id, day, size }) => ({
        url: `/tournaments-getTournamentDay`,
        params: { id, day, size }
      })
    }),
    getTournamentDayPage: build.query({
      query: ({ id, day, login, rank, size }) => ({
        url: `/tournaments-getTournamentDayPage`,
        params: { id, day, login, rank, size }
      })
    }),
    getParticipantCount: build.query({
      query: ({ docId }) => ({
        url: `/tournaments-getParticipantCount`,
        params: { docId }
      }),
      keepUnusedDataFor: 60,
      providesTags: result =>
        result
          ? [
              { type: 'ParticipantCount', id: result?.participants },
              'ParticipantCount'
            ]
          : ['ParicipantCount']
    }),
    getTournamentsByOrg: build.query({
      query: ({ organizer }) => ({
        url: `/tournaments-getTournamentsByOrg`,
        params: { organizer }
      }),
      providesTags: result =>
        result
          ? [{ type: 'Organizers', id: result?.tournament?.id }, 'Organizers']
          : ['Organizers']
    }),
    getLeagueData: build.query({
      query: () => ({
        url: `/tournaments-getLeagueData`
      })
    })
  })
})
