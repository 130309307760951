import React from 'react'
import { useUserIsOwner, useUserIsAdmin, useUserIsMod } from 'hooks'
import Modules from 'components/Modules'
import { useAppModules, useUser } from 'hooks'
import UserRoles from 'components/UserRoles'
import AdminStats from 'components/AdminStats'
import AdminStripe from 'components/AdminStripe'

const Admin = ({ content }) => {
  const isOwner = useUserIsOwner()
  const isAdmin = useUserIsAdmin()
  const user = useUser()

  return (
    <>
      <AdminStats />
      <AdminStripe />
      <UserRoles />
      <Modules />
    </>
  )
}

export default Admin
