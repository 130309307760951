import Firebase from 'utils/Firebase'
import { getFirestore, doc, getDoc } from 'firebase/firestore'

const db = getFirestore(Firebase)

// const getAll = () => {
//   return db;
// };

// const create = (data) => {
//   return db.add(data);
// };

// const update = (id, value) => {
//   return db.doc(id).update(value);
// };

// const remove = (id) => {
//   return db.doc(id).delete();
// };

async function getPage(id) {
  const docRef = doc(db, 'content/pages/public', id)
  const docSnap = await getDoc(docRef)
  if (docSnap.exists()) {
    return docSnap.data()
  }
}

async function getProtectedPage(id) {
  const docRef = doc(db, 'content/pages/private', id)
  const docSnap = await getDoc(docRef)
  if (docSnap.exists()) {
    return docSnap.data()
  }
}

const ContentService = {
  //getAll,
  //create,
  //update,
  //remove
  getPage,
  getProtectedPage
}

export default ContentService
