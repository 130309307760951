import React, { useState } from 'react'
import CardsList from 'components/Stripe/cards/CardsList'
import { Modal, Typography } from '@mui/material'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import CreditCardIcon from '@mui/icons-material/CreditCard'
import Alert from '@mui/material/Alert'
import { useGetCustomerCards } from 'components/Stripe/hooks/Cards'
import { StripeApi } from 'api/Stripe'
import AddCardModal from './modals/AddCardModal'

const PaymentMethods = ({ customerId, defaultMethod, subscription }) => {
  const { data: cards = [] } = useGetCustomerCards(customerId)
  const [hasError, setHasError] = useState(false)
  const [isDeleteOpen, setIsDeleteOpen] = useState(false)
  const [cardPendingId, setCardPendingId] = useState('')
  const [detachCard] = StripeApi.useDetachCardMutation()
  const [setFavoriteCardApi] = StripeApi.useSetDefaultCardMutation()

  const handleError = () => {
    setHasError(true)
    setTimeout(() => {
      setHasError(false)
    }, 3000)
  }

  const openDeleteCardConfirm = paymentId => {
    setIsDeleteOpen(true)
    setCardPendingId(paymentId)
  }

  const deleteCard = async event => {
    event.preventDefault()
    if (cardPendingId) {
      detachCard({
        paymentMethodId: cardPendingId
      })
        .then(response => {
          if (response) handleDeleteClose()
        })
        .catch(() => {
          handleError()
        })
    }
  }

  const setFavoriteCard = async paymentId => {
    if (defaultMethod === paymentId) return
    setFavoriteCardApi({ customerId, paymentId }).catch(() => {
      handleError()
    })
  }

  const handleDeleteClose = () => {
    setIsDeleteOpen(false)
  }

  const options = {
    mode: 'maintenance',
    hideNew: true
  }

  const actions = {
    deleteCard: paymentId => openDeleteCardConfirm(paymentId),
    favoriteCard: paymentId => setFavoriteCard(paymentId)
  }

  return (
    <div>
      <div className="aside-header aside-subscription-status">
        <span className="credit-card">Manage Payment Methods</span>
      </div>
      <div className="content-panel manage-payments">
        {hasError && (
          <Alert severity="error">
            There was an error processing your request, please try again.
          </Alert>
        )}
        <CardsList
          cards={cards}
          options={options}
          actions={actions}
          defaultMethod={defaultMethod}
          subscribed={
            subscription?.status === 'active'
              ? subscription?.default_payment_method
              : ''
          }
        />
        <Box className="payment-actions">
          <AddCardModal customerId={customerId} route={'/account'} />
          <Modal open={isDeleteOpen} onClose={handleDeleteClose}>
            <Box className="run-modal">
              <Box className="aside-header">
                <Typography
                  className="aside-header"
                  id="card-modal-title"
                  variant="h6"
                  component="span">
                  <CreditCardIcon /> Delete Payment Method
                </Typography>
              </Box>
              <Box className="modal-content">
                <Typography component="p">
                  Are you sure you want to delete this payment method?
                </Typography>
                <Box className="modal-controls" sx={{ marginTop: '8px' }}>
                  <Button
                    variant="contained"
                    onClick={handleDeleteClose}
                    sx={{ marginRight: '5px' }}>
                    Close
                  </Button>
                  <Button
                    variant="contained"
                    color="error"
                    onClick={deleteCard}>
                    Delete
                  </Button>
                </Box>
              </Box>
            </Box>
          </Modal>
        </Box>
      </div>
    </div>
  )
}

export default PaymentMethods
