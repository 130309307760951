import React, { useState, useEffect } from 'react'
import FormControl from '@mui/material/FormControl'
import TextField from '@mui/material/TextField'
import Tooltip from '@mui/material/Tooltip'
import Button from '@mui/material/Button'
import CircularProgress from '@mui/material/CircularProgress'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import { useUser, useUserData } from 'hooks'
import { Box, Switch, FormControlLabel, Alert } from '@mui/material'
import UserService from 'services/UserService'

const KickSettings = () => {
  const user = useUser()
  const userData = useUserData()
  const overlayRef = React.useRef(null)
  const [copyOpened, setCopyOpened] = useState(false)
  const [copy2Opened, setCopy2Opened] = useState(false)
  const [userIsKick, setUserIsKick] = useState(false)
  const [confirmLoading, setConfirmLoading] = useState(false)

  useEffect(() => {
    if (
      userData.display_name &&
      typeof userData.kickUsername === 'undefined' &&
      typeof userData.kickVerification === 'undefined'
    ) {
      UserService.generateKickVerification(data => {})
    }
  }, [user])

  useEffect(() => {
    if (userData.display_name && userData.usingKick === true) {
      setUserIsKick(true)
    }
  }, [userData])

  const setKickcom = () => {
    UserService.userIsKick(!userIsKick, data => {})
    setUserIsKick(!userIsKick)
  }

  const declineKickUsername = () => {
    setConfirmLoading(true)
    UserService.declineKickUsername(data => {
      setConfirmLoading(false)
    })
  }
  const confirmKickUsername = () => {
    setConfirmLoading(true)
    UserService.confirmKickUsername(data => {
      setConfirmLoading(false)
    })
  }

  return (
    <div className="overlay-settings">
      <div className="aside-header aside-kickcom">
        <span className="kickcom">Kick.com Settings</span>
      </div>
      <div className="content-panel">
        <span className="paragraph">
          Do you also stream on Kick.com? Link your kick.com and Fence.gg
          accounts and control which of your livestream channels is featured on
          the Fence.gg site.
        </span>
        {typeof userData.kickUsername !== 'undefined' && (
          <FormControlLabel
            control={<Switch checked={userIsKick} onChange={setKickcom} />}
            label={`Use my Kick.com account: ${userData.kickUsername}`}
          />
        )}

        {typeof userData.kickUsername === 'undefined' && (
          <Box className="form-control overlay-controls">
            <Alert severity="info">
              You must link your kick.com account first!
            </Alert>
            {typeof userData.tmpKickUsername === 'undefined' ||
            userData.tmpKickUsername === '' ? (
              <div className="kick-step-one">
                <span className="paragraph">
                  Make sure you are logged into the Kick.com account you want to
                  link to your Fence.gg account first!
                </span>
                <span className="subhead">
                  STEP 1 &ndash; Visit the Kick.com FenceGG page
                </span>
                <FormControl
                  fullWidth
                  component="form"
                  sx={{ m: 1, position: 'relative' }}
                  variant="standard">
                  <Tooltip
                    placement="top"
                    arrow
                    open={copyOpened}
                    title="Copied!">
                    <ContentCopyIcon
                      sx={{
                        position: 'absolute',
                        top: 16,
                        right: 17,
                        cursor: 'pointer',
                        zIndex: 99
                      }}
                      onClick={() => {
                        setCopyOpened(true)
                        navigator.clipboard.writeText(
                          'https://kick.com/fencegg'
                        )
                        setTimeout(() => {
                          setCopyOpened(false)
                        }, 3000)
                      }}
                    />
                  </Tooltip>
                  <TextField
                    id="outlined-read-only-input"
                    name="overlay"
                    label="Open This Page First"
                    inputRef={overlayRef}
                    value="https://kick.com/fencegg"
                    InputProps={{
                      readOnly: true
                    }}
                    onFocus={event => {
                      event.target.select()
                    }}
                  />
                </FormControl>
                <span className="subhead">
                  STEP 2 &ndash; Paste your verification code into the chat
                </span>
                <FormControl
                  fullWidth
                  component="form"
                  sx={{ m: 1, position: 'relative' }}
                  variant="standard">
                  <Tooltip
                    placement="top"
                    arrow
                    open={copy2Opened}
                    title="Copied!">
                    <ContentCopyIcon
                      sx={{
                        position: 'absolute',
                        top: 16,
                        right: 17,
                        cursor: 'pointer',
                        zIndex: 99
                      }}
                      onClick={() => {
                        setCopy2Opened(true)
                        navigator.clipboard.writeText(
                          `FenceCode:${userData.kickVerification}`
                        )
                        setTimeout(() => {
                          setCopy2Opened(false)
                        }, 3000)
                      }}
                    />
                  </Tooltip>
                  <TextField
                    id="outlined-read-only-input"
                    name="overlay"
                    label="Paste This Code Into The Chat"
                    inputRef={overlayRef}
                    value={
                      userData.kickVerification
                        ? `FenceCode:${userData.kickVerification}`
                        : 'loading...'
                    }
                    InputProps={{
                      readOnly: true
                    }}
                    onFocus={event => {
                      event.target.select()
                    }}
                  />
                </FormControl>
              </div>
            ) : (
              <div className="kick-step-one">
                <span className="paragraph">
                  Is this your Kick.com username: {userData.tmpKickUsername}
                </span>
                <div className="kick-controls">
                  <Button
                    variant="contained"
                    color="error"
                    disabled={confirmLoading}
                    onClick={declineKickUsername}
                    sx={{ marginRight: '5px' }}>
                    {confirmLoading && (
                      <CircularProgress
                        style={{
                          color: '#000',
                          height: 15,
                          width: 15,
                          marginRight: 10
                        }}
                      />
                    )}
                    No
                  </Button>
                  <Button
                    variant="contained"
                    onClick={confirmKickUsername}
                    disabled={confirmLoading}>
                    {confirmLoading && (
                      <CircularProgress
                        style={{
                          color: '#000',
                          height: 15,
                          width: 15,
                          marginRight: 10
                        }}
                      />
                    )}
                    Yes
                  </Button>
                </div>
              </div>
            )}
          </Box>
        )}
      </div>
    </div>
  )
}

export default KickSettings
