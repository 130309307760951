import React, { useEffect, useState } from 'react'
import ChargeForm from './Stripe/charge/ChargeForm'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'
import BountyService from 'services/BountyService'

import { useUserData } from 'hooks'
import { NavLink } from 'react-router-dom'

const BountyCreate = () => {
  const user = useUserData()
  const [selectedBounty, setSelectedBounty] = useState('')
  const [availableBounties, setAvailableBounties] = useState([])
  const [activeBounties, setActiveBounties] = useState([])

  const handleBountyChange = e => {
    setSelectedBounty(e.target.value)
  }

  useEffect(() => {
    BountyService.getEnabledBounties(data => {
      setAvailableBounties(data)
    })
    BountyService.getActivatedBounties(data => {
      setActiveBounties(data)
    })
  }, [])

  return (
    <div>
      <div className="aside-header aside-leagues">
        <span className="bounty">Post a Bounty Challenges</span>
      </div>
      <div className="content-panel">
        <span className="subhead green">What are bounties?</span>
        <span className="paragraph">
          Bounties are special challenges that you create for your favorite
          Escape From Tarkov content creators. When you create a bountry, an
          alert goes out to all Fence Premium members streaming Tarkov that have
          bounties activated. The first streamer to submit a timestamped VOD
          link of them completing the challenge gets the full bountry amount
          that you specified, minus fees.
        </span>
        {availableBounties.filter(
          b => activeBounties.filter(a => a.bounty === b.id).length === 0
        ).length > 0 ? (
          <div>
            <span className="subhead green">Select Your Bounty Callenge</span>
            <FormControl required fullWidth>
              <InputLabel id="demo-simple-select-label">
                Select a bounty challenge...
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={selectedBounty}
                label="Select a bounty challenge..."
                onChange={handleBountyChange}>
                {availableBounties
                  .filter(
                    b =>
                      activeBounties.filter(a => a.bounty === b.id).length === 0
                  )
                  .map(bounty => {
                    return (
                      <MenuItem key={bounty.id} value={bounty.id}>
                        {bounty.desc}
                      </MenuItem>
                    )
                  })}
              </Select>
            </FormControl>
            <div className="bounty-amount">
              <span className="subhead green">
                Enter A Bounty Amount in USD ($10 Min.)
              </span>
              <ChargeForm
                user={user}
                selectedBounty={selectedBounty}
                route="/bounty"
              />
            </div>
          </div>
        ) : (
          <div>
            <span className="no-competitors">
              There are no available bounties to activate. You must wait for the
              current active bounties to be completed before creating a new one.
            </span>
            <div className="bounty-buttons">
              <NavLink className="btn btn-primary" to={'/bounty'}>
                Return to Bounty List
              </NavLink>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default BountyCreate
