import Firebase from 'utils/Firebase'
import { getFirestore } from 'firebase/firestore'
import { onSnapshot, doc } from 'firebase/firestore'

const db = getFirestore(Firebase)

async function getMyReferrals(uid, callback) {
  return onSnapshot(doc(db, 'referral', uid), doc => {
    let refArr = []
    if (doc && doc.data() && typeof callback === 'function') {
      if (doc.data().referred && doc.data().referred.length > 0) {
        refArr = [...doc.data().referred]
      }
      callback(refArr)
    }
  })
}

const ReferralService = {
  getMyReferrals
}

export default ReferralService
