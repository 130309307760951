import React from 'react'
import { useAppIsLoggedIn } from 'hooks'
import Banner from 'components/Banner'
import BountyCreate from 'components/BountyCreate'
import BountyList from 'components/BountyList'
import { useLocation } from 'react-router-dom/dist'

const Bounty = () => {
  const isLoggedIn = useAppIsLoggedIn()
  const location = useLocation()

  return (
    <>
      <Banner />
      {location.pathname === '/bounty/create' ? (
        <BountyCreate />
      ) : (
        <BountyList />
      )}
    </>
  )
}

export default Bounty
