import { getGoonsMaps } from 'utils/GoonsMaps'
import TimeAgo from 'react-timeago'
import Username from '../Username'
import TableCell from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'
import { UserServiceApi } from 'api/UserServiceApi'
import { CircularProgress } from '@mui/material'
import { useEffect } from 'react'
import UsernameAvatar from 'components/UsernameDisplay/UsernameAvatar'
import UsernameStreamLink from 'components/UsernameDisplay/UsernameStreamLink'

const SightingDisplay = ({ sighting }) => {
  const goonsMaps = getGoonsMaps()
  const [getUsername, { data: username, isLoading }] =
    UserServiceApi.useLazyGetUsernameByIdCacheQuery()

  useEffect(() => {
    if (sighting?.sightedBy) {
      getUsername({ id: sighting.sightedBy }, true)
    }
  }, [getUsername, sighting.sightedBy])

  if (isLoading) {
    return (
      <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
        <TableCell component="th" scope="row" align="center" colSpan={3}>
          <CircularProgress
            style={{
              height: 15,
              width: 15,
              marginRight: 10
            }}
          />
        </TableCell>
      </TableRow>
    )
  }

  if (username) {
    return (
      <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
        <TableCell component="th" scope="row">
          <img
            style={{ width: 60, height: 30, marginRight: 10 }}
            src={goonsMaps[sighting.map].discordImg}
            alt={goonsMaps[sighting.map].name}
          />
          <span>{goonsMaps[sighting.map].name}</span>
        </TableCell>
        <TableCell align="center">
          <UsernameStreamLink username={username}>
            <UsernameAvatar
              style={{ width: 30, height: 30, marginRight: 10 }}
              username={username}
            />

            <span>
              <Username username={username} />
            </span>
          </UsernameStreamLink>
        </TableCell>
        <TableCell align="right">
          <TimeAgo date={sighting.datetime.seconds * 1000} />
        </TableCell>
      </TableRow>
    )
  }
}

export default SightingDisplay
