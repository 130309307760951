import moment from 'moment'
import React, { useEffect, useState, useCallback } from 'react'
import { Link } from 'react-router-dom'
import USAFlag from '../assets/img/icon-flag-usa.jpg'
import OnlineIcon from '../assets/img/icon-online.png'
import { TournamentServiceApi } from 'api/TournamentServiceApi'

const SidebarEventsLink = ({ tournament }) => {
  const { data: organizer } = TournamentServiceApi.useGetOrganizersByIdsQuery({
    ids: tournament?.organizer
  })

  return (
    <Link
      className="sidebar-events-item"
      to={
        tournament?.eventURL
          ? tournament?.eventURL
          : `/event/${tournament?.slug}/${tournament?.id}`
      }>
      <span className="calendar">
        <span className={`label ${tournament.eventList}`}>
          {tournament.eventList === 'upcoming'
            ? 'Starts'
            : tournament.eventList === 'past'
            ? 'Ended'
            : 'ends'}
        </span>
        <span className="day">
          {moment(
            tournament.eventList === 'upcoming'
              ? tournament.starts
              : tournament.ends
          ).format('ddd')}
        </span>
        <span className="date">
          {moment(
            tournament.eventList === 'upcoming'
              ? tournament.starts
              : tournament.ends
          ).format('MMM')}{' '}
          {moment(
            tournament.eventList === 'upcoming'
              ? tournament.starts
              : tournament.ends
          ).format('DD')}
        </span>
      </span>
      <span className="event-details">
        <span className="title">{tournament.display_name}</span>
        <span className="desc">By {organizer?.display_name}</span>
        {tournament?.location && (
          <span className="desc2">
            {tournament?.type === 'Online' ? (
              <img className="online-icon" src={OnlineIcon} alt={'Online'} />
            ) : (
              <img className="flag-icon" src={USAFlag} alt={'US Flag'} />
            )}
            <span>
              {tournament?.location?.display_name} ({tournament?.type})
            </span>
          </span>
        )}
      </span>
    </Link>
  )
}

export default SidebarEventsLink
