import React from 'react'

const UsernameStreamLink = ({ username, style, children }) => {
  const isBanned = username?.banned === true
  const isKick = username?.usingKick || false
  const displayName =
    username?.usingKick === true ? username?.kickUsername : username?.login

  return isBanned ? (
    <div>{children}</div>
  ) : isKick ? (
    <a
      style={style}
      href={`https://kick.com/${displayName?.toLowerCase()}`}
      target="_blank"
      rel="noreferrer">
      {children}
    </a>
  ) : (
    <a
      style={style}
      href={`https://www.twitch.tv/${displayName?.toLowerCase()}`}
      target="_blank"
      rel="noreferrer">
      {children}
    </a>
  )
}

export default UsernameStreamLink
