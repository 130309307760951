import { CircularProgress } from '@mui/material'

const { Link } = require('react-router-dom')

const TeamLink = ({ team }) => {
  return team.avatar ? (
    <Link
      className="event-standings-label-team"
      to={`/team/${team.display_name.replace(' ', '-').toLowerCase()}/${
        team.id
      }`}>
      <img src={team.avatar} />
      <span>{team?.display_name}</span>
    </Link>
  ) : (
    <div>
      <CircularProgress
        style={{
          color: '#7ab903',
          height: 20,
          width: 20
        }}
      />
    </div>
  )
}
export default TeamLink
