import React from 'react'
import { useAppIsLoggedIn } from 'hooks'
import Banner from 'components/Banner'

const Contact = () => {
  const isLoggedIn = useAppIsLoggedIn()
  return (
    <>
      <Banner />
      <div className="aside-header aside-leagues">
        <span className="admin">Contact Fence.gg</span>
      </div>
      <div className="content-panel">
        <span className="paragraph">
          For all inquiries, please join the discord and message an
          administrator.{' '}
          <a href="https://discord.gg/wBaSq8Nvjf" target="_blank">
            https://discord.gg/wBaSq8Nvjf
          </a>
        </span>
      </div>
    </>
  )
}

export default Contact
