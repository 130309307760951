import React, { useCallback, useEffect } from 'react'
import { TournamentServiceApi } from 'api/TournamentServiceApi'
import USAFlag from '../assets/img/icon-flag-usa.jpg'
import IconSocialX from '../assets/img/icon-social-x.jpg'
import IconSocialTwitch from '../assets/img/icon-social-twitch.jpg'
import IconSocialInstagram from '../assets/img/icon-social-instagram.jpg'
import IconSocialYoutube from '../assets/img/icon-social-youtube.jpg'
import IconSocialTiktok from '../assets/img/icon-social-tiktok.jpg'
import { CircularProgress } from '@mui/material'
import { useParams } from 'react-router'
import moment from 'moment'
import { Link } from 'react-router-dom'
import PlayerLink from 'components/PlayerLink'

const TeamPage = () => {
  const params = useParams()
  const [matches, setMatches] = React.useState([])
  const [events, setEvents] = React.useState([])
  const [teams, setTeams] = React.useState([])
  const [players, setPlayers] = React.useState([])

  const { data: team, isLoading } = TournamentServiceApi.useGetTeamsByIdsQuery({
    ids: parseInt(params.id, 10)
  })

  const [getMatchesApi] = TournamentServiceApi.useLazyGetMatchesByIdsQuery()

  const [getPlayersApi] = TournamentServiceApi.useLazyGetPlayersByIdsQuery()

  const getPlayersAsync = useCallback(async () => {
    const asyncPlayers = await Promise.all(
      team?.players.map(async player => {
        return await getPlayersApi({ ids: player.id }, true).unwrap()
      })
    )
    setPlayers(asyncPlayers)
  }, [team, getPlayersApi])

  useEffect(() => {
    if (team?.players?.length > 0) {
      getPlayersAsync()
    }
  }, [team])

  const getMatchesAsync = useCallback(async () => {
    const asyncMatches = await Promise.all(
      team?.matches.map(async match => {
        return await getMatchesApi({ ids: match }, true).unwrap()
      })
    )
    setMatches(asyncMatches)
  }, [team, getMatchesApi])

  useEffect(() => {
    if (team?.matches?.length > 0) {
      getMatchesAsync()
    }
  }, [team])

  const [getTeamsApi] = TournamentServiceApi.useLazyGetTeamsByIdsQuery()

  const getTeamsAsync = useCallback(async () => {
    const teams = []
    matches.forEach(m => {
      m.teams.forEach(t => {
        if (!teams.includes(t.id)) {
          teams.push(t.id)
        }
      })
    })
    const asyncTeams = await Promise.all(
      teams?.map(async t => {
        return await getTeamsApi({ ids: t }, true).unwrap()
      })
    )
    setTeams(asyncTeams)
  }, [team, matches])

  useEffect(() => {
    if (matches?.length > 0) {
      getTeamsAsync()
    }
  }, [matches])

  const [getEventsApi] = TournamentServiceApi.useLazyGetEventsByIdsQuery()

  const getEventsAsync = useCallback(async () => {
    const events = []
    matches.forEach(m => {
      if (!events.includes(m.event)) {
        events.push(m.event)
      }
    })
    const asyncEvents = await Promise.all(
      events?.map(async e => {
        return await getEventsApi({ ids: e }, true).unwrap()
      })
    )
    setEvents(asyncEvents)
  }, [matches])

  useEffect(() => {
    if (matches?.length > 0) {
      getEventsAsync()
    }
  }, [matches])

  const getTotalWins = () => {
    let wins = 0
    matches.forEach(m => {
      m.teams.forEach(t => {
        if (t.id === team.id && t.winner === true) {
          wins += 1
        }
      })
    })
    return wins
  }

  const getTotalLosses = () => {
    let losses = 0
    matches.forEach(m => {
      m.teams.forEach(t => {
        if (t.id === team.id && t.winner !== true) {
          losses += 1
        }
      })
    })
    return losses
  }

  const getWinStreak = () => {
    let onStreak = true
    let wins = 0
    let ms = [...matches].sort((a, b) => b.starts._seconds - a.starts._seconds)
    ms.forEach(m => {
      m.teams.forEach(t => {
        if (t.id === team.id) {
          if (t.winner === true && onStreak === true) {
            wins += 1
          } else {
            onStreak = false
            return wins
          }
        }
      })
    })
    return wins
  }

  const getWinPercent = () => {
    let wins = 0
    let losses = 0
    matches.forEach(m => {
      m.teams.forEach(t => {
        if (t.id === team.id) {
          if (t.winner === true) {
            wins += 1
          } else {
            losses += 1
          }
        }
      })
    })
    return ((wins / (wins + losses)) * 100).toFixed()
  }

  return (
    <>
      <div>
        <div className="aside-header aside-team">
          <span className="team">
            {isLoading ? 'Loading Team...' : team.display_name}
          </span>
        </div>
        {isLoading ? (
          <div className="content-panel">
            <div className="content-loader">
              <CircularProgress
                style={{
                  color: '#7ab903',
                  height: 55,
                  width: 55
                }}
              />
            </div>
          </div>
        ) : (
          <div className="content-panel">
            <div className="team-section team-header">
              <div className="team-desc">
                <img className="team-avatar" src={team?.avatar} />
                <div className="team-details">
                  <span className="team-name">{team?.display_name}</span>
                  <span className="team-location">
                    {team?.location?.display_name}
                  </span>
                </div>
              </div>
              <div className="team-rank-details">
                <span className="team-rank">{team?.ranking?.world}</span>
                <span className="team-rank-label">World Ranking</span>
                <span className="team-amount">${team?.winnings?.amount}</span>
                <span className="team-amount-label">Total Winnings</span>
              </div>
            </div>
            <div className="events-box">
              <div className="events-box-header">
                <span className="events-title">Team Members</span>
              </div>
              <div className="team-roster">
                {players?.length > 0 ? (
                  <div>
                    <div className="team-players-label">Captain</div>
                    {players
                      ?.filter(
                        p =>
                          p.id ===
                          team?.players?.filter(pl => pl.type === 'captain')[0]
                            ?.id
                      )
                      .map(player => {
                        return (
                          <div className="team-player">
                            <PlayerLink
                              className="team-player-link"
                              player={player.id}
                            />
                            <div className="team-player-socials">
                              <div className="team-player-social">
                                {player?.socials?.map(social => {
                                  return (
                                    <a href={social.url} target="_blank">
                                      {social.id === 'twitter' && (
                                        <img src={IconSocialX} />
                                      )}
                                      {social.id === 'twitch' && (
                                        <img src={IconSocialTwitch} />
                                      )}
                                      {social.id === 'instagram' && (
                                        <img src={IconSocialInstagram} />
                                      )}
                                      {social.id === 'youtube' && (
                                        <img src={IconSocialYoutube} />
                                      )}
                                      {social.id === 'tiktok' && (
                                        <img src={IconSocialTiktok} />
                                      )}
                                    </a>
                                  )
                                })}
                              </div>
                            </div>
                          </div>
                        )
                      })}
                    <div className="team-players-label">Members</div>
                    {players
                      ?.filter(
                        p =>
                          p.id ===
                          team?.players?.filter(
                            pl => pl.id === p.id && pl.type === 'player'
                          )[0]?.id
                      )
                      .map(player => {
                        return (
                          <div className="team-player">
                            <PlayerLink
                              className="team-player-link"
                              player={player.id}
                            />
                            <div className="team-player-socials">
                              <div className="team-player-social">
                                {player?.socials?.map(social => {
                                  return (
                                    <a href={social.url} target="_blank">
                                      {social.id === 'twitter' && (
                                        <img src={IconSocialX} />
                                      )}
                                      {social.id === 'twitch' && (
                                        <img src={IconSocialTwitch} />
                                      )}
                                      {social.id === 'instagram' && (
                                        <img src={IconSocialInstagram} />
                                      )}
                                      {social.id === 'youtube' && (
                                        <img src={IconSocialYoutube} />
                                      )}
                                      {social.id === 'tiktok' && (
                                        <img src={IconSocialTiktok} />
                                      )}
                                    </a>
                                  )
                                })}
                              </div>
                            </div>
                          </div>
                        )
                      })}
                    {players?.filter(
                      p =>
                        p.id ===
                        team?.players?.filter(
                          pl => pl.id === p.id && pl.type === 'ringer'
                        )[0]?.id
                    ).length > 0 && (
                      <div className="team-players-label">Ringers</div>
                    )}
                    {players
                      ?.filter(
                        p =>
                          p.id ===
                          team?.players?.filter(
                            pl => pl.id === p.id && pl.type === 'ringer'
                          )[0]?.id
                      )
                      .map(player => {
                        return (
                          <div className="team-player">
                            <PlayerLink
                              className="team-player-link"
                              player={player.id}
                            />
                            <div className="team-player-socials">
                              <div className="team-player-social">
                                {player?.socials?.map(social => {
                                  return (
                                    <a href={social.url} target="_blank">
                                      {social.id === 'twitter' && (
                                        <img src={IconSocialX} />
                                      )}
                                      {social.id === 'twitch' && (
                                        <img src={IconSocialTwitch} />
                                      )}
                                      {social.id === 'instagram' && (
                                        <img src={IconSocialInstagram} />
                                      )}
                                      {social.id === 'youtube' && (
                                        <img src={IconSocialYoutube} />
                                      )}
                                      {social.id === 'tiktok' && (
                                        <img src={IconSocialTiktok} />
                                      )}
                                    </a>
                                  )
                                })}
                              </div>
                            </div>
                          </div>
                        )
                      })}
                  </div>
                ) : (
                  <div className="content-panel">
                    <div className="content-loader">
                      <CircularProgress
                        style={{
                          color: '#7ab903',
                          height: 55,
                          width: 55
                        }}
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="events-box events-history">
              <div className="events-box-header">
                <span className="events-title">Match History</span>
              </div>
              {matches?.length > 0 && teams?.length > 0 && events?.length > 0 && (
                <div className="match-head-header">
                  <div className="match-head-score">
                    <span className="match-head-score-value">
                      {getTotalWins()}
                    </span>
                    <span className="match-head-score-label">Wins</span>
                  </div>
                  <div className="match-head-score">
                    <span className="match-head-score-value">
                      {getTotalLosses()}
                    </span>
                    <span className="match-head-score-label">Losses</span>
                  </div>
                  <div className="match-head-score">
                    <span className="match-head-score-value">
                      {getWinStreak()}
                    </span>
                    <span className="match-head-score-label">Win Streak</span>
                  </div>
                  <div className="match-head-score">
                    <span className="match-head-score-value">
                      {getWinPercent()}
                    </span>
                    <span className="match-head-score-label">Win %</span>
                  </div>
                </div>
              )}
              <div className="match-head-matches">
                {matches?.length > 0 &&
                teams?.length > 0 &&
                events?.length > 0 ? (
                  [...matches]
                    .sort((a, b) => b.starts._seconds - a.starts._seconds)
                    ?.map(match => {
                      return (
                        <Link
                          key={match.id}
                          to={`/match/${teams
                            ?.filter(t => t.id === match.teams[0].id)[0]
                            ?.display_name?.replace(/ /g, '-')
                            .toLowerCase()}-vs-${teams
                            ?.filter(t => t.id === match.teams[1].id)[0]
                            ?.display_name?.replace(/ /g, '-')
                            .toLowerCase()}-${events
                            ?.filter(e => e.id === match.event)[0]
                            ?.display_name?.replace(/ /g, '-')
                            .toLowerCase()}/${match.id}`}
                          className="match-head-match">
                          <div className="match-head-thumb">
                            <div
                              className="match-head-thumb-img"
                              style={{
                                backgroundImage: `url(${
                                  events?.filter(e => e.id === match.event)[0]
                                    ?.poster
                                })`
                              }}></div>

                            <span className="match-head-info">
                              <span className="match-head-title">
                                {
                                  events?.filter(e => e.id === match?.event)[0]
                                    ?.display_name
                                }
                              </span>
                              <span className="match-head-subhead">
                                {match.roundLabel}
                              </span>
                            </span>
                          </div>
                          <span className="match-head-match-results">
                            <img
                              className="match-head-team-logo"
                              src={team?.avatar}
                            />
                            <span
                              className={`match-head-match-score ${
                                match?.teams?.filter(t => t.id === team.id)[0]
                                  ?.winner === true
                                  ? 'winner'
                                  : 'loser'
                              }`}>
                              {
                                match?.teams?.filter(t => t.id === team.id)[0]
                                  ?.score
                              }
                            </span>
                            <span
                              className={`match-head-match-score ${
                                match?.teams?.filter(t => t.id !== team.id)[0]
                                  ?.winner === true
                                  ? 'winner'
                                  : 'loser'
                              }`}>
                              {
                                match?.teams?.filter(t => t.id !== team.id)[0]
                                  ?.score
                              }
                            </span>
                            <img
                              className="match-head-team-logo"
                              src={
                                teams?.filter(
                                  ts =>
                                    ts.id ===
                                    match?.teams?.filter(
                                      t => t.id !== team.id
                                    )[0]?.id
                                )[0]?.avatar
                              }
                            />
                          </span>
                          <span className="match-head-date">
                            {moment(match?.starts?._seconds * 1000).format(
                              'MMM DD, YYYY'
                            )}
                            <br />
                            {moment(match?.starts?._seconds * 1000).format(
                              'hh:mm A'
                            )}
                          </span>
                        </Link>
                      )
                    })
                ) : (
                  <div className="content-panel">
                    <div className="content-loader">
                      <CircularProgress
                        style={{
                          color: '#7ab903',
                          height: 55,
                          width: 55
                        }}
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="events-box events-history">
              <div className="events-box-header">
                <span className="events-title">Event History</span>
              </div>
              {events?.length > 0 ? (
                <div className="events-box-content">
                  {[...events]
                    .sort((a, b) => b.starts._seconds - a.starts._seconds)
                    .map(tournament => {
                      return (
                        <Link
                          key={tournament.id}
                          className="events-row"
                          to={`/event/${tournament.slug}/${tournament.id}`}>
                          <div className="events-row-desc">
                            <div
                              className="events-row-thumb"
                              style={{
                                backgroundImage: `url(${tournament.poster})`
                              }}></div>
                            <div className="events-row-desc-content">
                              <span className="events-row-title">
                                {tournament.display_name}
                              </span>
                              <span className="events-row-location">
                                <img className="flag-icon" src={USAFlag} />
                                <span>
                                  {tournament.location.display_name} (
                                  {tournament.type})
                                </span>
                              </span>
                            </div>
                          </div>
                          <div className="events-row-info">
                            <div
                              className={`events-row-game game-${tournament.game}`}></div>
                            <div className="events-row-prize">
                              <span className="events-row-info-label">
                                Prize
                              </span>
                              <span className="events-row-info-value">
                                ${tournament.prizepool.amount}
                              </span>
                            </div>
                            <span className="calendar">
                              <span
                                className={`label ${
                                  moment(
                                    tournament.ends._seconds * 1000
                                  ).isBefore(moment())
                                    ? 'ended'
                                    : moment(
                                        tournament.starts._seconds * 1000
                                      ).isAfter(moment())
                                    ? 'upcoming'
                                    : moment(
                                        tournament.starts._seconds * 1000
                                      ).isBefore(moment()) &&
                                      moment(
                                        tournament.ends._seconds * 1000
                                      ).isAfter(moment())
                                    ? 'ongoing'
                                    : ''
                                }`}>
                                {moment(
                                  tournament.ends._seconds * 1000
                                ).isBefore(moment())
                                  ? 'Ended'
                                  : moment(
                                      tournament.starts._seconds * 1000
                                    ).isAfter(moment())
                                  ? 'Upcoming'
                                  : moment(
                                      tournament.starts._seconds * 1000
                                    ).isBefore(moment()) &&
                                    moment(
                                      tournament.ends._seconds * 1000
                                    ).isAfter(moment())
                                  ? 'Ongoing'
                                  : ''}
                              </span>
                              <span className="day">
                                {moment(tournament.ends._seconds * 1000).format(
                                  'ddd'
                                )}
                              </span>
                              <span className="date">
                                {moment(tournament.ends._seconds * 1000).format(
                                  'MMM DD YY'
                                )}
                              </span>
                            </span>
                          </div>
                        </Link>
                      )
                    })}
                </div>
              ) : (
                <div className="content-panel">
                  <div className="content-loader">
                    <CircularProgress
                      style={{
                        color: '#7ab903',
                        height: 55,
                        width: 55
                      }}
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    </>
  )
}

export default TeamPage
