import React, { useCallback, useEffect, useState } from 'react'
import { NavLink, useLocation } from 'react-router-dom'
import Badge from '@mui/material/Badge'
import {
  useAppIsLoggedIn,
  useUserIsAdmin,
  useUserIsOwner,
  useAppUnreadLeagueRuns,
  useUser,
  useAppSettings,
  useUserIsEditor,
  useUserIsVodder,
  useAppUnreadMyRuns,
  useUserIsPremium
} from 'hooks'
import LeagueService from 'services/LeagueService'
import BountyService from 'services/BountyService'
import { useDispatch } from 'react-redux'
import { setUnreadLeagueRuns, setUnreadMyRuns } from 'store/slice/appSlice'

const SidebarNavigation = () => {
  const isLoggedIn = useAppIsLoggedIn()
  const isPremium = useUserIsPremium()
  const unreadMyRuns = useAppUnreadMyRuns()
  const unreadLeagueRuns = useAppUnreadLeagueRuns()
  const isOwner = useUserIsOwner()
  const isAdmin = useUserIsAdmin()
  const isVodder = useUserIsVodder()
  const appSettings = useAppSettings()
  const location = useLocation()
  const [activePath, setActivePath] = useState('')
  const [activeBountyCount, setActiveBountyCount] = useState(0)
  const [unreadLoaded, setUnreadLoaded] = useState(false)
  const dispatch = useDispatch()
  const user = useUser()

  const getUnreadLeagueRuns = useCallback(async () => {
    const response = await LeagueService.getUnreadLeagueRuns(docs => {
      let cleaned = []
      docs.forEach(doc => {
        cleaned.push({
          ...doc,
          createdAt: doc?.createdAt?.seconds * 1000,
          started: doc?.started?.seconds * 1000,
          endedAt: doc?.endedAt?.seconds * 1000
        })
      })
      cleaned.sort((a, b) => a.createdAt - b.createdAt)
      dispatch(setUnreadLeagueRuns(cleaned))
    })
    return response
  }, [dispatch])

  useEffect(() => {
    if (
      (isAdmin || isOwner || isVodder) &&
      window.location.href.indexOf('/overlay/') === -1
    ) {
      const unreadLeagueRuns = getUnreadLeagueRuns()

      return () => {
        unreadLeagueRuns.then(response =>
          response ? response.unsubscribe() : null
        )
      }
    }
  }, [isOwner, isAdmin, isVodder, getUnreadLeagueRuns])

  useEffect(() => {
    if (isLoggedIn && user.uid && unreadLoaded === false) {
      setUnreadLoaded(true)
      LeagueService.getUnreadReviewedRuns(user.uid, data => {
        if (data && data.length > 0) {
          dispatch(setUnreadMyRuns(data.length))
        } else {
          dispatch(setUnreadMyRuns(0))
        }
      })
    }
  }, [isLoggedIn, user, dispatch, unreadLoaded])

  const isActive = (key, exact) => {
    if (exact) {
      return location.pathname.indexOf(key) === 0 ? ' active' : ''
    }
    return activePath === key ? ' active' : ''
  }

  useEffect(() => {
    setActivePath(location.pathname.split('/')[1])
  }, [location])

  useEffect(() => {
    BountyService.getActivatedBounties(bounties => {
      setActiveBountyCount(bounties.length)
    })
  }, [])

  return (
    <div className="sidebar-nav-wrapper">
      <div className="aside-header aside-menu">
        <span className="menu">Menu</span>
      </div>
      <nav className="main-nav">
        <ul>
          <li className={'news' + isActive('')}>
            <NavLink to="/">Home</NavLink>
          </li>
          <li className="divider">
            <div></div>
          </li>
          <li
            className={
              'guide' +
              isActive(
                '/news/post/how-to-get-started-with-fencegg-tarkov-leagues',
                true
              )
            }>
            <NavLink to="/news/post/how-to-get-started-with-fencegg-tarkov-leagues">
              Get Started
            </NavLink>
          </li>
          <li className="divider">
            <div></div>
          </li>
          <li
            className={'premium' + isActive('subscribe') + isActive('account')}>
            <NavLink to={isPremium ? '/account' : '/subscribe'}>
              Fence Premium
            </NavLink>
          </li>
          <li className="divider">
            <div></div>
          </li>
          {(isOwner || isAdmin) && (
            <li className={'admin' + isActive('admin')}>
              <NavLink to="/admin">Admin Panel</NavLink>
            </li>
          )}
          {(isOwner || isAdmin) && (
            <li className="divider">
              <div></div>
            </li>
          )}
          {(isOwner || isAdmin || isVodder) && (
            <li className={'review' + isActive('review')}>
              <NavLink to="/review">
                <Badge
                  className="unread-vods"
                  badgeContent={unreadLeagueRuns.length}
                  color="primary">
                  <span>VOD Reviews</span>
                </Badge>
              </NavLink>
            </li>
          )}
          {(isOwner || isAdmin || isVodder) && (
            <li className="divider">
              <div></div>
            </li>
          )}
          {!isLoggedIn ? (
            <li className="twitch">
              <a
                href="#"
                onClick={e => {
                  e.preventDefault()
                  window.open(
                    'popup.html?ver=1',
                    'name',
                    'height=585,width=400'
                  )
                }}>
                Signup Now
              </a>
            </li>
          ) : (
            <li className={'dashboard' + isActive('dashboard')}>
              <NavLink to="/dashboard">My Dashboard</NavLink>
            </li>
          )}
          <li className="divider">
            <div></div>
          </li>
          {isLoggedIn && (
            <li className={'review' + isActive('runs')}>
              <NavLink to="/runs">
                <Badge
                  className="unread-vods"
                  badgeContent={unreadMyRuns}
                  color="primary">
                  <span>My Runs</span>
                </Badge>
              </NavLink>
            </li>
          )}
          {isLoggedIn && (
            <li className="divider">
              <div></div>
            </li>
          )}
          <li className={'trophy' + isActive('rankings')}>
            <NavLink to="/rankings">Leagues</NavLink>
          </li>

          <li className="divider">
            <div></div>
          </li>
          <li className={'tournaments' + isActive('tournaments')}>
            <NavLink to="/tournaments">Tournaments</NavLink>
          </li>
          <li className="divider">
            <div></div>
          </li>
          {isAdmin && (
            <li className={'bounty' + isActive('bounty')}>
              <NavLink to="/bounty">
                <Badge
                  className="unread-vods"
                  badgeContent={activeBountyCount}
                  color="primary">
                  <span>Bounties</span>
                </Badge>
              </NavLink>
            </li>
          )}
          {isAdmin && (
            <li className="divider">
              <div></div>
            </li>
          )}
          <li className={'donate' + isActive('donate')}>
            <NavLink to="/donate">Donate</NavLink>
          </li>
          <li className="divider">
            <div></div>
          </li>
          {/* <li className={'leagues' + isActive('leagues')}>
            <Link to="/leagues">Leagues</Link>
          </li>
          <li className="divider">
            <div></div>
          </li> */}
          {(appSettings.closeBeta === false || isAdmin || isOwner) && (
            <li className={'teams' + isActive('teams')}>
              <NavLink to="/teams">Teams</NavLink>
            </li>
          )}

          {(appSettings.closeBeta === false || isAdmin || isOwner) && (
            <li className="divider">
              <div></div>
            </li>
          )}
          <li className={'events' + isActive('events')}>
            <NavLink to="/events">Events</NavLink>
          </li>
          <li className="divider">
            <div></div>
          </li>
          <li className={'community' + isActive('community')}>
            <NavLink to="/community">Community</NavLink>
          </li>
          <li className="divider">
            <div></div>
          </li>
          <li className={'goons' + isActive('goons-history')}>
            <NavLink to="/goons-history">Goon Sightings</NavLink>
          </li>
          <li className="divider">
            <div></div>
          </li>
          {isLoggedIn && (
            <li className="logout">
              <NavLink to="/logout">Log out</NavLink>
            </li>
          )}
          {isLoggedIn && (
            <li className="divider">
              <div></div>
            </li>
          )}
        </ul>
      </nav>
    </div>
  )
}

export default SidebarNavigation
