import React, { useEffect } from 'react'
import { useAppIsLoggedIn } from 'hooks'
import LoginButton from './LoginButton'
import { useLocation, useNavigate } from 'react-router-dom'

const ProceedLogin = () => {
  const isLoggedIn = useAppIsLoggedIn()
  const { search } = useLocation()
  const navigate = useNavigate()
  const query = new URLSearchParams(search)
  const path = query.get('path')

  if (path === null) {
    navigate('/')
  }

  useEffect(() => {
    if (isLoggedIn) {
      navigate(`/${path}`)
    }
  }, [isLoggedIn])

  return (
    <div>
      <div className="aside-header aside-teams">
        <span className="dashboard">Please Login to Proceed...</span>
      </div>
      <div className="content-panel">
        <span className="heading-text">You Must Login First</span>
        <span className="paragraph">
          Please login first before proceeding to /{path}...
        </span>
        <div>
          <LoginButton />
        </div>
      </div>
    </div>
  )
}

export default ProceedLogin
