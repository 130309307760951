import React, { useEffect, useState } from 'react'
import moment from 'moment'
import { Link } from 'react-router-dom'
import CenteredLoader from './CenteredLoader'
import { TournamentServiceApi } from 'api/TournamentServiceApi'
import SidebarEventsLink from './SidebarEventsLink'

const SidebarEvents = () => {
  const [events, setEvents] = useState([])
  const { data: tournaments, isLoading } =
    TournamentServiceApi.useGetEventsQuery()
  const { data: leagues, isLoading: isLoadingLeagues } =
    TournamentServiceApi.useGetLeagueDataQuery()

  useEffect(() => {
    let tournamentArr = []
    if (tournaments?.length > 0) {
      tournamentArr = [...tournaments]
    }
    let leagueArr = []
    leagues?.leagues?.forEach(league => {
      league?.seasons
        ?.filter(s => s.hidden !== true)
        ?.forEach(season => {
          leagueArr.push({
            display_name: `${league.display_name} League ${
              season?.display_name
                ? season?.display_name
                : `Season ${season.docId.replace('season-', '')}`
            }`,
            starts: season?.weeks[0]?.starts,
            ends: season?.weeks[season?.weeks?.length - 1]?.ends,
            eventList: moment(season?.weeks[0]?.starts).isAfter(moment())
              ? 'upcoming'
              : moment(season?.weeks[season?.weeks?.length - 1]?.ends).isBefore(
                  moment()
                )
              ? 'past'
              : 'ongoing',
            organizer: 24895,
            location: {
              display_name: 'Global'
            },
            type: 'Online',
            eventURL: moment(season?.weeks[0]?.ends).isBefore(moment())
              ? `/history/${league.display_name.toLowerCase()}/${season.docId.replace(
                  'season-',
                  ''
                )}`
              : `/rankings/${league.display_name.toLowerCase()}`,
            status: 'approved'
          })
        })
    })
    setEvents([...tournamentArr, ...leagueArr])
  }, [tournaments, leagues])

  return (
    <div>
      <div className="aside-header aside-events">
        <span className="events">Events</span>
      </div>
      <div className="active-events">
        <span className="title">Ongoing Events</span>
        <div className="events">
          {!isLoading && !isLoadingLeagues ? (
            <ul>
              {[...events]?.filter(t => t.eventList === 'ongoing').length ===
              0 ? (
                <li>
                  <span className="no-competitors">
                    There are no active events at this time.
                  </span>
                </li>
              ) : (
                [...events]
                  .sort((a, b) => a.starts._seconds - b.starts._seconds)
                  .filter(t => t.eventList === 'ongoing')
                  .map((tournament, i) => {
                    return (
                      <li key={i}>
                        <SidebarEventsLink tournament={tournament} />
                      </li>
                    )
                  })
              )}
            </ul>
          ) : (
            <CenteredLoader />
          )}
        </div>
      </div>
      <div className="active-events">
        <span className="title">Upcoming Events</span>
        <div className="events">
          {!isLoading && !isLoadingLeagues ? (
            <ul>
              {[...events]
                .sort(
                  (a, b) => moment(a.starts).unix() - moment(b.starts).unix()
                )
                .filter(t => t.eventList === 'upcoming').length === 0 ? (
                <li>
                  <span className="no-competitors">
                    There are no upcoming events at this time.
                  </span>
                </li>
              ) : (
                [...events]
                  .sort(
                    (a, b) => moment(a.starts).unix() - moment(b.starts).unix()
                  )
                  .filter(t => t.eventList === 'upcoming')
                  .map((tournament, i) => {
                    return (
                      <li key={i}>
                        <SidebarEventsLink tournament={tournament} />
                      </li>
                    )
                  })
              )}
            </ul>
          ) : (
            <CenteredLoader />
          )}
        </div>
      </div>
      <div className="active-events">
        <span className="title">Past Events</span>
        <div className="events">
          {!isLoading && !isLoadingLeagues ? (
            <ul>
              {[...events]?.filter(t => t.eventList === 'past').length === 0 ? (
                <li>
                  <span className="no-competitors">
                    There are no past events at this time.
                  </span>
                </li>
              ) : (
                [...events]
                  .sort(
                    (a, b) => moment(b.starts).unix() - moment(a.starts).unix()
                  )
                  .filter(t => t.eventList === 'past')
                  .map((tournament, i) => {
                    return (
                      i < 4 && (
                        <li key={i}>
                          <SidebarEventsLink tournament={tournament} />
                        </li>
                      )
                    )
                  })
              )}
            </ul>
          ) : (
            <CenteredLoader />
          )}
        </div>
        <Link className="all-link" to="/tournaments">
          View All Events
        </Link>
      </div>
    </div>
  )
}

export default SidebarEvents
