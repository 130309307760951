import React, { useCallback, useEffect, useState } from 'react'
import { Grid } from '@mui/material'
import UserService from 'services/UserService'
import ChatbotService from 'services/ChatbotService'
import UsernameService from 'services/UsernameService'

const AdminStats = () => {
  const [usernames, setUsernames] = useState(0)
  const [premiumUsers, setPremiumUsers] = useState([])
  const [chatbotUsers, setChatbotUsers] = useState([])
  const [runUsers, setRunUsers] = useState([])

  const getUsernameCount = useCallback(async () => {
    const response = await UsernameService.getUsernameCount()
    setUsernames(response)
    return response
  }, [])

  useEffect(() => {
    getUsernameCount()
  }, [getUsernameCount])

  useEffect(() => {
    UserService.getPremiumUsers(users => {
      setPremiumUsers(users)
    })
    ChatbotService.getConnectedBots(users => {
      setChatbotUsers(users)
    })
    UserService.getUsersWithRuns(users => {
      setRunUsers(users)
    })
  }, [])

  return (
    <div>
      <div className="aside-header aside-stats">
        <span className="stats">User Stats</span>
      </div>
      <div className="content-panel">
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <span className="heading-text">
              Total Users: <span className="heading-value">{usernames}</span>
            </span>
            <span className="heading-text">
              With Run: <span className="heading-value">{runUsers.length}</span>
            </span>
          </Grid>
          <Grid item xs={12} md={6}>
            <span className="heading-text">
              Chatbot Installed:{' '}
              <span className="heading-value">{chatbotUsers.length}</span>
            </span>
            <span className="heading-text">
              Subscribed:{' '}
              <span className="heading-value">{premiumUsers.length}</span>
            </span>
          </Grid>
        </Grid>
      </div>
    </div>
  )
}

export default AdminStats
