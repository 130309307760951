import React from 'react'

const Banner = () => {
  return (
    <div className="banner">
      <div className="banner-text">
        <span className="title">Premium Tools for Creators</span>
        <span className="desc">
          Our custom overlays and chatbot will elevate your livestreams while
          you focus on the competition
        </span>
      </div>
    </div>
  )
}

export default Banner
