import Firebase from 'utils/Firebase'
import {
  getFirestore,
  collection,
  onSnapshot,
  setDoc,
  doc
} from 'firebase/firestore'
import { randomBytes } from 'crypto'

const db = getFirestore(Firebase)

async function getMods(callback) {
  onSnapshot(collection(db, 'modules'), docs => {
    callback(docs)
  })
}

async function toggleMod(id, enabled) {
  await setDoc(doc(db, 'modules', id), { enabled: enabled }, { merge: true })
}

async function setVolume(id, vol, enabled) {
  await setDoc(doc(db, 'modules', id), { volume: vol }, { merge: true })
}

async function regenURL(id) {
  await setDoc(
    doc(db, 'modules', id),
    { overlayKey: randomBytes(16).toString('hex') },
    { merge: true }
  )
}

const ModuleService = {
  getMods,
  toggleMod,
  regenURL,
  setVolume
}

export default ModuleService
