import Firebase from 'utils/Firebase'
import {
  collection,
  getFirestore,
  onSnapshot,
  orderBy,
  query,
  where
} from 'firebase/firestore'

const db = getFirestore(Firebase)

export const NotificationService = {
  getUsernameNotifications: async (date, callback) => {
    const q = query(
      collection(db, 'notifications'),
      where('createdAt', '>', date),
      where('type', '==', 'username'),
      orderBy('createdAt', 'asc')
    )
    const unsubscribe = onSnapshot(q, querySnapshot => {
      const docs = []
      querySnapshot.forEach(doc => {
        docs.push({ ...doc.data(), id: doc.id })
      })
      if (typeof callback === 'function') {
        callback(docs)
      }
    })
    return { unsubscribe }
  }
}
