export const getGoonsMaps = () => {
  return {
    customs: {
      name: 'Customs',
      discordImg: 'https://fence.gg/assets/img/goons-map-customs.jpg'
    },
    lighthouse: {
      name: 'Lighthouse',
      discordImg: 'https://fence.gg/assets/img/goons-map-lighthouse.jpg'
    },
    shoreline: {
      name: 'Shoreline',
      discordImg: 'https://fence.gg/assets/img/goons-map-shoreline.jpg'
    },
    woods: {
      name: 'Woods',
      discordImg: 'https://fence.gg/assets/img/goons-map-woods.jpg'
    }
  }
}
