import Firebase from 'utils/Firebase'
import {
  getFirestore,
  collection,
  addDoc,
  onSnapshot,
  serverTimestamp,
  doc,
  setDoc,
  getDoc
} from 'firebase/firestore'
import fetch from 'cross-fetch'

import { getHostFunctions } from 'utils/GetHost'

const db = getFirestore(Firebase)

async function resetShown(id, callback) {
  const result = await fetch(
    getHostFunctions() +
      '/resetQuestion?' +
      new URLSearchParams({
        id: id
      })
  )
  if (result.status === 200) {
    result.json().then(res => {
      if (typeof callback === 'function') {
        callback(res)
      }
    })
  }
}

async function getTwitchUser(username, callback, nocache) {
  const result = await fetch(
    getHostFunctions() +
      '/getTwitchUser?' +
      new URLSearchParams({
        username: username,
        nocache: nocache
      })
  )
  if (result.status === 200) {
    result.json().then(res => {
      if (typeof callback === 'function') {
        callback(res)
      }
    })
  }
}

async function nextQuestion(callback) {
  const result = await fetch(getHostFunctions() + '/nextQuestion')
  if (result.status === 200) {
    result.json().then(res => {
      if (typeof callback === 'function') {
        callback(res)
      }
    })
  }
}

async function switchChannel(streamer, callback) {
  const result = await fetch(
    getHostFunctions() + `/switchChannel/?channel=${streamer}`
  )
  if (result.status === 200) {
    result.json().then(res => {
      if (typeof callback === 'function') {
        callback(res)
      }
    })
  }
}

async function testShoutout(streamer, callback) {
  const result = await fetch(
    getHostFunctions() + `/testShoutout/?channel=${streamer}`
  )
  if (result.status === 200) {
    result.json().then(res => {
      if (typeof callback === 'function') {
        callback(res)
      }
    })
  }
}

async function shoutoutStreamer(streamer, callback) {
  const result = await fetch(
    getHostFunctions() + `/shoutout/?channel=${streamer}`
  )
  if (result.status === 200) {
    result.json().then(res => {
      if (typeof callback === 'function') {
        callback(res)
      }
    })
  }
}

async function addQuestion(question, uid, anon, callback) {
  const docRef = await addDoc(collection(db, 'questions'), {
    text: question,
    by: uid,
    isAnon: anon,
    timestamp: serverTimestamp()
  })
  if (typeof callback === 'function') {
    callback(docRef)
  }
}

async function getAll(callback) {
  onSnapshot(collection(db, 'questions'), docs => {
    callback(docs)
  })
}

async function getByID(id, callback) {
  // onSnapshot(doc(db, 'questions', id), doc => {
  //   console.log('getbyID callback')
  //   if (typeof callback === 'function') {
  //     callback(doc)
  //   }
  // })
  const docRef = await getDoc(doc(db, 'streamers', id))
  if (typeof callback === 'function') {
    callback(docRef)
  }
}

async function approve(id, uid, callback) {
  const docRef = await setDoc(
    doc(db, 'questions', id),
    {
      status: 'approved',
      approvedBy: uid,
      approvedAt: serverTimestamp(),
      unread: true
    },
    { merge: true }
  )
  if (typeof callback === 'function') {
    callback(docRef)
  }
}

async function resetStreamer(callback) {
  const docRef = await setDoc(
    doc(db, 'settings', 'app'),
    {
      showStreamer: ''
    },
    { merge: true }
  )
  if (typeof callback === 'function') {
    callback(docRef)
  }
}

async function getStreamerLogin(callback) {
  let login = null
  const settingsRef = await getDoc(doc(db, 'settings', 'app'))
  if (
    settingsRef.data() &&
    settingsRef.data().showStreamer &&
    settingsRef.data().showStreamer.length
  ) {
    const docRef = await getDoc(
      doc(db, 'streamers', settingsRef.data().showStreamer)
    )
    if (docRef.data() && docRef.data().profile && docRef.data().profile.login) {
      login = docRef.data().profile.login
    }
  }
  if (typeof callback === 'function') {
    callback(login)
  }
}

async function showClip(clip, duration, callback) {
  const docRef = await setDoc(
    doc(db, 'settings', 'app'),
    {
      showVideo: { clip: clip, duration: parseFloat(duration, 10) }
    },
    { merge: true }
  )
  if (typeof callback === 'function') {
    callback(docRef)
  }
}

async function resetVideo(callback) {
  const docRef = await setDoc(
    doc(db, 'settings', 'app'),
    {
      showVideo: {
        duration: 0,
        clip: 0
      }
    },
    { merge: true }
  )
  if (typeof callback === 'function') {
    callback(docRef)
  }
}

async function deny(id, uid, callback) {
  const docRef = await setDoc(
    doc(db, 'questions', id),
    {
      status: 'denied',
      approvedBy: uid,
      approvedAt: serverTimestamp(),
      unread: true
    },
    { merge: true }
  )
  if (typeof callback === 'function') {
    callback(docRef)
  }
}

const QuestionService = {
  getAll,
  getByID,
  addQuestion,
  approve,
  deny,
  resetShown,
  nextQuestion,
  resetStreamer,
  getTwitchUser,
  shoutoutStreamer,
  showClip,
  resetVideo,
  switchChannel,
  testShoutout,
  getStreamerLogin
}

export default QuestionService
