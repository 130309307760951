import React from 'react'
import { useAppIsLoggedIn } from 'hooks'
import Banner from 'components/Banner'
import CurrentLeagues from 'components/CurrentLeagues'

const Leagues = () => {
  const isLoggedIn = useAppIsLoggedIn()
  return (
    <>
      <Banner />
      <CurrentLeagues />
    </>
  )
}

export default Leagues
