import { useEffect, useState } from 'react'
import {
  Modal,
  Box,
  Button,
  Typography,
  CircularProgress,
  Alert
} from '@mui/material'
import Username from 'components/Username'
import { useUserData, useUserIsAdmin } from 'hooks'
import * as ga4 from 'utils/ga4'
import { LeagueServiceApi } from 'api/LeagueServiceApi'
import { UserServiceApi } from 'api/UserServiceApi'
import UserAvatar from 'components/UserDisplay/UserAvatar'
import UsernameAvatar from 'components/UsernameDisplay/UsernameAvatar'

const LeagueDuoDisplay = ({ league, setOpen, invites, seasonActive }) => {
  const [isDisbandOpen, setIsDisbandOpen] = useState(false)
  const [disbandLoading, setDisbandLoading] = useState(false)
  const [inviteError, setInviteError] = useState('')
  const isAdmin = useUserIsAdmin()
  const userData = useUserData()
  const [disbandDuosCall] = LeagueServiceApi.useDisbandDuosMutation()
  const [getPartner, { data: partner }] =
    UserServiceApi.useLazyGetUsernameByIdCacheQuery()

  useEffect(() => {
    if (invites.accepted) {
      getPartner({ id: invites.accepted }, true)
    }
  }, [getPartner, invites.accepted])

  const handleDisbandClose = () => {
    setIsDisbandOpen(false)
  }

  const disbandDuos = async () => {
    setDisbandLoading(true)
    await disbandDuosCall()
      .unwrap()
      .then(data => {
        if (data.error) {
          setInviteError(data.error)
        }
        setTimeout(() => {
          setInviteError('')
        }, 5000)
        setIsDisbandOpen(false)
        setDisbandLoading(false)
        ga4.sendEvent({
          category: 'Duo Partner',
          action: 'Disband',
          nonInteraction: false
        })
      })
  }

  return (
    <div className="duos-invite-form">
      {inviteError !== '' && (
        <div className="invite-error">
          <Alert severity="error">{inviteError}</Alert>
        </div>
      )}
      <span className="team-label">
        My Duos Team{' '}
        <span
          className="team-disband"
          onClick={() => {
            setIsDisbandOpen(true)
          }}>
          (Disband)
        </span>
        <Modal open={isDisbandOpen} onClose={handleDisbandClose}>
          <Box className="run-modal">
            <Box className="aside-header">
              <span className="teams">Disband Duos Team?</span>
            </Box>
            <Box className="modal-content">
              <Typography component="p" sx={{ paddingTop: 2 }}>
                Are you sure you want to disband your duos team? You will lose
                your progress for this season and it cannot be undone.
              </Typography>
              <Box className="modal-controls" sx={{ marginTop: '8px' }}>
                <Button
                  variant="contained"
                  onClick={handleDisbandClose}
                  sx={{ marginRight: '5px' }}>
                  cancel
                </Button>
                <Button variant="contained" color="error" onClick={disbandDuos}>
                  {disbandLoading && (
                    <CircularProgress
                      style={{
                        color: '#000',
                        height: 15,
                        width: 15,
                        marginRight: 10
                      }}
                    />
                  )}
                  Disband Team
                </Button>
              </Box>
            </Box>
          </Box>
        </Modal>
      </span>
      <div className="my-team">
        <div className="team-member">
          <div className="team-avatar">
            <UserAvatar user={userData} />
          </div>
          <div className="team-username">{userData.display_name}</div>
        </div>
        <div className="team-member">
          <div className="team-avatar">
            <UsernameAvatar username={partner} />
          </div>
          <div className="team-username">
            <Username username={partner} />
          </div>
        </div>
      </div>
      <div className="team-start-run">
        {seasonActive || isAdmin ? (
          <div>
            {/* <Alert severity="error">
              The league is temporarily paused until the Halloween event ends.
            </Alert> */}
            <Button
              className="btn btn-primary"
              onClick={e => {
                e.preventDefault()
                setOpen(true)
              }}>
              Start {league.display_name} Run
            </Button>
          </div>
        ) : (
          <span>
            You are signed up for Fence Premium and can start a run when the
            task starts.
          </span>
        )}
      </div>
    </div>
  )
}

export default LeagueDuoDisplay
