import React, { useCallback, useEffect, useState } from 'react'
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import CardsList from '../cards/CardsList'

import ChargeCardForm from './ChargeCardForm'
import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import CircularProgress from '@mui/material/CircularProgress'
import Box from '@mui/material/Box'
import Alert from '@mui/material/Alert'
import { stripeAmountFormat, stripeDisplayAmount } from 'utils/Stripe'
import { Typography } from '@mui/material'
import { getHostDomain } from 'utils/GetHost'
import { useGetCustomerCards } from '../hooks/Cards'
import { StripeApi } from 'api/Stripe'
import { useUserIsStripeTest } from 'hooks/use-user-isStripeTest'
import { useUserStripe } from 'hooks/use-user-data'
import { NavLink } from 'react-router-dom'

const ChargeForm = ({ user, route, selectedBounty }) => {
  const isStripeTest = useUserIsStripeTest()
  const stripeKey = isStripeTest
    ? process.env.REACT_APP_STRIPE_TEST_KEY
    : process.env.REACT_APP_STRIPE_KEY
  const stripePromise = loadStripe(stripeKey)

  const { id } = user
  const stripe = useUserStripe()
  const { customer: customerId, default_payment_method = '' } = stripe
  const [createPayment, { data: payment = {} }] =
    StripeApi.useCreatePaymentMutation()
  const { client_secret: clientSecret = '' } = payment
  const { data: cards = [], isLoading } = useGetCustomerCards(stripe?.customer)
  const [paymentMethod, setPaymentMethod] = useState(default_payment_method)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [error, setError] = useState(false)
  const [success, setSuccess] = useState(false)
  const [amount, setAmount] = useState('$10.00')
  const [rawAmount, setRawAmount] = useState(1000)
  const [fees, setFees] = useState(100)
  const [total, setTotal] = useState('$11.00')
  const [chargeTotal, setChargeTotal] = useState(1100)
  const [getPaymentStatus] = StripeApi.useGetPaymentStatusMutation()

  const showError = () => {
    setError(true)
    setTimeout(() => {
      setError(false)
    }, 5000)
  }

  const showSuccess = () => {
    setSuccess(true)
    setTimeout(() => {
      setSuccess(false)
    }, 5000)
  }

  const checkStatus = useCallback(async () => {
    const urlParams = new URLSearchParams(window.location.search)
    const paymentIntent = urlParams.get('payment_intent')
    if (paymentIntent) {
      getPaymentStatus({ paymentSecret: paymentIntent }).then(response => {
        if (response.data.status === 'succeeded') {
          showSuccess()
        }
      })
    }
  }, [getPaymentStatus])

  useEffect(() => {
    checkStatus()
  }, [checkStatus])

  useEffect(() => {
    if (paymentMethod === 'new' && !clientSecret) {
      createPayment({
        userId: id,
        amount: chargeTotal,
        customerId
      })
    }
  }, [paymentMethod, clientSecret, id, chargeTotal, customerId, createPayment])

  const appearance = {
    theme: 'night'
  }

  const paymentOnChange = paymentMethod => {
    setPaymentMethod(paymentMethod)
  }

  const createCharge = async event => {
    event.preventDefault()
    if (!customerId) {
      return
    }
    setIsSubmitting(true)

    await createPayment({
      userId: id,
      amount: chargeTotal,
      rawAmount: rawAmount,
      customerId,
      paymentMethod,
      type: route && route.split('/').length > 0 ? route.split('/')[1] : '',
      bountyId: selectedBounty ? selectedBounty : '',
      returnUrl: `${getHostDomain()}${route}`
    })
      .then(response => {
        setIsSubmitting(false)
        if (response.data.status === 'succeeded') {
          window.location = `${getHostDomain()}${route}?payment_intent=${
            response.data.id
          }`
        }
      })
      .catch(() => {
        showError()
        setIsSubmitting(false)
      })
  }

  const onSubmit = event => {
    event.preventDefault()
  }

  const handleChangeAmount = event => {
    const { value } = event.target
    let changedValue = value.replace(/[^\d.-]/g, '')

    if (changedValue.split('.').length > 2) {
      return false
    }

    if (
      changedValue.split('.').length === 2 &&
      changedValue.split('.')[1].length > 2
    ) {
      return false
    }

    setAmount('$' + changedValue)
    const convertAmount = stripeAmountFormat(
      changedValue > 0 ? changedValue : 0
    )
    setRawAmount(convertAmount)
    const fees = Math.round(convertAmount * 0.07) + 30
    const total = stripeDisplayAmount(convertAmount + fees)
    const chargeTotal = convertAmount + fees
    setFees(fees)
    setTotal(total)
    setChargeTotal(chargeTotal)
  }

  if (isLoading) {
    return (
      <Box sx={{ marginTop: '10px', textAlign: 'center' }}>
        <CircularProgress
          style={{
            color: '#7ab903',
            height: 75,
            width: 75
          }}
        />
      </Box>
    )
  }

  return (
    <React.Fragment>
      <form onSubmit={onSubmit}>
        <TextField
          fullWidth
          variant="outlined"
          placeholder="Amount"
          value={amount}
          onChange={handleChangeAmount}
        />
        {/* <Button type="submit">Next</Button> */}
      </form>

      {chargeTotal !== 0 && (
        <div>
          <span
            className="subhead green"
            style={{ paddingTop: 20, paddingBottom: 0 }}>
            Select a Payment Method
          </span>
          <span className="paragraph">
            To manage your payment methods, visit{' '}
            <NavLink to={'/account'}>your account</NavLink> Page.
          </span>
          <CardsList
            cards={cards}
            paymentMethod={paymentMethod}
            paymentOnChange={paymentOnChange}
          />
        </div>
      )}

      {paymentMethod === 'new' && clientSecret && chargeTotal !== 0 && (
        <Box marginTop="10px">
          <Elements
            stripe={stripePromise}
            options={{ clientSecret, appearance }}>
            <ChargeCardForm callback={setPaymentMethod} route={route} />
          </Elements>
        </Box>
      )}
      {amount !== 0 && (
        <div style={{ paddingTop: '20px' }}>
          <span className="disclaimer" style={{ paddingBottom: '30px' }}>
            By submitting this form you agree to pay the full amount specified.
            All payments are final and non-refundable.
          </span>
          <Typography variant="p" component="p" sx={{ textAlign: 'right' }}>
            Amount: {amount}
          </Typography>
          <Typography
            variant="caption"
            component="p"
            sx={{ textAlign: 'right' }}>
            fees: {stripeDisplayAmount(fees)}
          </Typography>
          <Box sx={{ textAlign: 'right' }}>
            <Typography
              variant="p"
              component="span"
              sx={{ marginRight: '5px' }}>
              Total:
            </Typography>
            <Typography variant="h5" component="span">
              {total}
            </Typography>
          </Box>
        </div>
      )}

      <div>
        {error && (
          <Alert
            severity="error"
            sx={{ marginBottom: '10px', marginTop: '10px' }}>
            There was an issue submitting your payment. Please try again. If the
            issue persists, please notify a site administrator in Discord.
          </Alert>
        )}
        {success && (
          <Alert
            severity="success"
            sx={{ marginBottom: '10px', marginTop: '10px' }}>
            Your payment was successful, thank you!
          </Alert>
        )}
      </div>

      {paymentMethod && paymentMethod !== 'new' && chargeTotal >= 1100 && (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'right'
          }}>
          {route === '/bounty' && (
            <NavLink
              style={{ marginRight: 20, color: '#707070' }}
              to={'/bounty'}>
              Cancel
            </NavLink>
          )}
          <Button
            variant="contained"
            sx={{ marginTop: '10px' }}
            onClick={createCharge}
            disabled={isSubmitting}>
            {isSubmitting && (
              <CircularProgress
                style={{
                  color: '#000',
                  height: 20,
                  width: 20,
                  marginRight: 10
                }}
              />
            )}
            {isSubmitting ? <span>Sending...</span> : <span>Pay {total}</span>}
          </Button>
        </div>
      )}
    </React.Fragment>
  )
}

export default ChargeForm
