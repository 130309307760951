import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { auth } from 'configs/firebaseConfig'
import { getHostFunctions } from 'utils/GetHost'

const base = getHostFunctions()
const prefix = '/stripe'

const getUserToken = async () => {
  const token = await auth.currentUser?.getIdToken()
  return token
}

export const StripeApi = createApi({
  reducerPath: 'StripeApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${base}${prefix}`,
    prepareHeaders: async headers => {
      headers.set('Content-Type', 'application/json')
      const token = await getUserToken()
      if (token) headers.set('Authorization', `Bearer ${token}`)
      return headers
    }
  }),
  tagTypes: [
    'StripeCustomer',
    'StripeCards',
    'StripeSubscriptions',
    'StripeAccount',
    'StripeBalance',
    'StripeAccountUsers'
  ],
  endpoints: build => ({
    getPublishKey: build.query({
      query: () => `/publish-key`
    }),
    createPayment: build.mutation({
      /**
       *
       * @param { userId, amount, customerId, paymentMethod, returnUrl, description } body
       * @returns
       */
      query: body => ({
        url: `/payment/create`,
        method: 'POST',
        body
      }),
      transformResponse: response => response.payment,
      invalidatesTags: ['StripeBalance']
    }),
    getCustomerCards: build.query({
      /**
       *
       * @param { customerId, type } body
       * @returns
       */
      query: body => ({
        url: `/customer/methods`,
        method: 'POST',
        body
      }),
      transformResponse: response => response.methods.data,
      providesTags: result =>
        result
          ? [
              ...result.map(({ id }) => ({ type: 'StripeCards', id })),
              'StripeCards'
            ]
          : ['StripeCards']
    }),
    getCard: build.mutation({
      /**
       *
       * @param { paymentMethodId } body
       * @returns
       */
      query: body => ({
        url: `/card/details`,
        method: 'POST',
        body
      }),
      transformResponse: response => response.cardDetails,
      providesTags: result =>
        result
          ? [{ type: 'StripeCards', id: result.id }, 'StripeCards']
          : ['StripeCards']
    }),
    createCard: build.mutation({
      /**
       *
       * @param { customerId } body
       * @returns
       */
      query: body => ({
        url: `/card/create`,
        method: 'POST',
        body
      }),
      transformResponse: response => response.clientSecret
    }),
    detachCard: build.mutation({
      /**
       *
       * @param { paymentMethodId } body
       * @returns
       */
      query: body => ({
        url: `/card/detach`,
        method: 'POST',
        body
      }),
      transformResponse: response => response.success,
      invalidatesTags: ['StripeCards']
    }),
    setDefaultCard: build.mutation({
      /**
       *
       * @param { customerId, customerData: { invoice_settings: { default_payment_method } } } payload
       * @returns
       */
      query: payload => {
        const body = {
          customerId: payload.customerId,
          customerData: {
            invoice_settings: {
              default_payment_method: payload.paymentId
            }
          }
        }
        return {
          url: `/customer/update`,
          method: 'POST',
          body
        }
      },
      transformResponse: response => response.customerDetails,
      invalidatesTags: ['StripeCards']
    }),
    getCardStatus: build.mutation({
      /**
       *
       * @param { secretIntent } body
       * @returns
       */
      query: body => ({
        url: `/card`,
        method: 'POST',
        body
      })
    }),
    getPaymentStatus: build.mutation({
      /**
       *
       * @param { paymentSecret } body
       * @returns
       */
      query: body => ({
        url: `/payment/status`,
        method: 'POST',
        body
      }),
      transformResponse: response => response.payment
    }),
    createCustomer: build.mutation({
      /**
       *
       * @param { email, userId } body
       * @returns
       */
      query: body => ({
        url: `/customer/create`,
        method: 'POST',
        body
      }),
      transformResponse: response => response.customerDetails,
      providesTags: ['StripeCustomer']
    }),
    getCustomer: build.query({
      /**
       *
       * @param { customerId } body
       * @returns
       */
      query: body => ({
        url: `/customer`,
        method: 'POST',
        body
      }),
      transformResponse: response => response.customerDetails,
      providesTags: ['StripeCustomer']
    }),
    getSubscriptions: build.query({
      /**
       *
       * @param { customerId, status } body
       * @returns
       */
      query: body => ({
        url: `/subscriptions`,
        method: 'POST',
        body
      }),
      transformResponse: response => response.subscriptions,
      providesTags: results =>
        results
          ? [
              ...results.data.map(({ id }) => ({
                type: 'StripeSubscriptions',
                id
              })),
              'StripeSubscriptions'
            ]
          : ['StripeSubscriptions']
    }),
    createSubscription: build.mutation({
      /**
       *
       * @param { userId, customerId, items, defaultPaymentMethod, metadata } body
       * @returns
       */
      query: body => ({
        url: `/subscriptions/create`,
        method: 'POST',
        body
      }),
      providesTags: result =>
        result
          ? [
              { type: 'StripeSubscriptions', id: result.id },
              'StripeSubscriptions'
            ]
          : ['StripeSubscriptions']
    }),
    deleteSubscription: build.mutation({
      /**
       *
       * @param { subscriptionId } body
       * @returns
       */
      query: body => ({
        url: `/subscriptions/delete`,
        method: 'POST',
        body
      }),
      transformResponse: response => response.subscription,
      invalidatesTags: ['StripeSubscriptions']
    }),
    updateSubscription: build.mutation({
      /**
       *
       * @param { subscriptionId, subscriptionData: { cancel_at_period_end } } body
       * @returns
       */
      query: body => ({
        url: `/subscriptions/update`,
        method: 'POST',
        body
      }),
      transformResponse: response => response.subscription,
      invalidatesTags: ['StripeSubscriptions']
    }),
    saveSubscription: build.mutation({
      /**
       *
       * @param { userId, subscription, premium } body
       * @returns
       */
      query: body => ({
        url: `/subscriptions/save`,
        method: 'POST',
        body
      }),
      transformResponse: response => response.success,
      invalidatesTags: ['StripeSubscriptions']
    }),
    getSubscriptionStatus: build.query({
      /**
       *
       * @param { customerId, subscriptionId } body
       * @returns
       */
      query: body => ({
        url: `/subscription/status`,
        method: 'POST',
        body
      })
    }),
    getCharges: build.query({
      /**
       *
       * @param { customerId, limit } body
       * @returns
       */
      query: body => ({
        url: `/charges`,
        method: 'POST',
        body
      }),
      transformResponse: response => response.charges
    }),
    getInvoices: build.query({
      /**
       *
       * @param { customerId, limit } body
       * @returns
       */
      query: body => ({
        url: `/invoices`,
        method: 'POST',
        body
      }),
      transformResponse: response => response.transfers
    }),
    getTransfer: build.mutation({
      /**
       *
       * @param { accountId, limit } body
       * @returns
       */
      query: body => ({
        url: `/account/transfers`,
        method: 'POST',
        body
      }),
      transformResponse: response => response.transfers
    }),
    userDelete: build.mutation({
      /**
       *
       * @param { accountId, customerId } body
       * @returns
       */
      query: body => ({
        url: `/user/delete`,
        method: 'POST',
        body
      }),
      transformResponse: response => response.success
    }),
    getAccount: build.mutation({
      /**
       *
       * @param { accountId } body
       * @returns
       */
      query: body => ({
        url: `/account`,
        method: 'POST',
        body
      }),
      transformResponse: response => response.account,
      providesTags: ['StripeAccount']
    }),
    createAccount: build.mutation({
      /**
       *
       * @param { userId, email, returnUrl, refreshUrl  } body
       * @returns
       */
      query: body => ({
        url: `/account/create`,
        method: 'POST',
        body
      }),
      providesTags: ['StripeCustomer']
    }),
    createAccountTransfer: build.mutation({
      /**
       *
       * @param { accountId, amount, currency } body
       * @returns
       */
      query: body => ({
        url: `/account/transfers/create`,
        method: 'POST',
        body
      }),
      transformResponse: response => response.transfer,
      invalidatesTags: ['StripeBalance']
    }),
    getAffiliateDetails: build.query({
      /**
       * @param { accountId } body
       * @returns
       */
      query: body => ({
        url: `/affiliate`,
        method: 'POST',
        body
      }),
      transformResponse: response => response.account,
      providesTags: ['StripeAccount']
    }),
    getAccountOnboardingLink: build.query({
      /**
       * @param { accountId, returnUrl, refreshUrl } body
       * @returns
       */
      query: body => ({
        url: `/account/onboarding/link`,
        method: 'POST',
        body
      }),
      transformResponse: response => response.accountLinks
    }),
    getAccountLoginLink: build.query({
      /**
       * @param { accountId } body
       * @returns
       */
      query: body => ({
        url: `/account/login/link`,
        method: 'POST',
        body
      }),
      transformResponse: response => response.accountLinks
    }),
    getBalance: build.query({
      /**
       *
       * @returns
       */
      query: () => ({
        url: `/balance`
      }),
      transformResponse: response => response.balance,
      providesTags: ['StripeBalance']
    }),
    createTopUp: build.mutation({
      /**
       *
       * @param { amount, currency, description } body
       * @returns
       */
      query: body => ({
        url: `/top-up`,
        method: 'POST',
        body
      }),
      transformResponse: response => response.topUp,
      invalidatesTags: ['StripeBalance']
    }),
    getAccountUsers: build.query({
      /**
       *
       * @returns
       */
      query: () => ({
        url: `/account/users`
      }),
      transformResponse: response => response.users,
      providesTags: ['StripeAccountUsers']
    })
  })
})
