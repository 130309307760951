import { useState } from 'react'
import {
  Box,
  ListItem,
  ToggleButton,
  ListItemAvatar,
  ListItemText,
  Avatar,
  IconButton,
  Divider,
  Stack,
  Switch,
  FormGroup,
  FormControlLabel,
  Button
} from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import ExpandLessIcon from '@mui/icons-material/ExpandLess'
import { styled } from '@mui/material/styles'
import OwnerService from 'services/OwnerService'
import { useUserIsAdmin, useUserIsOwner } from 'hooks'
import { StripeApi } from 'api/Stripe'
import { useUserSubscription } from 'hooks/use-user-data'

const StyledToggleButton = styled(ToggleButton)(({ theme }) => ({
  '&.Mui-selected': {
    color: '#b72824',
    backgroundColor: '#181818'
  },
  ['@media (max-width:410px)']: {
    '& > .MuiTypography-root': {
      display: 'none'
    }
  }
}))

const StyledListItem = styled(ListItem)(({ theme }) => ({
  ['@media (max-width:768px)']: {
    'padding': '20px',
    'display': 'block',
    'textAlign': 'center',
    '& .MuiAvatar-circular': {
      margin: '0 auto',
      width: 80,
      height: 80
    },
    '& > .MuiListItemText-root .MuiTypography-root': {
      fontWeight: 500
    }
  }
}))

const UserDisplay = ({ user, userId, defaultOpen = false }) => {
  const [isOpen, setIsOpen] = useState(defaultOpen)
  const { scopes = [] } = user
  const subscription = useUserSubscription()
  const isOwner = useUserIsOwner()
  const isAdmin = useUserIsAdmin()
  const userIsOwner = scopes?.includes('owner')
  const userIsAdmin = scopes?.includes('admin')
  const userIsBanned = scopes?.includes('banned')
  const userIsEditor = scopes?.includes('editor')
  const userIsVodder = scopes?.includes('vodder')
  const userIsBeta = scopes?.includes('beta')
  const userIsStripeTest = scopes?.includes('stripeTest')
  const userIsPromotion = scopes?.includes('promotion')
  const [cancelUserSubscription] = StripeApi.useDeleteSubscriptionMutation()

  const expand = event => {
    event.preventDefault()
    setIsOpen(!isOpen)
  }

  const setAdmin = event => {
    const { checked = false } = event.target
    if (checked) {
      OwnerService.removeAdmin(userId)
    } else {
      OwnerService.addAdmin(userId)
    }
  }

  const setBanned = event => {
    const { checked = false } = event.target
    if (checked) {
      OwnerService.removeBanned(userId)
    } else {
      OwnerService.addBanned(userId)
      if (subscription?.id) {
        cancelUserSubscription({ subscriptionId: subscription?.id })
      }
    }
  }

  const setEditor = event => {
    const { checked = false } = event.target
    if (checked) {
      OwnerService.removeEditor(userId)
    } else {
      OwnerService.addEditor(userId)
    }
  }

  const setVodder = event => {
    const { checked = false } = event.target
    if (checked) {
      OwnerService.removeVodder(userId)
    } else {
      OwnerService.addVodder(userId)
    }
  }

  const setBeta = event => {
    const { checked = false } = event.target
    if (checked) {
      OwnerService.removeBeta(userId)
    } else {
      OwnerService.addBeta(userId)
    }
  }

  const setStripeTest = event => {
    const { checked = false } = event.target
    if (checked) {
      OwnerService.removeStripeTest(userId)
    } else {
      OwnerService.addStripeTest(userId)
    }
  }

  const setPromotion = event => {
    const { checked = false } = event.target
    if (checked) {
      OwnerService.removePromotionRole(userId)
    } else {
      OwnerService.addPromotionRole(userId)
    }
  }

  const removeUser = event => {
    event.preventDefault()
    OwnerService.removeAllRoles(userId)
  }

  if (userIsOwner) return

  return (
    <Box>
      <StyledListItem alignItems="center">
        <ListItemAvatar>
          <Avatar alt={user.display_name} src={user.profile_image_url} />
        </ListItemAvatar>
        <ListItemText primary={user.display_name} />
        {userIsBanned && <span className="is-banned">Banned</span>}
        <IconButton onClick={expand}>
          {isOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </IconButton>
      </StyledListItem>
      {isOpen && (
        <StyledListItem sx={{ paddingBottom: '15px' }}>
          <Stack spacing={2}>
            <FormGroup>
              {((isOwner && !userIsOwner) || (isAdmin && !userIsOwner)) && (
                <>
                  {isOwner && (
                    <FormControlLabel
                      control={
                        <Switch checked={userIsAdmin} onChange={setAdmin} />
                      }
                      label="Admin"
                    />
                  )}
                  {isAdmin && (
                    <FormControlLabel
                      control={
                        <Switch checked={userIsBanned} onChange={setBanned} />
                      }
                      label="Banned"
                    />
                  )}
                  <FormControlLabel
                    control={
                      <Switch checked={userIsEditor} onChange={setEditor} />
                    }
                    label="Editor"
                  />
                  <FormControlLabel
                    control={
                      <Switch checked={userIsVodder} onChange={setVodder} />
                    }
                    label="Vodder"
                  />
                  <FormControlLabel
                    control={<Switch checked={userIsBeta} onChange={setBeta} />}
                    label="Beta"
                  />
                  <FormControlLabel
                    control={
                      <Switch
                        checked={userIsStripeTest}
                        onChange={setStripeTest}
                      />
                    }
                    label="Stripe Test"
                  />
                  <FormControlLabel
                    control={
                      <Switch
                        checked={userIsPromotion}
                        onChange={setPromotion}
                      />
                    }
                    label="Promotional User"
                  />
                </>
              )}
            </FormGroup>
            {((isOwner && !userIsOwner) || (isAdmin && !userIsOwner)) && (
              <Button color="error" variant="contained" onClick={removeUser}>
                Delete All Roles
              </Button>
            )}
          </Stack>
        </StyledListItem>
      )}
      <Divider />
    </Box>
  )
}

export default UserDisplay
