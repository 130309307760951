import React, { useState } from 'react'
import { useStripe, useElements, PaymentElement } from '@stripe/react-stripe-js'
import Button from '@mui/material/Button'
import { CircularProgress, Alert } from '@mui/material'

import { getHostDomain } from '../../../utils/GetHost'

const ChargeCardForm = ({ route }) => {
  const [isSaving, setIsSaving] = useState(false)
  const [error, setError] = useState(false)
  const stripe = useStripe()
  const elements = useElements()

  const showError = () => {
    setError(true)
    setTimeout(() => {
      setError(false)
    }, 3000)
  }

  const handleSubmit = async event => {
    event.preventDefault()
    setIsSaving(true)

    if (!stripe || !elements) {
      console.log('Stripe or Elements not initialized.')
      setIsSaving(false)
      return
    }

    await stripe
      .confirmPayment({
        elements,
        confirmParams: {
          return_url: `${getHostDomain()}${route}`
        }
      })
      .catch(response => {
        setIsSaving(false)
        showError()
      })
  }

  return (
    <form onSubmit={handleSubmit}>
      {error && (
        <Alert severity="error" sx={{ marginBottom: '10px' }}>
          There was an issue saving your card, please try again.
        </Alert>
      )}
      <PaymentElement />
      <Button
        variant="contained"
        type="submit"
        disabled={isSaving}
        sx={{ marginTop: '10px' }}>
        {isSaving && (
          <CircularProgress
            style={{
              color: '#000',
              height: 20,
              width: 20,
              marginRight: 10
            }}
          />
        )}
        {isSaving ? <span>Sending...</span> : <span>Send</span>}
      </Button>
    </form>
  )
}

export default ChargeCardForm
