import React from 'react'

const EventHighlights = ({ tournament }) => {
  return (
    tournament?.highlightsURL && (
      <div className="events-box events-highlights">
        <div className="events-box-header">
          <span className="events-title">Highlights</span>
        </div>
        <div className="events-embed-container">
          <div className="events-embed">
            <iframe
              src={`${tournament?.highlightsURL}&parent=${window.location.hostname}`}
              frameBorder="0"
              allowFullScreen="true"
              scrolling="no"></iframe>
          </div>
        </div>
      </div>
    )
  )
}

export default EventHighlights
