import Firebase from 'utils/Firebase'
import { getFirestore } from 'firebase/firestore'
import { collection, onSnapshot, where, query, or } from 'firebase/firestore'
import { getHostFunctions } from 'utils/GetHost'
import { getAuth } from 'firebase/auth'

const db = getFirestore(Firebase)
const auth = getAuth()

async function getEnabledBounties(callback) {
  const q = query(
    collection(db, 'bounties', 'settings', 'bounties'),
    where('enabled', '==', true)
  )
  const unsubscribe = onSnapshot(q, querySnapshot => {
    const docs = []
    querySnapshot.forEach(doc => {
      docs.push({ ...doc.data(), id: doc.id })
    })
    if (typeof callback === 'function') {
      callback(docs)
    }
  })
  return { unsubscribe }
}

async function getActivatedBounties(callback) {
  const q = query(
    collection(db, 'bounties', 'settings', 'activated'),
    where('status', '==', 'active')
  )
  const unsubscribe = onSnapshot(q, querySnapshot => {
    const docs = []
    querySnapshot.forEach(doc => {
      docs.push({ ...doc.data(), id: doc.id })
    })
    if (typeof callback === 'function') {
      callback(docs)
    }
  })
  return { unsubscribe }
}

async function getCompletedBounties(callback) {
  const q = query(
    collection(db, 'bounties', 'settings', 'activated'),
    where('status', '==', 'completed')
  )
  const unsubscribe = onSnapshot(q, querySnapshot => {
    const docs = []
    querySnapshot.forEach(doc => {
      docs.push({ ...doc.data(), id: doc.id })
    })
    if (typeof callback === 'function') {
      callback(docs)
    }
  })
  return { unsubscribe }
}

async function getActivatedOrPendingBounties(callback) {
  const q = query(
    collection(db, 'bounties', 'settings', 'activated'),
    or(where('status', '==', 'active'), where('status', '==', 'pending'))
  )
  const unsubscribe = onSnapshot(q, querySnapshot => {
    const docs = []
    querySnapshot.forEach(doc => {
      docs.push({ ...doc.data(), id: doc.id })
    })
    if (typeof callback === 'function') {
      callback(docs)
    }
  })
  return { unsubscribe }
}

async function getEntriesForBounty(id, callback) {
  const q = query(
    collection(db, 'bounties', 'settings', 'activated', id, 'entries')
  )
  const unsubscribe = onSnapshot(q, querySnapshot => {
    const docs = []
    querySnapshot.forEach(doc => {
      docs.push({ ...doc.data(), id: doc.id })
    })
    if (typeof callback === 'function') {
      callback(docs)
    }
  })
  return { unsubscribe }
}

async function submitBountyEntry(id, twitchLink, callback) {
  const token = await auth.currentUser?.getIdToken()
  const result = await fetch(
    getHostFunctions() + `/submitBountyEntry?id=${id}&twitchLink=${twitchLink}`,
    {
      headers: {
        authorization: `Bearer ${token}`
      }
    }
  )
  if (result.status === 200) {
    result.json().then(res => {
      if (typeof callback === 'function') {
        callback(res)
      }
    })
  }
}

async function submitBountyWinner(id, winner, callback) {
  const token = await auth.currentUser?.getIdToken()
  const result = await fetch(
    getHostFunctions() + `/submitBountyWinner?id=${id}&winner=${winner}`,
    {
      headers: {
        authorization: `Bearer ${token}`
      }
    }
  )
  if (result.status === 200) {
    result.json().then(res => {
      if (typeof callback === 'function') {
        callback(res)
      }
    })
  }
}

const BountyService = {
  getEnabledBounties,
  getActivatedBounties,
  submitBountyEntry,
  getEntriesForBounty,
  getActivatedOrPendingBounties,
  getCompletedBounties,
  submitBountyWinner
}

export default BountyService
