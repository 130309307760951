import React from 'react'
import Button from '@mui/material/Button'
import { useAppSettings } from 'hooks'

const LoginButton = ({ text }) => {
  const appSettings = useAppSettings()
  return (
    <a
      className="btn btn-login"
      onClick={e => {
        e.preventDefault()
        window.open('/popup.html?ver=1', 'name', 'height=585,width=400')
      }}>
      {appSettings.closedBeta === true
        ? 'Join Waitlist'
        : text
        ? text
        : 'Login with Twitch'}
    </a>
  )
}

export default LoginButton
