import React, { useCallback, useEffect } from 'react'
import { TournamentServiceApi } from 'api/TournamentServiceApi'
import USAFlag from '../assets/img/icon-flag-usa.jpg'
import IconSocialX from '../assets/img/icon-social-x.jpg'
import IconSocialTwitch from '../assets/img/icon-social-twitch.jpg'
import IconSocialInstagram from '../assets/img/icon-social-instagram.jpg'
import IconSocialYoutube from '../assets/img/icon-social-youtube.jpg'
import IconSocialTiktok from '../assets/img/icon-social-tiktok.jpg'
import { CircularProgress } from '@mui/material'
import { useParams } from 'react-router'
import moment from 'moment'
import { Link } from 'react-router-dom'

const PlayerPage = () => {
  const params = useParams()
  const [matches, setMatches] = React.useState([])
  const [events, setEvents] = React.useState([])
  const [teams, setTeams] = React.useState([])

  const { data: player, isLoading } =
    TournamentServiceApi.useGetPlayersByIdsQuery({
      ids: parseInt(params.id, 10)
    })

  const [getMatchesApi] = TournamentServiceApi.useLazyGetMatchesByIdsQuery()

  const getMatchesAsync = useCallback(async () => {
    const asyncMatches = await Promise.all(
      player?.matches.map(async match => {
        return await getMatchesApi({ ids: match }, true).unwrap()
      })
    )
    setMatches(asyncMatches)
  }, [player, getMatchesApi])

  useEffect(() => {
    if (player?.matches?.length > 0) {
      getMatchesAsync()
    }
  }, [player])

  const [getTeamsApi] = TournamentServiceApi.useLazyGetTeamsByIdsQuery()

  const getTeamsAsync = useCallback(async () => {
    const teams = []
    matches.forEach(m => {
      m.teams.forEach(t => {
        if (!teams.includes(t.id)) {
          teams.push(t.id)
        }
      })
    })
    const asyncTeams = await Promise.all(
      teams?.map(async t => {
        return await getTeamsApi({ ids: t }, true).unwrap()
      })
    )
    setTeams(asyncTeams)
  }, [matches])

  useEffect(() => {
    if (matches?.length > 0) {
      getTeamsAsync()
    }
  }, [matches])

  const [getEventsApi] = TournamentServiceApi.useLazyGetEventsByIdsQuery()

  const getEventsAsync = useCallback(async () => {
    const asyncEvents = await Promise.all(
      player?.events?.map(async e => {
        return await getEventsApi({ ids: e }, true).unwrap()
      })
    )
    setEvents(asyncEvents)
  }, [player])

  useEffect(() => {
    if (player?.events?.length > 0) {
      getEventsAsync()
    }
  }, [player])

  return (
    <>
      <div>
        <div className="aside-header aside-team">
          <span className="team">
            {isLoading ? 'Loading Player...' : player?.display_name}
          </span>
        </div>
        {isLoading ? (
          <div className="content-panel">
            <div className="content-loader">
              <CircularProgress
                style={{
                  color: '#7ab903',
                  height: 55,
                  width: 55
                }}
              />
            </div>
          </div>
        ) : (
          <div className="content-panel">
            <div className="team-section team-header">
              <div className="team-desc">
                <img className="team-avatar" src={player?.avatar} />
                <div className="team-details">
                  <span className="team-name">{player?.display_name}</span>
                  <span className="player-socials">
                    {player?.socials?.map(social => {
                      return (
                        <a href={social.url} target="_blank">
                          {social.id === 'twitter' && <img src={IconSocialX} />}
                          {social.id === 'twitch' && (
                            <img src={IconSocialTwitch} />
                          )}
                          {social.id === 'instagram' && (
                            <img src={IconSocialInstagram} />
                          )}
                          {social.id === 'youtube' && (
                            <img src={IconSocialYoutube} />
                          )}
                          {social.id === 'tiktok' && (
                            <img src={IconSocialTiktok} />
                          )}
                        </a>
                      )
                    })}
                  </span>
                </div>
              </div>
              <div className="team-rank-details">
                <span className="team-rank">TBD</span>
                <span className="team-rank-label">World Ranking</span>
                <span className="team-amount">$0</span>
                <span className="team-amount-label">Total Winnings</span>
              </div>
            </div>
            <div className="events-box events-history">
              <div className="events-box-header">
                <span className="events-title">Match History</span>
              </div>
              <div className="match-head-matches">
                {matches?.length > 0 &&
                teams?.length > 0 &&
                events?.length > 0 ? (
                  [...matches]
                    .sort((a, b) => b.starts._seconds - a.starts._seconds)
                    ?.map(match => {
                      return (
                        <Link
                          key={match.id}
                          to={`/match/${teams
                            ?.filter(t => t.id === match.teams[0].id)[0]
                            ?.display_name?.replace(/ /g, '-')
                            .toLowerCase()}-vs-${teams
                            ?.filter(t => t.id === match.teams[1].id)[0]
                            ?.display_name?.replace(/ /g, '-')
                            .toLowerCase()}-${events
                            ?.filter(e => e.id === match.event)[0]
                            ?.display_name?.replace(/ /g, '-')
                            .toLowerCase()}/${match.id}`}
                          className="match-head-match">
                          <div className="match-head-thumb">
                            <div
                              className="match-head-thumb-img"
                              style={{
                                backgroundImage: `url(${
                                  events?.filter(e => e.id === match.event)[0]
                                    ?.poster
                                })`
                              }}></div>

                            <span className="match-head-info">
                              <span className="match-head-title">
                                {
                                  events?.filter(e => e.id === match?.event)[0]
                                    ?.display_name
                                }
                              </span>
                              <span className="match-head-subhead">
                                {match.roundLabel}
                              </span>
                            </span>
                          </div>
                          <span className="match-head-match-results">
                            <img
                              className="match-head-team-logo"
                              src={
                                teams?.filter(
                                  team =>
                                    team.id ===
                                    match?.teams?.filter(t =>
                                      player?.teams?.includes(t.id)
                                    )[0]?.id
                                )[0]?.avatar
                              }
                            />
                            <span
                              className={`match-head-match-score ${
                                match?.teams?.filter(t =>
                                  player?.teams?.includes(t.id)
                                )[0]?.winner === true
                                  ? 'winner'
                                  : 'loser'
                              }`}>
                              {
                                match?.teams?.filter(t =>
                                  player?.teams?.includes(t.id)
                                )[0]?.score
                              }
                            </span>
                            <span
                              className={`match-head-match-score ${
                                match?.teams?.filter(
                                  t => !player?.teams?.includes(t.id)
                                )[0]?.winner === true
                                  ? 'winner'
                                  : 'loser'
                              }`}>
                              {
                                match?.teams?.filter(
                                  t => !player?.teams?.includes(t.id)
                                )[0]?.score
                              }
                            </span>
                            <img
                              className="match-head-team-logo"
                              src={
                                teams?.filter(
                                  team =>
                                    team.id ===
                                    match?.teams?.filter(
                                      t => !player?.teams?.includes(t.id)
                                    )[0]?.id
                                )[0]?.avatar
                              }
                            />
                          </span>
                          <span className="match-head-date">
                            {moment(match?.starts?._seconds * 1000).format(
                              'MMM DD, YYYY'
                            )}
                            <br />
                            {moment(match?.starts?._seconds * 1000).format(
                              'hh:mm A'
                            )}
                          </span>
                        </Link>
                      )
                    })
                ) : (
                  <div className="content-panel">
                    <div className="content-loader">
                      <CircularProgress
                        style={{
                          color: '#7ab903',
                          height: 55,
                          width: 55
                        }}
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="events-box events-history">
              <div className="events-box-header">
                <span className="events-title">Event History</span>
              </div>
              {events?.length > 0 ? (
                <div className="events-box-content">
                  {[...events]
                    .sort((a, b) => b.starts._seconds - a.starts._seconds)
                    .map(tournament => {
                      return (
                        <Link
                          key={tournament.id}
                          className="events-row"
                          to={`/event/${tournament.slug}/${tournament.id}`}>
                          <div className="events-row-desc">
                            <div
                              className="events-row-thumb"
                              style={{
                                backgroundImage: `url(${tournament.poster})`
                              }}></div>
                            <div className="events-row-desc-content">
                              <span className="events-row-title">
                                {tournament.display_name}
                              </span>
                              <span className="events-row-location">
                                <img className="flag-icon" src={USAFlag} />
                                <span>
                                  {tournament.location.display_name} (
                                  {tournament.type})
                                </span>
                              </span>
                            </div>
                          </div>
                          <div className="events-row-info">
                            <div
                              className={`events-row-game game-${tournament.game}`}></div>
                            <div className="events-row-prize">
                              <span className="events-row-info-label">
                                Prize
                              </span>
                              <span className="events-row-info-value">
                                ${tournament.prizepool.amount}
                              </span>
                            </div>
                            <span className="calendar">
                              <span
                                className={`label ${
                                  moment(
                                    tournament.ends._seconds * 1000
                                  ).isBefore(moment())
                                    ? 'ended'
                                    : moment(
                                        tournament.starts._seconds * 1000
                                      ).isAfter(moment())
                                    ? 'upcoming'
                                    : moment(
                                        tournament.starts._seconds * 1000
                                      ).isBefore(moment()) &&
                                      moment(
                                        tournament.ends._seconds * 1000
                                      ).isAfter(moment())
                                    ? 'ongoing'
                                    : ''
                                }`}>
                                {moment(
                                  tournament.ends._seconds * 1000
                                ).isBefore(moment())
                                  ? 'Ended'
                                  : moment(
                                      tournament.starts._seconds * 1000
                                    ).isAfter(moment())
                                  ? 'Upcoming'
                                  : moment(
                                      tournament.starts._seconds * 1000
                                    ).isBefore(moment()) &&
                                    moment(
                                      tournament.ends._seconds * 1000
                                    ).isAfter(moment())
                                  ? 'Ongoing'
                                  : ''}
                              </span>
                              <span className="day">
                                {moment(tournament.ends._seconds * 1000).format(
                                  'ddd'
                                )}
                              </span>
                              <span className="date">
                                {moment(tournament.ends._seconds * 1000).format(
                                  'MMM DD YY'
                                )}
                              </span>
                            </span>
                          </div>
                        </Link>
                      )
                    })}
                </div>
              ) : (
                <div className="content-panel">
                  <div className="content-loader">
                    <CircularProgress
                      style={{
                        color: '#7ab903',
                        height: 55,
                        width: 55
                      }}
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    </>
  )
}

export default PlayerPage
