import { CircularProgress } from '@mui/material'
import { UserServiceApi } from 'api/UserServiceApi'
import Username from 'components/Username'
import UsernameAvatar from 'components/UsernameDisplay/UsernameAvatar'
import { useEffect } from 'react'

const PartnerStats = ({ getRunDataFromID, run, user, points }) => {
  const [getUsername, { data: username, isLoading }] =
    UserServiceApi.useLazyGetUsernameByIdCacheQuery()

  useEffect(() => {
    if (run) {
      const getPartnerId = getRunDataFromID(run.id, user)
      getUsername({ id: getPartnerId }, true)
    }
  }, [getRunDataFromID, getUsername, run, user])

  if (isLoading) {
    return (
      <div className="stats-partner">
        <div className="stats-partner-user">
          <CircularProgress size={'20px'} />
        </div>
        <span className="stats-partner-dash">&ndash;</span>
        <span className="stats-partner-points">
          {getRunDataFromID(run.id, 'duoPoints')} <span>PTS</span>
        </span>
      </div>
    )
  }

  return (
    <div className="stats-partner">
      <div className="stats-partner-user">
        <UsernameAvatar username={username} className="stats-partner-avatar" />
        <span className="stats-partner-username">
          <Username username={username} />
        </span>
      </div>
      <span className="stats-partner-dash">&ndash;</span>
      <span className="stats-partner-points">
        {getRunDataFromID(run.id, points)} <span>PTS</span>
      </span>
    </div>
  )
}

export default PartnerStats
