import React, { useEffect } from 'react'
import { useAppMobileNavOpen } from 'hooks'
import SidebarNavigation from './SidebarNavigation'
import Footer from './Footer'
import { useDispatch } from 'react-redux'
import { setMobileNavOpen } from '../store/slice/appSlice'
import { useLocation, useNavigate } from 'react-router-dom'
import MenuCloseLg from '../assets/img/icon-menu-close-lg.png'

const MenuMobile = () => {
  const mobileMenuOpen = useAppMobileNavOpen()
  const dispatch = useDispatch()
  const location = useLocation()
  const navigate = useNavigate()

  const closeMobileMenu = () => {
    dispatch(setMobileNavOpen(false))
  }

  useEffect(() => {
    closeMobileMenu()
  }, [location])

  const css = `
    body { overflow:hidden; }
    .mobile-menu {
      overflow-y:scroll;
    }
  `

  return (
    mobileMenuOpen && (
      <div className="mobile-menu">
        <style>{css}</style>
        <div className="menu-header">
          <a
            href="#"
            onClick={e => {
              e.preventDefault()
              navigate('/')
            }}
            className="logo">
            <span className="text">Fence.gg | FTL</span>
          </a>
          <span
            className="btn btn-menu-close"
            onClick={() => {
              closeMobileMenu()
            }}>
            <img src={MenuCloseLg} alt={'Menu Close Lg'} />
          </span>
        </div>
        <SidebarNavigation />
        <Footer />
      </div>
    )
  )
}

export default MenuMobile
