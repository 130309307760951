import React from 'react'
import { Box, Typography } from '@mui/material'
import { DataGrid } from '@mui/x-data-grid'
import { stripeDisplayAmount } from 'utils/Stripe'
import moment from 'moment'
import { useGetInvoices } from 'components/Stripe/hooks/Transactions'

const Invoices = ({ userId }) => {
  const { invoices } = useGetInvoices(userId)

  const columns = [
    {
      field: 'status',
      headerName: 'Status',
      width: 100,
      renderCell: params => {
        let color = ''
        if (params.value === 'paid') color = 'success.main'
        return (
          <Typography variant="p" color={color}>
            {params.value}
          </Typography>
        )
      }
    },
    {
      field: 'amount_paid',
      headerName: 'Amount',
      width: 100,
      valueFormatter: params => {
        if (params.value == null) return ''
        const amountFormatted = stripeDisplayAmount(params.value)
        return `${amountFormatted}`
      }
    },
    {
      field: 'subscription',
      headerName: 'Subscription',
      flex: 1,
      valueGetter: params => {
        return `Premium ${moment
          .unix(params.row.lines.data[0].period.start)
          .format('M/DD/YYYY')} - ${moment
          .unix(params.row.lines.data[0].period.end)
          .format('M/DD/YYYY')}`
      }
    },
    {
      field: 'status_transitions',
      headerName: 'Date',
      width: 200,
      valueGetter: params => {
        if (params.row?.status_transitions.paid_at)
          return moment
            .unix(params.row.status_transitions.paid_at)
            .format('M/DD/YYYY h:mma')
      }
    },
    {
      field: 'hosted_invoice_url',
      headerName: 'Invoice',
      flex: 1,
      renderCell: params => {
        return (
          <a href={params.value} target={'_blank'} rel="noreferrer">
            Invoice
          </a>
        )
      }
    }
  ]

  return (
    <div>
      <div className="aside-header aside-subscription-status">
        <span className="admin">Recent Invoices</span>
      </div>
      <div className="content-panel is-private">
        <Box height={'400px'}>
          <DataGrid
            columns={columns}
            rows={invoices}
            autoPageSize
            components={{
              NoRowsOverlay: () => (
                <Typography
                  component="p"
                  sx={{ textAlign: 'center', marginTop: '10px' }}>
                  No recent charges
                </Typography>
              )
            }}
          />
        </Box>
      </div>
    </div>
  )
}

export default Invoices
