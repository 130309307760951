import { createSlice } from '@reduxjs/toolkit'
import { createSelector } from 'reselect'

const initialState = {
  settings: {},
  modules: [],
  leagues: [],
  isLoading: true,
  isLoggedIn: false,
  mobileNavOpen: false,
  unreadLeagueRuns: [],
  communityStreams: [],
  rankingsModal: '',
  rankingsModalLeague: '',
  unreadMyRuns: 0
}

export const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setModules: (state, action) => {
      state.modules = action.payload
    },
    setLeagues: (state, action) => {
      state.leagues = action.payload
    },
    setSettings: (state, action) => {
      state.settings = action.payload
    },
    setIsLoading: (state, action) => {
      state.isLoading = action.payload
    },
    setIsLoggedIn: (state, action) => {
      state.isLoggedIn = action.payload
    },
    setMobileNavOpen: (state, action) => {
      state.mobileNavOpen = action.payload
    },
    setUnreadLeagueRuns: (state, action) => {
      state.unreadLeagueRuns = action.payload
    },
    setCommunityStreams: (state, action) => {
      state.communityStreams = action.payload
    },
    setRankingsModal: (state, action) => {
      state.rankingsModal = action.payload
    },
    setRankingsModalLeague: (state, action) => {
      state.rankingsModalLeague = action.payload
    },
    setUnreadMyRuns: (state, action) => {
      state.unreadMyRuns = action.payload
    }
  }
})

export const selectApp = state => state.app

export const selectAppModules = createSelector(
  selectApp,
  ({ modules }) => modules
)

export const selectAppLeagues = createSelector(
  selectApp,
  ({ leagues }) => leagues
)

export const selectAppSettings = createSelector(
  selectApp,
  ({ settings }) => settings
)

export const selectAppVersion = createSelector(
  selectApp,
  ({ settings }) => settings.version
)

export const selectAppIsLoading = createSelector(
  selectApp,
  ({ isLoading }) => isLoading
)

export const selectAppIsLoggedIn = createSelector(
  selectApp,
  ({ isLoggedIn }) => isLoggedIn
)

export const selectAppMobileNavOpen = createSelector(
  selectApp,
  ({ mobileNavOpen }) => mobileNavOpen
)

export const selectUnreadLeagueRuns = createSelector(
  selectApp,
  ({ unreadLeagueRuns }) => unreadLeagueRuns
)

export const selectCommunityStreams = createSelector(
  selectApp,
  ({ communityStreams }) => communityStreams
)

export const selectRankingsModal = createSelector(
  selectApp,
  ({ rankingsModal }) => rankingsModal
)

export const selectRankingsModalLeague = createSelector(
  selectApp,
  ({ rankingsModalLeague }) => rankingsModalLeague
)

export const selectUnreadMyRuns = createSelector(
  selectApp,
  ({ unreadMyRuns }) => unreadMyRuns
)

export const { setModules } = appSlice.actions
export const { setLeagues } = appSlice.actions
export const { setSettings } = appSlice.actions
export const { setIsLoading } = appSlice.actions
export const { setIsLoggedIn } = appSlice.actions
export const { setMobileNavOpen } = appSlice.actions
export const { setUnreadLeagueRuns } = appSlice.actions
export const { setCommunityStreams } = appSlice.actions
export const { setRankingsModal } = appSlice.actions
export const { setRankingsModalLeague } = appSlice.actions
export const { setUnreadMyRuns } = appSlice.actions

export default appSlice.reducer
