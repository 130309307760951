import React, { useCallback, useEffect, useState } from 'react'
import LeagueService from 'services/LeagueService'
import moment from 'moment'
import { useNavigate } from 'react-router-dom'
import Container from '@mui/material/Container'
import Box from '@mui/material/Box'
import CircularProgress from '@mui/material/CircularProgress'
import {
  useAppSettings,
  useUserLeagueRun,
  useAppCommunityStreams,
  useAppRankingsModal,
  useUserIsPremium,
  useUserIsAdmin,
  useAppIsLoggedIn,
  useUserInvitesIncoming,
  useUserInvitesOutgoing,
  useUserInvitesAccepted
} from 'hooks'
import Button from '@mui/material/Button'
import EloSummary from './EloSummary'
import LoginButton from './LoginButton'
import * as ga4 from 'utils/ga4'
import LeagueRunModal from './Leagues/LeagueRunModal'
import LeagueTasks from './Leagues/LeagueTasks'
import LeagueUsersDisplay from './Leagues/LeagueUsersDisplay'
import LeagueDuoDisplay from './Leagues/LeagueDuoDisplay'
import LeagueDuoInvites from './Leagues/LeagueDuoInvites'
import LeagueDuoOutgoing from './Leagues/LeagueDuoOutgoing'
import LeagueDuoForm from './Leagues/LeagueDuoForm'
import { LeagueServiceApi } from 'api/LeagueServiceApi'
import { Alert } from '@mui/material'

const CurrentLeagueWeek = ({ historical, league, tier, season }) => {
  const invites = {
    incoming: useUserInvitesIncoming(),
    outgoing: useUserInvitesOutgoing(),
    accepted: useUserInvitesAccepted()
  }
  const isLoggedIn = useAppIsLoggedIn()
  const isPremium = useUserIsPremium()
  const isAdmin = useUserIsAdmin()
  const navigate = useNavigate()
  const appSettings = useAppSettings()
  const communityStreams = useAppCommunityStreams()
  const userLeagueRun = useUserLeagueRun()
  const rankingsModal = useAppRankingsModal()
  const [seasonData, setSeasonData] = useState({})
  const [weeksData, setWeeksData] = useState([])
  const [pendingRuns, setPendingRuns] = useState([])
  const [betaUsers, setBetaUsers] = useState([])
  const [currentWeek, setCurrentWeek] = useState({})
  const [open, setOpen] = useState(false)
  const [howToOpen, setHowToOpen] = useState(false)
  const [runLoading, setRunLoading] = useState(false)
  const [endLeagueRunCall] = LeagueServiceApi.useEndLeagueRunMutation()

  const handleClose = (e, r) => {
    if (r === 'backdropClick') {
      return false
    }
    setOpen(false)
  }

  const handleHowToClose = (e, r) => {
    setHowToOpen(false)
  }

  const getLeagueSeason = useCallback(async () => {
    const response = await LeagueService.getLeagueSeason(
      league.id,
      historical ? season : league.currentSeason,
      data => {
        setSeasonData(data.data())
      }
    )
    return response
  }, [historical, league.id, league.currentSeason, season])

  const getWeekData = useCallback(async () => {
    const response = await LeagueService.getLeagueSeasonWeek(
      league.id,
      historical ? season : league.currentSeason,
      league.currentWeek,
      data => {
        setCurrentWeek({ ...data.data(), id: data.id })
      }
    )
    return response
  }, [historical, season, league.currentWeek, league.currentSeason, league.id])

  useEffect(() => {
    const leagueSeason = getLeagueSeason()
    const weekData = getWeekData()
    return () => {
      leagueSeason.then(response => (response ? response.unsubscribe() : null))
      weekData.then(response => (response ? response.unsubscribe() : null))
    }
  }, [getLeagueSeason, getWeekData])

  useEffect(() => {
    if (appSettings.closedBeta === true) {
      LeagueService.getBetaUsers(results => {
        setBetaUsers(results)
      })
    } else {
    }
  }, [appSettings])

  const getPendingRuns = useCallback(async () => {
    const response = await LeagueService.getPendingRuns(docs => {
      setPendingRuns(docs)
    })
    return response
  }, [])

  useEffect(() => {
    ga4.init()
    const pendingRuns = getPendingRuns()
    return () => {
      pendingRuns.then(response => (response ? response.unsubscribe() : null))
    }
  }, [])

  const getCurrentWeekItems = () => {
    if (currentWeek && currentWeek.items && currentWeek.items.length) {
      let itemsString = ''
      currentWeek.items.forEach((item, index) => {
        if (index === currentWeek.items.length - 1) {
          itemsString += ` and ${item.display_name}`
        } else {
          itemsString += `${item.display_name}, `
        }
      })
      return itemsString
    }
  }

  const endRun = async () => {
    endLeagueRunCall()
      .unwrap()
      .then(() => {
        setRunLoading(false)
        ga4.sendEvent({
          category: 'League Run',
          action: 'End',
          nonInteraction: false
        })
      })
  }

  return (
    <div>
      {(currentWeek && currentWeek.ends) || historical ? (
        <div>
          <LeagueTasks
            historical={historical}
            currentWeek={currentWeek}
            season={season}
            league={league}
            seasonData={seasonData}
            tier={tier}
          />
          <div className="league-prizes">
            {!historical &&
              (typeof userLeagueRun.league === 'undefined' ? (
                <div className="league-prizes-cta">
                  {isLoggedIn ? (
                    isAdmin || isPremium || league.premium === false ? (
                      moment(currentWeek.starts.seconds * 1000).isAfter(
                        moment()
                      ) ? (
                        <div>
                          {league.id === 'duo' &&
                            (invites.accepted?.length > 0 ? (
                              <LeagueDuoDisplay
                                league={league}
                                setOpen={setOpen}
                                invites={invites}
                                seasonActive={false}
                              />
                            ) : (
                              <div className="duos-invite-form">
                                <LeagueDuoInvites invites={invites} />
                                {invites.outgoing !== '' ? (
                                  <LeagueDuoOutgoing invites={invites} />
                                ) : (
                                  <LeagueDuoForm />
                                )}
                              </div>
                            ))}
                        </div>
                      ) : moment(currentWeek.ends.seconds * 1000).isAfter(
                          moment()
                        ) ? (
                        <div>
                          {league.id === 'duo' ? (
                            invites.accepted?.length > 0 ? (
                              <LeagueDuoDisplay
                                league={league}
                                setOpen={setOpen}
                                invites={invites}
                                seasonActive={true}
                              />
                            ) : (
                              <div className="duos-invite-form">
                                <LeagueDuoInvites invites={invites} />
                                {invites.outgoing !== '' ? (
                                  <LeagueDuoOutgoing invites={invites} />
                                ) : (
                                  <LeagueDuoForm />
                                )}
                              </div>
                            )
                          ) : (
                            <div>
                              {/* <Alert severity="error">
                                The league is temporarily paused until the
                                Halloween event ends.
                              </Alert> */}
                              <Button
                                className="btn btn-primary"
                                onClick={e => {
                                  e.preventDefault()
                                  setOpen(true)
                                }}>
                                {runLoading && (
                                  <CircularProgress
                                    style={{
                                      color: '#000',
                                      height: 15,
                                      width: 15,
                                      marginRight: 10
                                    }}
                                  />
                                )}
                                Start {league.display_name} Run
                              </Button>
                            </div>
                          )}
                        </div>
                      ) : (
                        <span>
                          This season has ended. The next season will start
                          soon.
                        </span>
                      )
                    ) : (
                      <Button
                        className="btn btn-primary"
                        onClick={e => {
                          e.preventDefault()
                          navigate('/subscribe')
                        }}>
                        Join Fence Premium
                      </Button>
                    )
                  ) : (
                    <LoginButton />
                  )}
                </div>
              ) : (
                userLeagueRun.ended !== true && (
                  <div className="league-prizes-cta">
                    <Button
                      className="btn btn-primary btn-cancel"
                      onClick={e => {
                        e.preventDefault()
                        setRunLoading(true)
                        endRun()
                      }}>
                      {runLoading && (
                        <CircularProgress
                          style={{
                            color: '#000',
                            height: 15,
                            width: 15,
                            marginRight: 10
                          }}
                        />
                      )}
                      End Run
                    </Button>
                  </div>
                )
              ))}
          </div>
          <span className="disclaimer">
            *Rankings are not final until all submissions are reviewed and
            approved.
          </span>
          <LeagueUsersDisplay
            historical={historical}
            season={season}
            tier={tier}
            league={league}
            pendingRuns={pendingRuns}
            communityStreams={communityStreams}
          />
          {rankingsModal !== '' && (
            <EloSummary
              league={league}
              tier={tier}
              season={historical ? season : league.currentSeason}
            />
          )}
          <LeagueRunModal
            league={league}
            open={open}
            handleClose={handleClose}
            currentWeek={currentWeek}
          />
        </div>
      ) : (
        <div>
          <Container maxWidth="xl">
            <Box
              pt={3}
              pb={3}
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
              }}>
              <CircularProgress size="5rem" />
            </Box>
          </Container>
        </div>
      )}
    </div>
  )
}

export default CurrentLeagueWeek
