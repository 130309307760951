const Username = ({ username }) => {
  const isBanned = username?.banned === true
  const userUsername = username?.display_name || '{MissingUsername}'
  const userKickUsername = username?.kickUsername || '{MissingKickUsername}'
  const isKick = username?.usingKick || false

  return isBanned ? 'Banned User' : isKick ? userKickUsername : userUsername
}

export default Username
