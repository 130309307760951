import React, { useEffect } from 'react'
import moment from 'moment'
import { useAppCommunityStreams } from 'hooks'
import Modal from '@mui/material/Modal'
import CircularProgress from '@mui/material/CircularProgress'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import { useAppLeagues } from 'hooks'
import LeagueService from 'services/LeagueService'
import { getTarkovMaps } from 'utils/tarkovMaps'
import Grid from '@mui/material/Grid'
import SubmittedBy from './Leagues/Runs/SubmittedBy'

const PreviewRun = ({ open, setOpen, runDetailsId, setReviewRunID }) => {
  const communityStreams = useAppCommunityStreams()
  const leagues = useAppLeagues()
  const tarkovMaps = getTarkovMaps()
  const [detailsLoading, setDetailsLoading] = React.useState(true)
  const [reviewRun, setReviewRun] = React.useState({})
  const [reviewWeek, setReviewWeek] = React.useState({})

  let defaultSettings = {
    scav: {
      results: {
        columns: [1, 2, 3]
      },
      questions: [
        {
          id: 'costItems',
          results: {
            label: 'Cost of Items'
          }
        },
        {
          id: 'xp',
          results: {
            label: 'Raid XP'
          }
        },
        {
          id: 'killsPmc',
          results: {
            label: 'PMCS Kills'
          }
        },
        {
          id: 'killsRogue',
          results: {
            label: 'Rogues/Raiders Kills'
          }
        },
        {
          id: 'killsScav',
          results: {
            label: 'Scav Kills'
          }
        },
        {
          id: 'killsGuard',
          results: {
            label: 'Guard Kills'
          }
        },
        {
          id: 'killsBoss',
          results: {
            label: 'Boss Kills'
          }
        },
        {
          id: 'bonusItems',
          results: {
            label: 'Bonus Items'
          }
        },
        {
          id: 'damage',
          results: {
            label: 'Raid Damage'
          }
        }
      ]
    },
    solo: {
      results: {
        columns: [1, 2, 3]
      },
      questions: [
        {
          id: 'xp',
          results: {
            label: 'Raid XP'
          }
        },
        {
          id: 'killsPmc',
          results: {
            label: 'PMCS Kills'
          }
        },
        {
          id: 'killsRogue',
          results: {
            label: 'Rogues/Raiders Kills'
          }
        },
        {
          id: 'killsScav',
          results: {
            label: 'Scav Kills'
          }
        },
        {
          id: 'killsGuard',
          results: {
            label: 'Guard Kills'
          }
        },
        {
          id: 'killsBoss',
          results: {
            label: 'Boss Kills'
          }
        },
        {
          id: 'damage',
          results: {
            label: 'Raid Damage'
          }
        },
        {
          id: 'bonusItems',
          results: {
            label: 'Bonus Items'
          }
        }
      ]
    },
    duo: {
      results: {
        columns: [1, 2, 3, 4]
      },
      questions: [
        {
          id: 'survive',
          results: {
            label: 'P1 Survived'
          },
          type: 'yesno',
          value: '',
          answers: [
            {
              id: 'no',
              type: 'approve',
              label: 'No'
            },
            {
              id: 'yes',
              type: 'approve',
              label: 'Yes'
            }
          ]
        },
        {
          id: 'xp',
          results: {
            label: 'P1 XP'
          }
        },
        {
          id: 'killsPmc',
          results: {
            label: 'P1 PMCS Killed'
          }
        },
        {
          id: 'killsRogue',
          results: {
            label: 'P1 Rogues/Raiders Killed'
          }
        },
        {
          id: 'killsScav',
          results: {
            label: 'P1 Scav Kills'
          }
        },
        {
          id: 'killsGuard',
          results: {
            label: 'P1 Guards Killed'
          }
        },
        {
          id: 'killsBoss',
          results: {
            label: 'P1 Boss Kills'
          }
        },
        {
          id: 'damage',
          results: {
            label: 'P1 Damage'
          }
        },
        {
          id: 'bonusItems',
          results: {
            label: 'P1 Bonus Items'
          }
        },
        {
          id: 'survive2',
          results: {
            label: 'P2 Survived'
          },
          type: 'yesno',
          value: '',
          answers: [
            {
              id: 'no',
              type: 'approve',
              label: 'No'
            },
            {
              id: 'yes',
              type: 'approve',
              label: 'Yes'
            }
          ]
        },
        {
          id: 'xp2',
          results: {
            label: 'P2 XP'
          }
        },
        {
          id: 'killsPmc2',
          results: {
            label: 'P2 PMC Kills'
          }
        },
        {
          id: 'killsRogue2',
          results: {
            label: 'P2 Rogue/Raider Kills'
          }
        },
        {
          id: 'killsScav2',
          results: {
            label: 'P2 Scav Kills'
          }
        },
        {
          id: 'killsGuard2',
          results: {
            label: 'P2 Guard Kills'
          }
        },
        {
          id: 'killsBoss2',
          results: {
            label: 'P2 Boss Kills'
          }
        },
        {
          id: 'damage2',
          results: {
            label: 'P2 Damage'
          }
        },
        {
          id: 'bonusItems2',
          results: {
            label: 'P2 Bonus Items'
          }
        }
      ]
    }
  }
  const [leagueRunSettings, setLeagueRunSettings] = React.useState({})
  const [leagueRunAnswers, setLeagueRunAnswers] = React.useState({})
  const [questionIndex, setQuestionIndex] = React.useState(0)

  useEffect(() => {
    if (runDetailsId !== '') {
      LeagueService.getLeagueRun(runDetailsId, data => {
        let reviewRunData = data.data()
        setLeagueRunSettings(defaultSettings[reviewRunData.league])
        let defaultValues = {}
        defaultSettings[reviewRunData.league].questions.forEach(q => {
          defaultValues[q.id] = ''
        })
        setLeagueRunAnswers(defaultValues)
        setReviewRun(reviewRunData)
        if (reviewRunData.approved === true) {
          let updatedData = {}
          defaultSettings[reviewRunData.league].questions.forEach(q => {
            if (typeof reviewRunData[q.id] !== 'undefined') {
              updatedData[q.id] = reviewRunData[q.id]
            } else {
              let answer = ''
              q.answers.forEach(a => {
                if (a.type === 'approve') {
                  answer = a.id
                }
              })
              updatedData[q.id] = answer
            }
          })
          setLeagueRunAnswers(updatedData)
          setQuestionIndex(
            defaultSettings[reviewRunData.league].questions.length
          )
        }
        LeagueService.getSeasonWeeks(
          data.data().league,
          `season-${
            leagues.filter(league => league.id === data.data().league)[0]
              .currentSeason
          }`,
          weeks => {
            weeks.forEach(week => {
              if (week.id === `week-${data.data().week}`) {
                setReviewWeek(week.data())
              }
            })
            setDetailsLoading(false)
          }
        )
      })
    }
  }, [runDetailsId])

  const cancelReview = async () => {
    setOpen(false)
    setReviewRunID('')
  }

  const getCurrentWeekItems = () => {
    if (reviewWeek && reviewWeek.items && reviewWeek.items.length) {
      let itemsString = ''
      reviewWeek.items.forEach((item, index) => {
        if (index === reviewWeek.items.length - 1) {
          itemsString += ` and ${item.display_name}`
        } else {
          itemsString += `${item.display_name} `
        }
      })
      return itemsString
    }
  }

  const isFormCompeted = () => {
    let total = Object.entries(leagueRunAnswers).length
    if (total === questionIndex) {
      return true
    }
  }

  const checkIsLive = uid => {
    if (uid.length > 0) {
      let streamInfo = communityStreams.filter(s => s.id === uid)
      if (streamInfo.length > 0) {
        return streamInfo[0]
      }
    }
  }

  return (
    <Modal
      sx={{ overflowY: 'scroll' }}
      open={open}
      onClose={cancelReview}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description">
      <Box className="run-modal">
        <div className="aside-header aside-run">
          <span className="leagues">League Run Details</span>
        </div>
        <div className="modal-content">
          {!detailsLoading ? (
            <Grid container spacing={2} className="review-run-cols">
              <Grid item xs={12} md={6}>
                <div
                  className={`league-week-banner league-week-${reviewWeek.map}`}>
                  <div className="league-week-banner-text">
                    <span className="title">
                      {reviewRun.league} League Season {reviewRun.season}{' '}
                      &ndash; {reviewWeek.display_name}
                    </span>
                    <span className="duration">
                      Map:{' '}
                      <span className="duration-value">
                        {tarkovMaps[reviewWeek.map].name}
                      </span>
                    </span>
                    <span className="items">
                      Bonus Items:{' '}
                      <span className="items-value">
                        {getCurrentWeekItems()}
                      </span>
                    </span>
                    <span className="ends">
                      Starts:{' '}
                      <span className="items-value">
                        {moment(reviewWeek.starts.seconds * 1000).toString()}
                      </span>
                    </span>
                    <span className="ends">
                      Ends:{' '}
                      <span className="items-value">
                        {moment(reviewWeek.ends.seconds * 1000).toString()}
                      </span>
                    </span>
                  </div>
                  <div className="league-week-banner-gradient"></div>
                </div>
              </Grid>
              <Grid item xs={12} md={6}>
                <div className="run-submit-details">
                  <div className="league-week-banner-text">
                    <div className="league-week-banner-usernames">
                      <div className="league-week-banner-username">
                        <SubmittedBy
                          uid={reviewRun.submittedBy}
                          className={'run-submit-user'}
                        />
                      </div>
                      {reviewRun.league === 'duo' && (
                        <div className="league-week-banner-username">
                          <span>
                            <SubmittedBy
                              uid={reviewRun.duoPartner}
                              className={'run-submit-user'}
                            />
                          </span>
                        </div>
                      )}
                    </div>
                    <span className="ends">
                      Run Started:{' '}
                      <span className="items-value">
                        {moment(reviewRun.started.seconds * 1000).toString()}
                      </span>
                    </span>
                    <span className="ends">
                      Run Duration:{' '}
                      <span className="items-value">
                        {moment
                          .utc(
                            moment(reviewRun.endedAt.seconds * 1000).diff(
                              moment(reviewRun.started.seconds * 1000)
                            )
                          )
                          .format('HH:mm:ss')}
                      </span>
                    </span>
                    <span className="ends">
                      VOD:{' '}
                      <span className="items-value">
                        <a
                          href={reviewRun.twitchLink}
                          target="_blank"
                          rel={'noreferrer'}>
                          {reviewRun.twitchLink}
                        </a>
                      </span>
                    </span>
                    {reviewRun.league === 'duo' && (
                      <span className="ends">
                        VOD2:{' '}
                        <span className="items-value">
                          <a
                            href={reviewRun.twitchLink2}
                            target="_blank"
                            rel={'noreferrer'}>
                            {reviewRun.twitchLink2}
                          </a>
                        </span>
                      </span>
                    )}
                  </div>
                </div>
              </Grid>
              <Grid item xs={12}>
                <div className="league-submission-form">
                  {isFormCompeted() && (
                    <div className="league-run-summary">
                      <span className="subhead">
                        League Run Summary - {reviewRun.points} PTS
                      </span>
                      {reviewRun.league === 'duo' && (
                        <SubmittedBy
                          uid={reviewRun.submittedBy}
                          className={'run-submit-user preview-submit-user'}
                          pts={reviewRun?.duoPoints}
                        />
                      )}
                      <Grid container spacing={2}>
                        {leagueRunSettings.results.columns.map((a, i) => {
                          return (
                            <Grid
                              item
                              key={i}
                              xs={12}
                              md={
                                12 / leagueRunSettings.results.columns.length
                              }>
                              <div className="league-week-banner-text">
                                {leagueRunSettings.questions
                                  .filter(q => q.id.indexOf('2') === -1)
                                  .filter(q => q.results && q.results.label)
                                  .filter(
                                    (q, qi) =>
                                      qi >=
                                        (i *
                                          leagueRunSettings.questions.filter(
                                            q =>
                                              q.results &&
                                              q.results.label &&
                                              q.id.indexOf('2') === -1
                                          ).length) /
                                          leagueRunSettings.results.columns
                                            .length &&
                                      qi <
                                        ((i + 1) *
                                          leagueRunSettings.questions.filter(
                                            q =>
                                              q.results &&
                                              q.results.label &&
                                              q.id.indexOf('2') === -1
                                          ).length) /
                                          leagueRunSettings.results.columns
                                            .length
                                  )
                                  .map((q, qi) => {
                                    return (
                                      <span className="ends" key={qi}>
                                        {q.results.label.replace('P1 ', '')}:{' '}
                                        <span className="items-value">
                                          {typeof q.results.answer !==
                                          'undefined'
                                            ? q.results.answer
                                            : leagueRunAnswers[q.id]}
                                        </span>
                                      </span>
                                    )
                                  })}
                              </div>
                            </Grid>
                          )
                        })}
                      </Grid>
                      {reviewRun.league === 'duo' && (
                        <div>
                          <SubmittedBy
                            uid={reviewRun.duoPartner}
                            className={'run-submit-user preview-submit-user'}
                            pts={reviewRun?.duoPoints2}
                          />
                          <Grid container spacing={2}>
                            {leagueRunSettings.results.columns.map((a, i) => {
                              return (
                                <Grid
                                  item
                                  key={i}
                                  xs={12}
                                  md={
                                    12 /
                                    leagueRunSettings.results.columns.length
                                  }>
                                  <div className="league-week-banner-text">
                                    {leagueRunSettings.questions
                                      .filter(q => q.id.indexOf('2') > -1)
                                      .filter(q => q.results && q.results.label)
                                      .filter(
                                        (q, qi) =>
                                          qi >=
                                            (i *
                                              leagueRunSettings.questions.filter(
                                                q =>
                                                  q.results &&
                                                  q.results.label &&
                                                  q.id.indexOf('2') > -1
                                              ).length) /
                                              leagueRunSettings.results.columns
                                                .length &&
                                          qi <
                                            ((i + 1) *
                                              leagueRunSettings.questions.filter(
                                                q =>
                                                  q.results &&
                                                  q.results.label &&
                                                  q.id.indexOf('2') > -1
                                              ).length) /
                                              leagueRunSettings.results.columns
                                                .length
                                      )
                                      .map((q, qi) => {
                                        return (
                                          <span className="ends" key={qi}>
                                            {q.results.label.replace('P2 ', '')}
                                            :{' '}
                                            <span className="items-value">
                                              {typeof q.results.answer !==
                                              'undefined'
                                                ? q.results.answer
                                                : leagueRunAnswers[q.id]}
                                            </span>
                                          </span>
                                        )
                                      })}
                                  </div>
                                </Grid>
                              )
                            })}
                          </Grid>
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </Grid>
            </Grid>
          ) : (
            <div className="loader-centered">
              <CircularProgress
                style={{
                  color: '#7ab903',
                  height: 75,
                  width: 75
                }}
              />
            </div>
          )}
          <div className="league-submission-form">
            <div className="modal-controls">
              <Button
                className="btn btn-primary btn-cancel"
                onClick={e => {
                  e.preventDefault()
                  cancelReview()
                }}>
                Close
              </Button>
            </div>
          </div>
        </div>
      </Box>
    </Modal>
  )
}

export default PreviewRun
