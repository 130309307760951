import Firebase from 'utils/Firebase'
import { getFirestore } from 'firebase/firestore'
import {
  collection,
  onSnapshot,
  query,
  orderBy,
  limit
} from 'firebase/firestore'
import fetch from 'cross-fetch'
import { getHostFunctions } from 'utils/GetHost'

const db = getFirestore(Firebase)

async function getSightings(callback) {
  onSnapshot(collection(db, 'goons'), docs => {
    callback(docs)
  })
}

async function getLatestSightings(callback) {
  const q = query(
    collection(db, 'goons'),
    orderBy('datetime', 'desc'),
    limit(25)
  )
  const unsubscribe = onSnapshot(q, querySnapshot => {
    const docs = []
    querySnapshot.forEach(doc => {
      docs.push(doc.data())
    })
    callback(docs)
  })
  return { unsubscribe }
}

async function getMostRecentSighting(callback) {
  const q = query(
    collection(db, 'goons'),
    orderBy('datetime', 'desc'),
    limit(1)
  )
  const unsubscribe = onSnapshot(q, querySnapshot => {
    const docs = []
    querySnapshot.forEach(doc => {
      docs.push(doc.data())
    })
    callback(docs)
  })

  return { unsubscribe }
}

async function addSighting(map, timeModifier, uid, callback) {
  const result = await fetch(
    getHostFunctions() +
      '/addGoonSighting?' +
      new URLSearchParams({
        map: map,
        sightedBy: uid,
        datetime: timeModifier
      })
  )
  if (result.status === 200) {
    result.json().then(res => {
      if (typeof callback === 'function') {
        callback(res)
      }
    })
  }
}

const GoonsService = {
  getSightings,
  addSighting,
  getLatestSightings,
  getMostRecentSighting
}

export default GoonsService
