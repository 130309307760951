import React from 'react'
import SocialLinks from './SocialLinks'
import { Link } from 'react-router-dom'
import FenceLogo from '../assets/img/fence-logo.png'

const Footer = () => {
  return (
    <div className="footer-container">
      <footer className="wrapper">
        <div className="logo">
          <img src={FenceLogo} alt={'Fence.gg Logo'} />
          <span>Fence.gg | FTL</span>
        </div>
        <div className="footer-links">
          <Link to="/careers">Careers</Link>
          <Link to="/privacy">Privacy Policy</Link>
          <Link to="/tos">Terms of Use</Link>
          <Link to="/about">About Fence.gg</Link>
          <Link to="/contact">Contact Us</Link>
        </div>
        <SocialLinks />
        <span className="copyright">
          &copy; 2023 - Fence.gg | FTL &mdash; All Rights Reserved
        </span>
        <span className="affiliated">
          Not affiliated with Battlestate Games
        </span>
      </footer>
    </div>
  )
}

export default Footer
