import React, { useState, useEffect } from 'react'
import FormControl from '@mui/material/FormControl'
import TextField from '@mui/material/TextField'
import Box from '@mui/material/Box'
import Tooltip from '@mui/material/Tooltip'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import { useUser, useUserData } from 'hooks'
import { getHostDomain } from 'utils/GetHost'
import { Grid } from '@mui/material'
import ReferralService from 'services/ReferralService'
import { useGetReferrals } from './Stripe/hooks/Referrals'
import { stripeDisplayAmount } from 'utils/Stripe'
import { useUserStripe } from 'hooks/use-user-data'

const ReferralSettings = () => {
  const user = useUser()
  const overlayRef = React.useRef(null)
  const [copyOpened, setCopyOpened] = useState(false)
  const [referred, setReferred] = useState([])
  const myUser = useUserData()
  const referrals = useGetReferrals({ userId: user.uid })
  const stripe = useUserStripe()
  const amount = stripe?.balance
    ? stripeDisplayAmount(stripe?.balance)
    : '$0.00'

  useEffect(() => {
    ReferralService.getMyReferrals(user.uid, data => {
      setReferred(data)
    })
  }, [user.uid])

  return (
    <div className="overlay-settings">
      <div className="aside-header aside-goons">
        <span className="dashboard">Referral Settings</span>
      </div>
      <div className="content-panel">
        <span className="paragraph">
          Earn subscription credit by referring your friends. Earn $1
          subscription credit per month for every paying subscriber you refer.
          Credit cannot be redeemed for cash.
        </span>
        <div className="referred-info">
          <Grid container spacing={2} sx={{ marginTop: '5px' }}>
            <Grid item md={6}>
              <span className="subhead">Referred Users</span>
              <span className="value">{referred.length}</span>
            </Grid>
            <Grid item md={6}>
              <span className="subhead">Active</span>
              <span className="value">{referrals.active}</span>
            </Grid>
            <Grid item md={6}>
              <span className="subhead">Total Earned</span>
              <span className="value">{referrals.amount}</span>
            </Grid>
            <Grid item md={6}>
              <span className="subhead">Current Credit Balance</span>
              <span className="value">{amount}</span>
            </Grid>
          </Grid>
        </div>
        <Box className="form-control overlay-controls">
          <FormControl
            fullWidth
            component="form"
            sx={{ m: 1, position: 'relative' }}
            variant="standard">
            <Tooltip placement="top" arrow open={copyOpened} title="Copied!">
              <ContentCopyIcon
                sx={{
                  position: 'absolute',
                  top: 16,
                  right: 17,
                  cursor: 'pointer',
                  zIndex: 99
                }}
                onClick={() => {
                  setCopyOpened(true)
                  navigator.clipboard.writeText(overlayRef.current.value)
                  setTimeout(() => {
                    setCopyOpened(false)
                  }, 3000)
                }}
              />
            </Tooltip>
            <TextField
              id="outlined-read-only-input"
              name="overlay"
              label="Your Referral Link"
              inputRef={overlayRef}
              value={`${getHostDomain()}/?ref=${myUser.id}`}
              InputProps={{
                readOnly: true
              }}
              onFocus={event => {
                event.target.select()
              }}
            />
          </FormControl>
        </Box>
      </div>
    </div>
  )
}

export default ReferralSettings
