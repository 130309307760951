import { UserServiceApi } from 'api/UserServiceApi'
import { useCallback, useEffect, useState } from 'react'
import Username from 'components/Username'
import CommunityService from 'services/CommunityService'
import { CircularProgress } from '@mui/material'
import UsernameStreamLink from 'components/UsernameDisplay/UsernameStreamLink'
import UsernameAvatar from 'components/UsernameDisplay/UsernameAvatar'

const RankDisplay = ({ uid }) => {
  const [userIsLive, setUserIsLive] = useState(false)
  const [getUsername, { data: username, isLoading }] =
    UserServiceApi.useLazyGetUsernameByIdCacheQuery()

  const isLive = useCallback(async login => {
    const response = await CommunityService.checkUserIsLive(login).then(
      response => {
        if (response.length > 0) {
          setUserIsLive(true)
        } else {
          setUserIsLive(false)
        }
      }
    )
    return response
  }, [])

  useEffect(() => {
    if (uid) {
      getUsername({ id: uid }, true)
    }
  }, [getUsername, uid])

  useEffect(() => {
    if (username) {
      isLive(username?.login)
    }
  }, [username, isLive])

  if (isLoading) {
    return <CircularProgress size={'20px'} />
  }

  return (
    <UsernameStreamLink username={username}>
      <UsernameAvatar
        username={username}
        style={{
          width: 24,
          height: 24,
          marginRight: 10
        }}
      />

      <span className={userIsLive ? 'is-live' : ''}>
        <Username username={username} />
      </span>
    </UsernameStreamLink>
  )
}

export default RankDisplay
