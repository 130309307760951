import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import './assets/css/Wysiwyg.css'
import './assets/css/App.css'
import App from './App'
import { store } from './store/index'
import { Provider } from 'react-redux'
import { ThemeProvider, createTheme } from '@mui/material/styles'
import CssBaseline from '@mui/material/CssBaseline'
import { createRoot } from 'react-dom/client'

const theme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: '#7ab903'
    },
    secondary: {
      main: '#707070'
    }
  }
})

const container = document.getElementById('root')
const root = createRoot(container)

root.render(
  <Provider store={store}>
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <App />
    </ThemeProvider>
  </Provider>
)
