import React, { useState, useEffect } from 'react'
import FormControl from '@mui/material/FormControl'
import TextField from '@mui/material/TextField'
import Box from '@mui/material/Box'
import Tooltip from '@mui/material/Tooltip'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import Typography from '@mui/material/Typography'
import OverlayService from 'services/OverlayService'
import { useUser, useUserData } from 'hooks'
import { getHostDomain } from 'utils/GetHost'

const OverlaySettings = () => {
  const user = useUser()
  const overlayRef = React.useRef(null)
  const [copyOpened, setCopyOpened] = useState(false)
  const myUser = useUserData()

  const regenOverlayURL = () => {
    OverlayService.regenerateOverlay(data => {})
  }

  return (
    <div className="overlay-settings">
      <div className="aside-header aside-dashboard">
        <span className="dashboard">Overlay Settings</span>
      </div>
      <div className="content-panel">
        <span className="paragraph">
          The Fence.gg overlay allows you to customize and display various
          information on your livestreams. This will make your content more
          interactive for your viewers.
        </span>
        <span className="paragraph">
          Add this to your streaming software (like OBS) as a new browser source
          at 1920x1080.
        </span>
        <Box className="form-control overlay-controls">
          <FormControl
            fullWidth
            component="form"
            sx={{ m: 1, position: 'relative' }}
            variant="standard">
            <Tooltip placement="top" arrow open={copyOpened} title="Copied!">
              <ContentCopyIcon
                sx={{
                  position: 'absolute',
                  top: 16,
                  right: 17,
                  cursor: 'pointer',
                  zIndex: 99
                }}
                onClick={() => {
                  setCopyOpened(true)
                  navigator.clipboard.writeText(overlayRef.current.value)
                  setTimeout(() => {
                    setCopyOpened(false)
                  }, 3000)
                }}
              />
            </Tooltip>
            <TextField
              id="outlined-read-only-input"
              name="overlay"
              label="Overlay URL (browser source)"
              inputRef={overlayRef}
              value={`${getHostDomain()}/overlay/${myUser.overlayKey}`}
              InputProps={{
                readOnly: true
              }}
              onFocus={event => {
                event.target.select()
              }}
            />
          </FormControl>
          <Box sx={{ textAlign: 'center' }}>
            <Typography
              variant="body2"
              gutterBottom
              component="div"
              onClick={() => {
                regenOverlayURL()
              }}
              sx={{
                color: '#b72824',
                textDecoration: 'underline',
                cursor: 'pointer'
              }}>
              Regenerate Overlay URL
            </Typography>
          </Box>
        </Box>
      </div>
    </div>
  )
}

export default OverlaySettings
