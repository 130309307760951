import { initializeApp } from 'firebase/app'
import { getAuth } from 'firebase/auth'

export const firebaseConfig = {
  apiKey: 'AIzaSyBJ6SldocEKRwELL9fE4m52j23TtpxG8hc',
  authDomain: 'fence-gg.firebaseapp.com',
  databaseURL: 'https://fence-gg-default-rtdb.firebaseio.com',
  projectId: 'fence-gg',
  storageBucket: 'fence-gg.appspot.com',
  messagingSenderId: '803828337372',
  appId: '1:803828337372:web:55fb3cb2322babb9a70219',
  measurementId: 'G-L5B26RZWEN'
}

const firebaseApp = initializeApp(firebaseConfig)
const auth = getAuth(firebaseApp)

export { firebaseApp, auth }
