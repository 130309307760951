import React from 'react'
import { Box, Typography } from '@mui/material'
import { DataGrid } from '@mui/x-data-grid'
import { stripeDisplayAmount } from 'utils/Stripe'
import moment from 'moment'
import { useGetCharges } from 'components/Stripe/hooks/Transactions'

const Transactions = ({ userId }) => {
  const { charges = [] } = useGetCharges(userId)

  const columns = [
    {
      field: 'status',
      headerName: 'Status',
      flex: 1,
      renderCell: params => {
        let color = ''
        if (params.value === 'succeeded') color = 'success.main'
        return (
          <Typography variant="p" color={color}>
            {params.value}
          </Typography>
        )
      }
    },
    {
      field: 'amount',
      headerName: 'Amount',
      flex: 1,
      valueFormatter: params => {
        if (params.value == null) return ''
        const amountFormatted = stripeDisplayAmount(params.value)
        return `${amountFormatted}`
      }
    },
    {
      field: 'description',
      headerName: 'Description',
      flex: 1
    },
    {
      field: 'card',
      headerName: 'Card',
      flex: 1,
      valueGetter: params => {
        if (!params.row?.card) return ''
        return `${params.row.card.brand} ${params.row.card.last4} ${params.row.card.exp_month}/${params.row.card.exp_year}`
      }
    },
    {
      field: 'created',
      headerName: 'Date',
      flex: 1,
      valueFormatter: params => {
        if (params.value == null) return ''
        return moment.unix(params.value).format('M/DD/YYYY h:mma')
      }
    }
  ]

  return (
    <div>
      <div className="aside-header aside-subscription-status">
        <span className="admin">Recent Transactions</span>
      </div>
      <div className="content-panel is-private">
        <Box height={'400px'}>
          <DataGrid
            columns={columns}
            rows={charges}
            autoPageSize
            components={{
              NoRowsOverlay: () => (
                <Typography
                  component="p"
                  sx={{ textAlign: 'center', marginTop: '10px' }}>
                  No recent charges
                </Typography>
              )
            }}
          />
        </Box>
      </div>
    </div>
  )
}

export default Transactions
