import { Link } from 'react-router-dom'
import SidebarDisplay from './Streams/SidebarDisplay'
import { useGetSidebarStreams } from './Streams/hooks/Streams'
import FeaturedStream from './Streams/FeaturedStream'

const SidebarStreams = () => {
  const { featuredStream, streams } = useGetSidebarStreams()

  return (
    <div>
      <div className="aside-header aside-streams">
        <span className="live">Community Streams</span>
      </div>
      <FeaturedStream featuredStream={featuredStream} />
      <div className="streams">
        <ul>
          {streams.length > 0 ? (
            <>
              {streams.map(stream => (
                <SidebarDisplay stream={stream} key={stream.user_login} />
              ))}
            </>
          ) : (
            <li>
              <span className="no-competitors">
                There are no community members streaming at this time.
              </span>
            </li>
          )}
        </ul>
      </div>
      <Link className="all-link" to="/community">
        Show More
      </Link>
    </div>
  )
}

export default SidebarStreams
