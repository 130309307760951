import { Grid } from '@mui/material'
import React, { useEffect, useRef } from 'react'
import useSound from 'use-sound'
import { CSSTransition } from 'react-transition-group'
import CircularProgress from '@mui/material/CircularProgress'
import LeagueService from 'services/LeagueService'
import FenceLogo from '../../src/assets/img/fence-logo.png'
import notiSfx from '../../src/assets/sounds/notification.mp3'

const RunNotifications = ({ settings, username }) => {
  const overlayRef = useRef(null)
  const [notificationData, setNotificationData] = React.useState({})
  const [overlayLoading, setOverlayLoading] = React.useState(true)
  const [play] = useSound(notiSfx)

  useEffect(() => {
    let listener = null
    if (settings.owner && overlayLoading === true) {
      setOverlayLoading(false)
      listener = LeagueService.getNotificationRuns(settings.owner, data => {
        setNotificationData(data)
        if (data && data.length > 0) {
          play()
        }
      })
    }

    return () => {
      if (typeof listener === 'function') {
        listener()
      }
    }
  }, [settings, overlayLoading])

  return settings && settings.ranks ? (
    <CSSTransition
      nodeRef={overlayRef}
      in={notificationData.length > 0}
      timeout={0}
      classNames="animate-overlay-notification">
      <div
        ref={overlayRef}
        className={
          'overlay-notification' +
          (notificationData.length > 0 && notificationData[0].denied === true
            ? ' notification-denied'
            : '') +
          (settings &&
          settings.notification &&
          settings.notification.align === 'left'
            ? ' overlay-align-left'
            : ' overlay-align-right') +
          (notificationData.length > 0
            ? ' animate-overlay-notification-enter-done'
            : '')
        }
        style={{
          top:
            settings.notification &&
            typeof settings.notification.offset !== 'undefined'
              ? settings.notification.offset
              : 50
        }}>
        <div className="overlay-notification-inner">
          <div className="overlay-badge">
            <img src={FenceLogo} />
          </div>
          <div className="overlay-message">
            Your Run Has Been{' '}
            {notificationData.length > 0 && notificationData[0].denied === true
              ? 'Denied'
              : 'Approved'}
            .
          </div>
        </div>
      </div>
    </CSSTransition>
  ) : (
    <div></div>
  )
}

export default RunNotifications
