import React from 'react'
import Box from '@mui/material/Box'
import { useAppIsLoggedIn } from 'hooks'
import SidebarNavigation from 'components/SidebarNavigation'
import SidebarSponsors from 'components/SidebarSponsors'
import SidebarTeams from 'components/SidebarTeams'
import Banner from 'components/Banner'
import SocialLinks from 'components/SocialLinks'
import SiteTagline from 'components/SiteTagline'
import SidebarStreams from 'components/SidebarStreams'
import SidebarGoons from 'components/SidebarGoons'
import SidebarEvents from 'components/SidebarEvents'
import Footer from 'components/Footer'
import MenuMobile from 'components/MenuMobile'
import PendingLeagueRuns from 'components/PendingLeagueRuns'
import MyLeagueRuns from 'components/MyLeagueRuns'

const MyRuns = () => {
  const isLoggedIn = useAppIsLoggedIn()

  return (
    <>
      <Banner />
      <MyLeagueRuns />
    </>
  )
}

export default MyRuns
