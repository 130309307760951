import React from 'react'
import { useAppIsLoggedIn } from 'hooks'
import Banner from 'components/Banner'

const About = () => {
  const isLoggedIn = useAppIsLoggedIn()
  return (
    <>
      <Banner />
      <div className="aside-header aside-leagues">
        <span className="admin">About Fence.gg and FTL</span>
      </div>
      <div className="content-panel">
        <span className="paragraph">
          Fence.gg provides competitive leagues and streaming overlay tools for
          Escape From Tarkov Creators. Using your Twitch account, you are able
          to compete in leagues, utilize the chatbot and add interactive
          overlays to your livestreams.
        </span>
        <span className="paragraph">More info to come!</span>
      </div>
    </>
  )
}

export default About
