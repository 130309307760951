import React from 'react'
import TrophyGold from '../assets/img/icon-trophy-gold.png'
import TrophySilver from '../assets/img/icon-trophy-silver.png'
import TrophyBronze from '../assets/img/icon-trophy-bronze.png'
import TeamLink from './TeamLink'
import CenteredLoader from './CenteredLoader'

const EventStandings = ({ tournament, matches, teams }) => {
  const ordinal_suffix_of = i => {
    let j = i % 10,
      k = i % 100
    if (j == 1 && k != 11) {
      return i + 'st'
    }
    if (j == 2 && k != 12) {
      return i + 'nd'
    }
    if (j == 3 && k != 13) {
      return i + 'rd'
    }
    return i + 'th'
  }

  const getTeamOverallScore = id => {
    let wins = 0
    let losses = 0
    matches?.forEach(match => {
      if (match.teams.filter(t => t.id === id).length > 0) {
        match?.teams?.forEach(team => {
          if (team?.id === id) {
            wins += team?.score
          } else {
            losses += team?.score
          }
        })
      }
    })
    if (wins !== 0 || losses !== 0) {
      return `${wins}-${losses}`
    } else {
      return '---'
    }
  }

  return (
    <div className="events-box events-standings">
      <div className="events-box-header">
        <span className="events-title">Final Standings</span>
      </div>
      <div className="event-standings-header">
        <span className="event-standings-label">Place</span>
        <span className="event-standings-label">Team</span>
        <span className="event-standings-label">Score</span>
        <span className="event-standings-label">Prize</span>
      </div>

      {tournament?.teams?.length > 0 ? (
        [...tournament?.teams]
          .sort((a, b) => a.placement - b.placement)
          ?.map(team => {
            return (
              <div key={team.id} className="event-standings-row">
                <span className="event-standings-label">
                  {team?.placement === 1 && <img src={TrophyGold} />}
                  {team?.placement === 2 && <img src={TrophySilver} />}
                  {team?.placement === 3 && <img src={TrophyBronze} />}
                  <span>{ordinal_suffix_of(team?.placement)}</span>
                </span>
                <TeamLink
                  team={
                    teams?.filter(t => t.id === team?.id).length > 0
                      ? teams?.filter(t => t.id === team?.id)[0]
                      : ''
                  }
                />
                <span className="event-standings-label">
                  {getTeamOverallScore(team?.id)}
                </span>
                <span className="event-standings-label">${team?.prize}</span>
              </div>
            )
          })
      ) : (
        <CenteredLoader />
      )}
    </div>
  )
}

export default EventStandings
