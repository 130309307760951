import { useCallback, useEffect, useState } from 'react'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import { auth } from './configs/firebaseConfig'
import { onAuthStateChanged } from 'firebase/auth'
import { useDispatch } from 'react-redux'
import Container from '@mui/material/Container'
import CircularProgress from '@mui/material/CircularProgress'
import Box from '@mui/material/Box'
import Home from './pages/Home'
import Dashboard from './pages/protected/Dashboard'
import GoonsReport from './pages/protected/GoonsReport'
import {
  unsetUser,
  setUser,
  setUserLeagueRun,
  unsetUserLeagueRun,
  setLockedLeagueRun,
  unsetLockedLeagueRun,
  setUserData,
  unsetUserData
} from './store/slice/authSlice'
import {
  setIsLoading,
  setIsLoggedIn,
  setLeagues,
  setSettings
} from './store/slice/appSlice'
import ProtectedRoute from './utils/ProtectedRoute'
import Logout from 'components/Logout'
import {
  useAppIsLoading,
  useAppIsLoggedIn,
  useUser,
  useUserData,
  useAppSettings,
  useUserIsOwner,
  useUserIsAdmin,
  useUserIsBeta,
  useUserIsBanned
} from 'hooks'
import SettingsService from 'services/SettingsService'
import LeagueService from 'services/LeagueService'
import Navbar from 'components/Navbar'
import GoonsHistory from 'pages/GoonsHistory'
import Overlay from 'pages/Overlay'
import Leagues from 'pages/Leagues'
import Rankings from 'pages/Rankings'
import History from 'pages/History'
import Admin from 'pages/protected/Admin'
import Review from 'pages/protected/Review'
import Teams from 'pages/Teams'
import PostNews from 'pages/protected/PostNews'
import NewsStory from 'pages/NewsStory'
import Maintenance from 'pages/Maintenance'
import ClosedBeta from 'pages/ClosedBeta'
import Careers from 'pages/Careers'
import Contact from 'pages/Contact'
import Disclaimer from 'pages/Disclaimer'
import About from 'pages/About'
import ToS from 'pages/ToS'
import Privacy from 'pages/Privacy'
import Community from 'pages/Community'
import Events from 'pages/Events'
import Subscribe from 'pages/Subscribe'
import UserService from 'services/UserService'
import Bounty from 'pages/protected/Bounty'
import Account from 'pages/protected/Account'
import MyRuns from 'pages/protected/MyRuns'
import DefaultLayout from 'layouts'
import TwoColumn from 'layouts/TwoColumn'
import SiteBG from './assets/img/site-bg.jpg'
import Support from 'pages/protected/Support'
import Proceed from 'pages/Proceed'
import { useCreateCustomer } from 'components/Stripe/hooks/Customer'
import Banned from 'pages/Banned'
import EventPage from 'pages/EventPage'
import MatchPage from 'pages/MatchPage'
import Tournaments from 'pages/Tournaments'
import { useUsernameNotification } from 'hooks/notifications'
import TeamPage from 'pages/TeamPage'
import PlayerPage from 'pages/PlayerPage'

function App() {
  useUsernameNotification()
  const dispatch = useDispatch()
  const appSettings = useAppSettings()
  const appIsLoading = useAppIsLoading()
  const isLoggedIn = useAppIsLoggedIn()
  const isOwner = useUserIsOwner()
  const isAdmin = useUserIsAdmin()
  const isBeta = useUserIsBeta()
  const isBanned = useUserIsBanned()
  const user = useUser()
  const userData = useUserData()
  useCreateCustomer(userData)
  const [loadingUserLeagueRunData, setLoadingUserLeagueRunData] =
    useState(false)
  const [authListenerSet, SetAuthListenerSet] = useState(false)

  useEffect(() => {
    const queryParams = new Proxy(new URLSearchParams(window.location.search), {
      get: (searchParams, prop) => searchParams.get(prop)
    })
    const referralCode = queryParams.ref
    if (referralCode !== null && !window.localStorage.getItem('referralCode')) {
      window.localStorage.setItem('referralCode', referralCode)
    }
  }, [])

  useEffect(() => {
    SettingsService.getAppSettings(data => {
      dispatch(setSettings(data))
    })
  }, [dispatch])

  const getLeagues = useCallback(async () => {
    const response = await LeagueService.getLeagues(data => {
      dispatch(setLeagues(data))
    })
    return response
  }, [dispatch])

  useEffect(() => {
    const leagues = getLeagues()
    return () => {
      leagues.then(response => (response ? response.unsubscribe() : null))
    }
  }, [getLeagues])

  useEffect(() => {
    if (isLoggedIn) {
      if (user.owner !== true && user.uid === appSettings.owner) {
        dispatch(
          setUser({
            owner: true
          })
        )
      }
    }
  }, [appSettings, user, isLoggedIn, dispatch])

  useEffect(() => {
    if (user && user.uid && loadingUserLeagueRunData === false) {
      setLoadingUserLeagueRunData(true)
      LeagueService.getUserPendingRun(user.uid, result => {
        if (result.data()) {
          dispatch(
            setUserLeagueRun({
              ...result.data(),
              started: `${new Date(result.data().started.seconds * 1000)}`,
              endedAt: result.data().endedAt
                ? `${new Date(result.data().endedAt.seconds * 1000)}`
                : null
            })
          )
        } else {
          dispatch(unsetUserLeagueRun())
        }
      })
      LeagueService.getUserLockedRun(user.uid, result => {
        if (result.data()) {
          dispatch(
            setLockedLeagueRun({
              ...result.data()
            })
          )
        } else {
          dispatch(unsetLockedLeagueRun())
        }
      })
    }
  }, [user, userData, dispatch, loadingUserLeagueRunData])

  useEffect(() => {
    if (authListenerSet === false && appSettings.owner) {
      SetAuthListenerSet(true)
      onAuthStateChanged(auth, firebaseUser => {
        if (firebaseUser) {
          firebaseUser.getIdToken(true).then(function (idToken) {
            firebaseUser
              .getIdTokenResult()
              .then(idTokenResult => {
                const setUserAuth = {
                  ...user,
                  ...idTokenResult.claims,
                  uid: firebaseUser.uid,
                  accessToken: firebaseUser.accessToken,
                  refreshToken: firebaseUser.refreshToken,
                  displayName: firebaseUser.displayName,
                  photoUrl: firebaseUser.photoUrl,
                  email: firebaseUser.email,
                  emailVerified: firebaseUser.emailVerified,
                  createdAt: firebaseUser.metadata.createdAt,
                  lastLoginAt: firebaseUser.metadata.lastLoginAt
                }

                dispatch(setUser(setUserAuth))
                dispatch(setIsLoggedIn(true))
                if (appSettings.owner) {
                  dispatch(setIsLoading(false))
                }
                UserService.getMyUser(firebaseUser.uid, data => {
                  dispatch(setUserData(data.data()))
                })
              })
              .catch(error => {
                console.log(error)
              })
          })
        } else {
          dispatch(unsetUser())
          dispatch(unsetUserData())
          dispatch(setIsLoggedIn(false))
          if (appSettings.owner) {
            dispatch(setIsLoading(false))
          }
        }
      })
    }
  }, [auth, dispatch, appSettings, authListenerSet, user])

  const isOverlay = () => {
    return window.location.href.indexOf('/overlay/') > -1
  }

  const css = `
    #root > .MuiPaper-root { display: none }
    body { background:none transparent !important; }
    html { background:none transparent !important; }`

  const bgcss = `
    html {
      background: #000 url(${SiteBG}) no-repeat center center;
      background-size: cover;
      background-attachment: fixed;
    }`

  return (
    <Router>
      {!isOverlay() && <Navbar />}
      {!isOverlay() && <style>{bgcss}</style>}
      {appIsLoading ? (
        !isOverlay() ? (
          <Container maxWidth="xl">
            <Box
              pt={10}
              pb={3}
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
              }}>
              <CircularProgress size="5rem" />
            </Box>
          </Container>
        ) : (
          <style>{css}</style>
        )
      ) : !isBanned &&
        (appSettings.closedBeta === false || isAdmin || isOwner || isBeta) &&
        (appSettings.maintenance === false || isAdmin || isOwner) ? (
        <Routes>
          <Route path={`/overlay/:overlay`} element={<Overlay />} />

          <Route path="/account" exact element={<TwoColumn />}>
            <Route
              index
              element={
                <ProtectedRoute
                  isLoading={appIsLoading}
                  isLoggedIn={isLoggedIn}
                  component={Account}
                />
              }
            />
          </Route>

          <Route element={<DefaultLayout />}>
            <Route
              exact
              path="/dashboard"
              element={
                <ProtectedRoute
                  isLoading={appIsLoading}
                  isLoggedIn={isLoggedIn}
                  component={Dashboard}
                />
              }
            />

            <Route
              isLoading={appIsLoading}
              path="/subscribe"
              element={<Subscribe />}
            />

            <Route exact path="/bounty" element={<Bounty />} />

            <Route
              exact
              path="/bounty/create"
              element={
                <ProtectedRoute
                  isLoading={appIsLoading}
                  isLoggedIn={isLoggedIn}
                  component={Bounty}
                />
              }
            />

            <Route
              exact
              path="/donate"
              element={
                <ProtectedRoute
                  isLoading={appIsLoading}
                  isLoggedIn={isLoggedIn}
                  component={Support}
                />
              }
            />
            <Route
              exact
              path="/runs"
              element={
                <ProtectedRoute
                  isLoading={appIsLoading}
                  isLoggedIn={isLoggedIn}
                  component={MyRuns}
                />
              }
            />

            <Route exact path="/logout" element={<Logout />} />

            <Route
              exact
              path="/report-goons"
              element={
                <ProtectedRoute
                  isLoading={appIsLoading}
                  isLoggedIn={isLoggedIn}
                  component={GoonsReport}
                />
              }
            />

            <Route
              exact
              path="/admin"
              element={
                <ProtectedRoute
                  isLoading={appIsLoading}
                  isLoggedIn={isLoggedIn}
                  component={Admin}
                  scopes={['admin', 'owner']}
                />
              }
            />

            <Route
              isLoading={appIsLoading}
              path="/news/post/:id"
              element={<NewsStory />}
            />

            <Route
              isLoading={appIsLoading}
              path="/event/:slug/:id/:tab"
              element={<EventPage />}
            />

            <Route
              isLoading={appIsLoading}
              path="/event/:slug/:id/"
              element={<EventPage />}
            />

            <Route
              isLoading={appIsLoading}
              path="/match/:slug/:id"
              element={<MatchPage />}
            />

            <Route
              isLoading={appIsLoading}
              path="/team/:slug/:id"
              element={<TeamPage />}
            />

            <Route
              isLoading={appIsLoading}
              path="/player/:slug/:id"
              element={<PlayerPage />}
            />

            <Route
              exact
              path="/news/post"
              element={
                <ProtectedRoute
                  isLoading={appIsLoading}
                  isLoggedIn={isLoggedIn}
                  component={PostNews}
                  scopes={['admin', 'owner', 'editor']}
                />
              }
            />

            <Route
              exact
              path="/review"
              element={
                <ProtectedRoute
                  isLoading={appIsLoading}
                  isLoggedIn={isLoggedIn}
                  component={Review}
                  scopes={['admin', 'owner', 'vodder']}
                />
              }
            />

            <Route
              exact
              path="/review/approved"
              element={
                <ProtectedRoute
                  isLoading={appIsLoading}
                  isLoggedIn={isLoggedIn}
                  component={Review}
                  approved={true}
                  scopes={['admin', 'owner', 'vodder']}
                />
              }
            />

            <Route
              exact
              path="/review/denied"
              element={
                <ProtectedRoute
                  isLoading={appIsLoading}
                  isLoggedIn={isLoggedIn}
                  component={Review}
                  denied={true}
                  scopes={['admin', 'owner', 'vodder']}
                />
              }
            />

            <Route exact path="/goons-history" element={<GoonsHistory />} />
            <Route exact path="/leagues" element={<Leagues />} />

            <Route exact path="/proceed" element={<Proceed />} />
            <Route exact path="/teams" element={<Teams />} />

            <Route exact path="/careers" element={<Careers />} />

            <Route exact path="/privacy" element={<Privacy />} />

            <Route exact path="/tos" element={<ToS />} />

            <Route exact path="/about" element={<About />} />

            <Route exact path="/disclaimer" element={<Disclaimer />} />

            <Route exact path="/contact" element={<Contact />} />

            <Route exact path="/community" element={<Community />} />

            <Route exact path="/events" element={<Events />} />

            <Route exact path="/tournaments" element={<Tournaments />} />

            <Route path="/rankings/:league" element={<Rankings />} />
            <Route exact path="/rankings" element={<Rankings />} />
            <Route path="/history/:league/:season" element={<History />} />
            <Route exact path="/" element={<Home />} />
          </Route>
        </Routes>
      ) : !isOverlay() ? (
        <>
          <Routes>
            <Route exact path="/logout" element={<Logout />} />
          </Routes>
          {isBanned && <Banned />}
          {appSettings.closedBeta === true && <ClosedBeta />}
          {appSettings.maintenance === true && <Maintenance />}
        </>
      ) : (
        <>
          <Routes>
            <Route path={`/overlay/:overlay`} element={<Overlay />} />
          </Routes>
        </>
      )}
    </Router>
  )
}

export default App
