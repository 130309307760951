import { useState, useEffect } from 'react'
import { Grid, Typography } from '@mui/material'
import { StripeApi } from 'api/Stripe'
import { stripeDisplayAmount } from 'utils/Stripe'
import TopUp from './Stripe/account/TopUp'
import Payout from './Stripe/account/Payout'

const AdminStripe = () => {
  const { data: balance } = StripeApi.useGetBalanceQuery()
  const [availableBalance, setAvailableBalance] = useState()
  const [pendingBalance, setPendingBalance] = useState()

  useEffect(() => {
    if (balance) {
      setAvailableBalance(
        balance?.available.filter(avail => avail.currency === 'usd')[0]
      )
      setPendingBalance(
        balance?.pending.filter(pending => pending.currency === 'usd')[0]
      )
    }
  }, [balance])

  return (
    <div>
      <div className="aside-header aside-modules">
        <span className="dashboard">Stripe</span>
      </div>
      <div className="content-panel">
        <Typography>Balances</Typography>
        <Grid container spacing={2}>
          <Grid item md={6}>
            <span className="heading-text">
              Available:{' '}
              <span className="heading-value">
                {stripeDisplayAmount(availableBalance?.amount || 0)}
              </span>
            </span>
          </Grid>
          <Grid item md={6}>
            <span className="heading-text">
              Pending:{' '}
              <span className="heading-value">
                {stripeDisplayAmount(pendingBalance?.amount || 0)}
              </span>
            </span>
          </Grid>
        </Grid>
        <TopUp />
        <Payout />
      </div>
    </div>
  )
}

export default AdminStripe
