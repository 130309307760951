import React from 'react'
import Banner from 'components/Banner'
import SupportComponent from 'components/SupportComponent'

const Support = () => {
  return (
    <>
      <Banner />
      <SupportComponent />
    </>
  )
}

export default Support
