import React from 'react'

import Grid from '@mui/material/Grid'
import { Card as MuiCard, Box } from '@mui/material'
import CardContent from '@mui/material/CardContent'

const SingleCard = ({ cardDetails }) => {
  return (
    <Grid container spacing={4}>
      <Grid item xs={12} md={6}>
        <Box
          className={`card-box is-private card-${cardDetails?.brand.toLowerCase()}`}
          sx={{ cursor: 'pointer' }}>
          <MuiCard sx={{ flexGrow: 1, background: 'none' }}>
            <CardContent>
              <span className="card-type is-private">{cardDetails?.brand}</span>
              <span className="card-number-label">Credit Card Number</span>
              <span className="card-number">
                &bull;&bull;&bull;&bull; &bull;&bull;&bull;&bull;
                &bull;&bull;&bull;&bull; {cardDetails?.last4}
              </span>
              <span className="card-expiration">
                EXP {cardDetails?.exp_month}/{cardDetails?.exp_year}
              </span>
            </CardContent>
          </MuiCard>
        </Box>
      </Grid>
    </Grid>
  )
}

export default SingleCard
