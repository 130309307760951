import { useEffect, useState } from 'react'
import { Box, Button, CircularProgress } from '@mui/material'
import Username from 'components/Username'
import { LeagueServiceApi } from 'api/LeagueServiceApi'
import * as ga4 from 'utils/ga4'
import { UserServiceApi } from 'api/UserServiceApi'
import UsernameAvatar from 'components/UsernameDisplay/UsernameAvatar'

const LeagueDuoOutgoing = ({ invites }) => {
  const [cancelLoading, setCancelLoading] = useState(false)
  const [cancelInviteDuo] = LeagueServiceApi.useCancelInviteDuoPartnerMutation()
  const [getUsername, { data: username, isLoading }] =
    UserServiceApi.useLazyGetUsernameByIdCacheQuery()

  useEffect(() => {
    if (invites?.outgoing) {
      getUsername({ id: invites?.outgoing }, true)
    }
  }, [getUsername, invites.outgoing])

  const cancelInviteDuoPartner = async uid => {
    setCancelLoading(true)
    await cancelInviteDuo({ uid })
      .unwrap()
      .then(data => {
        setCancelLoading(false)
        ga4.sendEvent({
          category: 'Duo Partner',
          action: 'Cancel',
          nonInteraction: false
        })
      })
  }

  if (isLoading) {
    return (
      <Box textAlign={'center'}>
        <CircularProgress size={'20px'} />
      </Box>
    )
  }

  return (
    <div>
      <span className="helper-text">
        You sent an invite out for a duo partner, please tell them to accept it.
        To invite someone else, cancel your currently pending invite.
      </span>
      <div className="pending-invite">
        <div className="invite-user">
          <div className="invite-avatar">
            <UsernameAvatar username={username} />
          </div>
          <div className="invite-username">
            <span className="invite-display-name">
              <Username username={username} />
            </span>
            <span className="invite-status">(Pending)</span>
          </div>
        </div>
        <div className="invite-button">
          <Button
            className="btn btn-primary"
            disabled={cancelLoading}
            onClick={e => {
              e.preventDefault()
              cancelInviteDuoPartner(invites.outgoing)
            }}>
            {cancelLoading && (
              <CircularProgress
                style={{
                  color: '#000',
                  height: 15,
                  width: 15,
                  marginRight: 10
                }}
              />
            )}
            Cancel
          </Button>
        </div>
      </div>
    </div>
  )
}

export default LeagueDuoOutgoing
