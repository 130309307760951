import React, { useEffect, useState } from 'react'
import { Navigate } from 'react-router-dom'
import { getAuth, signOut } from 'firebase/auth'
import Container from '@mui/material/Container'
import CircularProgress from '@mui/material/CircularProgress'
import Box from '@mui/material/Box'

const Logout = () => {
  const auth = getAuth()
  const [loggedOut, setLoggedOut] = useState(false)

  useEffect(() => {
    signOut(auth)
      .then(() => {
        setLoggedOut(true)
      })
      .catch(error => {
        console.log('error', error)
      })
  }, [])

  return (
    <Container maxWidth="xl">
      <Box
        pt={10}
        pb={3}
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}>
        <CircularProgress />
      </Box>
      {loggedOut && <Navigate to="/" />}
    </Container>
  )
}

export default Logout
