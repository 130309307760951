import React from 'react'
import ChargeForm from './Stripe/charge/ChargeForm'

import { useUserData } from 'hooks'

const SupportComponent = () => {
  const user = useUserData()

  return (
    <div>
      <div className="aside-header aside-leagues">
        <span className="donate">Donate To Fence.gg</span>
      </div>
      <div className="content-panel">
        <span className="subhead green">
          Your support is greatly appreciated
        </span>
        <span className="parapraph">
          Any donations will help support our prize pools, server costs,
          development of new features, marketing efforts, and other operating
          expenses.
        </span>
        <div style={{ paddingTop: 10 }}>
          <ChargeForm user={user} route="/donate" />
        </div>
      </div>
    </div>
  )
}

export default SupportComponent
