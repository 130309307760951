import { useState } from 'react'
import { Modal, Box, Alert, Button, CircularProgress } from '@mui/material'
import OverallStats from '../../assets/img/overall-stats.jpg'
import { useUserData } from 'hooks'
import * as ga4 from 'utils/ga4'
import { LeagueServiceApi } from 'api/LeagueServiceApi'

const LeagueRunModal = ({ league, handleClose, open, currentWeek }) => {
  const [runLoading, setRunLoading] = useState(false)
  const [startRunError, setStartRunError] = useState('')
  const userData = useUserData()
  const [startLeagueRunCall] = LeagueServiceApi.useStartLeagueRunMutation()

  const startRun = async (league, week) => {
    setRunLoading(true)
    await startLeagueRunCall({
      leagueId: league.id,
      tierId: 'yellow',
      season: league.currentSeason,
      week: week.id
    })
      .unwrap()
      .then(response => {
        if (response.success) {
          handleClose()
          setRunLoading(false)
          ga4.sendEvent({
            category: 'League Run',
            action: 'Start',
            label: league.id,
            nonInteraction: false
          })
        } else if (response.error) {
          setStartRunError(response.error)
          setTimeout(() => {
            setStartRunError('')
            setRunLoading(false)
          }, 5000)
        }
      })
  }

  return (
    <Modal
      className="start-run-modal"
      disableEscapeKeyDown={true}
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description">
      <Box className="run-modal">
        <div className="aside-header aside-run">
          <span className="leagues">
            Starting a {league.display_name} League Run
          </span>
        </div>
        <div className="modal-content">
          <Alert severity="error">
            Read the rules carefully or your run may not count!
          </Alert>
          <span className="subhead green">
            {league.display_name} League Rules:
          </span>
          <ol>
            {league.id === 'duo' ? (
              <li>
                You and your duo partner must be livestreaming on the Twitch
                accounts used to form your Fence.gg duos team.
              </li>
            ) : (
              <li>
                You must be livestreaming on the Twitch account you are
                currently signed into Fence.gg with.
              </li>
            )}
            {league.id === 'duo' ? (
              <li>
                You and your duo partner must stream for the entire duration of
                the run with the Fence.gg league run overlay visible on both of
                your streams.
              </li>
            ) : (
              <li>
                You must stream for the entire duration of the run with the
                Fence.gg league run overlay visible.
              </li>
            )}
            {league.id === 'duo' ? (
              <li>
                You must submit a working timestamped VOD link from both you and
                your partner's streams of your run for verification.
              </li>
            ) : (
              <li>
                You must submit a working timestamped VOD link or highlight link
                of your run for verification.
              </li>
            )}

            <li>Runs must be submitted before the week's end date and time.</li>
            {league.id === 'duo' ? (
              <li>
                You must queue with your duo partner and no one else. No funny
                business.
              </li>
            ) : (
              <li>You must queue solo. No funny business.</li>
            )}
            {league.id === 'scav' && (
              <>
                <li className="reminder">BEFORE YOU EXTRACT:</li>
                <ol>
                  <li className="reminder-img">
                    <span>
                      Show your overall stats (statistics section) on stream.
                    </span>
                    <img src={OverallStats} alt="Overall Stats" />
                  </li>
                </ol>
              </>
            )}
            {league.id === 'duo' && (
              <li>One of you needs to survive for the run to count.</li>
            )}
            {league.id === 'duo' ? (
              <li>AT THE END OF THE RAID:</li>
            ) : (
              <li>AFTER YOU EXTRACT:</li>
            )}

            <ol>
              {league.id === 'duo' ? (
                <li>
                  Show any found in raid bonus items you have looted (If you
                  survived).
                </li>
              ) : (
                <li>Show any found in raid bonus items you have looted.</li>
              )}
              {league.id === 'duo' ? (
                <li>
                  Slowly show all raid summary screens on stream. (Even if you
                  died)
                </li>
              ) : (
                <li>Slowly show all raid summary screens on stream.</li>
              )}
            </ol>
          </ol>
          <span className="disclaimer">
            By clicking "Start Run" you agree to comply with all of the rules
            above and acknowledge that they are subject to change and that
            Admins reserve the final right to approve or deny your run entries.
          </span>
          {startRunError !== '' && (
            <div className="start-run-error">
              <Alert severity="error">{startRunError}</Alert>
            </div>
          )}
          <div className="modal-controls">
            <Button
              className="btn btn-primary btn-cancel"
              disabled={runLoading}
              onClick={e => {
                e.preventDefault()
                handleClose()
              }}>
              Cancel
            </Button>
            <Button
              className="btn btn-primary"
              disabled={runLoading}
              onClick={e => {
                e.preventDefault()
                startRun(league, currentWeek)
              }}>
              {runLoading && (
                <CircularProgress
                  style={{
                    color: '#000',
                    height: 15,
                    width: 15,
                    marginRight: 10
                  }}
                />
              )}
              Start {league.display_name} Run
            </Button>
          </div>
        </div>
      </Box>
    </Modal>
  )
}

export default LeagueRunModal
