import Firebase from 'utils/Firebase'
import { getDoc, getDocs, getFirestore } from 'firebase/firestore'
import {
  collection,
  onSnapshot,
  orderBy,
  limit,
  getCountFromServer,
  doc,
  and,
  or,
  where,
  query
} from 'firebase/firestore'

const db = getFirestore(Firebase)

async function getLeagues(callback) {
  const unsubscribe = onSnapshot(collection(db, 'leagues'), docs => {
    const results = []
    docs.forEach(doc => results.push({ ...doc.data(), id: doc.id }))
    if (typeof callback === 'function') {
      callback(results)
    }
  })
  return { unsubscribe }
}

async function getLeagueDetails(league) {
  return await getDoc(doc(db, 'leagues', league))
}

async function getLeagueTiers(league, callback) {
  onSnapshot(collection(db, 'leagues', league, 'tiers'), docs => {
    if (typeof callback === 'function') {
      callback(docs)
    }
  })
}

async function getLeagueSeasons(league, callback) {
  return onSnapshot(collection(db, 'leagues', league, 'seasons'), docs => {
    if (typeof callback === 'function') {
      callback(docs)
    }
  })
}

async function getLeagueSeason(league, season, callback) {
  const unsubscribe = onSnapshot(
    doc(db, 'leagues', league, 'seasons', `season-${season}`),
    doc => {
      if (typeof callback === 'function') {
        callback(doc)
      }
    }
  )

  return { unsubscribe }
}

async function getInvitedPlayers(callback) {
  const q = query(
    collection(db, 'invites'),
    and(
      where('accepted', '>=', 'twitch:'),
      where('accepted', '<=', 'twitch:\uf7ff')
    )
  )
  const unsubscribe = onSnapshot(q, querySnapshot => {
    const docs = []
    querySnapshot.forEach(doc => {
      docs.push({ ...doc.data(), id: doc.id })
    })
    if (typeof callback === 'function') {
      callback(docs)
    }
  })

  return { unsubscribe }
}

async function getSeasonWeeks(league, season, callback) {
  onSnapshot(
    collection(db, 'leagues', league, 'seasons', season, 'weeks'),
    docs => {
      if (typeof callback === 'function') {
        callback(docs)
      }
    }
  )
}

async function getLeagueSeasonWeeks(league, season, callback) {
  return onSnapshot(
    collection(db, 'leagues', league, 'seasons', `season-${season}`, 'weeks'),
    docs => {
      if (typeof callback === 'function') {
        callback(docs)
      }
    }
  )
}

async function getLeagueSeasonWeek(league, season, week, callback) {
  const unsubscribe = onSnapshot(
    doc(
      db,
      'leagues',
      league,
      'seasons',
      `season-${season}`,
      'weeks',
      `week-${week}`
    ),
    doc => {
      if (typeof callback === 'function') {
        callback(doc)
      }
    }
  )
  return { unsubscribe }
}

async function getLeagueSeasonByDate(league, season, start, end) {
  return await getDocs(
    query(
      collection(db, 'leagues', league, 'seasons', `season-${season}`, 'weeks'),
      where('starts', '>=', start),
      where('starts', '<', end),
      limit(1)
    )
  ).then(docs => {
    const data = []
    docs.forEach(doc => {
      data.push({ ...doc.data() })
    })
    return data
  })
}

async function getLeagueSeasonRankings(league, season, tier) {
  return getDoc(
    doc(db, 'leagues', league, 'seasons', `season-${season}`, 'rankings', tier)
  ).then(doc =>
    doc.data()?.rankings?.map(rank => {
      return { ...rank, league, season, tier }
    })
  )
}

async function getLeagueSeasonRankingsLive(league, season, tier, callback) {
  const q = doc(
    db,
    'leagues',
    league,
    'seasons',
    `season-${season}`,
    'rankings',
    tier
  )
  const unsubscribe = onSnapshot(q, doc => {
    if (typeof callback === 'function') {
      callback(doc)
    }
  })
  return { unsubscribe }
}

async function getUserPendingRun(id, callback) {
  onSnapshot(doc(db, 'pendingRuns', id), doc => {
    if (typeof callback === 'function') {
      callback(doc)
    }
  })
}

async function getLeagueRuns(callback) {
  return onSnapshot(collection(db, 'leagueRuns'), docs => {
    if (typeof callback === 'function') {
      callback(docs)
    }
  })
}

async function getLeagueRunsSinceTime(timestamp, callback) {
  const q = query(
    collection(db, 'leagueRuns'),
    where('createdAt', '>', timestamp)
  )
  return onSnapshot(q, querySnapshot => {
    const docs = []
    querySnapshot.forEach(doc => {
      docs.push({ ...doc.data(), id: doc.id })
    })
    if (typeof callback === 'function') {
      callback(docs)
    }
  })
}

async function getLeagueRunsCount(callback) {
  const snapshot = await getCountFromServer(query(collection(db, 'leagueRuns')))
  if (typeof callback === 'function') {
    callback(snapshot.data().count)
  }
}

async function getApprovedLeagueRunsCount(callback) {
  const snapshot = await getCountFromServer(
    query(collection(db, 'leagueRuns'), where('approved', '==', true))
  )
  if (typeof callback === 'function') {
    callback(snapshot.data().count)
  }
}

async function getDeniedLeagueRunsCount(callback) {
  const snapshot = await getCountFromServer(
    query(
      collection(db, 'leagueRuns'),
      where('approved', '==', false),
      where('denied', '==', true)
    )
  )
  if (typeof callback === 'function') {
    callback(snapshot.data().count)
  }
}

async function getLeagueRun(id, callback) {
  onSnapshot(doc(db, 'leagueRuns', id), doc => {
    if (typeof callback === 'function') {
      callback(doc)
    }
  })
}

async function getUserLockedRun(id, callback) {
  onSnapshot(doc(db, 'lockedRuns', id), doc => {
    if (typeof callback === 'function') {
      callback(doc)
    }
  })
}

async function getPendingRuns(callback) {
  const unsubscribe = onSnapshot(collection(db, 'pendingRuns'), docs => {
    const results = []
    docs.forEach(doc => results.push({ ...doc.data(), id: doc.id }))
    if (typeof callback === 'function') {
      callback(results)
    }
  })
  return { unsubscribe }
}

async function getPendingRunsByMax(callback) {
  const q = query(collection(db, 'pendingRuns'), orderBy('started'), limit(6))

  const unsubscribe = onSnapshot(q, docs => {
    const results = []
    docs.forEach(doc => results.push({ ...doc.data(), id: doc.id }))
    if (typeof callback === 'function') {
      callback(results)
    }
  })
  return { unsubscribe }
}

async function getUnreadLeagueRuns(callback) {
  const q = query(
    collection(db, 'leagueRuns'),
    where('approved', '==', false),
    where('denied', '==', false)
  )
  const unsubscribe = onSnapshot(q, querySnapshot => {
    const docs = []
    querySnapshot.forEach(doc => {
      docs.push({ ...doc.data(), id: doc.id })
    })
    if (typeof callback === 'function') {
      callback(docs)
    }
  })
  return { unsubscribe }
}

async function getApprovedLeagueRuns(callback) {
  const q = query(
    collection(db, 'leagueRuns'),
    where('approved', '==', true),
    where('denied', '==', false),
    orderBy('updatedAt', 'desc'),
    limit(100)
  )
  return onSnapshot(q, querySnapshot => {
    const docs = []
    querySnapshot.forEach(doc => {
      docs.push({ ...doc.data(), id: doc.id })
    })
    if (typeof callback === 'function') {
      callback(docs)
    }
  })
}

// async function getApprovedLeagueRunsCount(callback) {
//   const q = query(
//     collection(db, 'leagueRuns'),
//     where('approved', '==', true),
//     where('denied', '==', false)
//   )
//   return onSnapshot(q, querySnapshot => {
//     if (typeof callback === 'function') {
//       callback(querySnapshot.size)
//     }
//   })
// }

async function getDeniedLeagueRuns(callback) {
  const q = query(
    collection(db, 'leagueRuns'),
    where('approved', '==', false),
    where('denied', '==', true),
    orderBy('updatedAt', 'desc'),
    limit(100)
  )
  return onSnapshot(q, querySnapshot => {
    const docs = []
    querySnapshot.forEach(doc => {
      docs.push({ ...doc.data(), id: doc.id })
    })
    if (typeof callback === 'function') {
      callback(docs)
    }
  })
}

// async function getDeniedLeagueRunsCount(callback) {
//   const q = query(
//     collection(db, 'leagueRuns'),
//     where('approved', '==', false),
//     where('denied', '==', true)
//   )
//   return onSnapshot(q, querySnapshot => {
//     if (typeof callback === 'function') {
//       callback(querySnapshot.size)
//     }
//   })
// }

async function getMyLeagueRuns(uid, callback) {
  const q = query(
    collection(db, 'leagueRuns'),
    or(where('submittedBy', '==', uid), where('duoPartner', '==', uid))
  )
  const unsubscribe = onSnapshot(q, querySnapshot => {
    const docs = []
    querySnapshot.forEach(doc => {
      docs.push({ ...doc.data(), id: doc.id })
    })
    if (typeof callback === 'function') {
      callback(docs)
    }
  })
  return { unsubscribe }
}

async function getBetaUsers(callback) {
  const q = query(collection(db, 'earlyAccess'), where('beta', '==', true))
  const unsubscribe = onSnapshot(q, querySnapshot => {
    const docs = []
    querySnapshot.forEach(doc => {
      docs.push({ ...doc.data(), id: doc.id })
    })
    if (typeof callback === 'function') {
      callback(docs)
    }
  })
  return { unsubscribe }
}

async function getUnreadReviewedRuns(uid, callback) {
  const q = query(
    collection(db, 'leagueRuns'),
    and(
      or(
        and(where('submittedBy', '==', uid), where('unread', '==', true)),
        and(where('duoPartner', '==', uid), where('duoUnread', '==', true))
      )
    )
  )
  const unsubscribe = onSnapshot(q, querySnapshot => {
    const docs = []
    querySnapshot.forEach(doc => {
      docs.push({ ...doc.data(), id: doc.id })
    })
    if (typeof callback === 'function') {
      callback(docs)
    }
  })
  return { unsubscribe }
}

async function getNotificationRuns(uid, callback) {
  const q = query(
    collection(db, 'leagueRuns'),
    and(
      or(where('submittedBy', '==', uid), where('duoPartner', '==', uid)),
      where('notified', '==', false)
    )
  )
  const unsubscribe = onSnapshot(q, querySnapshot => {
    const docs = []
    querySnapshot.forEach(doc => {
      docs.push({ ...doc.data(), id: doc.id })
    })
    if (typeof callback === 'function') {
      callback(docs)
    }
  })
  return { unsubscribe }
}

async function getLeagueRunsFromUID(uid, callback) {
  const q = query(
    collection(db, 'leagueRuns'),
    and(
      where('approved', '==', true),
      or(where('submittedBy', '==', uid), where('duoPartner', '==', uid))
    )
  )
  const unsubscribe = onSnapshot(q, querySnapshot => {
    const docs = []
    querySnapshot.forEach(doc => {
      docs.push({ ...doc.data(), id: doc.id })
    })
    if (typeof callback === 'function') {
      callback(docs)
    }
  })
  return { unsubscribe }
}

async function getHightestPointsRunThisWeek(weekStart, weekEnd, callback) {
  const q = query(
    collection(db, 'leagueRuns'),
    and(
      where('approved', '==', true),
      where('createdAt', '>=', weekStart),
      where('createdAt', '<', weekEnd)
    )
  )
  const unsubscribe = onSnapshot(q, querySnapshot => {
    const docs = []
    querySnapshot.forEach(doc => {
      docs.push({ ...doc.data(), id: doc.id })
    })
    if (typeof callback === 'function') {
      callback(docs)
    }
  })
  return { unsubscribe }
}

async function getBadges(uid, callback) {
  return onSnapshot(doc(db, 'badges', uid), doc => {
    if (typeof callback === 'function') {
      callback(doc)
    }
  })
}

const LeagueService = {
  getLeagues,
  getLeagueDetails,
  getLeagueTiers,
  getLeagueSeasons,
  getLeagueSeason,
  getLeagueSeasonWeeks,
  getLeagueSeasonWeek,
  getLeagueSeasonByDate,
  getUserPendingRun,
  getPendingRuns,
  getPendingRunsByMax,
  getUnreadLeagueRuns,
  getSeasonWeeks,
  getUserLockedRun,
  getLeagueRuns,
  getLeagueRun,
  getLeagueSeasonRankings,
  getBetaUsers,
  getApprovedLeagueRunsCount,
  getDeniedLeagueRunsCount,
  getDeniedLeagueRuns,
  getApprovedLeagueRuns,
  getMyLeagueRuns,
  getUnreadReviewedRuns,
  getNotificationRuns,
  getLeagueRunsFromUID,
  getBadges,
  getHightestPointsRunThisWeek,
  getLeagueRunsCount,
  getLeagueRunsSinceTime,
  getInvitedPlayers,
  getLeagueSeasonRankingsLive
}

export default LeagueService
