import { CircularProgress } from '@mui/material'
import { TournamentServiceApi } from 'api/TournamentServiceApi'
import moment from 'moment'
import { useCallback, useEffect, useState } from 'react'
const { Link } = require('react-router-dom')

const HeadToHead = ({ team1, team2 }) => {
  const [matches, setMatches] = useState([])
  const [events, setEvents] = useState([])

  const [getMatchesApi] = TournamentServiceApi.useLazyGetMatchesByIdsQuery()

  const getMatchesAsync = useCallback(async () => {
    const asyncMatches = await Promise.all(
      [...team1?.matches, ...team2?.matches]
        .filter(function (item, pos, self) {
          return self.indexOf(item) == pos
        })
        .map(async match => {
          return await getMatchesApi({ ids: match }, true).unwrap()
        })
    )
    setMatches(
      asyncMatches.filter(
        team =>
          team.teams.filter(t => t.id === team1.id).length > 0 &&
          team.teams.filter(t => t.id === team2.id).length > 0
      )
    )
  }, [team1, team2])

  useEffect(() => {
    if (team1?.matches?.length > 0 && team2?.matches?.length > 0) {
      getMatchesAsync()
    }
  }, [getMatchesAsync, team1, team2])

  const [getEventsApi] = TournamentServiceApi.useLazyGetEventsByIdsQuery()

  const getEventsAsync = useCallback(
    async m => {
      const asyncEvents = await Promise.all(
        [...m.map(match => match.event)]
          .filter(function (item, pos, self) {
            return self.indexOf(item) == pos
          })
          .map(async e => {
            return await getEventsApi({ ids: e }, true).unwrap()
          })
      )
      if (asyncEvents?.length > 0 && asyncEvents[0]?.length > 0) {
        setEvents([...asyncEvents[0]])
      } else {
        setEvents([...asyncEvents])
      }
    },
    [team1, team2]
  )

  useEffect(() => {
    if (matches?.length > 0) {
      getEventsAsync(matches)
    }
  }, [getEventsAsync, matches])

  return matches ? (
    <div className="match-head">
      {matches?.length > 0 && events?.length > 0 ? (
        <div>
          <div className="match-head-header">
            <Link
              to={`/team/${team1.display_name
                .replace(' ', '-')
                .toLowerCase()}/${team1?.id}`}
              className="match-head-team">
              <img className={'match-header-team-logo'} src={team1?.avatar} />
              <span>{team1?.display_name}</span>
            </Link>
            <div className="match-head-score">
              <span className="match-head-score-value">
                {
                  matches?.filter(
                    m =>
                      m?.teams?.filter(t => t.id === team1.id)[0]?.winner ===
                      true
                  ).length
                }
              </span>
              <span className="match-head-score-label">Wins</span>
            </div>
            <div className="match-head-score">
              <span className="match-head-score-value">0</span>
              <span className="match-head-score-label">Draws</span>
            </div>
            <div className="match-head-score">
              <span className="match-head-score-value">
                {
                  matches?.filter(
                    m =>
                      m?.teams?.filter(t => t.id === team2.id)[0]?.winner ===
                      true
                  ).length
                }
              </span>
              <span className="match-head-score-label">Wins</span>
            </div>
            <Link
              to={`/team/${team2.display_name
                .replace(' ', '-')
                .toLowerCase()}/${team2?.id}`}
              className="match-head-team">
              <img className={'match-header-team-logo'} src={team2?.avatar} />
              <span>{team2?.display_name}</span>
            </Link>
          </div>
          <div className="match-head-matches">
            {matches?.length === 0 ? (
              <span className="no-results">No head to head matches found.</span>
            ) : (
              matches?.map(match => {
                return (
                  <div key={match.id} className="match-head-match">
                    <div className="match-head-thumb">
                      <div
                        className="match-head-thumb-img"
                        style={{
                          backgroundImage: `url(${
                            events?.filter(e => e.id === match.event)[0]?.poster
                          })`
                        }}></div>

                      <span className="match-head-info">
                        <span className="match-head-title">
                          {
                            events?.filter(e => e.id === match?.event)[0]
                              ?.display_name
                          }
                        </span>
                        <span className="match-head-subhead">
                          {match.roundLabel}
                        </span>
                      </span>
                    </div>
                    <span className="match-head-match-results">
                      <img
                        className="match-head-team-logo"
                        src={team1?.avatar}
                      />
                      <span
                        className={`match-head-match-score ${
                          match?.teams?.filter(t => t.id === team1.id)[0]
                            ?.winner === true
                            ? 'winner'
                            : 'loser'
                        }`}>
                        {match?.teams?.filter(t => t.id === team1.id)[0]?.score}
                      </span>
                      <span
                        className={`match-head-match-score ${
                          match?.teams?.filter(t => t.id === team2.id)[0]
                            ?.winner === true
                            ? 'winner'
                            : 'loser'
                        }`}>
                        {match?.teams?.filter(t => t.id === team2.id)[0]?.score}
                      </span>
                      <img
                        className="match-head-team-logo"
                        src={team2?.avatar}
                      />
                    </span>
                    <span className="match-head-date">
                      {moment(match?.starts?._seconds * 1000).format(
                        'MMM DD, YYYY'
                      )}
                      <br />
                      {moment(match?.starts?._seconds * 1000).format('hh:mm A')}
                    </span>
                  </div>
                )
              })
            )}
          </div>
        </div>
      ) : (
        <div className="content-panel">
          <div className="content-loader">
            <CircularProgress
              style={{
                color: '#7ab903',
                height: 55,
                width: 55
              }}
            />
          </div>
        </div>
      )}
    </div>
  ) : (
    <div className="content-panel">
      <div className="content-loader">
        <CircularProgress
          style={{
            color: '#7ab903',
            height: 55,
            width: 55
          }}
        />
      </div>
    </div>
  )
}
export default HeadToHead
