import React from 'react'
import KickAvatar from '../../assets/img/kickcom-avatar.jpg'
import BannedAvatar from '../../assets/img/banned-avatar.jpg'

const UserAvatar = ({ user, className, style }) => {
  const isBanned = user?.scopes?.includes('banned')
  const avatar = user?.profile_image_url
  const displayName = user?.display_name
  const isKick = user?.usingKick === true ? true : false
  return (
    <img
      style={style}
      className={className}
      src={isBanned ? BannedAvatar : isKick ? KickAvatar : avatar}
      alt={`${displayName}`}
    />
  )
}

export default UserAvatar
