import { useEffect } from 'react'
import { StripeApi } from 'api/Stripe'

export const useGetCustomerCards = customerId => {
  return StripeApi.useGetCustomerCardsQuery({ customerId })
}

export const useCreateCard = customerId => {
  const [createCard, { isLoading, data }] = StripeApi.useCreateCardMutation()
  useEffect(() => {
    if (customerId) {
      createCard({ customerId })
    }
    return () => {}
  }, [customerId, createCard])
  return { clientSecret: data, isLoading }
}

export const useGetCard = paymentMethodId => {
  const [getCard, { data, isLoading }] = StripeApi.useGetCardMutation()

  useEffect(() => {
    getCard({ paymentMethodId })
    return () => {}
  }, [paymentMethodId, getCard])

  return { cardDetails: data, isLoading }
}
