import React, { useState } from 'react'
import {
  convertFromRaw,
  EditorState,
  ContentState,
  convertFromHTML,
  convertToRaw
} from 'draft-js'
import { Editor } from 'react-draft-wysiwyg'
import { useNavigate } from 'react-router-dom'
import { useUserIsOwner, useUserIsAdmin } from 'hooks'
import { useUser } from 'hooks'
import { Button, CircularProgress } from '@mui/material'
import NewsService from 'services/NewsService'
import FormControl from '@mui/material/FormControl'
import TextField from '@mui/material/TextField'

const PostNews = ({ content }) => {
  const isOwner = useUserIsOwner()
  const isAdmin = useUserIsAdmin()
  const user = useUser()
  let navigate = useNavigate()
  const [contentState, setContentState] = useState(
    EditorState.createWithContent(
      ContentState.createFromBlockArray(
        convertFromHTML('<p>This is my story content.</p>')
      )
    )
  )
  const [titleState, setTitleState] = useState(
    EditorState.createWithContent(
      ContentState.createFromBlockArray(
        convertFromHTML('<h1>This is my title</h1>')
      )
    )
  )
  const [postLoading, setPostLoading] = React.useState(false)
  const [newsImage, setNewsImage] = React.useState('/assets/img/news-thumb.jpg')

  const onContentStateChange = contentState => {
    setContentState(contentState)
  }

  const onTitleStateChange = contentState => {
    setTitleState(contentState)
  }

  const handleImageChange = (e, v) => {
    setNewsImage(e.target.value)
  }

  const saveDraft = () => {
    setPostLoading(true)
    NewsService.addNewsStory(
      user.uid,
      titleState.getCurrentContent().getPlainText(),
      JSON.stringify(convertToRaw(titleState.getCurrentContent())),
      JSON.stringify(convertToRaw(contentState.getCurrentContent())),
      newsImage,
      false,
      (result, slug) => {
        if (slug) {
          navigate(`/news/post/${slug}`)
        }
      }
    )
  }

  const postStory = () => {
    setPostLoading(true)
    NewsService.addNewsStory(
      user.uid,
      titleState.getCurrentContent().getPlainText(),
      JSON.stringify(convertToRaw(titleState.getCurrentContent())),
      JSON.stringify(convertToRaw(contentState.getCurrentContent())),
      newsImage,
      true,
      result => {
        if (result && result.id) {
          navigate('/')
        } else {
          //alert error
        }
      }
    )
  }

  return (
    <>
      <div className="aside-header aside-news">
        <span className="news">Post a News Story</span>
      </div>
      <div className="content-panel">
        <div>
          <span className="subhead">News Story Title</span>
          <Editor
            wrapperClassName="news-wrapper"
            editorClassName="news-editor title-editor"
            toolbarClassName="hide-toolbar"
            editorState={titleState}
            onEditorStateChange={onTitleStateChange}
          />
          <span className="subhead">News Story Image</span>
          <FormControl className="input-news-image">
            <TextField
              id="news-story-image"
              onChange={(e, v) => {
                e.persist()
                handleImageChange(e, v)
              }}
              error={false}
              value={newsImage}
              placeholder="ex. 0"
              variant="outlined"
            />
          </FormControl>
          <span className="subhead">News Story Content</span>
          <Editor
            wrapperClassName="news-wrapper"
            editorClassName="news-editor"
            editorState={contentState}
            onEditorStateChange={onContentStateChange}
          />
        </div>
        <div className="news-buttons">
          <Button
            className="btn btn-primary"
            disabled={postLoading}
            onClick={e => {
              e.preventDefault()
              saveDraft()
            }}>
            {postLoading && (
              <CircularProgress
                style={{
                  color: '#000',
                  height: 15,
                  width: 15,
                  marginRight: 10
                }}
              />
            )}
            Save as Draft
          </Button>
          <Button
            className="btn btn-primary"
            disabled={false}
            onClick={e => {
              e.preventDefault()
              postStory()
            }}>
            {postLoading && (
              <CircularProgress
                style={{
                  color: '#000',
                  height: 15,
                  width: 15,
                  marginRight: 10
                }}
              />
            )}
            Post Story
          </Button>
        </div>
      </div>
    </>
  )
}

export default PostNews
