import React, { useEffect } from 'react'
import TimeAgo from 'react-timeago'
import Alert from '@mui/material/Alert'
import Pagination from '@mui/material/Pagination'
import { useUser, useAppUnreadMyRuns } from 'hooks'
import LeagueService from 'services/LeagueService'
import { LeagueServiceApi } from 'api/LeagueServiceApi'
import IconInfo from 'assets/img/icon-info.png'
import PreviewRun from './PreviewRun'
import ApprovedBy from './Leagues/Runs/ApprovedBy'
import DeniedBy from './Leagues/Runs/DeniedBy'

const MyLeagueRuns = ({ approved, denied }) => {
  const runsPerPage = 8
  const user = useUser()
  const unreadMyRuns = useAppUnreadMyRuns()
  const [reviewRunLoading, setReviewRunLoading] = React.useState(false)
  const [reviewRunID, setReviewRunID] = React.useState('')
  const [reviewRunError, setReviewRunError] = React.useState(false)
  const [reviewRunMsg, setReviewRunMsg] = React.useState('')
  const [myLeagueRuns, setMyLeagueRuns] = React.useState([])
  const [page, setPage] = React.useState(1)
  const [open, setOpen] = React.useState(false)
  const [runDetailsId, setRunDetailsId] = React.useState('')
  const [markMyRunsAsReadCall] = LeagueServiceApi.useMarkMyRunsAsReadMutation()

  useEffect(() => {
    LeagueService.getMyLeagueRuns(user.uid, data => {
      setMyLeagueRuns(data)
    })
  }, [])

  useEffect(() => {
    if (unreadMyRuns > 0) {
      markMyRunsAsReadCall()
        .unwrap()
        .then(result => {})
    }
  }, [unreadMyRuns, markMyRunsAsReadCall])

  return (
    <div>
      <div className="aside-header aside-leagues">
        <span className="review">My League Runs</span>
      </div>
      <div className="content-panel">
        <span className="heading-text">League runs that you submitted</span>
        <span className="parapraph">
          View your submitted runs and their current status. If runs were
          denied, please check the reason and submit a new one without errors.
        </span>
        <div className="league-runs my-league-runs">
          {reviewRunError && <Alert severity="error">{reviewRunMsg}</Alert>}
          {myLeagueRuns.length === 0 ? (
            <span className="no-competitors">
              You haven't submitted any runs yet.
            </span>
          ) : (
            <div>
              <span className="subhead">Your Submitted Runs:</span>
              {[...myLeagueRuns]
                .sort((a, b) => b.createdAt - a.createdAt)
                .slice(
                  (page - 1) * runsPerPage,
                  (page - 1) * runsPerPage + runsPerPage
                )
                .map((run, i) => {
                  return (
                    <div
                      key={i}
                      className={
                        'league-run' +
                        (run.locked ? ' league-run-locked' : '') +
                        (run.approved ? ' league-run-approved' : '') +
                        (run.denied ? ' league-run-denied' : '')
                      }>
                      <div className="league-run-status">
                        {run.approved === false && run.denied === true && (
                          <DeniedBy run={run} />
                        )}
                        {run.approved === true && run.denied !== true && (
                          <ApprovedBy run={run} />
                        )}
                        {run.approved === false &&
                          run.denied !== true &&
                          run.locked !== true && (
                            <span>
                              <span>Awaiting Review</span>
                            </span>
                          )}
                        {run.approved === false &&
                          run.denied !== true &&
                          run.locked === true && (
                            <span>
                              <span>In Review By Admin</span>
                            </span>
                          )}
                      </div>
                      <div className="league-run-details">
                        <div className="league-run-league">
                          <span className="run-league">
                            {run.league === 'duo' ? 'Duos' : run.league} League
                          </span>
                          <span className="run-season yellow">
                            Season {run.season} - Week {run.week}
                          </span>
                        </div>
                        <div className="league-run-info info-my-runs">
                          <div className="league-run-info-left">
                            <span className="run-created">
                              <TimeAgo date={run.createdAt.seconds * 1000} />
                            </span>
                            <a
                              className="run-link"
                              href={run.twitchLink}
                              target="_blank"
                              rel="noreferrer">
                              VOD Link
                            </a>
                            {run.twitchLink2 && (
                              <a
                                className="run-link"
                                href={run.twitchLink2}
                                target="_blank"
                                rel="noreferrer">
                                VOD Link2
                              </a>
                            )}
                          </div>
                          {run.approved === true && (
                            <div
                              className="league-run-info-right"
                              onClick={() => {
                                setRunDetailsId(run.id)
                                setOpen(true)
                              }}>
                              <img src={IconInfo} alt={'Info Icon'} />
                            </div>
                          )}
                        </div>
                      </div>
                      {run.denied === true && run.approved === false && (
                        <div className="run-reason">
                          <span>
                            Reason: <span>{run.reason}</span>
                          </span>
                        </div>
                      )}
                    </div>
                  )
                })}
              {myLeagueRuns.length > runsPerPage && (
                <div className="pagination">
                  <Pagination
                    count={Math.ceil(myLeagueRuns.length / runsPerPage)}
                    color="primary"
                    onChange={(e, value) => {
                      setPage(value)
                    }}
                  />
                </div>
              )}
            </div>
          )}
        </div>
      </div>
      {runDetailsId !== '' && (
        <PreviewRun
          open={open}
          setOpen={setOpen}
          runDetailsId={runDetailsId}
          setReviewRunID={setReviewRunID}
        />
      )}
    </div>
  )
}

export default MyLeagueRuns
