import KickAvatar from '../../assets/img/kickcom-avatar.jpg'
import BannedAvatar from '../../assets/img/banned-avatar.jpg'

const UsernameAvatar = ({ username, className, style }) => {
  const isBanned = username?.banned === true
  const isKick = username?.usingKick || false
  const displayName =
    username?.usingKick === true
      ? username?.kickUsername
      : username?.display_name

  const avatar = username?.profile_image_url
  return (
    <img
      style={style}
      className={className}
      src={isBanned ? BannedAvatar : isKick ? KickAvatar : avatar}
      alt={`${displayName}`}
    />
  )
}

export default UsernameAvatar
