import {
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  CircularProgress,
  Button
} from '@mui/material'

import { useState, useEffect, useCallback } from 'react'
import LeagueService from 'services/LeagueService'
import LeagueUserRanked from './Rankings/LeagueUserRanked'
import { UserServiceApi } from 'api/UserServiceApi'
import LeagueParticipant from './Rankings/LeagueParticipant'

const LeagueUsersDisplay = ({
  historical,
  season,
  tier,
  league,
  pendingRuns
}) => {
  const [teams, setTeams] = useState([])
  const [rankings, setRankings] = useState([])
  const [mergedRankings, setMergedRankings] = useState([])
  const [rankingsLoading, setRankingsLoading] = useState(true)
  const [participants, setParticipants] = useState([])
  const [lastUser, setLastUser] = useState()
  const [hasNext, setHasNext] = useState(true)
  const [duoMoreRankings, setDuoMoreRankings] = useState([])
  const [getUserList] = UserServiceApi.useLazyGetUserListQuery()
  const [getUsername] = UserServiceApi.useLazyGetUsernameByIdCacheQuery()

  const loadMore = async () => {
    getUserList({
      last: lastUser,
      exclude: JSON.stringify(rankings.map(rank => rank.uid))
    })
      .unwrap()
      .then(users => {
        const currentUsers = participants
        setParticipants([...currentUsers, ...users.data])
        setLastUser(users?.last)
        setHasNext(users?.hasNext)
      })
  }

  const getTeams = useCallback(async () => {
    const response = await LeagueService.getInvitedPlayers(async players => {
      const teams = []
      players.forEach(async player => {
        if (teams.filter(t => t.members.includes(player.id)).length === 0) {
          if (
            players.filter(p => p.id === player.accepted)[0]?.accepted ===
            player.id
          ) {
            const username = await getUsername({ id: player.id }, true).unwrap()
            if (username?.premium === true) {
              teams.push({
                members: [player.id, player.accepted]
              })
            }
          }
        }
      })
      setTeams(teams)
    })
    return response
  }, [getUsername])

  useEffect(() => {
    if (league && tier && league.id === 'duo') {
      const teams = getTeams()
      return () => {
        teams.then(response => (response ? response.unsubscribe() : null))
      }
    }
  }, [league, tier, getTeams])

  const formatRanks = useCallback(async data => {
    if (data) {
      const ranks = data.sort((a, b) => a.rank - b.rank)
      setRankings(ranks)
    }
    setRankingsLoading(false)
  }, [])

  const getSeasonRanks = useCallback(async () => {
    const rankings = await LeagueService.getLeagueSeasonRankings(
      league.id,
      historical ? season : league.currentSeason,
      tier.id
    ).then(data => {
      if (!historical) {
        formatRanks(data)
      } else {
        formatRanks(data)
        setRankingsLoading(false)
      }
    })

    return rankings
  }, [
    historical,
    formatRanks,
    league.id,
    league.currentSeason,
    season,
    tier.id
  ])

  useEffect(() => {
    getSeasonRanks()
  }, [getSeasonRanks])

  useEffect(() => {
    let arr = []
    arr = [...rankings]
    teams.forEach(team => {
      if (arr.filter(a => a.members.includes(team.members[0])).length === 0) {
        arr.push({
          members: team.members,
          points: 0
        })
      }
    })

    setMergedRankings(arr.filter(team => team.points > 0))
    setDuoMoreRankings(arr.filter(team => team.points === 0))
  }, [teams, rankings])

  const loadMoreDuo = () => {
    setMergedRankings(
      [...mergedRankings, ...duoMoreRankings].sort(
        (a, b) => b.points - a.points
      )
    )
    setDuoMoreRankings([])
  }

  return (
    <TableContainer component={Paper} sx={{ backgroundColor: '#000' }}>
      <Table className="rankings-table" aria-label="ranknigs table">
        <TableHead className="rankings-table-header">
          <TableRow>
            <TableCell>Rank</TableCell>
            <TableCell>Streamer</TableCell>
            <TableCell align="center">Qualifying</TableCell>
            <TableCell align="right">ELO</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rankingsLoading ? (
            <TableRow
              sx={{
                '&:last-child td, &:last-child th': { border: 0 }
              }}>
              <TableCell component="td" colSpan={4} scope="row" align="center">
                <CircularProgress size="5rem" />
              </TableCell>
            </TableRow>
          ) : mergedRankings.length > 0 ? (
            <>
              {mergedRankings
                .sort((a, b) => b.points - a.points)
                .map((rank, ri) => (
                  <LeagueUserRanked
                    key={ri}
                    rank={rank}
                    season={season}
                    league={league}
                    tier={tier}
                    pendingRuns={pendingRuns}
                  />
                ))}
              {league.id === 'duo' && duoMoreRankings?.length > 0 && (
                <TableRow>
                  <TableCell colSpan={4}>
                    <Button
                      variant={'contained'}
                      className="all-link"
                      onClick={loadMoreDuo}
                      fullWidth>
                      Load More
                    </Button>
                  </TableCell>
                </TableRow>
              )}
              {league.id === 'scav' && (
                <>
                  {participants.map((username, key) => (
                    <LeagueParticipant
                      key={key}
                      season={season}
                      league={league}
                      tier={tier}
                      username={username}
                      pendingRuns={pendingRuns}
                    />
                  ))}
                  {hasNext && !historical && (
                    <TableRow>
                      <TableCell colSpan={4}>
                        <Button
                          variant={'contained'}
                          className="all-link"
                          onClick={loadMore}
                          fullWidth>
                          Load More
                        </Button>
                      </TableCell>
                    </TableRow>
                  )}
                </>
              )}
            </>
          ) : (
            <TableRow
              sx={{
                '&:last-child td, &:last-child th': { border: 0 }
              }}>
              <TableCell component="td" colSpan={4} scope="row" align="center">
                There are no rankings yet.
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default LeagueUsersDisplay
