import { UserServiceApi } from 'api/UserServiceApi'
import { useUser } from 'hooks'
import { useEffect } from 'react'
import TimeAgo from 'react-timeago'

const ApprovedBy = ({ run }) => {
  const user = useUser()
  const [getUsername, { data: username }] =
    UserServiceApi.useLazyGetUsernameByIdCacheQuery()

  useEffect(() => {
    if (run.approvedBy) {
      getUsername({ id: run.approvedBy }, true)
    }
  }, [getUsername, run.approvedBy])

  return (
    <span className="status-approved">
      <span>
        {' '}
        Approved <TimeAgo date={run.updatedAt.seconds * 1000} /> by{' '}
        {username?.display_name}
      </span>
      <span>
        {run.points.toFixed(2)} PTS{' '}
        {typeof run.duoPartner !== 'undefined' && (
          <span>
            {run.submittedBy === user.uid
              ? `(${run.duoPoints} + ${run.duoPoints2})`
              : `(${run.duoPoints2} + ${run.duoPoints})`}
          </span>
        )}
      </span>
    </span>
  )
}

export default ApprovedBy
