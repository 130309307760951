import { useState, useEffect } from 'react'
import { StripeApi } from 'api/Stripe'

export const useCreateCustomer = async user => {
  const [hasRun, setHasRun] = useState(false)
  const [setCreateCustomer, customer] = StripeApi.useCreateCustomerMutation()
  const { stripe, email, id } = user

  useEffect(() => {
    if (
      typeof stripe?.customer === 'undefined' &&
      email &&
      id &&
      !hasRun &&
      customer.isUninitialized
    ) {
      setHasRun(true)
      setCreateCustomer({ email, userId: id })
    }
    return () => {}
  }, [stripe, email, id, hasRun, setCreateCustomer, customer])

  return customer?.data || {}
}
