import React from 'react'
import PlayerLink from './PlayerLink'
import CenteredLoader from './CenteredLoader'

const EventPlayerStats = ({ tournament }) => {
  return (
    <div className="events-box events-standings">
      <div className="events-box-header">
        <span className="events-title">Player Stats</span>
      </div>

      {tournament?.ratings?.length > 0 ? (
        <div>
          <div className="match-stats-header">
            <span className="match-stats-label">Player</span>
            <span className="match-stats-label">K-D</span>
            <span className="match-stats-label">+/-</span>
            <span className="match-stats-label">KAS</span>
            <span className="match-stats-label">SR</span>
            <span className="match-stats-label">ENT</span>
            <span className="match-stats-label">CAP</span>
            <span className="match-stats-label">Rating</span>
          </div>
          {[...tournament?.ratings]
            .sort((a, b) => b.rating - a.rating)
            ?.map(player => {
              return (
                <div key={player.p} className="match-stats-row">
                  <span className="match-stats-label">
                    <div className="match-stats-user">
                      <PlayerLink player={player.p} />
                    </div>
                  </span>
                  <span className="match-stats-label">
                    {player.k}-{player.d}
                  </span>
                  <span
                    className={`match-stats-label ${
                      player.k - player.d > 0
                        ? 'winner'
                        : player.k - player.d < 0
                        ? 'loser'
                        : ''
                    }`}>
                    {player.k - player.d > 0 ? '+' : ''}
                    {player.k - player.d}
                  </span>
                  <span className="match-stats-label">
                    {((player.kas / player.rounds) * 100).toFixed(0)}%
                  </span>
                  <span className="match-stats-label">
                    {(
                      ((player.rounds - player.d) / player.rounds) *
                      100
                    ).toFixed(0)}
                    %
                  </span>
                  <span className="match-stats-label">{player.e}</span>
                  <span className="match-stats-label">{player.c}</span>
                  <span className="match-stats-label">{player.rating}</span>
                </div>
              )
            })}
        </div>
      ) : (
        <CenteredLoader />
      )}
    </div>
  )
}

export default EventPlayerStats
