import { createSlice } from '@reduxjs/toolkit'
import { createSelector } from 'reselect'

const initialState = {
  user: {
    scopes: [],
    modFor: []
  },
  userData: {
    scopes: []
  },
  userLeagueRun: {},
  lockedLeagueRun: {},
  usernames: [],
  invites: {
    incoming: [],
    outgoing: '',
    accepted: ''
  }
}

export const authSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    unsetUser: (state, action) => {
      state.user = initialState.user
    },
    setUser: (state, action) => {
      state.user = { ...state.user, ...action.payload }
    },
    unsetUserData: (state, action) => {
      state.userData = initialState.userData
    },
    setUserData: (state, action) => {
      state.userData = { ...state.userData, ...action.payload }
    },
    unsetUserLeagueRun: (state, action) => {
      state.userLeagueRun = {}
    },
    setUserLeagueRun: (state, action) => {
      state.userLeagueRun = { ...state.userLeagueRun, ...action.payload }
    },
    unsetLockedLeagueRun: (state, action) => {
      state.lockedLeagueRun = {}
    },
    setLockedLeagueRun: (state, action) => {
      state.lockedLeagueRun = { ...state.lockedLeagueRun, ...action.payload }
    },
    unsetUsernames: (state, action) => {
      state.usernames = []
    },
    setUsernames: (state, action) => {
      state.usernames = [...action.payload]
    },
    setInvitesIncoming: (state, action) => {
      state.invites.incoming = [...action.payload]
    },
    setInvitesOutgoing: (state, action) => {
      state.invites.outgoing = action.payload
    },
    setInvitesAccepted: (state, action) => {
      state.invites.accepted = action.payload
    }
  }
})

export const selectAuth = state => state.auth

export const selectUser = createSelector(selectAuth, ({ user }) => user)
export const selectUserData = createSelector(
  selectAuth,
  ({ userData }) => userData
)
export const selectUserLeagueRun = createSelector(
  selectAuth,
  ({ userLeagueRun }) => userLeagueRun
)
export const selectLockedLeagueRun = createSelector(
  selectAuth,
  ({ lockedLeagueRun }) => lockedLeagueRun
)
export const selectUsernames = createSelector(
  selectAuth,
  ({ usernames }) => usernames
)
export const selectInvitesIncoming = createSelector(
  selectAuth,
  ({ invites }) => invites.incoming
)
export const selectInvitesOutgoing = createSelector(
  selectAuth,
  ({ invites }) => invites.outgoing
)
export const selectInvitesAccepted = createSelector(
  selectAuth,
  ({ invites }) => invites.accepted
)

export const selectIsAdmin = createSelector(selectAuth, ({ userData }) =>
  userData.scopes?.includes('admin')
)

export const selectIsPremium = createSelector(
  selectAuth,
  ({ userData }) =>
    userData.scopes?.includes('promotion') ||
    userData?.subscription?.status === 'active'
)

export const selectUserStripe = createSelector(
  selectAuth,
  ({ userData }) => userData?.stripe
)

export const selectUserSubscription = createSelector(
  selectAuth,
  ({ userData }) => userData?.subscription
)

export const selectIsBeta = createSelector(selectAuth, ({ userData }) =>
  userData.scopes?.includes('beta')
)
export const selectIsBanned = createSelector(selectAuth, ({ userData }) =>
  userData.scopes?.includes('banned')
)
export const selectIsEditor = createSelector(selectAuth, ({ userData }) =>
  userData.scopes?.includes('editor')
)
export const selectIsVodder = createSelector(selectAuth, ({ userData }) =>
  userData.scopes?.includes('vodder')
)

export const selectIsOwner = createSelector(selectAuth, ({ userData }) =>
  userData.scopes?.includes('owner')
)
export const selectIsStripeTest = createSelector(selectAuth, ({ userData }) =>
  userData.scopes?.includes('stripeTest')
)
export const selectIsPromotion = createSelector(selectAuth, ({ userData }) =>
  userData.scopes?.includes('promotion')
)

export const selectIsMod = createSelector(selectAuth, ({ user }) => user.modFor)

export const { unsetUser } = authSlice.actions
export const { setUser } = authSlice.actions
export const { unsetUserData } = authSlice.actions
export const { setUserData } = authSlice.actions
export const { unsetUserLeagueRun } = authSlice.actions
export const { setUserLeagueRun } = authSlice.actions
export const { unsetLockedLeagueRun } = authSlice.actions
export const { setLockedLeagueRun } = authSlice.actions
export const { unsetUsernames } = authSlice.actions
export const { setUsernames } = authSlice.actions
export const { setInvitesIncoming } = authSlice.actions
export const { setInvitesOutgoing } = authSlice.actions
export const { setInvitesAccepted } = authSlice.actions

export default authSlice.reducer
