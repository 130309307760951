import { useCallback, useEffect, useState } from 'react'
import CommunityService from 'services/CommunityService'

export const useGetSidebarStreams = () => {
  const [featuredStream, setFeaturedStream] = useState()
  const [streams, setStreams] = useState([])

  const checkFeaturedStream = useCallback(async () => {
    const featuredStreams = ['danbergundy', 'sawski21']
    const live = await Promise.all(
      featuredStreams.map(async streamer => {
        const isLive = await CommunityService.checkUserIsLive(streamer)
        return isLive.length > 0 ? streamer : false
      })
    )
    return live.filter(stream => stream !== false)
  }, [])

  const getFeatured = useCallback(async () => {
    const featuredLive = await checkFeaturedStream()

    if (featuredLive.length > 0) {
      const featuredStream = await CommunityService.getLivestreamByUser(
        featuredLive[0]
      )
      if (featuredStream.docs.length > 0) {
        return featuredStream
      }
    }

    return await CommunityService.getFeaturedLivestream(async doc => {
      const stream = { ...doc[0] }
      if (stream?.username?.premium) {
        setFeaturedStream(stream)
      }
    })
  }, [checkFeaturedStream])

  const getLivestreams = useCallback(async () => {
    const response = await CommunityService.getLivestreamsMax(data => {
      setStreams(
        data.filter(data => data?.user_login !== featuredStream?.user_login)
      )
    }, 8)
    return response
  }, [featuredStream?.user_login])

  useEffect(() => {
    const featured = getFeatured()
    const streams = getLivestreams()
    return () => {
      featured.then(response => (response ? response.unsubscribe() : null))
      streams.then(response => (response ? response.unsubscribe() : null))
    }
  }, [getFeatured, getLivestreams])

  return { featuredStream, streams }
}
