import React from 'react'

const CurrentTeams = () => {
  return (
    <div>
      <div className="aside-header aside-teams">
        <span className="teams">Teams</span>
      </div>
      <div className="content-panel">
        <span className="heading-text">Coming Early 2023</span>
        <span className="paragraph">
          More information on team events will be announced soon.
        </span>
      </div>
    </div>
  )
}

export default CurrentTeams
