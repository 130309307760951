import React, { useEffect, useState } from 'react'

import Grid from '@mui/material/Grid'
import { Card as MuiCard, Box, CardActions, IconButton } from '@mui/material'
import CardContent from '@mui/material/CardContent'
import { Delete } from '@mui/icons-material'

const Card = ({
  card,
  paymentMethod,
  paymentOnChange,
  options = {},
  actions = {},
  defaultMethod,
  grid,
  subscribed
}) => {
  const [border, setBorder] = useState({})
  const { card: cardDetails } = card
  const { mode = 'display' } = options
  const { deleteCard = () => {} } = actions

  useEffect(() => {
    if (paymentMethod === card.id) {
      setBorder({ border: '1px solid #7ab903' })
    } else {
      setBorder({ border: '1px solid transparent' })
    }
  }, [paymentMethod, card])

  const cardProps = {}
  if (paymentOnChange) {
    cardProps.onClick = () => paymentOnChange(card.id)
  }

  return (
    <Grid item xs={12} md={grid ? grid : 6}>
      <Box
        className={`card-box is-private card-${cardDetails.brand}`}
        sx={{ cursor: 'pointer', ...border }}>
        {paymentMethod === card.id && (
          <span className="card-selected">Selected</span>
        )}
        {subscribed === card.id && (
          <span className="card-selected">Subscribed</span>
        )}
        <MuiCard sx={{ flexGrow: 1, background: 'none' }} {...cardProps}>
          <CardContent>
            <span className="card-type is-private">{cardDetails.brand}</span>
            <span className="card-number-label">Credit Card Number</span>
            <span className="card-number">
              &bull;&bull;&bull;&bull; &bull;&bull;&bull;&bull;
              &bull;&bull;&bull;&bull; {cardDetails.last4}
            </span>
            <span className="card-expiration">
              EXP {cardDetails.exp_month}/{cardDetails.exp_year}
            </span>
          </CardContent>
          {mode === 'maintenance' && (
            <CardActions className="card-actions">
              {/* <IconButton onClick={() => favoriteCard(card.id)}>
                {defaultMethod === card.id ? (
                  <Favorite color="error" />
                ) : (
                  <FavoriteBorder />
                )}
              </IconButton> */}
              <IconButton onClick={() => deleteCard(card.id)}>
                <Delete />
              </IconButton>
            </CardActions>
          )}
        </MuiCard>
      </Box>
    </Grid>
  )
}

export default Card
