import React, { useEffect, useState } from 'react'
import { Navigate } from 'react-router'
import Container from '@mui/material/Container'
import CircularProgress from '@mui/material/CircularProgress'
import Box from '@mui/material/Box'
import { useLocation } from 'react-router'

import ContentService from 'services/ContentService'
import { useAppIsLoading, useAppIsLoggedIn, useUserData } from 'hooks'

const ProtectedRoute = ({
  component: Component,
  usernames,
  approved,
  denied,
  scopes = [],
  ...props
}) => {
  const userData = useUserData()
  const appIsLoading = useAppIsLoading()
  const isLoggedIn = useAppIsLoggedIn()
  const [content, setContent] = useState({})
  const [contentLoading, setContentLoading] = useState(true)
  const location = useLocation()
  const path = location.pathname.split('/')[1]

  const hasPerms = () => {
    if (scopes.length === 0) return true
    return scopes.some(value => userData.scopes?.indexOf(value) >= 0)
  }

  useEffect(() => {
    if (!appIsLoading && path) {
      if (isLoggedIn) {
        ContentService.getProtectedPage(path).then(content => {
          setContent(content)
          setContentLoading(false)
        })
      } else {
        setContentLoading(false)
      }
    }
  }, [appIsLoading, isLoggedIn, path, contentLoading])

  if (appIsLoading || contentLoading)
    return (
      <Container maxWidth="xl">
        <Box
          pt={10}
          pb={3}
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}>
          <CircularProgress />
        </Box>
      </Container>
    )

  if (isLoggedIn && hasPerms())
    return (
      <Component
        {...props}
        content={content}
        usernames={usernames}
        approved={approved}
        denied={denied}
      />
    )

  if (scopes.length === 0 && !isLoggedIn) {
    return <Navigate to={`/proceed?path=${path}`} />
  }

  return <Navigate to="/" />
}

export default ProtectedRoute
