import { useUserData } from 'hooks'
import { Button, CircularProgress } from '@mui/material'
import { StripeApi } from 'api/Stripe'
import getHostDomain from 'utils/GetHost'

const AccountSignup = () => {
  const userData = useUserData()
  const [createAccount, { isLoading }] = StripeApi.useCreateAccountMutation()

  const onBoard = async () => {
    const account = await createAccount({
      userId: userData?.id,
      email: userData?.email,
      returnUrl: `${getHostDomain()}/account`,
      refreshUrl: `${getHostDomain()}/account`
    }).unwrap()
    window.location.assign(account.accountLinks.url)
  }

  return (
    <div>
      <div className="aside-header aside-account-sign-up">
        <span className="bounty">Get Paid by Fence.gg</span>
      </div>
      <div className="content-panel">
        <span className="paragraph">
          Want to earn extra money through Fence.gg? To unlock features like
          bounties, instant payouts from leagues, and more - you must fill out
          the payments onboarding form. This is required by our payment
          processor Stripe - We do not store this information.
        </span>
        <Button onClick={onBoard} disabled={isLoading} variant="contained">
          {isLoading && (
            <CircularProgress
              style={{
                color: '#000',
                height: 20,
                width: 20,
                marginRight: 10
              }}
            />
          )}
          {isLoading ? (
            <span>Loading...</span>
          ) : (
            <span>Setup FenceGG Payments</span>
          )}
        </Button>
      </div>
    </div>
  )
}

export default AccountSignup
