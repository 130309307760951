import React from 'react'
import { useAppIsLoggedIn } from 'hooks'
import Banner from 'components/Banner'

const Disclaimer = () => {
  const isLoggedIn = useAppIsLoggedIn()
  return (
    <>
      <Banner />
      <div className="aside-header aside-leagues">
        <span className="admin">Fence.gg Disclaimer</span>
      </div>
      <div className="content-panel">
        <span className="paragraph">Disclaimer.</span>
      </div>
    </>
  )
}

export default Disclaimer
