import ga4 from 'react-ga4'

const TRACKING_ID = 'G-M00LGDMZBZ'
const isProduction =
  window.location.href.indexOf('localhost') > 0 ? false : true

export const init = () =>
  ga4.initialize(TRACKING_ID, {
    testMode: !isProduction
  })

export const sendEvent = data =>
  ga4.event({
    ...data
  })

export const sendPageview = (page, title) =>
  ga4.send({
    hitType: 'pageview',
    page: page,
    title: title
  })

export const setUserId = uid => ga4.set({ userId: uid })
