import React, { useCallback, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import PropTypes from 'prop-types'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import Box from '@mui/material/Box'
import { useAppLeagues, useUserIsAdmin } from 'hooks'
import Container from '@mui/material/Container'
import CircularProgress from '@mui/material/CircularProgress'
import CurrentLeagueWeek from './CurrentLeagueWeek'
import { useDispatch } from 'react-redux'

function TabPanel(props) {
  const { children, value, index, league, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={
        league ? `${league}-tier-tabpanel-${index}` : `league-tabpanel-${index}`
      }
      aria-labelledby={
        league ? `${league}-tier-tab-${index}` : `league-tab-${index}`
      }
      {...other}>
      {value === index && <Box>{children}</Box>}
    </div>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired
}

function a11yLeagueProps(index) {
  return {
    'id': `league-tab-${index}`,
    'aria-controls': `league-tabpanel-${index}`
  }
}

function a11yTierProps(league, index) {
  return {
    'id': `${league}-tier-tab-${index}`,
    'aria-controls': `${league}-tier-tabpanel-${index}`
  }
}

const CurrentRankings = ({ historical, params }) => {
  const isAdmin = useUserIsAdmin()
  const leagues = useAppLeagues()
  const dispatch = useDispatch()
  let navigate = useNavigate()
  const [leagueTab, setLeagueTab] = React.useState(() => {
    //fix direct traffic to locked leagues, redirect away
    return params.league && params.league === 'solos'
      ? 1
      : params.league && params.league === 'duos'
      ? 2
      : params.league && params.league === 'scav'
      ? 0
      : 0
  })
  const [soloTierTab, setSoloTierTab] = React.useState(0)
  const [duoTierTab, setDuoTierTab] = React.useState(0)
  const [scavTierTab, setScavTierTab] = React.useState(0)
  const [tiersTab, setTiersTab] = React.useState({
    solo: 0,
    duo: 0,
    scav: 0
  })

  const handleChangeLeagues = (event, newValue) => {
    setLeagueTab(newValue)
    if (newValue === 1) {
      navigate('/rankings/solos')
    } else if (newValue === 2) {
      navigate('/rankings/duos')
    } else if (newValue === 0) {
      navigate('/rankings/scav')
    }
  }

  const handleChangeSoloTiers = (event, newValue) => {
    setSoloTierTab(newValue)
  }

  const handleChangeDuoTiers = (event, newValue) => {
    setDuoTierTab(newValue)
  }

  const handleChangeScavTiers = (event, newValue) => {
    setScavTierTab(newValue)
  }

  const handleChangeTiers = (event, newValue, value) => {
    setTiersTab({
      ...tiersTab,
      [value]: newValue
    })
  }

  const renderer = ({ days, hours, minutes, seconds, completed }) => {
    const needsPad = value => {
      if (value > 9) {
        return false
      }
      return true
    }
    if (completed) {
      return 'Ended...'
    } else {
      return (
        <span>
          {needsPad(days) ? '0' + days : days}
          <span className="timer-label">D</span>
          {needsPad(hours) ? '0' + hours : hours}
          <span className="timer-label">H</span>
          {needsPad(minutes) ? '0' + minutes : minutes}
          <span className="timer-label">M</span>
          {needsPad(seconds) ? '0' + seconds : seconds}
          <span className="timer-label">S</span>
        </span>
      )
    }
  }

  const rendererTable = ({ days, hours, minutes, seconds, completed }) => {
    const needsPad = value => {
      if (value > 9) {
        return false
      }
      return true
    }
    if (completed) {
      return 'Ended...'
    } else {
      return (
        <span className="timer">
          <span>{needsPad(hours) ? '0' + hours : hours}</span>
          <span className="label">H</span>
          <span>{needsPad(minutes) ? '0' + minutes : minutes}</span>
          <span className="label">M</span>
          <span>{needsPad(seconds) ? '0' + seconds : seconds}</span>
          <span className="label">S</span>
        </span>
      )
    }
  }

  const renderLeagues = () => {
    if (leagues && leagues.length > 0) {
      return leagues
        .filter(league => league.visible)
        .sort((a, b) => a.order - b.order)
    } else {
      return []
    }
  }

  return (
    <div>
      <div className="aside-header aside-rankings">
        <span className="rankings">Fence League Rankings</span>
      </div>
      <div className="content-panel">
        {leagues && leagues.length > 0 ? (
          <Box>
            <Tabs
              value={leagueTab}
              className="rankings-tabs"
              onChange={handleChangeLeagues}
              aria-label="basic tabs example">
              {renderLeagues().map((league, i) => {
                return (
                  <Tab
                    key={i}
                    className="rankings-tab"
                    label={
                      league.locked && !isAdmin ? (
                        <span className="league-label">
                          {league.display_name}
                          <span className="league-locked"></span>
                        </span>
                      ) : (
                        league.display_name
                      )
                    }
                    disabled={league.locked && !isAdmin}
                    {...a11yLeagueProps(i)}
                  />
                )
              })}
            </Tabs>
            {renderLeagues().map((league, i) => {
              return (
                <TabPanel key={i} value={leagueTab} index={i}>
                  <Tabs
                    value={tiersTab[league.id]}
                    className="tiers-tabs"
                    onChange={(e, v) => {
                      handleChangeTiers(e, v, league.id, league.currentSeason)
                    }}
                    aria-label="basic tabs example">
                    {league.tiers
                      .filter(tier => tier.visible)
                      .map((tier, ti) => {
                        return (
                          <Tab
                            key={tier.id}
                            className="tiers-tab"
                            disabled={tier.locked && !isAdmin}
                            label={
                              <span className={`tier-label tier-${tier.id}`}>
                                {tier.display_name} Tier
                                {tier.locked && !isAdmin && (
                                  <span className="tier-locked"></span>
                                )}
                              </span>
                            }
                            {...a11yTierProps(league.id, ti)}
                          />
                        )
                      })}
                  </Tabs>
                  {league.tiers
                    .filter(tier => tier.visible)
                    .map((tier, ti) => {
                      return (
                        <TabPanel
                          key={ti}
                          league={league.id}
                          value={tiersTab[league.id]}
                          index={ti}>
                          <div className="tier-content">
                            <CurrentLeagueWeek
                              league={league}
                              tier={tier}
                              season={params.season}
                              historical={historical}
                            />
                          </div>
                        </TabPanel>
                      )
                    })}
                </TabPanel>
              )
            })}
          </Box>
        ) : (
          <Box>
            <Container maxWidth="xl">
              <Box
                pt={3}
                pb={3}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center'
                }}>
                <CircularProgress size="5rem" />
              </Box>
            </Container>
          </Box>
        )}
      </div>
    </div>
  )
}

export default CurrentRankings
