import { UserServiceApi } from 'api/UserServiceApi'
import { useCallback, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { NotificationService } from 'services/NotificationService'

export const useUsernameNotification = () => {
  const dispatch = useDispatch()
  const getUserNotifications = useCallback(async () => {
    const response = await NotificationService.getUsernameNotifications(
      new Date(Date.now()),
      docs => {
        docs.map(doc =>
          dispatch(
            UserServiceApi.util.invalidateTags([
              { type: 'Username', id: doc.login }
            ])
          )
        )
      }
    )
    return response
  }, [dispatch])

  useEffect(() => {
    const notifications = getUserNotifications()
    return () => {
      notifications.then(response => (response ? response.unsubscribe() : null))
    }
  }, [getUserNotifications])
}
