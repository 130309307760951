import React, { useCallback, useEffect } from 'react'
import moment from 'moment/moment'
import { Link } from 'react-router-dom'
import GoonsService from 'services/GoonsService'
import TimeAgo from 'react-timeago'
import { getGoonsMaps } from 'utils/GoonsMaps'
import Username from './Username'
import { UserServiceApi } from 'api/UserServiceApi'

const SidebarGoons = () => {
  const [goonsSightings, setGoonsSightings] = React.useState({})
  const [goonsMap, setGoonsMap] = React.useState('')
  const [goonsMapCode, setGoonsMapCode] = React.useState('')
  const [goonsDateTime, setGoonsDateTime] = React.useState('')
  const [getUsername, { data: username }] =
    UserServiceApi.useLazyGetUsernameByIdCacheQuery()

  useEffect(() => {
    if (goonsSightings?.sightedBy) {
      getUsername(
        {
          id: goonsSightings.sightedBy
        },
        true
      )
    }
  }, [getUsername, goonsSightings.sightedBy])

  const getMostRecent = useCallback(async () => {
    const goonsMaps = getGoonsMaps()
    const response = await GoonsService.getMostRecentSighting(async data => {
      const goonsSightings = data[0]
      setGoonsSightings(goonsSightings)
      const dateTime = goonsSightings.datetime.toDate()
      setGoonsMapCode(goonsSightings.map)
      setGoonsMap(goonsMaps[goonsSightings.map].name)
      setGoonsDateTime(dateTime)
    })
    return response
  }, [])

  useEffect(() => {
    const goonsRecent = getMostRecent()
    return () => {
      goonsRecent.then(response => (response ? response.unsubscribe() : null))
    }
  }, [getMostRecent])

  const isVerified = () => {
    let now = moment()
    if (now.diff(moment(goonsDateTime)) > 1800000) {
      return false
    }
    return true
  }

  return (
    <div>
      {Object.keys(goonsSightings).length > 0 && (
        <div>
          <div className="aside-header aside-goons">
            <span className="goons">Goon Sightings</span>
          </div>
          <div className={'goons-map map-' + goonsMapCode}>
            <div className="goons-map-img"></div>
            <div className="goons-map-lines"></div>
            <Link to={'/goons-history'} className="goons-map-inner">
              <span>
                <span className={'title' + (isVerified() ? ' verified' : '')}>
                  {goonsMap}
                </span>
                <span className="spotter">
                  Last spotted by <Username username={username} />
                </span>
                <span className="time">
                  <TimeAgo date={goonsDateTime} />
                </span>
              </span>
            </Link>
          </div>
          <div className="dual-link">
            <Link to="/goons-history">View History</Link>
            <span> | </span>
            <Link to="/report-goons">Post a Sighting</Link>
          </div>
        </div>
      )}
    </div>
  )
}

export default SidebarGoons
