import Firebase from 'utils/Firebase'
import {
  getFirestore,
  doc,
  onSnapshot,
  collection,
  where,
  query
} from 'firebase/firestore'
import fetch from 'cross-fetch'

import { getHostFunctions } from 'utils/GetHost'
import { getAuth } from 'firebase/auth'

const db = getFirestore(Firebase)
const auth = getAuth()

async function addMod(mod, callback) {
  const token = await auth.currentUser?.getIdToken()
  const result = await fetch(
    getHostFunctions() +
      '/toggleMod?' +
      new URLSearchParams({
        mod: mod,
        token: token
      })
  )
  if (result.status === 200) {
    result.json().then(res => {
      if (typeof callback === 'function') {
        callback(res)
      }
    })
  }
}

async function addAdmin(mod, callback) {
  const token = await auth.currentUser?.getIdToken()
  const result = await fetch(
    getHostFunctions() +
      '/toggleAdmin?' +
      new URLSearchParams({
        mod: mod,
        token: token
      })
  )
  if (result.status === 200) {
    result.json().then(res => {
      if (typeof callback === 'function') {
        callback(res)
      }
    })
  }
}

async function addStripeTest(mod, callback) {
  const token = await auth.currentUser?.getIdToken()
  const result = await fetch(
    getHostFunctions() +
      '/toggleStripeTest?' +
      new URLSearchParams({
        mod: mod,
        token: token
      })
  )
  if (result.status === 200) {
    result.json().then(res => {
      if (typeof callback === 'function') {
        callback(res)
      }
    })
  }
}

async function addVodder(mod, callback) {
  const token = await auth.currentUser?.getIdToken()
  const result = await fetch(
    getHostFunctions() +
      '/toggleVodder?' +
      new URLSearchParams({
        mod: mod,
        token: token
      })
  )
  if (result.status === 200) {
    result.json().then(res => {
      if (typeof callback === 'function') {
        callback(res)
      }
    })
  }
}

async function addBanned(mod, callback) {
  const token = await auth.currentUser?.getIdToken()
  const result = await fetch(
    getHostFunctions() +
      '/toggleBanned?' +
      new URLSearchParams({
        mod: mod,
        token: token
      })
  )
  if (result.status === 200) {
    result.json().then(res => {
      if (typeof callback === 'function') {
        callback(res)
      }
    })
  }
}

async function addEditor(mod, callback) {
  const token = await auth.currentUser?.getIdToken()
  const result = await fetch(
    getHostFunctions() +
      '/toggleEditor?' +
      new URLSearchParams({
        mod: mod,
        token: token
      })
  )
  if (result.status === 200) {
    result.json().then(res => {
      if (typeof callback === 'function') {
        callback(res)
      }
    })
  }
}

async function addBeta(mod, callback) {
  const token = await auth.currentUser?.getIdToken()
  const result = await fetch(
    getHostFunctions() +
      '/toggleBeta?' +
      new URLSearchParams({
        mod: mod,
        token: token
      })
  )
  if (result.status === 200) {
    result.json().then(res => {
      if (typeof callback === 'function') {
        callback(res)
      }
    })
  }
}

async function addPromotionRole(mod, callback) {
  const token = await auth.currentUser?.getIdToken()
  const result = await fetch(
    getHostFunctions() +
      '/togglePromotionRole?' +
      new URLSearchParams({
        mod: mod,
        token: token
      })
  )
  if (result.status === 200) {
    result.json().then(res => {
      if (typeof callback === 'function') {
        callback(res)
      }
    })
  }
}

async function removeMod(mod, callback) {
  const token = await auth.currentUser?.getIdToken()
  const result = await fetch(
    getHostFunctions() +
      '/toggleMod?' +
      new URLSearchParams({
        mod: mod,
        token: token
      })
  )
  if (result.status === 200) {
    result.json().then(res => {
      if (typeof callback === 'function') {
        callback(res)
      }
    })
  }
}

async function removeAdmin(mod, callback) {
  const token = await auth.currentUser?.getIdToken()
  const result = await fetch(
    getHostFunctions() +
      '/toggleAdmin?' +
      new URLSearchParams({
        mod: mod,
        token: token
      })
  )
  if (result.status === 200) {
    result.json().then(res => {
      if (typeof callback === 'function') {
        callback(res)
      }
    })
  }
}

async function removeStripeTest(mod, callback) {
  const token = await auth.currentUser?.getIdToken()
  const result = await fetch(
    getHostFunctions() +
      '/toggleStripeTest?' +
      new URLSearchParams({
        mod: mod,
        token: token
      })
  )
  if (result.status === 200) {
    result.json().then(res => {
      if (typeof callback === 'function') {
        callback(res)
      }
    })
  }
}

async function removeVodder(mod, callback) {
  const token = await auth.currentUser?.getIdToken()
  const result = await fetch(
    getHostFunctions() +
      '/toggleVodder?' +
      new URLSearchParams({
        mod: mod,
        token: token
      })
  )
  if (result.status === 200) {
    result.json().then(res => {
      if (typeof callback === 'function') {
        callback(res)
      }
    })
  }
}

async function removeBanned(mod, callback) {
  const token = await auth.currentUser?.getIdToken()
  const result = await fetch(
    getHostFunctions() +
      '/toggleBanned?' +
      new URLSearchParams({
        mod: mod,
        token: token
      })
  )
  if (result.status === 200) {
    result.json().then(res => {
      if (typeof callback === 'function') {
        callback(res)
      }
    })
  }
}

async function removeEditor(mod, callback) {
  const token = await auth.currentUser?.getIdToken()
  const result = await fetch(
    getHostFunctions() +
      '/toggleEditor?' +
      new URLSearchParams({
        mod: mod,
        token: token
      })
  )
  if (result.status === 200) {
    result.json().then(res => {
      if (typeof callback === 'function') {
        callback(res)
      }
    })
  }
}

async function removeBeta(mod, callback) {
  const token = await auth.currentUser?.getIdToken()
  const result = await fetch(
    getHostFunctions() +
      '/toggleBeta?' +
      new URLSearchParams({
        mod: mod,
        token: token
      })
  )
  if (result.status === 200) {
    result.json().then(res => {
      if (typeof callback === 'function') {
        callback(res)
      }
    })
  }
}

async function removePromotionRole(mod, callback) {
  const token = await auth.currentUser?.getIdToken()
  const result = await fetch(
    getHostFunctions() +
      '/togglePromotionRole?' +
      new URLSearchParams({
        mod: mod,
        token: token
      })
  )
  if (result.status === 200) {
    result.json().then(res => {
      if (typeof callback === 'function') {
        callback(res)
      }
    })
  }
}

async function removeAllRoles(mod, callback) {
  const token = await auth.currentUser?.getIdToken()
  const result = await fetch(
    getHostFunctions() +
      '/removeAllRoles?' +
      new URLSearchParams({
        mod: mod,
        token: token
      })
  )
  if (result.status === 200) {
    result.json().then(res => {
      if (typeof callback === 'function') {
        callback(res)
      }
    })
  }
}

async function getAdmins(callback) {
  onSnapshot(collection(db, 'admin'), docs => {
    if (typeof callback === 'function') {
      callback(docs)
    }
  })
}

async function getMods(owner, callback) {
  const unsubscribe = onSnapshot(doc(db, 'mod', owner), doc => {
    if (typeof callback === 'function') {
      callback(doc.data())
    }
  })
  return { unsubscribe }
}

async function getBetas(callback) {
  onSnapshot(collection(db, 'beta'), docs => {
    if (typeof callback === 'function') {
      callback(docs)
    }
  })
}

async function getVodders(callback) {
  onSnapshot(collection(db, 'vodders'), docs => {
    if (typeof callback === 'function') {
      callback(docs)
    }
  })
}

async function getEditors(callback) {
  onSnapshot(collection(db, 'editors'), docs => {
    if (typeof callback === 'function') {
      callback(docs)
    }
  })
}

async function getStripeTests(callback) {
  onSnapshot(collection(db, 'stripeTest'), docs => {
    if (typeof callback === 'function') {
      callback(docs)
    }
  })
}

async function getUserHasScopes(callback) {
  const q = query(
    collection(db, 'users'),
    where('scopes', 'array-contains-any', [
      'owner',
      'admin',
      'banned',
      'editor',
      'vodder',
      'beta',
      'stripeTest',
      'promotion'
    ])
  )
  onSnapshot(q, docs => {
    if (typeof callback === 'function') callback(docs)
  })
}

const OwnerService = {
  addMod,
  removeMod,
  addAdmin,
  removeAdmin,
  addBanned,
  removeBanned,
  addEditor,
  removeEditor,
  addBeta,
  removeBeta,
  addStripeTest,
  removeStripeTest,
  addVodder,
  removeVodder,
  addPromotionRole,
  removePromotionRole,
  removeAllRoles,
  getMods,
  getAdmins,
  getBetas,
  getVodders,
  getStripeTests,
  getEditors,
  getUserHasScopes
}

export default OwnerService
