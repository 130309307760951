import { UserServiceApi } from 'api/UserServiceApi'
import Username from '../Username'
import { CircularProgress } from '@mui/material'
import TimeAgo from 'react-timeago'
import CommunityService from 'services/CommunityService'
import { useEffect } from 'react'
import UsernameStreamLink from 'components/UsernameDisplay/UsernameStreamLink'
import UsernameAvatar from 'components/UsernameDisplay/UsernameAvatar'

const CommentDisplay = ({ comment, i }) => {
  const [getUsername, { data: username, isLoading }] =
    UserServiceApi.useLazyGetUsernameByIdCacheQuery()

  useEffect(() => {
    if (comment?.postedBy) {
      getUsername({ id: comment.postedBy }, true)
    }
  }, [getUsername, comment.postedBy])

  if (isLoading) {
    return (
      <div className="story-comment">
        <CircularProgress
          style={{
            height: 15,
            width: 15,
            marginRight: 10
          }}
        />
      </div>
    )
  }

  const checkIsLive = async login => {
    if (login) {
      const stream = await CommunityService.checkUserIsLive(login)
      if (stream.length > 0) {
        return true
      }
    }
    return false
  }

  return (
    <div className="story-comment">
      <div className="story-comment-left">
        <div className="story-comment-avatar">
          <UsernameStreamLink username={username}>
            <UsernameAvatar className="profile-image" username={username} />
          </UsernameStreamLink>
        </div>
        <div className="story-comment-number">#{i + 1}</div>
      </div>
      <div className="story-comment-right">
        <div className="story-comment-user">
          <UsernameStreamLink username={username}>
            <span
              className={
                checkIsLive(username?.login) === true ? 'is-live' : ''
              }>
              <Username username={username} />
            </span>
          </UsernameStreamLink>
        </div>
        <div className="story-comments-posted">
          <TimeAgo date={comment.createdAt.seconds * 1000} />
        </div>
        <div className="story-comment-text">{comment.comment}</div>
      </div>
    </div>
  )
}

export default CommentDisplay
