import { useAppSettings, usePreviousValue } from 'hooks'
import React, { useEffect, useRef } from 'react'
import { CSSTransition } from 'react-transition-group'
import OverlayService from 'services/OverlayService'
import Username from './Username'
import AmpedLogo from '../assets/img/amped-major-logo.png'
import AmpedIconKills from '../assets/img/amped-icon-kills.png'
import AmpedIconTags from '../assets/img/amped-icon-tags.png'
import AmpedIconScavs from '../assets/img/amped-icon-scavs.png'
import AmpedIconBosses from '../assets/img/amped-icon-bosses.png'
import AmpedIconItems from '../assets/img/amped-icon-items.png'

const AmpedMajor = ({ settings, username }) => {
  const overlayRef = useRef(null)
  const appSettings = useAppSettings()
  const [ampedMajorData, setAmpedMajorData] = React.useState({})

  useEffect(() => {
    if (username?.display_name) {
      let myUsername =
        username?.usingKick === true
          ? username?.kickUsername
          : username?.display_name
      OverlayService.getAmpedMajorDoc(myUsername, data => {
        if (data?.data() && data.data() !== 'undefined') {
          setAmpedMajorData(data.data())
        }
      })
    }
  }, [username])

  return (
    <CSSTransition
      nodeRef={overlayRef}
      in={
        typeof ampedMajorData?.points !== 'undefined' &&
        appSettings?.overlays?.ampedMajor?.overlay === true
      }
      timeout={0}
      classNames="animate-overlay-bounty">
      <div
        className="bounty-overlay-wrapper overlay-streamer-challenge overlay-amped-major"
        style={{
          left: appSettings?.overlays?.ampedMajor?.offsetX,
          top: appSettings?.overlays?.ampedMajor?.offsetY
        }}>
        <div
          ref={overlayRef}
          className="bounty-overlay"
          style={{
            transform: `scale(${
              appSettings?.overlays?.ampedMajor?.scale / 100
            })`
          }}>
          <div className="bounty-left">
            <div className="streamer-rank">
              <span
                className="streamer-rank-place"
                style={{
                  backgroundImage:
                    ampedMajorData?.rank === 1
                      ? 'url(/assets/img/1st-place.png)'
                      : ampedMajorData?.rank === 2
                      ? 'url(/assets/img/2nd-place.png)'
                      : ampedMajorData?.rank === 3
                      ? 'url(/assets/img/3rd-place.png)'
                      : 'url(/assets/img/other-place.png)'
                }}>
                <span
                  className={`streamer-rank-place-number ${
                    ampedMajorData?.rank === 1
                      ? 'text-gold'
                      : ampedMajorData?.rank === 2
                      ? 'text-silver'
                      : ampedMajorData?.rank === 3
                      ? 'text-bronze'
                      : ''
                  }`}>
                  {ampedMajorData?.rank || '--'}
                </span>
              </span>
              <span className="streamer-rank-place-label">Place</span>
            </div>
          </div>
          <div className="bounty-right">
            <div className="bounty-right-label">
              <span>
                <span>
                  <Username username={username} />
                </span>
              </span>
            </div>
            <div className="bounty-right-details">
              <span className="bounty-right-prize">
                {ampedMajorData?.points}
                <span className="bounty-right-prize-label">
                  Total
                  <br />
                  Points
                </span>
              </span>
              {/* <span className="bounty-right-prize">
                <span
                  className={
                    myLastRank === 0
                      ? ''
                      : myLastRank === ampedMajorData?.rank
                      ? ''
                      : myLastRank > ampedMajorData?.rank
                      ? `text-green`
                      : `text-red`
                  }>
                  {myLastRank === 0
                    ? '--'
                    : myLastRank === ampedMajorData?.rank
                    ? '--'
                    : myLastRank > ampedMajorData?.rank
                    ? `▲${myLastRank - ampedMajorData?.rank}`
                    : `▼${ampedMajorData?.rank - myLastRank}`}
                </span>
                <span className="bounty-right-prize-label">
                  Rank
                  <br />
                  Change
                </span>
              </span> */}
              <span className="bounty-right-prize amped-icons">
                <div className="amped-icon icon-kills">
                  <img src={AmpedIconKills} />
                  <span>{ampedMajorData?.scores?.pmckill}</span>
                </div>
                <div className="amped-icon icon-tags">
                  <img src={AmpedIconTags} />
                  <span>{ampedMajorData?.scores?.pmcdogtag}</span>
                </div>
                <div className="amped-icon icon-scavs">
                  <img src={AmpedIconScavs} />
                  <span>{ampedMajorData?.scores?.scavkill}</span>
                </div>
                <div className="amped-icon icon-bosses">
                  <img src={AmpedIconBosses} />
                  <span>{ampedMajorData?.scores?.bosskill}</span>
                </div>
                <div className="amped-icon icon-items">
                  <img src={AmpedIconItems} />
                  <span>{ampedMajorData?.scores?.survivedstatuswithfp100}</span>
                </div>
              </span>
            </div>
            <div className="bounty-right-posted">
              <div className="bounty-right-posted-inner">
                <span className="bounty-right-posted-label">
                  <img src={AmpedLogo} />
                  <span>By Hunt Premier</span>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </CSSTransition>
  )
}

export default AmpedMajor
