import React, { useEffect } from 'react'
import moment from 'moment'
import { useAppCommunityStreams } from 'hooks'
import Modal from '@mui/material/Modal'
import CircularProgress from '@mui/material/CircularProgress'
import Box from '@mui/material/Box'
import Alert from '@mui/material/Alert'
import Button from '@mui/material/Button'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormControl from '@mui/material/FormControl'
import FormLabel from '@mui/material/FormLabel'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import TextField from '@mui/material/TextField'
import { useLockedLeagueRun, useAppLeagues } from 'hooks'
import LeagueService from 'services/LeagueService'
import { getTarkovMaps } from 'utils/tarkovMaps'
import Grid from '@mui/material/Grid'
import { LeagueServiceApi } from 'api/LeagueServiceApi'
import SubmittedBy from './Leagues/Runs/SubmittedBy'
import { UserServiceApi } from 'api/UserServiceApi'

const ReviewLockedRun = () => {
  const lockedLeagueRun = useLockedLeagueRun()
  const communityStreams = useAppCommunityStreams()
  const leagues = useAppLeagues()
  const tarkovMaps = getTarkovMaps()
  const [reviewRunLoading, setReviewRunLoading] = React.useState(false)
  const [detailsLoading, setDetailsLoading] = React.useState(true)
  const [reviewRun, setReviewRun] = React.useState({})
  const [reviewWeek, setReviewWeek] = React.useState({})

  const [getSubmittedBy, { data: submittedUser }] =
    UserServiceApi.useLazyGetUsernameByIdCacheQuery()
  const [getDuoPartner, { data: duoUser }] =
    UserServiceApi.useLazyGetUsernameByIdCacheQuery()

  useEffect(() => {
    if (reviewRun?.submittedBy) {
      getSubmittedBy({ id: reviewRun?.submittedBy }, true)
    }
    if (reviewRun?.duoPartner) {
      getDuoPartner({ id: reviewRun?.duoPartner }, true)
    }
  }, [
    getSubmittedBy,
    reviewRun.submittedBy,
    getDuoPartner,
    reviewRun.duoPartner
  ])

  let defaultSettings = {
    scav: {
      results: {
        columns: [1, 2, 3]
      },
      questions: [
        {
          id: 'mode',
          label: 'Did they queue as a scav and not a PMC?',
          type: 'yesno',
          value: '',
          answers: [
            {
              id: 'no',
              type: 'deny',
              label: 'No'
            },
            {
              id: 'yes',
              type: 'approve',
              label: 'Yes'
            }
          ]
        },
        {
          id: 'timeframe',
          label:
            'Did the VOD show that the run happened between the dates the week started and ended?',
          type: 'yesno',
          value: '',
          answers: [
            {
              id: 'no',
              type: 'deny',
              label: 'No'
            },
            {
              id: 'yes',
              type: 'approve',
              label: 'Yes'
            }
          ]
        },
        {
          id: 'map',
          label: 'Was the run performed on the correct map?',
          type: 'yesno',
          value: '',
          answers: [
            {
              id: 'no',
              type: 'deny',
              label: 'No'
            },
            {
              id: 'yes',
              type: 'approve',
              label: 'Yes'
            }
          ]
        },
        {
          id: 'solo',
          label: 'Did they queue solo and not as a team?',
          type: 'yesno',
          value: '',
          answers: [
            {
              id: 'no',
              type: 'deny',
              label: 'No'
            },
            {
              id: 'yes',
              type: 'approve',
              label: 'Yes'
            }
          ]
        },
        {
          id: 'overlay',
          label: 'Was the overlay visible during the run?',
          results: {
            label: 'Followed Rules',
            answer: 'Yes'
          },
          type: 'yesno',
          value: '',
          answers: [
            {
              id: 'no',
              type: 'deny',
              label: 'No'
            },
            {
              id: 'yes',
              type: 'approve',
              label: 'Yes'
            }
          ]
        },
        {
          id: 'overallShown',
          label: 'Did they show overall stats screen before extract?',
          type: 'yesno',
          value: '',
          answers: [
            {
              id: 'no',
              type: 'deny',
              label: 'No'
            },
            {
              id: 'yes',
              type: 'approve',
              label: 'Yes'
            }
          ]
        },
        {
          id: 'raidShown',
          label: 'Did they show all of the end of raid screens?',
          results: {
            label: 'Showed Screens',
            answer: 'Yes'
          },
          type: 'yesno',
          value: '',
          answers: [
            {
              id: 'no',
              type: 'deny',
              label: 'No'
            },
            {
              id: 'yes',
              type: 'approve',
              label: 'Yes'
            }
          ]
        },
        {
          id: 'costItems',
          label: 'What was the total cost of items in stash at extract?',
          results: {
            label: 'Cost of Items'
          },
          type: 'number',
          value: '',
          helperText: 'Please enter a valid number of roubles.',
          placeholder: 'ex. 250000',
          min: 0,
          max: 10000000,
          validation: (e, v, id, type, callback) => {
            v = v || e.target.value
            v = v.toString().replace(/\D/g, '')
            if (v.length) {
              v = Math.floor(parseInt(v, 10))
            }
            if (v > 10000000) {
              v = 10000000
            }
            if (v < 0) {
              v = 0
            }
            if (typeof callback === 'function') {
              callback(v.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','))
            }
          }
        },
        {
          id: 'xp',
          label: 'How much XP did they get?',
          results: {
            label: 'Raid XP'
          },
          type: 'number',
          value: '',
          helperText: 'Please enter a valid number for raid XP.',
          placeholder: 'ex. 3000',
          min: 0,
          max: 1000000,
          validation: (e, v, id, type, callback) => {
            v = v || e.target.value
            v = v.toString().replace(/\D/g, '')
            if (v.length) {
              v = Math.floor(parseInt(v, 10))
            }
            if (v > 1000000) {
              v = 1000000
            }
            if (v < 0) {
              v = 0
            }
            if (typeof callback === 'function') {
              callback(v.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','))
            }
          }
        },
        {
          id: 'killsPmc',
          label: 'How many PMCs did they kill?',
          results: {
            label: 'PMCS Kills'
          },
          type: 'number',
          value: '',
          helperText: 'Please enter a valid number of zero or greater.',
          placeholder: 'ex. 0',
          min: 0,
          max: 99,
          validation: (e, v, id, type, callback) => {
            v = v || e.target.value
            if (v === '') {
              v = 0
            }
            v = Math.floor(parseInt(v, 10))
            if (v > 99) {
              v = 99
            }
            if (v < 0) {
              v = 0
            }
            if (typeof callback === 'function') {
              callback(v)
            }
          }
        },
        {
          id: 'killsRogue',
          label: 'How many Rogues/Raiders did they kill?',
          results: {
            label: 'Rogues/Raiders Kills'
          },
          type: 'number',
          value: '',
          helperText: 'Please enter a valid number of zero or greater.',
          placeholder: 'ex. 0',
          min: 0,
          max: 99,
          validation: (e, v, id, type, callback) => {
            v = v || e.target.value
            if (v === '') {
              v = 0
            }
            v = Math.floor(parseInt(v, 10))
            if (v > 99) {
              v = 99
            }
            if (v < 0) {
              v = 0
            }
            if (typeof callback === 'function') {
              callback(v)
            }
          }
        },
        {
          id: 'killsScav',
          label: 'How many scavs did they kill?',
          results: {
            label: 'Scav Kills'
          },
          type: 'number',
          value: '',
          helperText: 'Please enter a valid number of zero or greater.',
          placeholder: 'ex. 0',
          min: 0,
          max: 99,
          validation: (e, v, id, type, callback) => {
            v = v || e.target.value
            if (v === '') {
              v = 0
            }
            v = Math.floor(parseInt(v, 10))
            if (v > 99) {
              v = 99
            }
            if (v < 0) {
              v = 0
            }
            if (typeof callback === 'function') {
              callback(v)
            }
          }
        },
        {
          id: 'killsGuard',
          label: 'How many guards did they kill?',
          results: {
            label: 'Guard Kills'
          },
          type: 'number',
          value: '',
          helperText: 'Please enter a valid number of zero or greater.',
          placeholder: 'ex. 0',
          min: 0,
          max: 99,
          validation: (e, v, id, type, callback) => {
            v = v || e.target.value
            if (v === '') {
              v = 0
            }
            v = Math.floor(parseInt(v, 10))
            if (v > 99) {
              v = 99
            }
            if (v < 0) {
              v = 0
            }
            if (typeof callback === 'function') {
              callback(v)
            }
          }
        },
        {
          id: 'killsBoss',
          label: 'How many bosses did they kill?',
          results: {
            label: 'Boss Kills'
          },
          type: 'number',
          value: '',
          helperText: 'Please enter a valid number of zero or greater.',
          placeholder: 'ex. 0',
          min: 0,
          max: 99,
          validation: (e, v, id, type, callback) => {
            v = v || e.target.value
            if (v === '') {
              v = 0
            }
            v = Math.floor(parseInt(v, 10))
            if (v > 99) {
              v = 99
            }
            if (v < 0) {
              v = 0
            }
            if (typeof callback === 'function') {
              callback(v)
            }
          }
        },
        {
          id: 'bonusItems',
          label: `How many found in raid {getCurrentWeekItems} did
          they extract with?`,
          results: {
            label: 'Bonus Items'
          },
          type: 'number',
          value: '',
          helperText: 'Please enter a valid number of zero or greater.',
          placeholder: 'ex. 0',
          min: 0,
          max: 99,
          validation: (e, v, id, type, callback) => {
            v = v || e.target.value
            if (v === '') {
              v = 0
            }
            v = Math.floor(parseInt(v, 10))
            if (v > 99) {
              v = 99
            }
            if (v < 0) {
              v = 0
            }
            if (typeof callback === 'function') {
              callback(v)
            }
          }
        },
        {
          id: 'damage',
          label: `How much damage to body did they do?`,
          results: {
            label: 'Raid Damage'
          },
          type: 'number',
          value: '',
          helperText: 'Please enter a valid number of zero or greater.',
          placeholder: 'ex. 500',
          min: 0,
          max: 100000,
          validation: (e, v, id, type, callback) => {
            v = v || e.target.value
            if (v === '') {
              v = 0
            }
            v = Math.floor(parseInt(v, 10))
            if (v > 100000) {
              v = 100000
            }
            if (v < 0) {
              v = 0
            }
            if (typeof callback === 'function') {
              callback(v)
            }
          }
        }
      ]
    },
    solo: {
      results: {
        columns: [1, 2, 3]
      },
      questions: [
        {
          id: 'mode',
          label: 'Did they queue as a PMC and not a scav?',
          type: 'yesno',
          value: '',
          answers: [
            {
              id: 'no',
              type: 'deny',
              label: 'No'
            },
            {
              id: 'yes',
              type: 'approve',
              label: 'Yes'
            }
          ]
        },
        {
          id: 'online',
          label: 'Was this an online run (not offline)?',
          type: 'yesno',
          value: '',
          answers: [
            {
              id: 'no',
              type: 'deny',
              label: 'No'
            },
            {
              id: 'yes',
              type: 'approve',
              label: 'Yes'
            }
          ]
        },
        {
          id: 'timeframe',
          label:
            'Did the VOD show that the run happened between the dates the week started and ended?',
          type: 'yesno',
          value: '',
          answers: [
            {
              id: 'no',
              type: 'deny',
              label: 'No'
            },
            {
              id: 'yes',
              type: 'approve',
              label: 'Yes'
            }
          ]
        },
        {
          id: 'map',
          label: 'Was the run performed on the correct map?',
          type: 'yesno',
          value: '',
          answers: [
            {
              id: 'no',
              type: 'deny',
              label: 'No'
            },
            {
              id: 'yes',
              type: 'approve',
              label: 'Yes'
            }
          ]
        },
        {
          id: 'solo',
          label: 'Did they queue solo and not as a team?',
          type: 'yesno',
          value: '',
          answers: [
            {
              id: 'no',
              type: 'deny',
              label: 'No'
            },
            {
              id: 'yes',
              type: 'approve',
              label: 'Yes'
            }
          ]
        },
        {
          id: 'overlay',
          label: 'Was the overlay visible during the run?',
          results: {
            label: 'Followed Rules',
            answer: 'Yes'
          },
          type: 'yesno',
          value: '',
          answers: [
            {
              id: 'no',
              type: 'deny',
              label: 'No'
            },
            {
              id: 'yes',
              type: 'approve',
              label: 'Yes'
            }
          ]
        },
        {
          id: 'raidShown',
          label: 'Did they show all of the end of raid screens?',
          results: {
            label: 'Showed Screens',
            answer: 'Yes'
          },
          type: 'yesno',
          value: '',
          answers: [
            {
              id: 'no',
              type: 'deny',
              label: 'No'
            },
            {
              id: 'yes',
              type: 'approve',
              label: 'Yes'
            }
          ]
        },
        {
          id: 'xp',
          label: 'How much XP did they get?',
          results: {
            label: 'Raid XP'
          },
          type: 'number',
          value: '',
          helperText: 'Please enter a valid number for raid XP.',
          placeholder: 'ex. 3000',
          min: 0,
          max: 1000000,
          validation: (e, v, id, type, callback) => {
            v = v || e.target.value
            v = v.toString().replace(/\D/g, '')
            if (v.length) {
              v = Math.floor(parseInt(v, 10))
            }
            if (v > 1000000) {
              v = 1000000
            }
            if (v < 0) {
              v = 0
            }
            if (typeof callback === 'function') {
              callback(v.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','))
            }
          }
        },
        {
          id: 'killsPmc',
          label: 'How many PMCs did they kill?',
          results: {
            label: 'PMCS Kills'
          },
          type: 'number',
          value: '',
          helperText: 'Please enter a valid number of zero or greater.',
          placeholder: 'ex. 0',
          min: 0,
          max: 99,
          validation: (e, v, id, type, callback) => {
            v = v || e.target.value
            if (v === '') {
              v = 0
            }
            v = Math.floor(parseInt(v, 10))
            if (v > 99) {
              v = 99
            }
            if (v < 0) {
              v = 0
            }
            if (typeof callback === 'function') {
              callback(v)
            }
          }
        },
        {
          id: 'killsRogue',
          label: 'How many Rogues/Raiders did they kill?',
          results: {
            label: 'Rogues/Raiders Kills'
          },
          type: 'number',
          value: '',
          helperText: 'Please enter a valid number of zero or greater.',
          placeholder: 'ex. 0',
          min: 0,
          max: 99,
          validation: (e, v, id, type, callback) => {
            v = v || e.target.value
            if (v === '') {
              v = 0
            }
            v = Math.floor(parseInt(v, 10))
            if (v > 99) {
              v = 99
            }
            if (v < 0) {
              v = 0
            }
            if (typeof callback === 'function') {
              callback(v)
            }
          }
        },
        {
          id: 'killsScav',
          label: 'How many scavs did they kill?',
          results: {
            label: 'Scav Kills'
          },
          type: 'number',
          value: '',
          helperText: 'Please enter a valid number of zero or greater.',
          placeholder: 'ex. 0',
          min: 0,
          max: 99,
          validation: (e, v, id, type, callback) => {
            v = v || e.target.value
            if (v === '') {
              v = 0
            }
            v = Math.floor(parseInt(v, 10))
            if (v > 99) {
              v = 99
            }
            if (v < 0) {
              v = 0
            }
            if (typeof callback === 'function') {
              callback(v)
            }
          }
        },
        {
          id: 'killsGuard',
          label: 'How many guards did they kill?',
          results: {
            label: 'Guard Kills'
          },
          type: 'number',
          value: '',
          helperText: 'Please enter a valid number of zero or greater.',
          placeholder: 'ex. 0',
          min: 0,
          max: 99,
          validation: (e, v, id, type, callback) => {
            v = v || e.target.value
            if (v === '') {
              v = 0
            }
            v = Math.floor(parseInt(v, 10))
            if (v > 99) {
              v = 99
            }
            if (v < 0) {
              v = 0
            }
            if (typeof callback === 'function') {
              callback(v)
            }
          }
        },
        {
          id: 'killsBoss',
          label: 'How many bosses did they kill?',
          results: {
            label: 'Boss Kills'
          },
          type: 'number',
          value: '',
          helperText: 'Please enter a valid number of zero or greater.',
          placeholder: 'ex. 0',
          min: 0,
          max: 99,
          validation: (e, v, id, type, callback) => {
            v = v || e.target.value
            if (v === '') {
              v = 0
            }
            v = Math.floor(parseInt(v, 10))
            if (v > 99) {
              v = 99
            }
            if (v < 0) {
              v = 0
            }
            if (typeof callback === 'function') {
              callback(v)
            }
          }
        },
        {
          id: 'damage',
          label: `How much damage to body did they do?`,
          results: {
            label: 'Raid Damage'
          },
          type: 'number',
          value: '',
          helperText: 'Please enter a valid number of zero or greater.',
          placeholder: 'ex. 500',
          min: 0,
          max: 100000,
          validation: (e, v, id, type, callback) => {
            v = v || e.target.value
            if (v === '') {
              v = 0
            }
            v = Math.floor(parseInt(v, 10))
            if (v > 100000) {
              v = 100000
            }
            if (v < 0) {
              v = 0
            }
            if (typeof callback === 'function') {
              callback(v)
            }
          }
        },
        {
          id: 'bonusItems',
          label: `How many found in raid {getCurrentWeekItems} did
            they extract with?`,
          results: {
            label: 'Bonus Items'
          },
          type: 'number',
          value: '',
          helperText: 'Please enter a valid number of zero or greater.',
          placeholder: 'ex. 0',
          min: 0,
          max: 99,
          validation: (e, v, id, type, callback) => {
            v = v || e.target.value
            if (v === '') {
              v = 0
            }
            v = Math.floor(parseInt(v, 10))
            if (v > 99) {
              v = 99
            }
            if (v < 0) {
              v = 0
            }
            if (typeof callback === 'function') {
              callback(v)
            }
          }
        }
      ]
    },
    duo: {
      results: {
        columns: [1, 2, 3, 4]
      },
      questions: [
        {
          id: 'mode',
          label: 'Did they queue as PMCs and not as scavs?',
          type: 'yesno',
          value: '',
          answers: [
            {
              id: 'no',
              type: 'deny',
              label: 'No'
            },
            {
              id: 'yes',
              type: 'approve',
              label: 'Yes'
            }
          ]
        },
        {
          id: 'online',
          label: 'Was this an online run (not offline)?',
          type: 'yesno',
          value: '',
          answers: [
            {
              id: 'no',
              type: 'deny',
              label: 'No'
            },
            {
              id: 'yes',
              type: 'approve',
              label: 'Yes'
            }
          ]
        },
        {
          id: 'timeframe',
          label:
            'Did both of the VODs show that the run happened between the dates the week started and ended?',
          type: 'yesno',
          value: '',
          answers: [
            {
              id: 'no',
              type: 'deny',
              label: 'No'
            },
            {
              id: 'yes',
              type: 'approve',
              label: 'Yes'
            }
          ]
        },
        {
          id: 'map',
          label: 'Was the run performed on the correct map?',
          type: 'yesno',
          value: '',
          answers: [
            {
              id: 'no',
              type: 'deny',
              label: 'No'
            },
            {
              id: 'yes',
              type: 'approve',
              label: 'Yes'
            }
          ]
        },
        {
          id: 'solo',
          label: 'Did they queue together and with no one else in their party?',
          type: 'yesno',
          value: '',
          answers: [
            {
              id: 'no',
              type: 'deny',
              label: 'No'
            },
            {
              id: 'yes',
              type: 'approve',
              label: 'Yes'
            }
          ]
        },
        {
          id: 'overlay',
          label: 'Was the overlay visible on both streams during the run?',
          results: {
            label: 'Followed Rules',
            answer: 'Yes'
          },
          type: 'yesno',
          value: '',
          answers: [
            {
              id: 'no',
              type: 'deny',
              label: 'No'
            },
            {
              id: 'yes',
              type: 'approve',
              label: 'Yes'
            }
          ]
        },
        {
          id: 'raidShown',
          label: 'Did they show all of the end of raid screens?',
          results: {
            label: 'Showed Screens',
            answer: 'Yes'
          },
          type: 'yesno',
          value: '',
          answers: [
            {
              id: 'no',
              type: 'deny',
              label: 'No'
            },
            {
              id: 'yes',
              type: 'approve',
              label: 'Yes'
            }
          ]
        },
        {
          id: 'survive',
          label: 'Did {duoPartner1} survive?',
          results: {
            label: 'P1 Survived'
          },
          type: 'yesno',
          value: '',
          answers: [
            {
              id: 'no',
              type: 'approve',
              label: 'No'
            },
            {
              id: 'yes',
              type: 'approve',
              label: 'Yes'
            }
          ]
        },
        {
          id: 'xp',
          label: 'How much XP did {duoPartner1} get?',
          results: {
            label: 'P1 XP'
          },
          type: 'number',
          value: '',
          helperText: 'Please enter a valid number for raid XP.',
          placeholder: 'ex. 3000',
          min: 0,
          max: 1000000,
          validation: (e, v, id, type, callback) => {
            v = v || e.target.value
            v = v.toString().replace(/\D/g, '')
            if (v.length) {
              v = Math.floor(parseInt(v, 10))
            }
            if (v > 1000000) {
              v = 1000000
            }
            if (v < 0) {
              v = 0
            }
            if (typeof callback === 'function') {
              callback(v.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','))
            }
          }
        },
        {
          id: 'killsPmc',
          label: 'How many PMC kills did {duoPartner1} get?',
          results: {
            label: 'P1 PMCS Killed'
          },
          type: 'number',
          value: '',
          helperText: 'Please enter a valid number of zero or greater.',
          placeholder: 'ex. 0',
          min: 0,
          max: 99,
          validation: (e, v, id, type, callback) => {
            v = v || e.target.value
            if (v === '') {
              v = 0
            }
            v = Math.floor(parseInt(v, 10))
            if (v > 99) {
              v = 99
            }
            if (v < 0) {
              v = 0
            }
            if (typeof callback === 'function') {
              callback(v)
            }
          }
        },
        {
          id: 'killsRogue',
          label: 'How many Rogue/Raider kills did {duoPartner1} get?',
          results: {
            label: 'P1 Rogues/Raiders Killed'
          },
          type: 'number',
          value: '',
          helperText: 'Please enter a valid number of zero or greater.',
          placeholder: 'ex. 0',
          min: 0,
          max: 99,
          validation: (e, v, id, type, callback) => {
            v = v || e.target.value
            if (v === '') {
              v = 0
            }
            v = Math.floor(parseInt(v, 10))
            if (v > 99) {
              v = 99
            }
            if (v < 0) {
              v = 0
            }
            if (typeof callback === 'function') {
              callback(v)
            }
          }
        },
        {
          id: 'killsScav',
          label: 'How many scav kills did {duoPartner1} get?',
          results: {
            label: 'P1 Scav Kills'
          },
          type: 'number',
          value: '',
          helperText: 'Please enter a valid number of zero or greater.',
          placeholder: 'ex. 0',
          min: 0,
          max: 99,
          validation: (e, v, id, type, callback) => {
            v = v || e.target.value
            if (v === '') {
              v = 0
            }
            v = Math.floor(parseInt(v, 10))
            if (v > 99) {
              v = 99
            }
            if (v < 0) {
              v = 0
            }
            if (typeof callback === 'function') {
              callback(v)
            }
          }
        },
        {
          id: 'killsGuard',
          label: 'How many guard kills did {duoPartner1} get?',
          results: {
            label: 'P1 Guards Killed'
          },
          type: 'number',
          value: '',
          helperText: 'Please enter a valid number of zero or greater.',
          placeholder: 'ex. 0',
          min: 0,
          max: 99,
          validation: (e, v, id, type, callback) => {
            v = v || e.target.value
            if (v === '') {
              v = 0
            }
            v = Math.floor(parseInt(v, 10))
            if (v > 99) {
              v = 99
            }
            if (v < 0) {
              v = 0
            }
            if (typeof callback === 'function') {
              callback(v)
            }
          }
        },
        {
          id: 'killsBoss',
          label: 'How many boss kills did {duoPartner1} get?',
          results: {
            label: 'P1 Boss Kills'
          },
          type: 'number',
          value: '',
          helperText: 'Please enter a valid number of zero or greater.',
          placeholder: 'ex. 0',
          min: 0,
          max: 99,
          validation: (e, v, id, type, callback) => {
            v = v || e.target.value
            if (v === '') {
              v = 0
            }
            v = Math.floor(parseInt(v, 10))
            if (v > 99) {
              v = 99
            }
            if (v < 0) {
              v = 0
            }
            if (typeof callback === 'function') {
              callback(v)
            }
          }
        },
        {
          id: 'damage',
          label: `How much damage to body did
          {duoPartner1} do?`,
          results: {
            label: 'P1 Damage'
          },
          type: 'number',
          value: '',
          helperText: 'Please enter a valid number of zero or greater.',
          placeholder: 'ex. 500',
          min: 0,
          max: 100000,
          validation: (e, v, id, type, callback) => {
            v = v || e.target.value
            if (v === '') {
              v = 0
            }
            v = Math.floor(parseInt(v, 10))
            if (v > 100000) {
              v = 100000
            }
            if (v < 0) {
              v = 0
            }
            if (typeof callback === 'function') {
              callback(v)
            }
          }
        },
        {
          id: 'bonusItems',
          label: `How many found in raid {getCurrentWeekItems} did
          {duoPartner1} extract with?`,
          results: {
            label: 'P1 Bonus Items'
          },
          type: 'number',
          value: '',
          helperText: 'Please enter a valid number of zero or greater.',
          placeholder: 'ex. 0',
          min: 0,
          max: 99,
          validation: (e, v, id, type, callback) => {
            v = v || e.target.value
            if (v === '') {
              v = 0
            }
            v = Math.floor(parseInt(v, 10))
            if (v > 99) {
              v = 99
            }
            if (v < 0) {
              v = 0
            }
            if (typeof callback === 'function') {
              callback(v)
            }
          }
        },
        {
          id: 'survive2',
          label: 'Did {duoPartner2} survive?',
          results: {
            label: 'P2 Survived'
          },
          type: 'yesno',
          value: '',
          answers: [
            {
              id: 'no',
              type: 'approve',
              label: 'No'
            },
            {
              id: 'yes',
              type: 'approve',
              label: 'Yes'
            }
          ]
        },
        {
          id: 'xp2',
          label: 'How much XP did {duoPartner2} get?',
          results: {
            label: 'P2 XP'
          },
          type: 'number',
          value: '',
          helperText: 'Please enter a valid number for raid XP.',
          placeholder: 'ex. 3000',
          min: 0,
          max: 1000000,
          validation: (e, v, id, type, callback) => {
            v = v || e.target.value
            v = v.toString().replace(/\D/g, '')
            if (v.length) {
              v = Math.floor(parseInt(v, 10))
            }
            if (v > 1000000) {
              v = 1000000
            }
            if (v < 0) {
              v = 0
            }
            if (typeof callback === 'function') {
              callback(v.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','))
            }
          }
        },
        {
          id: 'killsPmc2',
          label: 'How many PMC kills did {duoPartner2} get?',
          results: {
            label: 'P2 PMC Kills'
          },
          type: 'number',
          value: '',
          helperText: 'Please enter a valid number of zero or greater.',
          placeholder: 'ex. 0',
          min: 0,
          max: 99,
          validation: (e, v, id, type, callback) => {
            v = v || e.target.value
            if (v === '') {
              v = 0
            }
            v = Math.floor(parseInt(v, 10))
            if (v > 99) {
              v = 99
            }
            if (v < 0) {
              v = 0
            }
            if (typeof callback === 'function') {
              callback(v)
            }
          }
        },
        {
          id: 'killsRogue2',
          label: 'How many Rogue/Raider kills did {duoPartner2} get?',
          results: {
            label: 'P2 Rogue/Raider Kills'
          },
          type: 'number',
          value: '',
          helperText: 'Please enter a valid number of zero or greater.',
          placeholder: 'ex. 0',
          min: 0,
          max: 99,
          validation: (e, v, id, type, callback) => {
            v = v || e.target.value
            if (v === '') {
              v = 0
            }
            v = Math.floor(parseInt(v, 10))
            if (v > 99) {
              v = 99
            }
            if (v < 0) {
              v = 0
            }
            if (typeof callback === 'function') {
              callback(v)
            }
          }
        },
        {
          id: 'killsScav2',
          label: 'How many scav kills did {duoPartner2} get?',
          results: {
            label: 'P2 Scav Kills'
          },
          type: 'number',
          value: '',
          helperText: 'Please enter a valid number of zero or greater.',
          placeholder: 'ex. 0',
          min: 0,
          max: 99,
          validation: (e, v, id, type, callback) => {
            v = v || e.target.value
            if (v === '') {
              v = 0
            }
            v = Math.floor(parseInt(v, 10))
            if (v > 99) {
              v = 99
            }
            if (v < 0) {
              v = 0
            }
            if (typeof callback === 'function') {
              callback(v)
            }
          }
        },
        {
          id: 'killsGuard2',
          label: 'How many guard kills did {duoPartner2} get?',
          results: {
            label: 'P2 Guard Kills'
          },
          type: 'number',
          value: '',
          helperText: 'Please enter a valid number of zero or greater.',
          placeholder: 'ex. 0',
          min: 0,
          max: 99,
          validation: (e, v, id, type, callback) => {
            v = v || e.target.value
            if (v === '') {
              v = 0
            }
            v = Math.floor(parseInt(v, 10))
            if (v > 99) {
              v = 99
            }
            if (v < 0) {
              v = 0
            }
            if (typeof callback === 'function') {
              callback(v)
            }
          }
        },
        {
          id: 'killsBoss2',
          label: 'How many boss kills did {duoPartner2} get?',
          results: {
            label: 'P2 Boss Kills'
          },
          type: 'number',
          value: '',
          helperText: 'Please enter a valid number of zero or greater.',
          placeholder: 'ex. 0',
          min: 0,
          max: 99,
          validation: (e, v, id, type, callback) => {
            v = v || e.target.value
            if (v === '') {
              v = 0
            }
            v = Math.floor(parseInt(v, 10))
            if (v > 99) {
              v = 99
            }
            if (v < 0) {
              v = 0
            }
            if (typeof callback === 'function') {
              callback(v)
            }
          }
        },
        {
          id: 'damage2',
          label: `How much damage to body did
          {duoPartner2} do?`,
          results: {
            label: 'P2 Damage'
          },
          type: 'number',
          value: '',
          helperText: 'Please enter a valid number of zero or greater.',
          placeholder: 'ex. 500',
          min: 0,
          max: 100000,
          validation: (e, v, id, type, callback) => {
            v = v || e.target.value
            if (v === '') {
              v = 0
            }
            v = Math.floor(parseInt(v, 10))
            if (v > 100000) {
              v = 100000
            }
            if (v < 0) {
              v = 0
            }
            if (typeof callback === 'function') {
              callback(v)
            }
          }
        },
        {
          id: 'bonusItems2',
          label: `How many found in raid {getCurrentWeekItems} did
          {duoPartner2} extract with?`,
          results: {
            label: 'P2 Bonus Items'
          },
          type: 'number',
          value: '',
          helperText: 'Please enter a valid number of zero or greater.',
          placeholder: 'ex. 0',
          min: 0,
          max: 99,
          validation: (e, v, id, type, callback) => {
            v = v || e.target.value
            if (v === '') {
              v = 0
            }
            v = Math.floor(parseInt(v, 10))
            if (v > 99) {
              v = 99
            }
            if (v < 0) {
              v = 0
            }
            if (typeof callback === 'function') {
              callback(v)
            }
          }
        }
      ]
    }
  }
  const [leagueRunSettings, setLeagueRunSettings] = React.useState({})
  const [leagueRunAnswers, setLeagueRunAnswers] = React.useState({})
  const [leagueRunError, setLeagueRunError] = React.useState(false)
  const [isDenying, setIsDenying] = React.useState(false)
  const [reasonError, setReasonError] = React.useState(false)
  const [reason, setReason] = React.useState('')
  const [questionIndex, setQuestionIndex] = React.useState(0)
  const [unReviewLeagueRunCall] =
    LeagueServiceApi.useUnReviewLeagueRunMutation()
  const [approveReviewLeagueRunCall] =
    LeagueServiceApi.useApproveReviewLeagueRunMutation()
  const [denyReviewLeagueRunCall] =
    LeagueServiceApi.useDenyReviewLeagueRunMutation()

  useEffect(() => {
    LeagueService.getLeagueRun(lockedLeagueRun.id, data => {
      let reviewRunData = data.data()
      setLeagueRunSettings(defaultSettings[reviewRunData.league])
      let defaultValues = {}
      defaultSettings[reviewRunData.league].questions.forEach(q => {
        defaultValues[q.id] = ''
      })
      setLeagueRunAnswers(defaultValues)
      setReviewRun(reviewRunData)
      if (reviewRunData.approved === true) {
        let updatedData = {}
        defaultSettings[reviewRunData.league].questions.forEach(q => {
          if (typeof reviewRunData[q.id] !== 'undefined') {
            updatedData[q.id] = reviewRunData[q.id]
          } else {
            let answer = ''
            q.answers.forEach(a => {
              if (a.type === 'approve') {
                answer = a.id
              }
            })
            updatedData[q.id] = answer
          }
        })
        setLeagueRunAnswers(updatedData)
        setQuestionIndex(defaultSettings[reviewRunData.league].questions.length)
      }
      LeagueService.getSeasonWeeks(
        data.data().league,
        `season-${
          leagues.filter(league => league.id === data.data().league)[0]
            .currentSeason
        }`,
        weeks => {
          weeks.forEach(week => {
            if (week.id === `week-${data.data().week}`) {
              setReviewWeek(week.data())
            }
          })
          setDetailsLoading(false)
        }
      )
    })
  }, [])

  const cancelReview = async () => {
    if (reviewRunLoading === false) {
      setReviewRunLoading(true)
      await unReviewLeagueRunCall({ id: lockedLeagueRun.id })
        .unwrap()
        .then(result => {})
    }
  }

  const denyReview = async () => {
    if (reviewRunLoading === false) {
      setReviewRunLoading(true)
      await denyReviewLeagueRunCall({ id: lockedLeagueRun.id, reason })
        .unwrap()
        .then(result => {})
    }
  }

  const approveReview = async () => {
    if (reviewRunLoading === false) {
      setReviewRunLoading(true)
      let data = {}
      leagueRunSettings.questions.forEach(q => {
        if (q.type === 'number') {
          if (typeof leagueRunAnswers[q.id] === 'string') {
            data[q.id] = parseInt(leagueRunAnswers[q.id].replace(/,/g, ''))
          } else {
            data[q.id] = leagueRunAnswers[q.id]
          }
        } else {
          data[q.id] = leagueRunAnswers[q.id]
        }
      })
      await approveReviewLeagueRunCall({
        id: lockedLeagueRun.id,
        run: data
      })
        .unwrap()
        .then(result => {})
    }
  }

  const backQuestion = () => {
    let count = 0
    let data = {}
    for (const [key, value] of Object.entries(leagueRunAnswers)) {
      if (count === questionIndex - 1) {
        //data[key] = ''
      }
      if (isDenying && count === questionIndex - 1) {
        data[key] = ''
      }
      if (isDenying && count === questionIndex) {
        data[key] = ''
      }
      count += 1
    }
    setLeagueRunAnswers({
      ...leagueRunAnswers,
      ...data
    })
    setIsDenying(false)
    if (isDenying) {
      setQuestionIndex(questionIndex)
    } else {
      setQuestionIndex(questionIndex - 1)
    }
  }

  const skipKills = () => {
    let count = 0
    let skipData = {}
    for (const [key, value] of Object.entries(leagueRunAnswers)) {
      if (count > questionIndex - 1 && count < questionIndex + 5) {
        skipData[key] = 0
      }
      count += 1
    }
    setLeagueRunAnswers({
      ...leagueRunAnswers,
      ...skipData
    })
    setQuestionIndex(questionIndex + 5)
  }

  const resetForm = () => {
    let resetData = {}
    for (const [key, value] of Object.entries(leagueRunAnswers)) {
      resetData[key] = ''
    }
    setIsDenying(false)
    setQuestionIndex(0)
    setLeagueRunAnswers(resetData)
  }

  const getCurrentWeekItems = () => {
    if (reviewWeek && reviewWeek.items && reviewWeek.items.length) {
      let itemsString = ''
      reviewWeek.items.forEach((item, index) => {
        if (index === reviewWeek.items.length - 1) {
          itemsString += ` and ${item.display_name}`
        } else {
          itemsString += `${item.display_name} `
        }
      })
      return itemsString
    }
  }

  const handleChange = (event, value, name, type) => {
    if (type === 'yesno') {
      setLeagueRunAnswers({ ...leagueRunAnswers, [name]: value })
      if (
        leagueRunSettings.questions
          .filter(q => q.id === name)[0]
          .answers.filter(a => a.id === value)[0].type === 'deny'
      ) {
        setIsDenying(true)
      } else if (
        leagueRunSettings.questions
          .filter(q => q.id === name)[0]
          .answers.filter(a => a.id === value)[0].type === 'approve'
      ) {
        if (
          leagueRunAnswers.survive === 'no' &&
          name === 'survive2' &&
          value === 'no'
        ) {
          setIsDenying(true)
          setReason('At least one member of the team must survive the raid.')
        } else {
          setQuestionIndex(questionIndex + 1)
        }
      }
    } else if (type === 'number') {
      setLeagueRunAnswers({ ...leagueRunAnswers, [name]: value })
    }
  }

  const getFormProgress = () => {
    let count = questionIndex
    let total = Object.entries(leagueRunAnswers).length
    return `${(count / total).toFixed(2) * 100}%`
  }

  const isFormReset = () => {
    for (const [key, value] of Object.entries(leagueRunAnswers)) {
      if (value !== '') {
        return false
      }
    }
    return true
  }

  const isFormCompeted = () => {
    let total = Object.entries(leagueRunAnswers).length
    if (total === questionIndex) {
      return true
    }
  }

  const checkIsLive = uid => {
    if (uid.length > 0) {
      let streamInfo = communityStreams.filter(s => s.id === uid)
      if (streamInfo.length > 0) {
        return streamInfo[0]
      }
    }
  }

  return (
    <Modal
      disableEscapeKeyDown={true}
      sx={{ overflowY: 'scroll' }}
      open={typeof lockedLeagueRun.id !== 'undefined'}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description">
      <Box className="run-modal">
        <div className="aside-header aside-run">
          <span className="leagues">Reviewing League Run</span>
        </div>
        <div className="modal-content">
          {reasonError ? (
            <Alert severity="error">
              Please enter a reason for denying the league run.
            </Alert>
          ) : (
            <Alert severity="info">
              Please fill in the fields as accurately as possible!
            </Alert>
          )}
          {!detailsLoading ? (
            <Grid container spacing={2} className="review-run-cols">
              <Grid item xs={12} md={6}>
                <div
                  className={`league-week-banner league-week-${reviewWeek.map}`}>
                  <div className="league-week-banner-text">
                    <span className="title">
                      {reviewRun.league} League Season {reviewRun.season}{' '}
                      &ndash; {reviewWeek.display_name}
                    </span>
                    <span className="duration">
                      Map:{' '}
                      <span className="duration-value">
                        {tarkovMaps[reviewWeek.map].name}
                      </span>
                    </span>
                    <span className="items">
                      Bonus Items:{' '}
                      <span className="items-value">
                        {getCurrentWeekItems()}
                      </span>
                    </span>
                    <span className="ends">
                      Starts:{' '}
                      <span className="items-value">
                        {moment(reviewWeek.starts.seconds * 1000).toString()}
                      </span>
                    </span>
                    <span className="ends">
                      Ends:{' '}
                      <span className="items-value">
                        {moment(reviewWeek.ends.seconds * 1000).toString()}
                      </span>
                    </span>
                  </div>
                  <div className="league-week-banner-gradient"></div>
                </div>
              </Grid>
              <Grid item xs={12} md={6}>
                <div className="run-submit-details">
                  <div className="league-week-banner-text">
                    <div className="league-week-banner-usernames">
                      <div className="league-week-banner-username">
                        <SubmittedBy
                          uid={reviewRun.submittedBy}
                          className={'run-submit-user'}
                        />
                      </div>
                      {reviewRun.league === 'duo' && (
                        <div className="league-week-banner-username">
                          <span>
                            <SubmittedBy
                              uid={reviewRun.duoPartner}
                              className={'run-submit-user'}
                            />
                          </span>
                        </div>
                      )}
                    </div>
                    <span className="ends">
                      Run Started:{' '}
                      <span className="items-value">
                        {moment(reviewRun.started.seconds * 1000).toString()}
                      </span>
                    </span>
                    <span className="ends">
                      Run Duration:{' '}
                      <span className="items-value">
                        {moment
                          .utc(
                            moment(reviewRun.endedAt.seconds * 1000).diff(
                              moment(reviewRun.started.seconds * 1000)
                            )
                          )
                          .format('HH:mm:ss')}
                      </span>
                    </span>
                    <span className="ends">
                      VOD:{' '}
                      <span className="items-value">
                        <a href={reviewRun.twitchLink} target="_blank">
                          {reviewRun.twitchLink}
                        </a>
                      </span>
                    </span>
                    {reviewRun.league === 'duo' && (
                      <span className="ends">
                        VOD2:{' '}
                        <span className="items-value">
                          <a href={reviewRun.twitchLink2} target="_blank">
                            {reviewRun.twitchLink2}
                          </a>
                        </span>
                      </span>
                    )}
                  </div>
                </div>
              </Grid>
              <Grid item xs={12}>
                {isDenying ? (
                  <div className="league-submission-form">
                    <span className="subhead">Review Progress</span>
                    <div className="form-progress">
                      <div
                        className="form-progress-inner"
                        style={{ width: '100%' }}></div>
                    </div>
                    <FormControl>
                      <FormLabel id="scav-reason" sx={{ pb: 1 }}>
                        <span className="subhead green">
                          What is your reason for denying this run?
                        </span>
                      </FormLabel>
                      <TextField
                        id="scav-reason"
                        onChange={(e, v) => {
                          e.persist()
                          v = v || e.target.value
                          setReason(v)
                        }}
                        error={leagueRunError}
                        value={reason}
                        placeholder="ex. Link timestamp is not provided or run is on the wrong map..."
                        variant="outlined"
                      />
                    </FormControl>
                  </div>
                ) : (
                  <div className="league-submission-form">
                    <span className="subhead">Review Progress</span>
                    <div className="form-progress">
                      <div
                        className="form-progress-inner"
                        style={{ width: getFormProgress() }}></div>
                    </div>
                    {leagueRunSettings.questions
                      .filter((q, i) => i === questionIndex)
                      .map((q, i) => {
                        return (
                          <FormControl key={q.id}>
                            <FormLabel
                              id={`scav-${q.id}`}
                              sx={{ pb: q.type !== 'yesno' ? 1 : 0 }}>
                              <span className="subhead green">
                                {q.label
                                  .replace(
                                    '{duoPartner1}',
                                    submittedUser?.display_name
                                  )
                                  .replace(
                                    '{duoPartner2}',
                                    duoUser?.display_name
                                  )
                                  .replace(
                                    '{getCurrentWeekItems}',
                                    getCurrentWeekItems()
                                  )}
                              </span>
                            </FormLabel>
                            {q.type === 'yesno' && (
                              <RadioGroup
                                value={leagueRunAnswers[q.id]}
                                onChange={(e, v) => {
                                  handleChange(e, v, q.id, q.type)
                                }}
                                row
                                aria-labelledby={`scav-${q.id}`}
                                name="row-radio-buttons-group">
                                {q.answers &&
                                  q.answers.map(a => {
                                    return (
                                      <FormControlLabel
                                        key={a.id}
                                        value={a.id}
                                        control={<Radio />}
                                        label={a.label}
                                      />
                                    )
                                  })}
                              </RadioGroup>
                            )}
                            {q.type === 'number' && (
                              <TextField
                                id={`scav-${q.id}`}
                                onChange={(e, v) => {
                                  e.persist()
                                  q.validation(e, v, q.id, q.type, value => {
                                    handleChange(e, value, q.id, q.type)
                                  })
                                }}
                                inputProps={{
                                  step: 1,
                                  min: q.min,
                                  max: q.max
                                }}
                                error={leagueRunError}
                                helperText={q.helperText}
                                value={leagueRunAnswers[q.id]}
                                placeholder={q.placeholder}
                                variant="outlined"
                              />
                            )}
                            <div className="question-controls">
                              {(q.id === 'killsPmc' || q.id === 'killsPmc2') &&
                                (leagueRunAnswers[q.id] === '' ||
                                  leagueRunAnswers[q.id] === 0) && (
                                  <div className="modal-controls">
                                    <Button
                                      className="btn btn-primary"
                                      onClick={e => {
                                        skipKills()
                                      }}>
                                      No Kills
                                    </Button>
                                  </div>
                                )}
                              {q.type !== 'yesno' &&
                                leagueRunAnswers[q.id] !== '' && (
                                  <div className="modal-controls">
                                    <Button
                                      className="btn btn-primary"
                                      onClick={e => {
                                        setQuestionIndex(questionIndex + 1)
                                      }}>
                                      Next Question
                                    </Button>
                                  </div>
                                )}
                            </div>
                          </FormControl>
                        )
                      })}
                    {isFormCompeted() && (
                      <div className="league-run-summary">
                        <span className="subhead">League Run Summary</span>
                        <Grid container spacing={2}>
                          {leagueRunSettings.results.columns.map((a, i) => {
                            return (
                              <Grid
                                item
                                key={i}
                                xs={12}
                                md={
                                  12 / leagueRunSettings.results.columns.length
                                }>
                                <div className="league-week-banner-text">
                                  {leagueRunSettings.questions
                                    .filter(q => q.results && q.results.label)
                                    .filter(
                                      (q, qi) =>
                                        qi >=
                                          (i *
                                            leagueRunSettings.questions.filter(
                                              q => q.results && q.results.label
                                            ).length) /
                                            leagueRunSettings.results.columns
                                              .length &&
                                        qi <
                                          ((i + 1) *
                                            leagueRunSettings.questions.filter(
                                              q => q.results && q.results.label
                                            ).length) /
                                            leagueRunSettings.results.columns
                                              .length
                                    )
                                    .map((q, qi) => {
                                      return (
                                        <span
                                          style={{ cursor: 'pointer' }}
                                          onClick={() => {
                                            setQuestionIndex(
                                              leagueRunSettings.questions.findIndex(
                                                question => question.id === q.id
                                              )
                                            )
                                          }}
                                          className="ends"
                                          key={qi}>
                                          {q.results.label}:{' '}
                                          <span className="items-value">
                                            {typeof q.results.answer !==
                                            'undefined'
                                              ? q.results.answer
                                              : leagueRunAnswers[q.id]}
                                          </span>
                                        </span>
                                      )
                                    })}
                                </div>
                              </Grid>
                            )
                          })}
                        </Grid>
                      </div>
                    )}
                  </div>
                )}
              </Grid>
            </Grid>
          ) : (
            <div className="loader-centered">
              <CircularProgress
                style={{
                  color: '#7ab903',
                  height: 75,
                  width: 75
                }}
              />
            </div>
          )}
          <div className="league-submission-form">
            <div className="modal-controls">
              {!isFormReset() && (
                <Button
                  className="btn btn-primary btn-cancel"
                  disabled={reviewRunLoading}
                  onClick={e => {
                    e.preventDefault()
                    resetForm()
                  }}>
                  {reviewRunLoading && (
                    <CircularProgress
                      style={{
                        color: '#000',
                        height: 15,
                        width: 15,
                        marginRight: 10
                      }}
                    />
                  )}
                  Reset Form
                </Button>
              )}
              {questionIndex > 0 && (
                <Button
                  className="btn btn-primary btn-cancel"
                  disabled={reviewRunLoading}
                  onClick={e => {
                    e.preventDefault()
                    backQuestion()
                  }}>
                  {reviewRunLoading && (
                    <CircularProgress
                      style={{
                        color: '#000',
                        height: 15,
                        width: 15,
                        marginRight: 10
                      }}
                    />
                  )}
                  Back
                </Button>
              )}
              <Button
                className="btn btn-primary btn-cancel"
                disabled={reviewRunLoading}
                onClick={e => {
                  e.preventDefault()
                  cancelReview()
                }}>
                {reviewRunLoading && (
                  <CircularProgress
                    style={{
                      color: '#000',
                      height: 15,
                      width: 15,
                      marginRight: 10
                    }}
                  />
                )}
                Cancel{' '}
                {reviewRun.approved || reviewRun.denied ? 'Edit' : 'Review'}
              </Button>
              {isDenying && (
                <Button
                  className="btn btn-primary"
                  disabled={reviewRunLoading}
                  onClick={e => {
                    e.preventDefault()
                    if (isDenying === false) {
                      setReasonError(false)
                      setIsDenying(true)
                    } else {
                      if (reason === '') {
                        setReasonError(true)
                      } else {
                        setReasonError(false)
                        denyReview()
                      }
                    }
                  }}>
                  {reviewRunLoading && (
                    <CircularProgress
                      style={{
                        color: '#000',
                        height: 15,
                        width: 15,
                        marginRight: 10
                      }}
                    />
                  )}
                  Deny Run
                </Button>
              )}
              {isFormCompeted() && (
                <Button
                  className="btn btn-primary"
                  disabled={reviewRunLoading}
                  onClick={e => {
                    e.preventDefault()
                    approveReview()
                  }}>
                  {reviewRunLoading && (
                    <CircularProgress
                      style={{
                        color: '#000',
                        height: 15,
                        width: 15,
                        marginRight: 10
                      }}
                    />
                  )}
                  Approve Run
                </Button>
              )}
            </div>
          </div>
        </div>
      </Box>
    </Modal>
  )
}

export default ReviewLockedRun
