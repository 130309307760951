import React, { useEffect } from 'react'
import { useAppIsLoggedIn, useUser } from 'hooks'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import Button from '@mui/material/Button'
import Select from '@mui/material/Select'
import GoonsService from 'services/GoonsService'
import Alert from '@mui/material/Alert'
import CircularProgress from '@mui/material/CircularProgress'

const ReportGoons = () => {
  const isLoggedIn = useAppIsLoggedIn()
  const [goonsMap, setGoonsMap] = React.useState('')
  const [goonsTime, setGoonsTime] = React.useState(0)
  const [addGoonsSuccess, setAddGoonsSuccess] = React.useState(false)
  const [addGoonsLoading, setAddGoonsLoading] = React.useState(false)
  const [addGoonsError, setAddGoonsError] = React.useState(false)
  const [goonsErrorMsg, setGoonsErrorMsg] = React.useState('')
  const user = useUser()

  const handleMapChange = event => {
    setGoonsMap(event.target.value)
  }
  const handleTimeChange = event => {
    setGoonsTime(event.target.value)
  }
  const reportGoons = () => {
    if (addGoonsLoading === false) {
      setAddGoonsLoading(true)
      GoonsService.addSighting(goonsMap, goonsTime, user.uid, result => {
        setAddGoonsLoading(false)
        if (result.success) {
          setAddGoonsSuccess(true)
          setAddGoonsError(false)
          setGoonsMap('')
          setGoonsTime(0)
          setTimeout(() => {
            setAddGoonsSuccess(false)
          }, 3000)
        } else if (result.error) {
          setGoonsErrorMsg(result.error)
          setAddGoonsError(true)
          setAddGoonsSuccess(false)
          setTimeout(() => {
            setAddGoonsError(false)
            setGoonsErrorMsg('')
          }, 3000)
        }
      })
    }
  }
  return (
    <div>
      <div className="aside-header aside-goons">
        <span className="goons">Report a Goons Sighting</span>
      </div>
      <div className="content-panel">
        <span className="heading-text">
          Did you just see the Goons on a map in Escape From Tarkov?
        </span>
        <span className="paragraph">
          Report which map you saw them on and how long ago it was to help
          others in the community hunt them down.
        </span>
        <div className="form-alerts">
          {addGoonsSuccess && (
            <Alert severity="success">
              Goons Sighting Successfully Reported, Thanks!
            </Alert>
          )}
          {addGoonsError && <Alert severity="error">{goonsErrorMsg}</Alert>}
        </div>
        <form
          action="/"
          method="POST"
          onSubmit={e => {
            e.preventDefault()
            reportGoons()
          }}>
          <FormControl required fullWidth sx={{ pb: 2, mt: 2 }}>
            <InputLabel id="demo-simple-select-label">Map</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={goonsMap}
              label="Map *"
              onChange={handleMapChange}>
              <MenuItem value="customs">Customs</MenuItem>
              <MenuItem value="lighthouse">Lighthouse</MenuItem>
              <MenuItem value="shoreline">Shoreline</MenuItem>
              <MenuItem value="woods">Woods</MenuItem>
            </Select>
          </FormControl>
          <FormControl required fullWidth sx={{ pb: 3 }}>
            <InputLabel id="demo-simple-select-label">How Long Ago</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={goonsTime}
              label="How Long Ago *"
              onChange={handleTimeChange}>
              <MenuItem value="0">Just Now</MenuItem>
              <MenuItem value="-10">~10 Mins. Ago</MenuItem>
              <MenuItem value="-20">~20 Mins. Ago</MenuItem>
              <MenuItem value="-30">~30 Mins. Ago</MenuItem>
            </Select>
          </FormControl>

          <span className="paragraph disclaimer">
            By submitting a report, you agree to not intentionally misreport the
            Goons location or abuse this feature &ndash; abusers will be
            permanently banned.
          </span>
          <FormControl className="form-control">
            <Button
              variant="contained"
              type="submit"
              disabled={addGoonsLoading}>
              {addGoonsLoading && (
                <CircularProgress
                  style={{
                    color: '#000',
                    height: 15,
                    width: 15,
                    marginRight: 10
                  }}
                />
              )}
              Report the Goons
            </Button>
          </FormControl>
        </form>
      </div>
    </div>
  )
}

export default ReportGoons
