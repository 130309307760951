import { useUserStripe } from 'hooks/use-user-data'
import AccountSignup from './account/AccountSignup'
import { StripeApi } from 'api/Stripe'
import AccountOnboard from './account/AccountOnboard'
import { Alert, Button, CircularProgress } from '@mui/material'
import SingleCard from 'components/Stripe/cards/SingleCard'
import SingleBankAccount from 'components/Stripe/BankAccount/SingleBankAccount'

const AccountDisplay = () => {
  const stripe = useUserStripe()
  const { account = '' } = stripe
  const { data: accountData, isLoading } =
    StripeApi.useGetAffiliateDetailsQuery({ accountId: account })
  const { data: loginLink } = StripeApi.useGetAccountLoginLinkQuery({
    accountId: account
  })

  if (isLoading)
    return (
      <div>
        <div className="aside-header aside-account-sign-up">
          <span className="admin">Payouts</span>
        </div>
        <div className="content-panel">
          <CircularProgress size="5rem" />
        </div>
      </div>
    )

  if (!account) return <AccountSignup />

  if (!accountData.details_submitted)
    return <AccountOnboard account={account} />

  return (
    <div>
      <div className="aside-header aside-account-display">
        <span className="bounty">Get Paid by Fence.gg</span>
      </div>
      <div className="content-panel">
        {accountData?.requirements?.disabled_reason?.indexOf(
          'pending_verification'
        ) > -1 && (
          <Alert sx={{ marginBottom: 3 }} severity="info">
            Your FenceGG payments account is pending verification.
          </Alert>
        )}
        {accountData?.requirements?.disabled_reason?.indexOf(
          'pending_verification'
        ) === 0 &&
          accountData.payouts_enabled !== true && (
            <Alert sx={{ marginBottom: 3 }} severity="error">
              Additional information is needed to accept Fence.gg payments,
              please go to Manage FenceGG Payments.
            </Alert>
          )}
        <span className="subhead">Payout Account </span>
        <div className="is-private">
          {accountData?.external_accounts?.data[0] &&
            accountData?.external_accounts?.data[0].object === 'card' && (
              <SingleCard
                cardDetails={accountData?.external_accounts?.data[0]}
              />
            )}
          {accountData?.external_accounts?.data[0] &&
            accountData?.external_accounts?.data[0].object ===
              'bank_account' && (
              <SingleBankAccount
                bankDetails={accountData?.external_accounts?.data[0]}
              />
            )}
        </div>
        {loginLink && (
          <div style={{ paddingTop: 20 }}>
            <Button
              className="btn btn-primary"
              href={loginLink?.url}
              target="_blank">
              Manage FenceGG Payments
            </Button>
          </div>
        )}
      </div>
    </div>
  )
}

export default AccountDisplay
