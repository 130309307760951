import {
  Box,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Avatar,
  Typography,
  Divider
} from '@mui/material'
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents'

const OwnerDisplay = ({ owner }) => {
  if (!owner) return
  return (
    <Box>
      <ListItem alignItems="center">
        <ListItemAvatar>
          <Avatar alt={owner?.display_name} src={owner?.profile_image_url} />
        </ListItemAvatar>
        <ListItemText primary={owner?.display_name} />
        <Box display="flex" alignItems="center" sx={{ color: '#b72824' }}>
          <EmojiEventsIcon />
          <Typography variant="body2" component="span" display="flex" pl={0.5}>
            Owner
          </Typography>
        </Box>
      </ListItem>
      <Divider />
    </Box>
  )
}

export default OwnerDisplay
