import React, { useEffect, useState } from 'react'
import TextField from '@mui/material/TextField'
import Autocomplete from '@mui/material/Autocomplete'
import List from '@mui/material/List'
import Paper from '@mui/material/Paper'

import { useUser, useAppSettings, useUserIsOwner, useUserIsAdmin } from 'hooks'
import OwnerService from 'services/OwnerService'
import OwnerDisplay from './roles/OwnerDisplay'
import UserDisplay from './roles/UserDisplay'
import SetUserRoles from './roles/SetUserRoles'
import { UserServiceApi } from 'api/UserServiceApi'
import { Box, CircularProgress, ListItem } from '@mui/material'
import { styled } from '@mui/material/styles'

const UserRoles = () => {
  const user = useUser()
  const appSettings = useAppSettings()
  const isOwner = useUserIsOwner()
  const isAdmin = useUserIsAdmin()
  const [isUsersLoading, setIsUsersLoading] = useState(true)
  const [users, setUsers] = useState([])
  const [newUser, setNewUser] = useState({})
  const [mods, setMods] = useState({})
  const [searchUsername, { data: usernames = [], isLoading }] =
    UserServiceApi.useSearchUsernameMutation()

  useEffect(() => {
    if (appSettings && appSettings.owner) {
      OwnerService.getMods(appSettings.owner, data => {
        if (data && data.mod) {
          setMods(data.mod)
        }
      })
      OwnerService.getUserHasScopes(data => {
        const users = []
        data.forEach(doc => {
          const user = { ...doc.data() }
          users.push(user)
        })
        setUsers(users)
      })
    }
  }, [appSettings])

  useEffect(() => {
    if (users?.length > 0) {
      setIsUsersLoading(false)
    }
  }, [users])

  const handleUserSelect = (e, v) => {
    setNewUser(v)
  }

  const resetNewUser = () => {
    setNewUser({})
  }

  const getOwner = () => {
    return users.filter(u => `twitch:${u.id}` === appSettings.owner)[0]
  }

  return (
    <div>
      <div className="aside-header aside-roles">
        <span className="admin">User Management</span>
      </div>
      <div className="content-panel">
        <span className="heading-text">Roles and Permissions</span>
        <span className="paragraph">
          Update user roles and permissions for the Fence.gg site.
        </span>
        <Paper variant="outlined" sx={{ marginBottom: 3 }}>
          <List sx={{ width: '100%', bgcolor: 'background.paper', padding: 0 }}>
            <OwnerDisplay owner={getOwner()} />
          </List>
        </Paper>
        <Paper variant="outlined" sx={{ marginBottom: 3 }}>
          {isUsersLoading === false && (
            <List
              sx={{ width: '100%', bgcolor: 'background.paper', padding: 0 }}>
              {users.map((r, i) => (
                <UserDisplay key={r.id} user={r} userId={`twitch:${r.id}`} />
              ))}
            </List>
          )}
          {isUsersLoading === true && (
            <Box textAlign={'center'}>
              <CircularProgress size={'5rem'} />
            </Box>
          )}
        </Paper>
        {(isAdmin || isOwner) && (
          <Autocomplete
            disablePortal
            getOptionLabel={option => option.display_name}
            noOptionsText={'No Users Found'}
            loading={isLoading}
            onChange={(e, v) => {
              handleUserSelect(e, v)
            }}
            freeSolo
            onInputChange={(e, value) => {
              if (value.length >= 3) {
                searchUsername({
                  username: value
                })
              }
            }}
            id="combo-box-demo"
            options={
              usernames
                ? usernames.filter(
                    u => u.uid !== user.uid && u.uid !== appSettings.owner
                  )
                : []
            }
            renderInput={params => (
              <TextField {...params} label="Search User" />
            )}
          />
        )}
        {newUser?.id && (
          <Paper variant="outlined" sx={{ marginBottom: 3, marginTop: 3 }}>
            <List
              sx={{ width: '100%', bgcolor: 'background.paper', padding: 0 }}>
              <SetUserRoles
                user={newUser}
                userId={newUser.id}
                resetNewUser={resetNewUser}
              />
            </List>
          </Paper>
        )}
      </div>
    </div>
  )
}

export default UserRoles
