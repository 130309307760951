import Firebase from 'utils/Firebase'
import {
  getFirestore,
  collection,
  query,
  getDocs,
  onSnapshot,
  doc,
  orderBy,
  getCountFromServer,
  documentId,
  where
} from 'firebase/firestore'

const db = getFirestore(Firebase)

const StripeService = {
  async getChargesFirestore(userId) {
    return await getDocs(
      query(
        collection(db, 'transactions', userId, 'charges'),
        orderBy('created', 'desc')
      )
    )
  },
  async getInvoicesFirestore(userId) {
    return await getDocs(
      query(
        collection(db, 'transactions', userId, 'invoices'),
        orderBy('created', 'desc')
      )
    )
  },
  async getReferralsFirestore(userId) {
    return await getDocs(
      query(
        collection(db, 'transactions', userId, 'referrals'),
        orderBy('created', 'desc')
      )
    )
  },
  async getReferralActivePremium(users) {
    if (users) {
      const q = query(
        collection(db, 'usernames'),
        where(documentId(), 'in', users)
      )
      return await getCountFromServer(q).then(docs => docs.data().count)
    }
    return 0
  },
  async getAffiliateFirestore(accountId, callback) {
    //return await getDoc(query(doc(db, 'affiliates', accountId)))
    return onSnapshot(doc(db, 'affiliates', accountId), doc => {
      callback(doc?.data())
    })
  }
}

export default StripeService
