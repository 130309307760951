import { CircularProgress } from '@mui/material'
import { TournamentServiceApi } from 'api/TournamentServiceApi'
const { Link } = require('react-router-dom')

const PlayerLink = ({ player, className }) => {
  const { data } = TournamentServiceApi.useGetPlayersByIdsQuery({
    ids: parseInt(player, 10)
  })

  return data?.avatar ? (
    <Link
      className={className}
      to={`/player/${data?.display_name.replace(' ', '-').toLowerCase()}/${
        data?.id
      }`}>
      <img src={data?.avatar} />
      <span>{data?.display_name}</span>
    </Link>
  ) : (
    <div>
      <CircularProgress
        style={{
          color: '#7ab903',
          height: 20,
          width: 20
        }}
      />
    </div>
  )
}
export default PlayerLink
