import React, { useEffect } from 'react'
import { useUserData } from 'hooks'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormControl from '@mui/material/FormControl'
import FormLabel from '@mui/material/FormLabel'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import TextField from '@mui/material/TextField'
import Button from '@mui/material/Button'
import CircularProgress from '@mui/material/CircularProgress'
import OverlayService from 'services/OverlayService'
import Alert from '@mui/material/Alert'

const RankSettings = () => {
  const userData = useUserData()
  const [isLoading, setIsLoading] = React.useState(false)
  const [saveSuccess, setSaveSuccess] = React.useState(false)
  const [overlayLoaded, setOverlayLoaded] = React.useState(false)

  //also update defaults in chatbot command
  const [settings, setSettings] = React.useState({
    align: 'right',
    offset: 200
  })

  useEffect(() => {
    if (userData.overlayKey && overlayLoaded === false) {
      setOverlayLoaded(true)
      OverlayService.getOverlaySettings(userData.overlayKey, data => {
        if (data && data.data() && data.data().ranks) {
          setSettings({
            ...settings,
            ...data.data().ranks
          })
        }
      })
    }
  }, [userData])

  const handleChange = (event, value, name) => {
    event.persist()
    value = value || event.target.value
    setSettings({
      ...settings,
      [name]: name === 'offset' ? parseInt(value, 10) : value
    })
  }

  const updateSettings = () => {
    setIsLoading(true)
    OverlayService.setOverlaySettings(
      'ranks',
      settings,
      userData.overlayKey,
      data => {
        setIsLoading(false)
        setSaveSuccess(true)
        setTimeout(() => {
          setSaveSuccess(false)
        }, 3000)
      }
    )
  }

  return (
    <div className="widget-settings">
      <div className="aside-header aside-goons">
        <span className="rankings">Rank Settings</span>
      </div>
      <form
        action="/"
        method="POST"
        onSubmit={e => {
          e.preventDefault()
          updateSettings()
        }}>
        <div className="content-panel">
          <span className="paragraph">
            Configure your own personal settings for the rankings overlay and
            chatbot.
          </span>
          <span className="subhead">Overlay Settings</span>
          <div className="controls-group">
            {/* <FormControl>
              <FormLabel id="demo-row-radio-buttons-group-label">
                Show overlay on stream when !goons is typed in chat
              </FormLabel>
              <RadioGroup
                value={settings.overlay}
                onChange={(e, v) => {
                  handleChange(e, v, 'overlay')
                }}
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group">
                <FormControlLabel value="no" control={<Radio />} label="No" />
                <FormControlLabel value="yes" control={<Radio />} label="Yes" />
              </RadioGroup>
            </FormControl> */}

            <FormControl>
              <FormLabel id="demo-row-radio-buttons-group-label">
                Overlay Alignment
              </FormLabel>
              <RadioGroup
                value={settings.align}
                onChange={(e, v) => {
                  handleChange(e, v, 'align')
                }}
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group">
                <FormControlLabel
                  value="left"
                  control={<Radio />}
                  label="Left"
                />
                <FormControlLabel
                  value="right"
                  control={<Radio />}
                  label="Right"
                />
              </RadioGroup>
            </FormControl>
            <FormControl>
              <TextField
                id="outlined-basic"
                value={settings.offset}
                type="number"
                InputProps={{
                  inputProps: { min: '0', max: '1080', step: '1' }
                }}
                onChange={(e, v) => {
                  handleChange(e, v, 'offset')
                }}
                label="Top Offset (px)"
                variant="outlined"
              />
            </FormControl>
          </div>
          {/* <span className="subhead">Chatbot</span> */}
          {/* <div className="controls-group"> */}
          {/* <FormControl>
              <FormLabel id="demo-row-radio-buttons-group-label">
                Set !goons command permissions in chat
              </FormLabel>
              <RadioGroup
                value={settings.permission}
                onChange={(e, v) => {
                  handleChange(e, v, 'permission')
                }}
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group">
                <FormControlLabel
                  value="mod"
                  control={<Radio />}
                  label="Mods"
                />
                <FormControlLabel
                  value="vip"
                  control={<Radio />}
                  label="VIPs/Mods"
                />
                <FormControlLabel
                  value="any"
                  control={<Radio />}
                  label="Anyone"
                />
                <FormControlLabel
                  value="none"
                  control={<Radio />}
                  label="No one"
                />
              </RadioGroup>
            </FormControl> */}
          {/* <FormControl>
              <FormLabel id="demo-row-radio-buttons-group-label">
                Enable real-time sightings in chat
              </FormLabel>
              <RadioGroup
                value={settings.realtime}
                onChange={(e, v) => {
                  handleChange(e, v, 'realtime')
                }}
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group">
                <FormControlLabel value="no" control={<Radio />} label="No" />
                <FormControlLabel value="yes" control={<Radio />} label="Yes" />
              </RadioGroup>
            </FormControl> */}
          {/* <FormControl>
              <FormLabel id="demo-row-radio-buttons-group-label">
                Show usernames of goons sighters
              </FormLabel>
              <RadioGroup
                value={settings.usernames}
                onChange={(e, v) => {
                  handleChange(e, v, 'usernames')
                }}
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group">
                <FormControlLabel value="no" control={<Radio />} label="No" />
                <FormControlLabel value="yes" control={<Radio />} label="Yes" />
              </RadioGroup>
            </FormControl> */}
          {/* </div> */}
          {/* <span className="subhead">Discord</span>
          <div className="controls-group">
            <span className="paragraph">
              Visit the official Fence.gg discord, navigate to the
              #goons-sightings channel and click the follow button to add these
              alerts to your own personal discord.
            </span>
          </div> */}
          {saveSuccess && (
            <Alert severity="success" className="settings-alert-success">
              Rankings Settings updated.
            </Alert>
          )}
          <FormControl className="form-control">
            <Button variant="contained" type="submit" disabled={isLoading}>
              {isLoading && (
                <CircularProgress
                  style={{
                    color: '#000',
                    height: 20,
                    width: 20,
                    marginRight: 10
                  }}
                />
              )}
              {isLoading ? <span>Saving...</span> : <span>Save Settings</span>}
            </Button>
          </FormControl>
        </div>
      </form>
    </div>
  )
}

export default RankSettings
