import Firebase from 'utils/Firebase'
import { getAuth } from 'firebase/auth'
import { getFirestore } from 'firebase/firestore'
import { collection, onSnapshot, doc, query, where } from 'firebase/firestore'
import { getHostFunctions } from 'utils/GetHost'

const auth = getAuth()
const db = getFirestore(Firebase)

async function getMyUser(id, callback) {
  return onSnapshot(doc(db, 'users', id), doc => {
    if (typeof callback === 'function') {
      callback(doc)
    }
  })
}

async function getMyInvites(id, callback) {
  const unsubscribe = onSnapshot(doc(db, 'invites', id), doc => {
    if (typeof callback === 'function' && doc && doc.data()) {
      callback(doc.data())
    }
  })

  return { unsubscribe }
}

async function getPremiumUsers(callback) {
  const q = query(
    collection(db, 'users'),
    where('subscription.status', '==', 'active')
  )
  return onSnapshot(q, querySnapshot => {
    const docs = []
    querySnapshot.forEach(doc => {
      if (
        !doc.data().scopes?.includes('stripeTest') &&
        doc.data().subscription?.cancel_at_period_end !== true
      ) {
        docs.push({ ...doc.data(), id: doc.id })
      }
    })
    if (typeof callback === 'function') {
      callback(docs)
    }
  })
}

async function getUsersWithRuns(callback) {
  const q = query(collection(db, 'leagueRuns'))
  return onSnapshot(q, querySnapshot => {
    const docs = []
    querySnapshot.forEach(doc => {
      if (!docs.includes(doc.data().submittedBy)) {
        docs.push(doc.data().submittedBy)
      }
    })
    if (typeof callback === 'function') {
      callback(docs)
    }
  })
}

async function generateKickVerification(callback) {
  const token = await auth.currentUser?.getIdToken()
  const result = await fetch(
    getHostFunctions() + '/generateKickVerification?',
    {
      headers: {
        authorization: `Bearer ${token}`
      }
    }
  )
  if (result.status === 200) {
    result.json().then(res => {
      if (typeof callback === 'function') {
        callback(res)
      }
    })
  }
}

async function declineKickUsername(callback) {
  const token = await auth.currentUser?.getIdToken()
  const result = await fetch(getHostFunctions() + '/declineKickUsername?', {
    headers: {
      authorization: `Bearer ${token}`
    }
  })
  if (result.status === 200) {
    result.json().then(res => {
      if (typeof callback === 'function') {
        callback(res)
      }
    })
  }
}

async function confirmKickUsername(callback) {
  const token = await auth.currentUser?.getIdToken()
  const result = await fetch(getHostFunctions() + '/confirmKickUsername?', {
    headers: {
      authorization: `Bearer ${token}`
    }
  })
  if (result.status === 200) {
    result.json().then(res => {
      if (typeof callback === 'function') {
        callback(res)
      }
    })
  }
}

async function userIsKick(isKick, callback) {
  const token = await auth.currentUser?.getIdToken()
  const result = await fetch(
    getHostFunctions() + `/userIsKick?isKick=${isKick}`,
    {
      headers: {
        authorization: `Bearer ${token}`
      }
    }
  )
  if (result.status === 200) {
    result.json().then(res => {
      if (typeof callback === 'function') {
        callback(res)
      }
    })
  }
}

const UserService = {
  getMyUser,
  getPremiumUsers,
  getUsersWithRuns,
  getMyInvites,
  generateKickVerification,
  declineKickUsername,
  confirmKickUsername,
  userIsKick
}

export default UserService
