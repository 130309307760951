import React from 'react'

import Grid from '@mui/material/Grid'
import { Card as MuiCard, Box } from '@mui/material'
import CardContent from '@mui/material/CardContent'
import { AccountBalance as AccountBalanceIcon } from '@mui/icons-material'

const SingleBankAccount = ({ bankDetails }) => {
  return (
    <Grid container spacing={4}>
      <Grid item xs={12} md={6}>
        <Box
          className={`card-box is-private card-bank`}
          sx={{ cursor: 'pointer' }}>
          <MuiCard sx={{ flexGrow: 1, background: 'none' }}>
            <CardContent>
              <span className="card-type is-private">
                <AccountBalanceIcon /> {bankDetails?.bank_name}
              </span>
              <span className="card-number-label">Account Number</span>
              <span className="card-number">
                &bull;&bull;&bull;&bull; &bull;&bull;&bull;&bull;
                &bull;&bull;&bull;&bull; {bankDetails?.last4}
              </span>
              <span className="card-expiration">
                Routing {bankDetails?.routing_number}
              </span>
            </CardContent>
          </MuiCard>
        </Box>
      </Grid>
    </Grid>
  )
}

export default SingleBankAccount
