import moment from 'moment'
import { getTarkovMaps } from 'utils/tarkovMaps'
import Countdown from 'react-countdown'
import HowToLogin from '../../assets/img/howto-login.jpg'
import HowToOverlay from '../../assets/img/howto-overlay.jpg'
import HowToChatbot from '../../assets/img/howto-chatbot.jpg'
import HowToPremium from '../../assets/img/howto-premium.jpg'
import HowToStart from '../../assets/img/howto-start.jpg'
import { useEffect, useState } from 'react'
import { Button, Modal } from '@mui/material'
import { Box } from '@mui/system'
import { NavLink } from 'react-router-dom'
import {
  useAppIsLoggedIn,
  useUser,
  useUserIsPremium,
  useUserLeagueRun
} from 'hooks'
import LeagueService from 'services/LeagueService'

const LeagueTasks = ({
  historical,
  currentWeek,
  season,
  league,
  seasonData,
  tier
}) => {
  const tarkovMaps = getTarkovMaps()
  const [howToOpen, setHowToOpen] = useState(false)
  const [leagueRunsLoaded, setLeagueRunsLoaded] = useState(false)
  const [leagueRunsCount, setLeagueRunsCount] = useState(0)
  const [runLoading, setRunLoading] = useState(false)
  const user = useUser()
  const isLoggedIn = useAppIsLoggedIn()
  const isPremium = useUserIsPremium()
  const userLeagueRun = useUserLeagueRun()

  useEffect(() => {
    if (user.uid && leagueRunsLoaded === false) {
      setLeagueRunsLoaded(true)
      LeagueService.getMyLeagueRuns(user.uid, data => {
        setLeagueRunsCount(data.length)
      })
    }
  }, [user])

  const handleHowToClose = (e, r) => {
    setHowToOpen(false)
  }

  const renderer = ({ days, hours, minutes, seconds, completed }) => {
    const needsPad = value => {
      if (value > 9) {
        return false
      }
      return true
    }
    if (completed) {
      return 'Ended...'
    } else {
      return (
        <span>
          {needsPad(days) ? '0' + days : days}
          <span className="timer-label">:</span>
          {needsPad(hours) ? '0' + hours : hours}
          <span className="timer-label">:</span>
          {needsPad(minutes) ? '0' + minutes : minutes}
          <span className="timer-label">:</span>
          {needsPad(seconds) ? '0' + seconds : seconds}
        </span>
      )
    }
  }

  return (
    <div className="task-week">
      <div className={`task-header ${historical ? ' task-historical' : ''}`}>
        <span className="task-title-container">
          <span className="task-title">
            {historical
              ? seasonData?.display_name
                ? seasonData?.display_name
                : `Season ${season}`
              : currentWeek.title
              ? currentWeek.title
              : 'New Task'}
          </span>
          {!historical && (
            <span className="task-season">
              {seasonData?.subhead
                ? seasonData.subhead
                : `Season ${league.currentSeason} ${currentWeek.display_name}`}
            </span>
          )}
        </span>
        <span className="task-ends">
          {historical ? (
            <span>ENDED</span>
          ) : (
            <>
              {moment(currentWeek.starts.seconds * 1000).isAfter(moment()) ? (
                <span className="task-starts">
                  Starts In{' '}
                  <Countdown
                    renderer={renderer}
                    date={moment(currentWeek.starts.seconds * 1000).toDate()}
                  />
                </span>
              ) : (
                <span>
                  <Countdown
                    renderer={renderer}
                    date={moment(currentWeek.ends.seconds * 1000).toDate()}
                  />
                </span>
              )}
            </>
          )}
        </span>
      </div>
      {!historical && (
        <div className="task-details">
          <div className={`task-map task-map-${currentWeek.map}`}>
            <div className="task-map-label">
              {tarkovMaps[currentWeek.map].name}
            </div>
          </div>
          <div className="task-desc">
            {currentWeek.desc
              ? currentWeek.desc
              : 'This task has no description.'}
          </div>
        </div>
      )}

      {!historical && (
        <div className="task-objectives">
          <div className="task-objectives-title">Task Objectives</div>
          <div className="task-objectives-list">
            {league?.currentSeason === 'evasion-trios-2023' ? (
              <div>
                <div className="task-objectives-item">
                  <span className="task-objectives-label">
                    +10 Points for Boss Kills
                  </span>
                </div>
                <div className="task-objectives-item">
                  <span className="task-objectives-label">
                    +5 Points for PMC Kills
                  </span>
                </div>
                <div className="task-objectives-item">
                  <span className="task-objectives-label">
                    +2 Points for Spark Plugs
                  </span>
                </div>
                <div className="task-objectives-item">
                  <span className="task-objectives-label">
                    +2 Points for Packs of Milk
                  </span>
                </div>
                <div className="task-objectives-item">
                  <span className="task-objectives-label">
                    +1 Point for Scav or Guard Kills
                  </span>
                </div>
                <div className="task-objectives-item">
                  <span className="task-objectives-label">
                    +1 Point for Every 2,000 XP
                  </span>
                </div>
              </div>
            ) : (
              <div>
                {league.id === 'scav' && (
                  <div className="task-objectives-item">
                    <span className="task-objectives-label">
                      Extract with High Value Loot
                    </span>
                  </div>
                )}
                <div className="task-objectives-item">
                  <span className="task-objectives-label">
                    Extract with Found in Raid{' '}
                    {currentWeek.items[0].display_name}
                  </span>
                </div>
                <div className="task-objectives-item">
                  <span className="task-objectives-label">
                    Extract with Found in Raid{' '}
                    {currentWeek.items[1].display_name}
                  </span>
                </div>
                <div className="task-objectives-item">
                  <span className="task-objectives-label">
                    Eliminate PMC Operatives
                  </span>
                </div>
                {league.id === 'scav' && (
                  <div className="task-objectives-item">
                    <span className="task-objectives-label">
                      Eliminate Raiders and USEC Rogues
                    </span>
                  </div>
                )}
                {league.id !== 'scav' && (
                  <div className="task-objectives-item">
                    <span className="task-objectives-label">
                      Eliminate All Enemies
                    </span>
                  </div>
                )}
                {league.id !== 'duo' ? (
                  <div className="task-objectives-item">
                    <span className="task-objectives-label">
                      Survive and Extract
                    </span>
                  </div>
                ) : (
                  <div className="task-objectives-item">
                    <span className="task-objectives-label">
                      One Teammate Must Survive and Extract
                    </span>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      )}
      <div className="task-objectives">
        <div className="howto-buttons">
          {league?.currentSeason !== 'evasion-trios-2023' ? (
            <button
              className="btn btn-secondary"
              onClick={() => {
                setHowToOpen(true)
              }}>
              How it Works
            </button>
          ) : (
            <div>Your best 3 raids will make up your final score.</div>
          )}
          <Modal
            className="howto-modal"
            open={howToOpen}
            onClose={handleHowToClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description">
            <Box className="run-modal">
              <div className="aside-header aside-run">
                <span className="leagues">Fence.gg Leagues FAQ</span>
              </div>
              <div className="modal-content">
                <span className="subhead first" style={{ paddingTop: 15 }}>
                  What is Fence.gg?
                </span>
                <span className="paragraph grey">
                  We provide compeititve leagues and streaming tools for anyone
                  that livestreams the game Escape From Tarkov, providing
                  additional engaging content and cash prizes.
                  {league.id === 'scav' &&
                    'Scav League is free and you can join at any time.'}
                  {league.id === 'solo' &&
                    'Solos League requires Fence Premium and you can join at any time.'}
                  {league.id === 'duo' &&
                    'Duos League requires both members of the team to have Fence Premium, your team can join at any time.'}
                </span>
                {leagueRunsCount === 0 && !userLeagueRun.league && (
                  <div>
                    <span className="subhead">How do I get started?</span>
                    <div className="howto-getting-started">
                      {!isLoggedIn && (
                        <div className="howto-step">
                          <div className="howto-img">
                            <img src={HowToLogin} />
                          </div>
                          <span className="paragraph grey">
                            Login with Twitch
                          </span>
                        </div>
                      )}
                      <div className="howto-step">
                        <div className="howto-img">
                          <img src={HowToOverlay} />
                        </div>
                        <span className="paragraph grey">
                          Setup the Overlay
                        </span>
                      </div>
                      <div className="howto-step">
                        <div className="howto-img">
                          <img src={HowToChatbot} />
                        </div>
                        <span className="paragraph grey">
                          Connect the Chatbot
                        </span>
                      </div>
                      {league.premium && !isPremium && (
                        <div className="howto-step">
                          <div className="howto-img">
                            <img src={HowToPremium} />
                          </div>
                          <span className="paragraph grey">
                            Join Fence Premium
                          </span>
                        </div>
                      )}
                      <div className="howto-step">
                        <div className="howto-img">
                          <img src={HowToStart} />
                        </div>
                        <span className="paragraph grey">Start a Run</span>
                      </div>
                    </div>
                  </div>
                )}
                <span className="subhead">What are the league schedules?</span>
                <span className="paragraph grey">
                  Each league usually lasts 3-4 weeks. The map and task
                  objectives rotate each week during the active league.
                </span>
                <span className="subhead">How many runs can I do?</span>
                <span className="paragraph grey">
                  You can submit as many raids as you want and your best 3 will
                  make up your score for that week.
                </span>
                <span className="subhead">
                  What if I an unable to play for a part of the season?
                </span>
                <span className="paragraph grey">
                  Your lowest week from each season is always dropped from your
                  final score - if you don't like a map or are out of town, you
                  can still win!
                </span>
                <span className="subhead">How do I submit my runs?</span>
                <span className="paragraph grey">
                  To submit runs, you simply need to click the start run button
                  on this page once you have gone through all of the getting
                  started steps. When you start a run, you should see the Fence
                  overlay appear on your stream. When you are done with your
                  raid, you end the run on the Fence website and submit your
                  timestampped VOD link for that run. Once your run is
                  submitted, our admins will review it and your scores,
                  rankings, and overlays will update automatically.
                </span>
                <span className="subhead">How does the point system work?</span>
                {league.id === 'solo' && (
                  <span className="paragraph grey">
                    Our exact points algorithm is not public, however, you get
                    the most points for PMC kills, found-in-raid bonus items
                    extracted, and all other kills with diminishing value based
                    on their difficulty, scavs being the lowest. Your score also
                    has minor impact from other misc. data points from your end
                    of raid screens so don't forget to show and scroll through
                    those screens.
                  </span>
                )}
                {league.id === 'scav' && (
                  <span className="paragraph grey">
                    Our exact points algorithm is not public, however, you get
                    the most points for cost of items on you at extract,
                    followed by PMC kills, found-in-raid bonus items extracted,
                    and then rogue/raider kills. Any scav, boss or guard kills
                    that would lower your scav karma will also lower your points
                    for the raid. Your score also has minor impact from other
                    misc. data points from your end of raid screens so don't
                    forget to show and scroll through those screens.
                  </span>
                )}
                {league.id === 'duo' && (
                  <span className="paragraph grey">
                    Our exact points algorithm is not public, however, you get
                    the most points for PMC kills, found-in-raid bonus items
                    extracted, and all other kills with diminishing value based
                    on their difficulty, scavs being the lowest. Your score also
                    has minor impact from other misc. data points from your end
                    of raid screens so don't forget to show and scroll through
                    those screens.
                  </span>
                )}
                {league.id === 'duo' && (
                  <span className="paragraph grey">
                    For Duos League, each player gets a score for the raid and
                    the combined score is your team's score for that raid. If
                    one of the duo partners dies, their points will be penalized
                    for that run but not the points of the surviving duo
                    partner.
                  </span>
                )}
                {seasonData.howToLink && (
                  <span className="paragraph grey">
                    <NavLink to={seasonData.howToLink}>
                      Learn more about the current season
                    </NavLink>
                  </span>
                )}
                <div className="modal-controls">
                  <Button
                    className="btn btn-primary"
                    disabled={runLoading}
                    onClick={e => {
                      e.preventDefault()
                      setHowToOpen(false)
                    }}>
                    Close
                  </Button>
                </div>
              </div>
            </Box>
          </Modal>
        </div>
        <div className="task-objectives-title">Season Rewards</div>
        <div className="task-objectives-list task-rewards-list">
          {seasonData &&
            seasonData.prizes &&
            seasonData.prizes[tier.id] &&
            seasonData.prizes[tier.id].map((prize, i) => {
              return (
                <div
                  key={i}
                  className={
                    'league-prizes-place place-' +
                    (i === 0 ? 'first' : i === 1 ? 'second' : 'third')
                  }>
                  {prize.indexOf('Gifted') > 0 ? prize : `$${prize}`}
                </div>
              )
            })}
        </div>
        {league?.currentSeason === 'evasion-trios-2023' && (
          <div style={{ paddingTop: 10 }}>
            10 captains spots (5 NA and 5 EU) for the Evasion Trio Battles event
            will be given to top finishers in the qualifer based on the player's
            region as determined by the Evasion admins.
          </div>
        )}
        {league.id === 'duo' && (
          <span className="disclaimer" style={{ paddingTop: 5 }}>
            *Prizes will be split between team members.
          </span>
        )}
      </div>
    </div>
  )
}

export default LeagueTasks
