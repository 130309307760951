import { CircularProgress } from '@mui/material'
import { TournamentServiceApi } from 'api/TournamentServiceApi'
import moment from 'moment'
import { useCallback, useEffect, useState } from 'react'
import TeamLink from './TeamLink'
import TeamName from './TeamName'
const { Link } = require('react-router-dom')

const MatchHistory = ({ team1, team2 }) => {
  const [matches, setMatches] = useState([])
  const [events, setEvents] = useState([])
  const [teams, setTeams] = useState([])

  const [getMatchesApi] = TournamentServiceApi.useLazyGetMatchesByIdsQuery()

  const getMatchesAsync = useCallback(async () => {
    const asyncMatches = await Promise.all(
      [...team1?.matches, ...team2?.matches]
        .filter(function (item, pos, self) {
          return self.indexOf(item) == pos
        })
        .map(async match => {
          return await getMatchesApi({ ids: match }, true).unwrap()
        })
    )
    setMatches(asyncMatches)
  }, [team1, team2])

  useEffect(() => {
    if (team1?.matches?.length > 0 && team2?.matches?.length > 0) {
      getMatchesAsync()
    }
  }, [getMatchesAsync, team1, team2])

  const [getEventsApi] = TournamentServiceApi.useLazyGetEventsByIdsQuery()

  const getEventsAsync = useCallback(async m => {
    const asyncEvents = await Promise.all(
      [...m.map(match => match.event)]
        .filter(function (item, pos, self) {
          return self.indexOf(item) == pos
        })
        .map(async e => {
          return await getEventsApi({ ids: e }, true).unwrap()
        })
    )
    if (asyncEvents?.length > 0 && asyncEvents[0]?.length > 0) {
      setEvents([...asyncEvents[0]])
    } else {
      setEvents([...asyncEvents])
    }
  }, [])

  useEffect(() => {
    if (matches?.length > 0) {
      getEventsAsync(matches)
      getTeamsAsync(matches)
    }
  }, [getEventsAsync, matches])

  const [getTeamsApi] = TournamentServiceApi.useLazyGetTeamsByIdsQuery()

  const getTeamsAsync = useCallback(async m => {
    let teams = []
    m.forEach(match => {
      match.teams.forEach(team => {
        teams.push(team.id)
      })
    })

    const asyncTeams = await Promise.all(
      teams
        .filter(function (item, pos, self) {
          return self.indexOf(item) == pos
        })
        .map(async team => {
          return await getTeamsApi({ ids: team }, true).unwrap()
        })
    )
    setTeams([...asyncTeams])
  }, [])

  return matches ? (
    <div className="match-past-matches-outer">
      <div className="match-past-matches">
        <div className="match-past-team">
          <div className="match-past-team-header">
            <TeamLink team={team1} />
          </div>
          <div className="match-past-history">
            {matches?.length > 0 && events?.length > 0 && teams?.length > 0 ? (
              [...matches]
                .sort((a, b) => b.starts._seconds - a.starts._seconds)
                ?.filter(m => m.teams.filter(t => t.id === team1.id).length > 0)
                .map(match => {
                  return (
                    <Link
                      key={match.id}
                      to={`/match/${teams
                        ?.filter(t => t.id === match.teams[0].id)[0]
                        ?.display_name?.replace(/ /g, '-')
                        .toLowerCase()}-vs-${teams
                        ?.filter(t => t.id === match.teams[1].id)[0]
                        ?.display_name?.replace(/ /g, '-')
                        .toLowerCase()}-${events
                        ?.filter(e => e.id === match.event)[0]
                        ?.display_name?.replace(/ /g, '-')
                        .toLowerCase()}/${match.id}`}
                      className={`match-past-match ${
                        match.teams.filter(t => t.id === team1.id)[0].winner ===
                        true
                          ? 'winner'
                          : 'loser'
                      }`}>
                      <span className="match-past-match-info">
                        <span className="match-past-versus">
                          {
                            teams?.filter(
                              t =>
                                t.id ===
                                match.teams.filter(t => t.id !== team1.id)[0].id
                            )[0]?.display_name
                          }
                        </span>
                        <span className="match-past-date">
                          {moment(match.starts._seconds * 1000).format(
                            'MMM DD, YYYY'
                          )}
                          <br />
                          {moment(match.starts._seconds * 1000).format(
                            'hh:mm A'
                          )}
                        </span>
                      </span>
                      <span className="match-past-score">
                        {match.teams.filter(t => t.id === team1.id)[0].score}-
                        {match.teams.filter(t => t.id !== team1.id)[0].score}
                      </span>
                    </Link>
                  )
                })
            ) : (
              <div className="content-panel">
                <div className="content-loader">
                  <CircularProgress
                    style={{
                      color: '#7ab903',
                      height: 55,
                      width: 55
                    }}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="match-past-team">
          <div className="match-past-team-header">
            <TeamLink team={team2} />
          </div>
          <div className="match-past-history">
            {matches?.length > 0 && events?.length > 0 && teams?.length > 0 ? (
              [...matches]
                .sort((a, b) => b.starts._seconds - a.starts._seconds)
                ?.filter(m => m.teams.filter(t => t.id === team2.id).length > 0)
                .map(match => {
                  return (
                    <Link
                      key={match.id}
                      to={`/match/${teams
                        ?.filter(t => t.id === match.teams[0].id)[0]
                        ?.display_name?.replace(/ /g, '-')
                        .toLowerCase()}-vs-${teams
                        ?.filter(t => t.id === match.teams[1].id)[0]
                        ?.display_name?.replace(/ /g, '-')
                        .toLowerCase()}-${events
                        ?.filter(e => e.id === match.event)[0]
                        ?.display_name?.replace(/ /g, '-')
                        .toLowerCase()}/${match.id}`}
                      className={`match-past-match ${
                        match.teams.filter(t => t.id === team2.id)[0].winner ===
                        true
                          ? 'winner'
                          : 'loser'
                      }`}>
                      <span className="match-past-match-info">
                        <span className="match-past-versus">
                          {
                            teams?.filter(
                              t =>
                                t.id ===
                                match.teams.filter(t => t.id !== team2.id)[0].id
                            )[0]?.display_name
                          }
                        </span>
                        <span className="match-past-date">
                          {moment(match.starts._seconds * 1000).format(
                            'MMM DD, YYYY'
                          )}
                          <br />
                          {moment(match.starts._seconds * 1000).format(
                            'hh:mm A'
                          )}
                        </span>
                      </span>
                      <span className="match-past-score">
                        {match.teams.filter(t => t.id === team2.id)[0].score}-
                        {match.teams.filter(t => t.id !== team2.id)[0].score}
                      </span>
                    </Link>
                  )
                })
            ) : (
              <div className="content-panel">
                <div className="content-loader">
                  <CircularProgress
                    style={{
                      color: '#7ab903',
                      height: 55,
                      width: 55
                    }}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  ) : (
    <div className="content-panel">
      <div className="content-loader">
        <CircularProgress
          style={{
            color: '#7ab903',
            height: 55,
            width: 55
          }}
        />
      </div>
    </div>
  )
}
export default MatchHistory
