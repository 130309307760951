import React, { useEffect } from 'react'
import { useAppIsLoggedIn } from 'hooks'
import Banner from 'components/Banner'
import HistoryGoons from 'components/HistoryGoons'

const GoonsHistory = () => {
  const isLoggedIn = useAppIsLoggedIn()
  return (
    <>
      <Banner />
      <HistoryGoons />
    </>
  )
}

export default GoonsHistory
