import React, { useEffect } from 'react'
import TimeAgo from 'react-timeago'
import CircularProgress from '@mui/material/CircularProgress'
import Pagination from '@mui/material/Pagination'
import Alert from '@mui/material/Alert'
import Chip from '@mui/material/Chip'
import Badge from '@mui/material/Badge'
import { styled } from '@mui/material/styles'
import { useAppUnreadLeagueRuns } from 'hooks'
import LeagueService from 'services/LeagueService'
import LockIcon from '../assets/img/icon-lock.png'
import OpenGreenIcon from '../assets/img/icon-open-green.png'
import { useNavigate } from 'react-router-dom/dist'
import moment from 'moment'
import { LeagueServiceApi } from 'api/LeagueServiceApi'
import DeniedBy from './Leagues/Runs/DeniedBy'
import ApprovedBy from './Leagues/Runs/ApprovedBy'
import InReview from './Leagues/Runs/InReview'
import SubmittedBy from './Leagues/Runs/SubmittedBy'
import { UserServiceApi } from 'api/UserServiceApi'

const StyledBadge = styled(Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    right: 18,
    top: 16,
    padding: '0 4px',
    backgroundColor: '#e41a1a',
    color: '#000',
    fontFamily: '"Bender Bold"'
  }
}))

const PendingLeagueRuns = ({ approved, denied }) => {
  const runsPerPage = 8
  const unreadLeagueRuns = useAppUnreadLeagueRuns()
  const navigate = useNavigate()
  const [reviewRunLoading, setReviewRunLoading] = React.useState(false)
  const [reviewRunID, setReviewRunID] = React.useState('')
  const [reviewRunError, setReviewRunError] = React.useState(false)
  const [reviewRunMsg, setReviewRunMsg] = React.useState('')
  const [approvedCount, setApprovedCount] = React.useState(0)
  const [deniedCount, setDeniedCount] = React.useState(0)
  const [approvedRuns, setApprovedRuns] = React.useState([])
  const [deniedRuns, setDeniedRuns] = React.useState([])
  const [page, setPage] = React.useState(1)
  const [totalRuns, setTotalRuns] = React.useState(0)
  const [totalRunsLastWeek, setTotalRunsLastWeek] = React.useState(0)
  const [avgTotalResponseLastWeek, setAvgTotalResponseLastWeek] =
    React.useState(0)
  const [topVodders, setTopVodders] = React.useState([])
  const [reviewLeagueRunCall] = LeagueServiceApi.useReviewLeagueRunMutation()
  const [getUsername] = UserServiceApi.useLazyGetUsernameByIdCacheQuery()

  useEffect(() => {
    let listenerAll = LeagueService.getLeagueRunsSinceTime(
      moment().subtract(1, 'weeks').toDate(),
      async runs => {
        setTotalRunsLastWeek(runs.length)
        let totalRunsLastWeek = 0
        let totalSecsLastWeek = 0
        let vodders = {}
        runs.forEach(runData => {
          if (runData.approved || runData.denied) {
            vodders[runData.approvedBy] = vodders[runData.approvedBy] || 0
            vodders[runData.approvedBy] += 1
            totalRunsLastWeek += 1
            totalSecsLastWeek +=
              runData.updatedAt.seconds - runData.endedAt.seconds
          }
        })
        let vodderArr = await Promise.all(
          Object.keys(vodders).map(async key => {
            return {
              display_name: await getUsername({ id: key }, true)
                .unwrap()
                .then(user => user.display_name),
              count: vodders[key]
            }
          })
        )
        vodderArr.sort((a, b) => b.count - a.count)
        vodderArr.length = 3
        setTopVodders(vodderArr)
        setAvgTotalResponseLastWeek(
          parseFloat(totalSecsLastWeek / totalRunsLastWeek / 60 / 60).toFixed(2)
        )
      }
    )
    return () => {
      if (typeof listenerAll === 'function') {
        listenerAll()
      }
    }
  }, [getUsername])

  useEffect(() => {
    let listenerAppr = null
    let listenerDeny = null
    let listenerCnt = LeagueService.getLeagueRunsCount(count => {
      setTotalRuns(count)
    })
    let listenerApprCnt = LeagueService.getApprovedLeagueRunsCount(count => {
      setApprovedCount(count)
    })
    let listenerDenyCnt = LeagueService.getDeniedLeagueRunsCount(count => {
      setDeniedCount(count)
    })
    if (approved) {
      //add pagination eventually
      listenerAppr = LeagueService.getApprovedLeagueRuns(result => {
        setApprovedRuns(result)
      })
    } else if (denied) {
      //add pagination eventually
      listenerDeny = LeagueService.getDeniedLeagueRuns(result => {
        setDeniedRuns(result)
      })
    }
    return () => {
      if (typeof listenerAppr === 'function') {
        listenerAppr()
      }
      if (typeof listenerDeny === 'function') {
        listenerDeny()
      }
      if (typeof listenerCnt === 'function') {
        listenerCnt()
      }
      if (typeof listenerApprCnt === 'function') {
        listenerApprCnt()
      }
      if (typeof listenerDenyCnt === 'function') {
        listenerDenyCnt()
      }
    }
  }, [approved, denied])

  const reviewLeagueRun = async run => {
    if (reviewRunLoading === false) {
      setReviewRunID(run.id)
      setReviewRunLoading(true)
      await reviewLeagueRunCall({ id: run.id })
        .unwrap()
        .then(result => {
          setReviewRunID('')
          setReviewRunLoading(false)
          if (result.error) {
            setReviewRunMsg(result.error)
            setReviewRunError(true)
            setTimeout(() => {
              setReviewRunError(false)
              setReviewRunMsg('')
            }, 3000)
          }
        })
    }
  }

  const runsList = () => {
    if (approved) {
      return [...approvedRuns]
    } else if (denied) {
      return [...deniedRuns]
    } else {
      return [...unreadLeagueRuns]
    }
  }

  return (
    <div>
      <div className="aside-header aside-leagues">
        <span className="review">
          {approved ? 'Approved' : denied ? 'Denied' : 'Pending'} League Runs
        </span>
      </div>
      <div className="content-panel">
        <span className="heading-text">
          {approved
            ? 'Submitted league runs that were approved'
            : denied
            ? 'Submitted league runs that were denied'
            : 'Submitted league runs that need review'}
        </span>
        <span className="parapraph">
          When you click into a pending league run, it will temporarily lock it
          until you are finished reviewing it so that other admins know that you
          are actively reviewing it.
        </span>
        <span className="response-time response-avg">
          Total Runs Submitted:{' '}
          <span className="response-time-value">{totalRuns}</span>
        </span>
        <span className="response-time">
          Past Week:{' '}
          <span className="response-time-value">{totalRunsLastWeek}</span>
        </span>
        {/* <span className="response-time response-avg">
          Lifetime Avg. Response Time:{' '}
          <span className="response-time-value">{avgTotalResponse} hrs.</span>
        </span> */}
        <span className="response-time">
          Weekly Response Time:{' '}
          <span className="response-time-value">
            {avgTotalResponseLastWeek} hrs.
          </span>
        </span>
        <span className="top-vodders">
          {topVodders.map((vodder, i) => {
            return (
              <span
                key={i}
                className={`top-vodder ${
                  i === 0
                    ? 'place-first'
                    : i === 1
                    ? 'place-second'
                    : 'place-third'
                }`}>
                <span className="top-vodder-name">{vodder.display_name}</span>
                <span className="top-vodder-count">{vodder.count}</span>
              </span>
            )
          })}
        </span>
        <div className="runs-archive">
          {(approved || denied) && unreadLeagueRuns.length > 0 && (
            <StyledBadge
              badgeContent={unreadLeagueRuns.length}
              color="secondary"
              onClick={() => {
                navigate('/review')
              }}>
              <Chip
                label="Pending Review"
                component="a"
                onClick={e => {
                  e.preventDefault()
                }}
                href="/review"
                variant="outlined"
                clickable
              />
            </StyledBadge>
          )}
          {!approved && approvedCount > 0 && (
            <StyledBadge
              badgeContent={approvedCount}
              color="secondary"
              onClick={() => {
                navigate('/review/approved')
              }}>
              <Chip
                label="Approved Runs"
                component="a"
                onClick={e => {
                  e.preventDefault()
                }}
                href="/review/approved"
                variant="outlined"
                clickable
              />
            </StyledBadge>
          )}
          {!denied && deniedCount > 0 && (
            <StyledBadge
              badgeContent={deniedCount}
              color="secondary"
              onClick={() => {
                navigate('/review/denied')
              }}>
              <Chip
                label="Denied Runs"
                component="a"
                onClick={e => {
                  e.preventDefault()
                }}
                href="/review/denied"
                variant="outlined"
                clickable
              />
            </StyledBadge>
          )}
        </div>
        <div className="league-runs">
          {reviewRunError && <Alert severity="error">{reviewRunMsg}</Alert>}
          {runsList().length === 0 ? (
            <span className="no-competitors">
              There are no{' '}
              {approved ? 'approved' : denied ? 'denied' : 'pending'} league
              runs to review.
            </span>
          ) : (
            <div>
              <span className="subhead">
                {approved
                  ? 'Approved Runs:'
                  : denied
                  ? 'Denied Runs:'
                  : 'Pending Review'}
              </span>
              {runsList()
                .sort((a, b) => {
                  if (approved || denied) {
                    return b.updatedAt - a.updatedAt
                  }
                  return a.createdAt - b.createdAt
                })
                .slice(
                  (page - 1) * runsPerPage,
                  (page - 1) * runsPerPage + runsPerPage
                )
                .map((run, i) => {
                  return (
                    <div
                      key={i}
                      onClick={() => {
                        if (!run.locked) {
                          reviewLeagueRun(run)
                        }
                      }}
                      className={
                        'league-run' +
                        (run.locked ? ' league-run-locked' : '') +
                        (run.approved ? ' league-run-approved' : '') +
                        (run.denied ? ' league-run-denied' : '')
                      }>
                      <div className="league-run-status">
                        {run.approved === false && run.denied === true && (
                          <DeniedBy run={run} />
                        )}
                        {run.approved === true && run.denied !== true && (
                          <ApprovedBy run={run} />
                        )}
                        {run.approved === false &&
                          run.denied !== true &&
                          run.locked !== true && (
                            <span>
                              <span>Awaiting Review</span>
                            </span>
                          )}
                        {run.approved === false &&
                          run.denied !== true &&
                          run.locked === true && <InReview run={run} />}
                      </div>
                      <div className="league-run-details">
                        <div className="league-run-league">
                          <SubmittedBy
                            uid={run.submittedBy}
                            className={'run-submitted-by'}
                          />
                          <span className="run-season yellow">
                            {run.league === 'duo' ? 'Duos' : run.league} League
                            - Season {run.season} - Week {run.week}
                          </span>
                        </div>
                        <div className="league-run-info">
                          <div className="run-controls">
                            {reviewRunLoading && reviewRunID === run.id ? (
                              <CircularProgress
                                style={{
                                  color: '#7ab903',
                                  height: 20,
                                  width: 20
                                }}
                              />
                            ) : run.locked ? (
                              <img
                                className="run-open"
                                src={LockIcon}
                                alt={'Lock Icon'}
                              />
                            ) : (
                              <img
                                className="run-open"
                                src={OpenGreenIcon}
                                alt={'Unlocked'}
                              />
                            )}
                          </div>
                          <span className="run-created">
                            <TimeAgo date={run.createdAt} />
                          </span>
                        </div>
                      </div>
                      {run.denied === true && run.approved === false && (
                        <div className="run-reason">
                          <span>
                            Reason: <span>{run.reason}</span>
                          </span>
                        </div>
                      )}
                    </div>
                  )
                })}

              {runsList().length > runsPerPage && (
                <div className="pagination">
                  <Pagination
                    count={Math.ceil(runsList().length / runsPerPage)}
                    color="primary"
                    onChange={(e, value) => {
                      setPage(value)
                    }}
                  />
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default PendingLeagueRuns
