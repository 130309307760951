import React from 'react'
import { useParams } from 'react-router-dom'
import CurrentRankings from 'components/CurrentRankings'
import CoTW from 'components/CoTW'

const Rankings = () => {
  const params = useParams()
  return (
    <>
      <CoTW />
      <CurrentRankings params={params} />
    </>
  )
}

export default Rankings
