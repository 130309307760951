import React, { useCallback, useEffect } from 'react'
import { Link } from 'react-router-dom'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import GoonsService from 'services/GoonsService'
import SightingDisplay from './Goons/SightingDisplay'

const HistoryGoons = () => {
  const [goonsSightings, setGoonsSightings] = React.useState([])
  const [loadingSightings, setLoadingSightings] = React.useState(true)

  const getGoonsHistory = useCallback(async () => {
    const response = await GoonsService.getLatestSightings(data => {
      setGoonsSightings(data)
      setLoadingSightings(false)
    })
    return response
  }, [])

  useEffect(() => {
    const goonsHistory = getGoonsHistory()
    return () => {
      goonsHistory.then(response => (response ? response.unsubscribe() : null))
    }
  }, [getGoonsHistory])

  return (
    <div>
      <div className="aside-header aside-goons">
        <span className="goons">Goons Sightings</span>
      </div>
      <div className="content-panel">
        <span className="heading-text">
          Historical Goon Sightings by the Community
        </span>
        <span className="paragraph">
          View the latest Goon sighting reports over time and report your own
          Goon sightings!
        </span>
        <a
          href="https://dashboard.twitch.tv/extensions/ys2vhbzv0gbe4yjl4fyqv5tqqlzzh9-0.0.1"
          target="_blank"
          className="banner banner-goons">
          <div className="banner-text">
            <span className="title">NEW Goons Sighting Twitch Extension</span>
            <span className="desc">
              Keep your viewers on your stream by letting them see where the
              Goons were recently sighted right on your Twitch page!
            </span>
            <div className="btn btn-primary">Get the Extension</div>
          </div>
        </a>
        <div className="spot-goons">
          <span>See the Goons in game?</span>
          <Link to={'/report-goons'} className="btn btn-primary">
            Post a Sighting
          </Link>
        </div>
        {!loadingSightings ? (
          <TableContainer component={Paper} sx={{ backgroundColor: '#000' }}>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Map</TableCell>
                  <TableCell align="center">Sighted By</TableCell>
                  <TableCell align="right">When</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {goonsSightings.map((sighting, i) => (
                  <SightingDisplay key={i} sighting={sighting} />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <span className="paragraph">Loading Goon Sightings...</span>
        )}
      </div>
    </div>
  )
}

export default HistoryGoons
