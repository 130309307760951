export const getTarkovMaps = () => {
  return {
    customs: {
      name: 'Customs',
      img: 'https://fence.gg/assets/img/goons-map-customs.jpg'
    },
    lighthouse: {
      name: 'Lighthouse',
      img: 'https://fence.gg/assets/img/goons-map-lighthouse.jpg'
    },
    shoreline: {
      name: 'Shoreline',
      img: 'https://fence.gg/assets/img/goons-map-shoreline.jpg'
    },
    woods: {
      name: 'Woods',
      img: 'https://fence.gg/assets/img/goons-map-woods.jpg'
    },
    interchange: {
      name: 'Interchange',
      img: 'https://fence.gg/assets/img/goons-map-interchange.jpg'
    },
    factory: {
      name: 'Factory',
      img: 'https://fence.gg/assets/img/goons-map-factory.jpg'
    },
    streets: {
      name: 'Streets of Tarkov',
      img: 'https://fence.gg/assets/img/goons-map-streets.jpg'
    },
    reserve: {
      name: 'Reserve',
      img: 'https://fence.gg/assets/img/goons-map-reserve.jpg'
    }
  }
}
