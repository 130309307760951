import React, { useCallback, useEffect, useState } from 'react'
import { Link, NavLink, useLocation } from 'react-router-dom'
import Badge from '@mui/material/Badge'
import moment from 'moment'
import Countdown from 'react-countdown'
import Button from '@mui/material/Button'
import { setMobileNavOpen } from '../store/slice/appSlice'
import { useDispatch } from 'react-redux'
import Modal from '@mui/material/Modal'
import CircularProgress from '@mui/material/CircularProgress'
import Box from '@mui/material/Box'
import Alert from '@mui/material/Alert'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormControl from '@mui/material/FormControl'
import FormLabel from '@mui/material/FormLabel'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import TextField from '@mui/material/TextField'
import Menu from '@mui/material/Menu'
import Tooltip from '@mui/material/Tooltip'

import {
  useAppIsLoggedIn,
  useAppIsLoading,
  useUser,
  useUserLeagueRun,
  useAppSettings,
  useUserIsAdmin,
  useUserIsOwner,
  useLockedLeagueRun,
  useUserInvitesIncoming,
  useUserData,
  useUserInvitesAccepted
} from 'hooks'
import LoginButton from 'components/LoginButton'
import LeagueService from 'services/LeagueService'
import ReviewLockedRun from './ReviewLockedRun'
import { scrollToTop } from 'utils/ScrollToTop'

import MenuGreenLgIcon from '../assets/img/icon-menu-green-lg.png'
import WarningRedIcon from '../assets/img/icon-warning-red.png'
import WarningGreenIcon from '../assets/img/icon-warning-green.png'
import MailIcon from '../assets/img/icon-mail.png'
import Username from './Username'
import * as ga4 from 'utils/ga4'
import { LeagueServiceApi } from 'api/LeagueServiceApi'
import UserService from 'services/UserService'
import {
  setInvitesAccepted,
  setInvitesIncoming,
  setInvitesOutgoing
} from 'store/slice/authSlice'
import DuoInvite from './Notifications/DuoInvite'
import { UserServiceApi } from 'api/UserServiceApi'
import UserAvatar from './UserDisplay/UserAvatar'

const ResponsiveAppBar = () => {
  const invites = useUserInvitesIncoming()
  const duoPartner = useUserInvitesAccepted()
  const isLoggedIn = useAppIsLoggedIn()
  const appIsLoading = useAppIsLoading()
  const appSettings = useAppSettings()
  const isAdmin = useUserIsAdmin()
  const isOwner = useUserIsOwner()
  const user = useUser()
  const userData = useUserData()
  const userLeagueRun = useUserLeagueRun()
  const location = useLocation()
  const dispatch = useDispatch()
  const lockedLeagueRun = useLockedLeagueRun()
  const [leagueRunSettings, setLeagueRunSettings] = React.useState({
    twitchLink: '',
    twitchLink2: '',
    survived: ''
  })
  const [leagueRunLoading, setLeagueRunLoading] = React.useState(false)
  const [leagueRunError, setLeagueRunError] = React.useState(false)
  const [leagueRunsLoaded, setLeagueRunsLoaded] = React.useState(false)
  const [leagueRunsCount, setLeagueRunsCount] = React.useState(1)
  const [anchorEl, setAnchorEl] = React.useState(null)
  const [inviteLoading, setInviteLoading] = useState(false)
  const [acceptDuo] = LeagueServiceApi.useAcceptDuoPartnerMutation()
  const [declineDuo] = LeagueServiceApi.useDeclineDuoPartnerMutation()
  const [endLeagueRunCall] = LeagueServiceApi.useEndLeagueRunMutation()
  const [deleteLeagueRunCall] = LeagueServiceApi.useDeleteLeagueRunMutation()
  const [submitLeagueRunCall] = LeagueServiceApi.useSubmitLeagueRunMutation()
  const [getDuoPartner, { data: duoUsername }] =
    UserServiceApi.useLazyGetUsernameByIdCacheQuery()

  useEffect(() => {
    if (duoPartner) {
      getDuoPartner({ id: duoPartner }, true)
    }
  }, [getDuoPartner, duoPartner])

  const open = Boolean(anchorEl)

  const handleClick = event => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  const getInvites = useCallback(async () => {
    const response = await UserService.getMyInvites(user.uid, data => {
      if (data) {
        if (typeof data.incoming !== 'undefined') {
          dispatch(setInvitesIncoming(data.incoming))
        }
        if (typeof data.outgoing !== 'undefined') {
          dispatch(setInvitesOutgoing(data.outgoing))
        }
        if (typeof data.accepted !== 'undefined') {
          dispatch(setInvitesAccepted(data.accepted))
        }
      }
    })
    return response
  }, [dispatch, user.uid])

  useEffect(() => {
    if (user?.uid) {
      const invites = getInvites()
      return () => {
        invites.then(response => (response ? response.unsubscribe() : null))
      }
    }
  }, [getInvites, user.uid])

  const declineDuoPartner = async uid => {
    setInviteLoading(true)
    await declineDuo({ uid })
      .unwrap()
      .then(data => {
        setInviteLoading(false)
        ga4.sendEvent({
          category: 'Duo Partner',
          action: 'Decline',
          nonInteraction: false
        })
      })
  }

  const acceptDuoPartner = async uid => {
    setInviteLoading(true)
    await acceptDuo({ uid })
      .unwrap()
      .then(data => {
        setInviteLoading(false)
        ga4.sendEvent({
          category: 'Duo Partner',
          action: 'Accept',
          nonInteraction: false
        })
      })
  }

  useEffect(() => {
    ga4.init()
  }, [])

  useEffect(() => {
    setLeagueRunSettings({
      twitchLink: '',
      twitchLink2: '',
      survived: ''
    })
  }, [userLeagueRun])

  useEffect(() => {
    scrollToTop(250)
    ga4.sendPageview(location.pathname, location.pathname)
  }, [location])

  useEffect(() => {
    if (user.uid && leagueRunsLoaded === false) {
      ga4.setUserId(user.uid)
      setLeagueRunsLoaded(true)
      LeagueService.getMyLeagueRuns(user.uid, data => {
        setLeagueRunsCount(data.length)
      })
    }
  }, [user, leagueRunsLoaded])

  const renderer = ({ hours, minutes, seconds }) => {
    const needsPad = value => {
      if (value > 9) {
        return false
      }
      return true
    }
    return (
      <span>
        {needsPad(hours) ? '0' + hours : hours}
        <span className="timer-label">H</span>
        {needsPad(minutes) ? '0' + minutes : minutes}
        <span className="timer-label">M</span>
        {needsPad(seconds) ? '0' + seconds : seconds}
        <span className="timer-label">S</span>
      </span>
    )
  }

  const openMobileMenu = () => {
    dispatch(setMobileNavOpen(true))
  }

  const endLeagueRun = async () => {
    await endLeagueRunCall()
      .unwrap()
      .then(result => {
        setLeagueRunLoading(false)
        ga4.sendEvent({
          category: 'League Run',
          action: 'End',
          label: userLeagueRun.league,
          nonInteraction: false
        })
      })
  }

  const deleteLeagueRun = async () => {
    await deleteLeagueRunCall()
      .unwrap()
      .then(result => {
        setLeagueRunLoading(false)
        ga4.sendEvent({
          category: 'League Run',
          action: 'Delete',
          label: userLeagueRun.league,
          nonInteraction: false
        })
      })
  }

  const duoPartnerIsKick = () => {
    return duoUsername?.usingKick === true
  }

  const submitRun = async () => {
    const validateTwitch = link => {
      if (
        leagueRunSettings.twitchLink.indexOf(
          'https://www.twitch.tv/videos/'
        ) === 0 &&
        leagueRunSettings.twitchLink.indexOf('?t=') > 0
      ) {
        if (userLeagueRun.league === 'duo' && !duoPartnerIsKick()) {
          if (
            leagueRunSettings.twitchLink2.indexOf(
              'https://www.twitch.tv/videos/'
            ) === 0 &&
            leagueRunSettings.twitchLink2.indexOf('?t=') > 0
          ) {
            setLeagueRunError(false)
            return true
          } else {
            setLeagueRunError(true)
            return false
          }
        } else if (userLeagueRun.league === 'duo' && duoPartnerIsKick()) {
          if (
            leagueRunSettings.twitchLink2.indexOf('https://kick.com/video/') ===
              0 &&
            leagueRunSettings.timestampHours2 <= 59 &&
            leagueRunSettings.timestampHours2 >= 0 &&
            leagueRunSettings.timestampMinutes2 <= 59 &&
            leagueRunSettings.timestampMinutes2 >= 0 &&
            leagueRunSettings.timestampSeconds2 <= 59 &&
            leagueRunSettings.timestampSeconds2 >= 0
          ) {
            setLeagueRunError(false)
            return true
          } else {
            setLeagueRunError(true)
            return false
          }
        } else {
          setLeagueRunError(false)
          return true
        }
      }
      setLeagueRunError(true)
      return false
    }

    const validateKick = link => {
      if (
        leagueRunSettings.twitchLink.indexOf('https://kick.com/video/') === 0 &&
        leagueRunSettings.timestampHours <= 59 &&
        leagueRunSettings.timestampHours >= 0 &&
        leagueRunSettings.timestampMinutes <= 59 &&
        leagueRunSettings.timestampMinutes >= 0 &&
        leagueRunSettings.timestampSeconds <= 59 &&
        leagueRunSettings.timestampSeconds >= 0
      ) {
        if (userLeagueRun.league === 'duo' && duoPartnerIsKick()) {
          if (
            leagueRunSettings.twitchLink2.indexOf('https://kick.com/video/') ===
              0 &&
            leagueRunSettings.timestampHours2 <= 59 &&
            leagueRunSettings.timestampHours2 >= 0 &&
            leagueRunSettings.timestampMinutes2 <= 59 &&
            leagueRunSettings.timestampMinutes2 >= 0 &&
            leagueRunSettings.timestampSeconds2 <= 59 &&
            leagueRunSettings.timestampSeconds2 >= 0
          ) {
            setLeagueRunError(false)
            return true
          } else {
            setLeagueRunError(true)
            return false
          }
        } else if (userLeagueRun.league === 'duo' && !duoPartnerIsKick()) {
          if (
            leagueRunSettings.twitchLink2.indexOf(
              'https://www.twitch.tv/videos/'
            ) === 0 &&
            leagueRunSettings.twitchLink2.indexOf('?t=') > 0
          ) {
            setLeagueRunError(false)
            return true
          } else {
            setLeagueRunError(true)
            return false
          }
        } else {
          setLeagueRunError(false)
          return true
        }
      }
      setLeagueRunError(true)
      return false
    }

    if (userData.usingKick === true) {
      if (validateKick()) {
        await submitLeagueRunCall({
          twitchLink: `${leagueRunSettings.twitchLink}?t=${leagueRunSettings.timestampHours}h${leagueRunSettings.timestampMinutes}m${leagueRunSettings.timestampSeconds}s`,
          twitchLink2:
            userLeagueRun.league === 'duo'
              ? duoPartnerIsKick()
                ? `${leagueRunSettings.twitchLink2}?t=${leagueRunSettings.timestampHours2}h${leagueRunSettings.timestampMinutes2}m${leagueRunSettings.timestampSeconds2}s`
                : leagueRunSettings.twitchLink2
              : ''
        })
          .unwrap()
          .then(result => {
            setLeagueRunLoading(false)
            ga4.sendEvent({
              category: 'League Run',
              action: 'Submit',
              label: userLeagueRun.league,
              nonInteraction: false
            })
          })
      }
    } else {
      if (validateTwitch()) {
        await submitLeagueRunCall({
          twitchLink: leagueRunSettings.twitchLink,
          twitchLink2:
            userLeagueRun.league === 'duo'
              ? duoPartnerIsKick()
                ? `${leagueRunSettings.twitchLink2}?t=${leagueRunSettings.timestampHours2}h${leagueRunSettings.timestampMinutes2}m${leagueRunSettings.timestampSeconds2}s`
                : leagueRunSettings.twitchLink2
              : ''
        })
          .unwrap()
          .then(result => {
            setLeagueRunLoading(false)
            ga4.sendEvent({
              category: 'League Run',
              action: 'Submit',
              label: userLeagueRun.league,
              nonInteraction: false
            })
          })
      }
    }
  }

  const handleChange = (event, value, name) => {
    event.persist()
    value = value || event.target.value
    if (name === 'twitchLink') {
      if (userData.usingKick === true) {
        value = value.split('?')[0]
        if (value.indexOf('https://kick.com/video/') !== 0) {
          value = ''
        }
      } else {
        if (value.indexOf('https://www.twitch.tv/videos/') !== 0) {
          value = ''
        }
        if (value.indexOf('?t=') === -1) {
          value = ''
        }
      }
    }
    if (name === 'twitchLink2') {
      if (duoPartnerIsKick()) {
        value = value.split('?')[0]
        if (value.indexOf('https://kick.com/video/') !== 0) {
          value = ''
        }
      } else {
        if (value.indexOf('https://www.twitch.tv/videos/') !== 0) {
          value = ''
        }
        if (value.indexOf('?t=') === -1) {
          value = ''
        }
      }
    }
    if (
      name === 'timestampHours' ||
      name === 'timestampHours2' ||
      name === 'timestampMinutes' ||
      name === 'timestampMinutes2' ||
      name === 'timestampSeconds' ||
      name === 'timestampSeconds2'
    ) {
      let v = 0
      if (!/^\d+$/.test(value)) {
        value = ''
      }
      if (value.length > 2) {
        value = value.slice(0, 2)
      }
      if (value === '00') {
        value = 0
      }
      v = Math.floor(parseInt(value, 10))
      if (v > 59) {
        value = 59
      }
      if (v < 0) {
        value = 0
      }
    }
    setLeagueRunSettings({
      ...leagueRunSettings,
      [name]: value
    })
  }

  return (
    <div>
      <div className="header-container">
        <header className="wrapper">
          <Link to="/" className="logo">
            <span className="text">Fence.gg | FTL</span>
          </Link>
          {!appIsLoading && (
            <nav>
              {isLoggedIn ? (
                <div className="nav-items-right">
                  <div className="invites">
                    <Tooltip className="invites-badge" placement="bottom">
                      <Badge
                        className="invites-badge"
                        badgeContent={invites.length}
                        color="primary"
                        onClick={handleClick}>
                        <img src={MailIcon} alt={'Mail'} />
                      </Badge>
                    </Tooltip>
                    <Menu
                      disableScrollLock={true}
                      anchorEl={anchorEl}
                      id="account-menu"
                      open={open}
                      onClose={handleClose}
                      PaperProps={{
                        elevation: 0,
                        sx: {
                          'overflow': 'visible',
                          'filter': 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                          'mt': 1.5,
                          '& .MuiAvatar-root': {
                            width: 32,
                            height: 32,
                            ml: -0.5,
                            mr: 1
                          },
                          '&:before': {
                            content: '""',
                            display: 'block',
                            position: 'absolute',
                            top: 0,
                            right: 'calc(50% - 5px)',
                            width: 10,
                            height: 10,
                            bgcolor: 'background.paper',
                            transform: 'translateY(-50%) rotate(45deg)',
                            zIndex: 0
                          }
                        }
                      }}
                      transformOrigin={{
                        horizontal: 'center',
                        vertical: 'top'
                      }}
                      anchorOrigin={{
                        horizontal: 'center',
                        vertical: 'bottom'
                      }}>
                      <div className="invites-dropdown">
                        <span className="invite-title">Duos Invites</span>
                        {invites.length > 0 ? (
                          invites.map(invite => (
                            <DuoInvite
                              key={invite}
                              invite={invite}
                              inviteLoading={inviteLoading}
                              acceptDuoPartner={acceptDuoPartner}
                              declineDuoPartner={declineDuoPartner}
                            />
                          ))
                        ) : (
                          <span className="invite-desc">
                            You do not have any pending invites.
                          </span>
                        )}
                      </div>
                    </Menu>
                  </div>
                  <div className="avatar">
                    <div className="avatar-image">
                      <UserAvatar user={userData} />
                    </div>
                    <span>{user.displayName}</span>
                  </div>
                </div>
              ) : (
                <LoginButton />
              )}
              <span
                className="btn btn-menu"
                onClick={() => {
                  openMobileMenu()
                }}>
                <img src={MenuGreenLgIcon} alt={'Menu Icon'} />
              </span>
            </nav>
          )}
        </header>
      </div>
      {isLoggedIn && lockedLeagueRun.id && <ReviewLockedRun />}
      {appSettings.maintenance === true && (isAdmin || isOwner) && (
        <div className="maintenance-banner-container">
          <div className="wrapper">
            <span className="maintenance-banner-header">
              <img src={WarningRedIcon} alt={'Warning Icon'} />
              <span>Maintenance Mode is On</span>
            </span>
          </div>
        </div>
      )}
      {leagueRunsCount === 0 && !userLeagueRun.league && (
        <div className="get-started-banner-container">
          <div className="wrapper">
            <span className="get-started-banner-header">
              <span>Get Started With Fence.gg</span>
            </span>
            <div className="get-started-banner-steps">
              <div className="get-started-banner-steps-bg">
                <div className="get-started-banner-steps-bg-bar"></div>
              </div>
              <div className="get-started-banner-steps-content">
                <NavLink
                  to={
                    '/news/post/how-to-get-started-with-fencegg-tarkov-leagues'
                  }
                  className="get-started-banner-step">
                  <span className="get-started-banner-step-number">
                    <span>1</span>
                  </span>
                  <span className="get-started-banner-step-text">
                    Read the Guide
                  </span>
                </NavLink>
                <NavLink to={'/dashboard'} className="get-started-banner-step">
                  <span className="get-started-banner-step-number">
                    <span>2</span>
                  </span>
                  <span className="get-started-banner-step-text">
                    Setup Overlay/Chatbot
                  </span>
                </NavLink>
                <NavLink to={'/rankings'} className="get-started-banner-step">
                  <span className="get-started-banner-step-number">
                    <span>3</span>
                  </span>
                  <span className="get-started-banner-step-text">
                    Submit Your First Run
                  </span>
                </NavLink>
              </div>
            </div>
          </div>
        </div>
      )}

      {userLeagueRun.league && (
        <div className="league-run-banner-container">
          <div className="wrapper">
            <span className="league-run-banner-header">
              <img src={WarningGreenIcon} alt={'Warning Green'} />
              <span>League Run In Progress...</span>
            </span>
            <span className="league-run-banner-details">
              <span>
                {userLeagueRun.league === 'duo' ? 'Duos' : userLeagueRun.league}{' '}
                League &mdash; {userLeagueRun.tier} Tier &ndash; Week{' '}
                {userLeagueRun.week}{' '}
              </span>
            </span>
            <span className="league-run-banner-timer">
              {userLeagueRun.ended === true ? (
                <span className="ended">Ending...</span>
              ) : (
                <Countdown
                  renderer={renderer}
                  overtime={true}
                  date={moment(userLeagueRun.started).toDate()}
                />
              )}
            </span>
            {userLeagueRun.ended !== true ? (
              <div className="league-run-banner-cta">
                <Button
                  className="btn btn-primary btn-cancel"
                  onClick={e => {
                    e.preventDefault()
                    setLeagueRunLoading(true)
                    endLeagueRun()
                  }}>
                  {leagueRunLoading && (
                    <CircularProgress
                      style={{
                        color: '#000',
                        height: 15,
                        width: 15,
                        marginRight: 10
                      }}
                    />
                  )}
                  End Run
                </Button>
              </div>
            ) : (
              <Modal
                className="start-run-modal"
                disableEscapeKeyDown={true}
                open={userLeagueRun.ended}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description">
                <Box className="run-modal">
                  <div className="aside-header aside-run">
                    <span className="leagues">
                      Ending Your{' '}
                      {userLeagueRun.league === 'duo'
                        ? 'Duos'
                        : userLeagueRun.league}{' '}
                      League Run
                    </span>
                  </div>
                  <div className="modal-content">
                    {leagueRunSettings.survived === 'no' ? (
                      userLeagueRun.league === 'duo' ? (
                        <Alert severity="error">
                          Sorry, at least one of you must survive the duos run
                          to submit it to the league.
                        </Alert>
                      ) : (
                        <Alert severity="error">
                          Sorry, you must survive the {userLeagueRun.league} run
                          to submit it to the league.
                        </Alert>
                      )
                    ) : (
                      <Alert severity="info">
                        Please fill in the fields as accurately as possible!
                      </Alert>
                    )}
                    <div className="league-submission-form">
                      <FormControl>
                        <FormLabel id="scav-survived">
                          {userLeagueRun.league === 'duo' ? (
                            <span className="subhead green">
                              Did at least one of you survive your duos run?
                            </span>
                          ) : (
                            <span className="subhead green">
                              Did you survive the {userLeagueRun.league} run?
                            </span>
                          )}
                        </FormLabel>
                        <RadioGroup
                          value={leagueRunSettings.survived}
                          onChange={(e, v) => {
                            handleChange(e, v, 'survived')
                          }}
                          row
                          aria-labelledby="scav-survived"
                          name="row-radio-buttons-group">
                          <FormControlLabel
                            value="no"
                            control={<Radio />}
                            label="No"
                          />
                          <FormControlLabel
                            value="yes"
                            control={<Radio />}
                            label="Yes"
                          />
                        </RadioGroup>
                      </FormControl>
                      {leagueRunSettings.survived === 'yes' && (
                        <div>
                          <FormControl>
                            <FormLabel id="twitch-link">
                              <span className="subhead green">
                                Your{' '}
                                {userData.usingKick === true
                                  ? 'Kick'
                                  : 'Timestamped Twitch'}{' '}
                                VOD Link for the run
                              </span>
                              <span className="paragraph">
                                Please paste the{' '}
                                {userData.usingKick === true
                                  ? ''
                                  : 'timestamped'}{' '}
                                VOD link for the run you just completed.
                              </span>
                            </FormLabel>
                            <TextField
                              id="twitch-link"
                              onChange={(e, v) => {
                                handleChange(e, v, 'twitchLink')
                              }}
                              error={leagueRunError}
                              helperText={
                                userData.usingKick === true
                                  ? 'Please enter a valid Kick VOD link. ex. https://kick.com/video/b230f154-80cf-4940-a066-4a358779c345'
                                  : 'Please enter a valid Twitch VOD link WITH a ?t= timestamp. ex. https://www.twitch.tv/videos/794029068?t=00h01m37s'
                              }
                              value={leagueRunSettings.twitchLink}
                              placeholder={
                                userData.usingKick === true
                                  ? 'ex. https://kick.com/video/b230f154-80cf-4940-a066-4a358779c345'
                                  : 'ex. https://www.twitch.tv/videos/794029068?t=00h01m37s'
                              }
                              variant="outlined"
                            />
                          </FormControl>
                          {userData.usingKick === true && (
                            <FormControl>
                              <FormLabel id="twitch-link">
                                <span className="subhead green">
                                  Timestamp in VOD for your run
                                </span>
                                <span className="paragraph">
                                  Please type in the timestamp on the vod for
                                  when your run starts in the vod
                                </span>
                              </FormLabel>
                              <div className="vod-timestamp">
                                <TextField
                                  onChange={(e, v) => {
                                    handleChange(e, v, 'timestampHours')
                                  }}
                                  error={leagueRunError}
                                  helperText="Timestamp hours"
                                  value={leagueRunSettings.timestampHours}
                                  placeholder="Hours (ex. 03)"
                                  variant="outlined"
                                />
                                <TextField
                                  onChange={(e, v) => {
                                    handleChange(e, v, 'timestampMinutes')
                                  }}
                                  error={leagueRunError}
                                  helperText="Timestamp minutes"
                                  value={leagueRunSettings.timestampMinutes}
                                  placeholder="Minutes (ex. 03)"
                                  variant="outlined"
                                />
                                <TextField
                                  onChange={(e, v) => {
                                    handleChange(e, v, 'timestampSeconds')
                                  }}
                                  error={leagueRunError}
                                  helperText="Timestamp seconds"
                                  value={leagueRunSettings.timestampSeconds}
                                  placeholder="Seconds (ex. 03)"
                                  variant="outlined"
                                />
                              </div>
                            </FormControl>
                          )}

                          {userLeagueRun.league === 'duo' && (
                            <div>
                              <FormControl>
                                <FormLabel id="twitch-link">
                                  <span className="subhead green">
                                    Your Duo Partner's (
                                    <Username username={duoUsername} />)
                                    Timestamped Twitch VOD Link
                                  </span>
                                  <span className="paragraph">
                                    Please paste the timestamped VOD link for
                                    the run you just completed.
                                  </span>
                                </FormLabel>
                                <TextField
                                  id="twitch-link"
                                  onChange={(e, v) => {
                                    handleChange(e, v, 'twitchLink2')
                                  }}
                                  error={leagueRunError}
                                  helperText={
                                    duoUsername?.usingKick === true
                                      ? 'Please enter a valid Kick VOD link. ex. https://kick.com/video/b230f154-80cf-4940-a066-4a358779c345'
                                      : 'Please enter a valid Twitch VOD link WITH a ?t= timestamp. ex. https://www.twitch.tv/videos/794029068?t=00h01m37s'
                                  }
                                  value={leagueRunSettings.twitchLink2}
                                  placeholder={
                                    duoUsername?.usingKick === true
                                      ? 'ex. https://kick.com/video/b230f154-80cf-4940-a066-4a358779c345'
                                      : 'ex. https://www.twitch.tv/videos/794029068?t=00h01m37s'
                                  }
                                  variant="outlined"
                                />
                              </FormControl>
                              {duoUsername?.usingKick === true && (
                                <FormControl>
                                  <FormLabel id="twitch-link">
                                    <span className="subhead green">
                                      Timestamp in VOD for{' '}
                                      <Username username={duoUsername} />
                                      's run
                                    </span>
                                    <span className="paragraph">
                                      Please type in the timestamp on the vod
                                      for when their run starts in the vod
                                    </span>
                                  </FormLabel>
                                  <div className="vod-timestamp">
                                    <TextField
                                      onChange={(e, v) => {
                                        handleChange(e, v, 'timestampHours2')
                                      }}
                                      error={leagueRunError}
                                      helperText="Timestamp hours"
                                      value={leagueRunSettings.timestampHours2}
                                      placeholder="Hours (ex. 03)"
                                      variant="outlined"
                                    />
                                    <TextField
                                      onChange={(e, v) => {
                                        handleChange(e, v, 'timestampMinutes2')
                                      }}
                                      error={leagueRunError}
                                      helperText="Timestamp minutes"
                                      value={
                                        leagueRunSettings.timestampMinutes2
                                      }
                                      placeholder="Minutes (ex. 03)"
                                      variant="outlined"
                                    />
                                    <TextField
                                      onChange={(e, v) => {
                                        handleChange(e, v, 'timestampSeconds2')
                                      }}
                                      error={leagueRunError}
                                      helperText="Timestamp seconds"
                                      value={
                                        leagueRunSettings.timestampSeconds2
                                      }
                                      placeholder="Seconds (ex. 03)"
                                      variant="outlined"
                                    />
                                  </div>
                                </FormControl>
                              )}
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                    {leagueRunSettings.survived === 'yes' && (
                      <span className="disclaimer">
                        By clicking "Submit Run" you acknowledge that the Admins
                        reserve the final right to approve or deny your run
                        entries.
                      </span>
                    )}
                    <div className="modal-controls">
                      {leagueRunSettings.survived === 'no' && (
                        <Button
                          className="btn btn-primary btn-cancel"
                          disabled={leagueRunLoading}
                          onClick={e => {
                            e.preventDefault()
                            setLeagueRunLoading(true)
                            deleteLeagueRun()
                          }}>
                          {leagueRunLoading && (
                            <CircularProgress
                              style={{
                                color: '#000',
                                height: 15,
                                width: 15,
                                marginRight: 10
                              }}
                            />
                          )}
                          Delete Run
                        </Button>
                      )}
                      {leagueRunSettings.survived === 'yes' && (
                        <Button
                          className="btn btn-primary"
                          disabled={leagueRunLoading}
                          onClick={e => {
                            e.preventDefault()
                            submitRun()
                          }}>
                          {leagueRunLoading && (
                            <CircularProgress
                              style={{
                                color: '#000',
                                height: 15,
                                width: 15,
                                marginRight: 10
                              }}
                            />
                          )}
                          Submit Run
                        </Button>
                      )}
                    </div>
                  </div>
                </Box>
              </Modal>
            )}
          </div>
        </div>
      )}
    </div>
  )
}
export default ResponsiveAppBar
