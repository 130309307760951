import React, { useEffect, useState } from 'react'
import { TournamentServiceApi } from 'api/TournamentServiceApi'
import { CircularProgress } from '@mui/material'
import TournamentsListItem from './TournamentsListItem'
import moment from 'moment'

const TournamentsList = () => {
  const [events, setEvents] = useState([])
  const { data: tournaments, isLoading } =
    TournamentServiceApi.useGetEventsQuery()
  const { data: leagues, isLoading: isLoadingLeagues } =
    TournamentServiceApi.useGetLeagueDataQuery()

  useEffect(() => {
    let tournamentArr = []
    if (tournaments?.length > 0) {
      tournamentArr = [...tournaments]
    }
    let leagueArr = []
    leagues?.leagues?.forEach(league => {
      league?.seasons
        ?.filter(s => s.hidden !== true)
        ?.forEach(season => {
          let prize = 0
          season?.prizes?.yellow?.forEach(p => {
            prize += parseInt(p, 10)
          })
          leagueArr.push({
            display_name: `${league.display_name} League ${
              season?.display_name
                ? season?.display_name
                : `Season ${season.docId.replace('season-', '')}`
            }`,
            starts: season?.weeks[0]?.starts,
            ends: season?.weeks[season?.weeks?.length - 1]?.ends,
            eventList: moment(season?.weeks[0]?.starts).isAfter(moment())
              ? 'upcoming'
              : moment(season?.weeks[season?.weeks?.length - 1]?.ends).isBefore(
                  moment()
                )
              ? 'past'
              : 'ongoing',
            organizer: 24895,
            location: {
              display_name: 'Global'
            },
            type: 'Online',
            eventURL: moment(season?.weeks[0]?.ends).isBefore(moment())
              ? `/history/${league.display_name.toLowerCase()}/${season.docId.replace(
                  'season-',
                  ''
                )}`
              : `/rankings/${league.display_name.toLowerCase()}`,
            status: 'approved',
            game: 'tarkov',
            prizepool: {
              currency: 'USD',
              amount: prize
            },
            poster: `https://storage.googleapis.com/fence-gg.appspot.com/uploads/tournaments/events/ftl/event-${league.display_name.toLowerCase()}-league.jpg`
          })
        })
    })
    setEvents([...tournamentArr, ...leagueArr])
  }, [tournaments, leagues])

  return (
    <div>
      <div className="aside-header aside-tournaments">
        <span className="tournaments">Tournaments</span>
      </div>
      <div className="content-panel tournaments-panel">
        <div className="events-box">
          <div className="events-box-header">
            <span className="events-title">Ongoing Events</span>
          </div>
          {isLoading ? (
            <div className="content-loader">
              <CircularProgress
                style={{
                  color: '#7ab903',
                  height: 55,
                  width: 55
                }}
              />
            </div>
          ) : events?.filter(t => t.eventList === 'ongoing')?.length > 0 ? (
            <div className="events-box-content">
              {events
                ?.filter(t => t.eventList === 'ongoing')
                ?.map(tournament => {
                  return (
                    <TournamentsListItem
                      key={tournament?.id}
                      tournament={tournament}
                      type={'ongoing'}
                    />
                  )
                })}
            </div>
          ) : (
            <span className="no-results">No ongoing events.</span>
          )}
        </div>
        <div className="events-box">
          <div className="events-box-header">
            <span className="events-title">Upcoming Events</span>
          </div>
          {isLoading ? (
            <div className="content-loader">
              <CircularProgress
                style={{
                  color: '#7ab903',
                  height: 55,
                  width: 55
                }}
              />
            </div>
          ) : events?.filter(t => t.eventList === 'upcoming')?.length > 0 ? (
            <div className="events-box-content">
              {[...events]
                .sort(
                  (a, b) => moment(a.starts).unix() - moment(b.starts).unix()
                )
                ?.filter(t => t.eventList === 'upcoming')
                ?.map(tournament => {
                  return (
                    <TournamentsListItem
                      key={tournament?.id}
                      tournament={tournament}
                      type={'upcoming'}
                    />
                  )
                })}
            </div>
          ) : (
            <span className="no-results">No upcoming events.</span>
          )}
        </div>
        <div className="events-box">
          <div className="events-box-header">
            <span className="events-title">Past Events</span>
          </div>
          {isLoading ? (
            <div className="content-loader">
              <CircularProgress
                style={{
                  color: '#7ab903',
                  height: 55,
                  width: 55
                }}
              />
            </div>
          ) : events?.filter(t => t.eventList === 'past')?.length > 0 ? (
            <div className="events-box-content">
              {[...events]
                .sort(
                  (a, b) => moment(b.starts).unix() - moment(a.starts).unix()
                )
                ?.filter(t => t.eventList === 'past')
                ?.map(tournament => {
                  return (
                    <TournamentsListItem
                      key={tournament?.id}
                      tournament={tournament}
                      type={'ended'}
                    />
                  )
                })}
            </div>
          ) : (
            <span className="no-results">No past events.</span>
          )}
        </div>
      </div>
    </div>
  )
}

export default TournamentsList
