import React, { useState } from 'react'
import { useUserData, useUser } from 'hooks'
import AccountComponent from 'components/AccountComponent'

const Account = () => {
  const user = useUserData()
  const { user_id } = useUser()
  const [isPrivacy, setIsPrivacy] = useState(true)

  const togglePrivacy = () => {
    setIsPrivacy(!isPrivacy)
  }

  return (
    <>
      <style>
        {isPrivacy
          ? `.is-private {
    filter: blur(0.25rem) !important;
    transition: all 1s;
  }`
          : `.is-private {
    filter: blur(0rem) !important;
    transition: all 1s;
  }`}
      </style>
      <AccountComponent
        isPrivacy={isPrivacy}
        togglePrivacy={togglePrivacy}
        user={user}
        user_id={user_id}
      />
    </>
  )
}

export default Account
