import Firebase from 'utils/Firebase'
import { getDocs, getFirestore, limit, orderBy } from 'firebase/firestore'
import { collection, onSnapshot, where, query } from 'firebase/firestore'

const db = getFirestore(Firebase)

async function getLivestreams(callback) {
  const q = query(
    collection(db, 'livestreams'),
    where('type', '==', 'live'),
    orderBy('username.premium', 'desc'),
    orderBy('viewer_count', 'desc')
  )

  const unsubscribe = onSnapshot(q, querySnapshot => {
    const docs = []
    querySnapshot.forEach(doc => {
      docs.push({ ...doc.data(), id: doc.id })
    })
    if (typeof callback === 'function') {
      callback(docs)
    }
  })

  return { unsubscribe }
}

async function getLivestreamsMax(callback, max = null) {
  const q = query(
    collection(db, 'livestreams'),
    where('type', '==', 'live'),
    orderBy('username.premium', 'desc'),
    orderBy('viewer_count', 'desc'),
    limit(max)
  )

  const unsubscribe = onSnapshot(q, querySnapshot => {
    const docs = []
    querySnapshot.forEach(doc => {
      docs.push({ ...doc.data(), id: doc.id })
    })
    if (typeof callback === 'function') {
      callback(docs)
    }
  })

  return { unsubscribe }
}

async function getFeaturedLivestream(callback, game = 'Escape from Tarkov') {
  const q = query(
    collection(db, 'livestreams'),
    where('type', '==', 'live'),
    where('game_name', '==', game),
    where('username.premium', '==', true),
    orderBy('viewer_count', 'desc'),
    limit(1)
  )

  const unsubscribe = onSnapshot(q, querySnapshot => {
    const docs = []
    querySnapshot.forEach(doc => {
      docs.push({ ...doc.data(), id: doc.id })
    })
    if (typeof callback === 'function') {
      callback(docs)
    }
  })

  return { unsubscribe }
}

async function getLivestreamByUser(user, game = 'Escape from Tarkov') {
  const q = query(
    collection(db, 'livestreams'),
    where('type', '==', 'live'),
    where('user_login', '==', user),
    where('game_name', '==', game),
    limit(1)
  )

  const docs = []
  const unsubscribe = onSnapshot(q, querySnapshot => {
    querySnapshot.forEach(doc => {
      docs.push({ ...doc.data(), id: doc.id })
    })
  })

  return { unsubscribe, docs }
}

async function checkUserIsLive(user = null) {
  if (user) {
    const users = await getDocs(
      query(
        collection(db, 'livestreams'),
        where('user_login', '==', user),
        limit(1)
      )
    )
    const response = []
    users.forEach(user => {
      response.push({ ...user.data(), id: user.id })
    })
    return response
  }
  return false
}

const CommunityService = {
  getLivestreams,
  getLivestreamsMax,
  checkUserIsLive,
  getLivestreamByUser,
  getFeaturedLivestream
}

export default CommunityService
