import React, { useCallback, useEffect } from 'react'
import CommentDisplay from 'components/Comments/CommentDisplay'
import SubmitComment from 'components/Comments/SubmitComment'
import { useAppIsLoggedIn } from 'hooks'
import { CircularProgress } from '@mui/material'
import { useParams } from 'react-router-dom'
import TournamentService from 'services/TournamentService'

const EventComments = () => {
  const [postCommentsLoading, setPostCommentsLoading] = React.useState(true)
  const [storyComments, setStoryComments] = React.useState('')
  const isLoggedIn = useAppIsLoggedIn()
  const params = useParams()

  const getComments = useCallback(async () => {
    const response = await TournamentService.getCommentsByEventId(
      params.id,
      comments => {
        setStoryComments(comments)
        setPostCommentsLoading(false)
      }
    )
    return response
  }, [params.id])

  useEffect(() => {
    if (params?.id) {
      const comments = getComments()
      return () => {
        comments.then(response => (response ? response.unsubscribe() : null))
      }
    }
  }, [getComments, params.id])

  return (
    <div className="events-box events-comments">
      <div className="events-box-header">
        <span className="events-title">Comments</span>
      </div>
      <div className="news-comments">
        {postCommentsLoading ? (
          <div className="loader-centered">
            <CircularProgress
              style={{
                color: '#7ab903',
                height: 55,
                width: 55,
                marginRight: 10
              }}
            />
          </div>
        ) : storyComments.length === 0 ? (
          <div className="no-competitors">Be the first to post a commment.</div>
        ) : (
          <div className="story-comments">
            {[...storyComments]
              .sort((a, b) => a.createdAt - b.createdAt)
              .map((comment, i) => (
                <CommentDisplay comment={comment} i={i} key={i} />
              ))}
          </div>
        )}

        <div className="post-comment">
          {isLoggedIn ? (
            <SubmitComment
              storyProps={{
                id: params.id,
                category: 'tarkovarena',
                type: 'event'
              }}
            />
          ) : (
            <div className="no-competitors">
              Please login to post a comment.
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default EventComments
