import React, { useEffect, useState } from 'react'
import { useAppIsLoggedIn, useUserIsPremium } from 'hooks'
import SubscribeComponent from 'components/SubscribeComponent'
import { useLocation, useNavigate } from 'react-router-dom'
import { Grid } from '@mui/material'
import TrophyGreenIcon from '../assets/img/icon-trophy-green.png'
import AdminGreenIcon from '../assets/img/icon-admin-green.png'
import CashGreenIcon from '../assets/img/icon-cash-green.png'
import LoginButton from 'components/LoginButton'

const Subscribe = () => {
  const isLoggedIn = useAppIsLoggedIn()
  const isPremium = useUserIsPremium()
  const navigate = useNavigate()
  const [isUpgrading, setIsUpgrading] = useState(false)
  const location = useLocation()

  useEffect(() => {
    if (isPremium === true) {
      navigate('/account')
    }
  }, [isPremium])

  const isNewCardRedirect = () => {
    return location.search.indexOf('addCard=true') > 0
  }

  const isBypassPromo = () => {
    return location.search.indexOf('skipPromo=true') > 0
  }

  return (
    <>
      <div>
        <div className="aside-header aside-premium">
          <span className="premium">Get Fence.gg Premium</span>
        </div>
        {isUpgrading || isBypassPromo() || isNewCardRedirect() ? (
          <div className="content-panel">
            <SubscribeComponent newCard={isNewCardRedirect()} />
            {/* <div className="subscribe-buttons">
              <button
                href="#"
                onClick={e => {
                  e.preventDefault()
                  setIsUpgrading(false)
                }}
                className="btn btn-primary btn-cancel">
                Cancel Upgrade
              </button>
            </div> */}
          </div>
        ) : (
          <div className="content-panel subscribe">
            <div className="join-fence">
              {!isLoggedIn && <LoginButton text="Join Fence Now" />}
              {isLoggedIn && !isPremium && (
                <div className="upgrade-premium">
                  <span className="subhead">
                    You Have the Fence.gg Free Plan.
                  </span>
                  <a
                    href="#"
                    onClick={e => {
                      e.preventDefault()
                      setIsUpgrading(true)
                    }}
                    className="btn btn-login">
                    Upgrade to Fence Premium
                  </a>
                  <div>
                    <button
                      className="btn btn-primary btn-grey"
                      onClick={() => {
                        navigate('/account')
                      }}>
                      Transaction History
                    </button>
                  </div>
                </div>
              )}
            </div>
            <Grid className="value-props" container spacing={2}>
              <Grid className="value-prop" item xs={12}>
                <div className="value-prop-inner">
                  <div className="value-prop-icon-outer">
                    <div className="value-prop-icon">
                      <img src={TrophyGreenIcon} />
                    </div>
                  </div>
                  <div className="value-prop-text">
                    <span className="subhead">Competitive Leagues</span>
                    <span className="paragraph">
                      Compete in Escape From Tarkov leagues. Unlike other
                      tournaments, you can compete on your own time - during
                      your own stream schedule.
                    </span>
                  </div>
                </div>
              </Grid>
              <Grid className="value-prop" item xs={12}>
                <div className="value-prop-inner">
                  <div className="value-prop-icon-outer">
                    <div className="value-prop-icon">
                      <img src={AdminGreenIcon} />
                    </div>
                  </div>
                  <div className="value-prop-text">
                    <span className="subhead">Streaming Tools</span>
                    <span className="paragraph">
                      Boost your viewer interactivity and stream professionalism
                      with our custom overlays and chatbot.
                    </span>
                  </div>
                </div>
              </Grid>
              <Grid className="value-prop" item xs={12}>
                <div className="value-prop-inner">
                  <div className="value-prop-icon-outer">
                    <div className="value-prop-icon">
                      <img src={CashGreenIcon} />
                    </div>
                  </div>
                  <div className="value-prop-text">
                    <span className="subhead">Cash Prizes</span>
                    <span className="paragraph">
                      Win cash prizes every month by competing in leagues and
                      other custom Fence.gg events.
                    </span>
                  </div>
                </div>
              </Grid>
            </Grid>
            <div className="comparison-chart">
              <div className="comparison-desc">
                {/* <span className="subhead">Fence.gg Plans</span>
                <span className="paragraph">
                  Sign up for FREE and compete in the free leagues for cash
                  prizes while using the basic streaming overlays and chatbot to
                  elevate your Tarkov content. Premium subscribers will gain
                  access to exclusive leagues with larger prize pools while also
                  utilizing premium overlays and chatbot commands. Any fees
                  collected will help increase the prize pools and fund
                  development for the agressive roadmap of new features that we
                  have planned.
                </span> */}
              </div>
              <Grid className="comparison-chart-inner" container spacing={2}>
                <Grid className="plan-free" item xs={12} md={6} lg={6}>
                  <div className="plan-inner">
                    <span className="subhead">Standard</span>
                    <div className="plan-price">
                      <span>FREE</span>
                    </div>
                    <span className="plan-item">Compete in Leagues</span>
                    <span className="plan-item">Smaller Prize Pool</span>
                    <span className="plan-item">Basic Overlays</span>
                    <span className="plan-item">Basic Chatbot Commands</span>
                    <span className="plan-item">Get Exposure</span>
                  </div>
                </Grid>
                <Grid className="plan-premium" item xs={12} md={6} lg={6}>
                  <div className="plan-inner">
                    <span className="subhead">Fence Premium</span>
                    <div className="plan-price">
                      <span>$8.99/mo</span>
                    </div>
                    <span className="plan-item">Join Premium Leagues</span>
                    <span className="plan-item">Larger Prize Pools</span>
                    <span className="plan-item">Premium Overlays</span>
                    <span className="plan-item">Premium Chatbot Commands</span>
                    <span className="plan-item">Prize Bounties</span>
                    <span className="plan-item">Special Events</span>
                    <span className="plan-item">+ More</span>
                  </div>
                </Grid>
              </Grid>
            </div>
            <div className="join-fence">
              {!isLoggedIn && <LoginButton text="Join Fence Now" />}
              {isLoggedIn && !isPremium && (
                <div className="upgrade-premium">
                  <span className="subhead">
                    You Have the Fence.gg Free Plan.
                  </span>
                  <a
                    href="#"
                    onClick={e => {
                      e.preventDefault()
                      setIsUpgrading(true)
                    }}
                    className="btn btn-login">
                    Upgrade to Fence Premium
                  </a>
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    </>
  )
}

export default Subscribe
