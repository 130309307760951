import React from 'react'
import { useAppIsLoggedIn } from 'hooks'
import Banner from 'components/Banner'
import ProceedLogin from 'components/ProceedLogin'

const Proceed = () => {
  const isLoggedIn = useAppIsLoggedIn()
  return (
    <>
      <Banner />
      <ProceedLogin />
    </>
  )
}

export default Proceed
