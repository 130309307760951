import React from 'react'
import { useAppIsLoggedIn } from 'hooks'
import Banner from 'components/Banner'
import PendingLeagueRuns from 'components/PendingLeagueRuns'

const Review = ({ approved, denied }) => {
  const isLoggedIn = useAppIsLoggedIn()

  return (
    <>
      <Banner />
      <PendingLeagueRuns approved={approved} denied={denied} />
    </>
  )
}

export default Review
