import { useCallback, useEffect, useState } from 'react'
import Banner from 'components/Banner'
import Grid from '@mui/material/Grid'
import CommunityService from 'services/CommunityService'
import StreamDisplay from 'components/Streams/StreamDisplay'

const Community = () => {
  const [streams, setStreams] = useState([])

  const getStreams = useCallback(async () => {
    const response = await CommunityService.getLivestreams(docs => {
      setStreams(docs)
    })
    return response
  }, [])

  useEffect(() => {
    const streamers = getStreams()
    return () => {
      streamers.then(response => (response ? response.unsubscribe() : null))
    }
  }, [getStreams])

  return (
    <>
      <Banner />
      <div className="aside-header aside-streams">
        <span className="live">Community Streams</span>
      </div>
      <div className="content-panel">
        <span className="paragraph">
          Follow these live channels and support the Fence.gg community members!
        </span>
        {streams.length > 0 ? (
          <div>
            <Grid container spacing={2}>
              {streams.map(stream => (
                <StreamDisplay stream={stream} key={stream.user_login} />
              ))}
            </Grid>
          </div>
        ) : (
          <span className="no-competitors">
            There are no community members streaming at this time.
          </span>
        )}
      </div>
    </>
  )
}

export default Community
