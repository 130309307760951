import React, { useCallback } from 'react'
import moment from 'moment'
import Countdown from 'react-countdown'
import Username from './Username'
import { useEffect } from 'react'
import LeagueService from 'services/LeagueService'
import { useState } from 'react'
import CommunityService from 'services/CommunityService'
import { UserServiceApi } from 'api/UserServiceApi'
import { Box, CircularProgress } from '@mui/material'
import UsernameAvatar from './UsernameDisplay/UsernameAvatar'
import UsernameStreamLink from './UsernameDisplay/UsernameStreamLink'

const CoTW = () => {
  const [cotw, setCotw] = useState({})
  const [checkIsLive, setCheckIsLive] = useState(false)
  const [getUsername, { data: username, isLoading }] =
    UserServiceApi.useLazyGetUsernameByIdCacheQuery()

  useEffect(() => {
    if (cotw?.submittedBy) {
      getUsername(
        {
          id: cotw.submittedBy
        },
        true
      )
    }
  }, [getUsername, cotw.submittedBy])

  const COTW = useCallback(async () => {
    const response = await LeagueService.getHightestPointsRunThisWeek(
      moment().utcOffset('-0600', true).day(0).startOf('day').toDate(),
      moment()
        .utcOffset('-0600', true)
        .add(1, 'week')
        .day(0)
        .startOf('day')
        .toDate(),
      data => {
        let topRun = {}
        data.forEach(run => {
          if (
            run.points >
            (typeof topRun.points === 'undefined' ? 0 : topRun.points)
          ) {
            topRun = run
          }
        })
        setCotw(topRun)
      }
    )
    return response
  }, [])

  useEffect(() => {
    const getHighest = COTW()
    return () => {
      getHighest.then(response => (response ? response.unsubscribe() : null))
    }
  }, [COTW])

  useEffect(() => {
    const checkUserLive = async user => {
      const userLive = await CommunityService.checkUserIsLive(user)
      return typeof userLive.exists === 'function' && userLive.exists()
        ? true
        : false
    }

    checkUserLive(cotw.submittedBy).then(doc => {
      if (doc) {
        setCheckIsLive(true)
      } else {
        setCheckIsLive(false)
      }
    })

    return () => {}
  }, [cotw])

  const renderer = ({ days, hours, minutes, seconds }) => {
    const needsPad = value => {
      if (value > 9) {
        return false
      }
      return true
    }
    return (
      <span>
        {needsPad(days) ? '0' + days : days}
        <span className="timer-label">:</span>
        {needsPad(hours) ? '0' + hours : hours}
        <span className="timer-label">:</span>
        {needsPad(minutes) ? '0' + minutes : minutes}
        <span className="timer-label">:</span>
        {needsPad(seconds) ? '0' + seconds : seconds}
      </span>
    )
  }

  if (isLoading) {
    return (
      <div className="cotw-banner">
        <div className="aside-header aside-cotw">
          <span className="cotw desktop-only">Chad of the Week</span>
          <span className="cotw mobile-only">Weekly Chad</span>
          <span className="cotw-ends">
            <span className="desktop-only inline-block">Ends </span>{' '}
            <Countdown
              renderer={renderer}
              date={moment()
                .utcOffset('-0600', true)
                .add(1, 'week')
                .day(0)
                .startOf('day')
                .toDate()}
            />
          </span>
        </div>
        <div className="content-panel">
          <Box textAlign={'center'}>
            <CircularProgress size={'20px'} />
          </Box>
        </div>
      </div>
    )
  }

  return (
    <div className="cotw-banner">
      <div className="aside-header aside-cotw">
        <span className="cotw desktop-only">Chad of the Week</span>
        <span className="cotw mobile-only">Weekly Chad</span>
        <span className="cotw-ends">
          <span className="desktop-only inline-block">Ends </span>{' '}
          <Countdown
            renderer={renderer}
            date={moment()
              .utcOffset('-0600', true)
              .add(1, 'week')
              .day(0)
              .startOf('day')
              .toDate()}
          />
        </span>
      </div>
      {typeof cotw.submittedBy === 'undefined' ? (
        <div className="content-panel">
          <span className="no-competitors">
            Get featured here by submitting a raid with the highest points for
            the week.
          </span>
        </div>
      ) : (
        <>
          {isLoading === false && username?.login && (
            <div className="content-panel">
              <div className="cotw-streamer">
                <div className="cotw-avatar">
                  <UsernameStreamLink username={username}>
                    <UsernameAvatar username={username} />
                  </UsernameStreamLink>
                </div>
                <div className="cotw-details">
                  <span className="cotw-username">
                    <UsernameStreamLink username={username}>
                      <span className={checkIsLive ? 'is-live' : ''}>
                        <Username username={username} />
                      </span>
                    </UsernameStreamLink>
                  </span>
                  <span className="cotw-league">
                    {cotw.league} LEAGUE - SEASON {cotw.season} - WEEK{' '}
                    {cotw.week}
                  </span>
                  <span className="cotw-points">
                    {cotw.points} <span>PTS</span>
                  </span>
                </div>
              </div>
              <div className="cotw-ctas">
                <UsernameStreamLink username={username}>
                  <button className="btn btn-primary">Watch Live</button>
                </UsernameStreamLink>
                <a
                  href={cotw.twitchLink}
                  target="_blank"
                  rel="noreferrer"
                  className="cotw-vod">
                  <button className="btn btn-primary">Watch VOD</button>
                </a>
              </div>
            </div>
          )}
        </>
      )}
    </div>
  )
}

export default CoTW
