import { Button, CircularProgress, Box } from '@mui/material'
import Username from 'components/Username'
import { UserServiceApi } from 'api/UserServiceApi'
import { useEffect } from 'react'
import UsernameAvatar from 'components/UsernameDisplay/UsernameAvatar'
const InviteDisplay = ({
  invite,
  acceptDuoPartner,
  declineDuoPartner,
  inviteLoading
}) => {
  const [getUsername, { data: username, isLoading }] =
    UserServiceApi.useLazyGetUsernameByIdCacheQuery()

  useEffect(() => {
    if (invite) {
      getUsername({ id: invite }, true)
    }
  }, [getUsername, invite])

  if (isLoading) {
    return (
      <div className="invite-row">
        <Box textAlign={'center'}>
          <CircularProgress size={'20px'} />
        </Box>
      </div>
    )
  }
  return (
    <div className="invite-row">
      <div className="pending-invite">
        <div className="invite-user">
          <div className="invite-avatar">
            <UsernameAvatar username={username} />
          </div>
          <div className="invite-username">
            <span className="invite-display-name">
              <Username username={username} />
            </span>
            <span className="invite-status">(Pending)</span>
          </div>
        </div>
        <div className="invite-button">
          <Button
            className="btn btn-primary"
            disabled={inviteLoading}
            onClick={e => {
              e.preventDefault()
              acceptDuoPartner(invite)
            }}>
            {inviteLoading && (
              <CircularProgress
                style={{
                  color: '#000',
                  height: 15,
                  width: 15,
                  marginRight: 10
                }}
              />
            )}
            Accept
          </Button>
          <Button
            className="btn btn-primary btn-cancel"
            disabled={inviteLoading}
            onClick={e => {
              e.preventDefault()
              declineDuoPartner(invite)
            }}>
            {inviteLoading && (
              <CircularProgress
                style={{
                  color: '#000',
                  height: 15,
                  width: 15,
                  marginRight: 10
                }}
              />
            )}
            Decline
          </Button>
        </div>
      </div>
    </div>
  )
}

export default InviteDisplay
