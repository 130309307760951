import Username from 'components/Username'
import UsernameAvatar from 'components/UsernameDisplay/UsernameAvatar'
import UsernameStreamLink from 'components/UsernameDisplay/UsernameStreamLink'

const FeaturedStream = ({ featuredStream = null }) => {
  if (featuredStream) {
    return (
      <div className="featured-stream">
        <div className="iframe-wrapper">
          <div className="iframe-inner">
            <iframe
              src={`https://player.twitch.tv/?channel=${
                featuredStream?.user_login
              }&parent=${
                window.location.href.indexOf('localhost') > -1
                  ? 'localhost'
                  : 'fence.gg'
              }&muted=true&autoplay=true`}
              allowFullScreen
              title={`Twitch ${featuredStream?.user_login}`}></iframe>
          </div>
        </div>
        <div className="featured-details">
          <UsernameStreamLink username={featuredStream?.username}>
            <UsernameAvatar
              username={featuredStream?.username}
              className={'avatar'}
            />

            <span
              className={`username ${
                featuredStream?.username?.premium === true
                  ? 'premium-badge'
                  : ''
              }`}>
              <Username username={featuredStream?.username} />
            </span>
            <span className="viewers">{featuredStream?.viewer_count}</span>
          </UsernameStreamLink>
        </div>
      </div>
    )
  }
}

export default FeaturedStream
