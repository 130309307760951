import { configureStore } from '@reduxjs/toolkit'
import { setupListeners } from '@reduxjs/toolkit/query'
import authReducer from './slice/authSlice'
import appReducer from './slice/appSlice'
import { api } from '../api'
import { StripeApi } from 'api/Stripe'
import { LeagueServiceApi } from 'api/LeagueServiceApi'
import { TournamentServiceApi } from 'api/TournamentServiceApi'
import { UserServiceApi } from 'api/UserServiceApi'
import { StoryServiceApi } from 'api/StoryServiceApi'

export const store = configureStore({
  reducer: {
    auth: authReducer,
    app: appReducer,
    [api.reducerPath]: api.reducer,
    [StripeApi.reducerPath]: StripeApi.reducer,
    [LeagueServiceApi.reducerPath]: LeagueServiceApi.reducer,
    [TournamentServiceApi.reducerPath]: TournamentServiceApi.reducer,
    [UserServiceApi.reducerPath]: UserServiceApi.reducer,
    [StoryServiceApi.reducerPath]: StoryServiceApi.reducer
  },
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware().concat([
      api.middleware,
      StripeApi.middleware,
      LeagueServiceApi.middleware,
      TournamentServiceApi.middleware,
      UserServiceApi.middleware,
      StoryServiceApi.middleware
    ])
})

setupListeners(store.dispatch)
