import { useState, useEffect } from 'react'
import StripeService from 'services/StripeService'

export const useGetReferrals = ({ userId }) => {
  const [referralData, setReferralData] = useState({
    amount: '$0.00',
    referrals: [],
    active: []
  })

  useEffect(() => {
    if (userId) {
      StripeService.getReferralsFirestore(userId).then(async docs => {
        const rows = []
        const referralIds = []
        docs.forEach(doc => {
          const { user } = doc.data()
          rows.push({ ...doc.data() })
          if (!referralIds.includes(user)) {
            referralIds.push(user)
          }
        })
        if (rows.length > 0) {
          const active = await StripeService.getReferralActivePremium(
            referralIds
          )

          const count = rows.length
          const amount = `$${count.toFixed(2)}`
          setReferralData({ amount, referrals: rows, active })
        }
      })
    }
    return () => {}
  }, [userId])

  return referralData
}
