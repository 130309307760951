import React from 'react'
import { useAppIsLoggedIn } from 'hooks'
import { useParams } from 'react-router-dom'
import { Link } from 'react-router-dom'
import { Chip, CircularProgress } from '@mui/material'
import { useEffect } from 'react'
import SkullIcon from '../assets/img/icon-skull.png'
import RoundLoss from '../assets/img/icon-round-loss.png'
import FlagIcon from '../assets/img/icon-flag.png'
import { TournamentServiceApi } from 'api/TournamentServiceApi'
import TeamName from 'components/TeamName'
import TeamAvatar from 'components/TeamAvatar'
import moment from 'moment'
import { useCallback } from 'react'
import PlayerLink from 'components/PlayerLink'
import HeadToHead from 'components/HeadToHead'
import MatchHistory from 'components/MatchHistory'
import SubmitComment from 'components/Comments/SubmitComment'
import CommentDisplay from 'components/Comments/CommentDisplay'
import TournamentService from 'services/TournamentService'

const MatchPage = () => {
  const isLoggedIn = useAppIsLoggedIn()
  const params = useParams()
  const [postCommentsLoading, setPostCommentsLoading] = React.useState(true)
  const [storyComments, setStoryComments] = React.useState('')
  const [statsFilter, setStatsFilter] = React.useState(-1)
  const [isLoading, setIsLoading] = React.useState(true)
  const [teams, setTeams] = React.useState(true)

  const [getTeamsApi] = TournamentServiceApi.useLazyGetTeamsByIdsQuery()

  const { data: matches } = TournamentServiceApi.useGetMatchesByIdsQuery({
    ids: parseInt(params.id, 10)
  })

  const getTeamsAsync = useCallback(async () => {
    const asyncTeams = await Promise.all(
      matches?.teams.map(async team => {
        return await getTeamsApi({ ids: team.id }, true).unwrap()
      })
    )
    setTeams(asyncTeams)
  }, [matches?.teams])

  useEffect(() => {
    if (matches?.teams?.length > 0) {
      getTeamsAsync()
    }
  }, [getTeamsAsync, matches])

  const [getEvents, { data: tournament }] =
    TournamentServiceApi.useLazyGetEventsQuery()

  useEffect(() => {
    if (matches?.event) {
      getEvents({ ids: matches?.event }, true)
    }
  }, [getEvents, matches])

  useEffect(() => {
    if (teams?.length > 0) {
      setIsLoading(false)
    }
  }, [teams])

  const handleStatsFilter = index => {
    setStatsFilter(index)
  }

  const getComments = useCallback(async () => {
    const response = await TournamentService.getCommentsByMatchId(
      params.id,
      comments => {
        setStoryComments(comments)
        setPostCommentsLoading(false)
      }
    )
    return response
  }, [params.id])

  useEffect(() => {
    if (params?.id) {
      const comments = getComments()
      return () => {
        comments.then(response => (response ? response.unsubscribe() : null))
      }
    }
  }, [getComments, params.id])

  const getTotalMapRoundsWon = (map, team) => {
    let wins = 0
    matches?.results
      ?.filter(r => r.map === map)[0]
      ?.rounds?.map(round => {
        if (round.winner.team === team) {
          wins += 1
        }
      })
    return wins
  }

  const displayTeamBasedOnFilter = (filter, index, flag) => {
    if (filter === -1) {
      if (index === 0) {
        //all winner
        let team = teams?.filter(
          t => t.id === matches?.teams?.filter(t => t.winner === true)[0]?.id
        )[0]
        if (flag) {
          return team?.id
        } else {
          return <TeamName team={team} />
        }
      } else {
        //all loser
        let team = teams?.filter(
          t => t.id === matches?.teams?.filter(t => t.winner !== true)[0]?.id
        )[0]
        if (flag) {
          return team?.id
        } else {
          return <TeamName team={team} />
        }
      }
    } else {
      if (index === 0) {
        //map winner
        let team = teams?.filter(
          t => t.id === matches?.results[filter]?.winner?.team
        )[0]
        if (flag) {
          return team?.id
        } else {
          return <TeamName team={team} />
        }
      } else {
        //map loser
        let team = teams?.filter(
          t => t.id !== matches?.results[filter]?.winner?.team
        )[0]
        if (flag) {
          return team?.id
        } else {
          return <TeamName team={team} />
        }
      }
    }
  }

  const getPlayersFromMap = (map, team) => {
    let players = []
    if (map > -1) {
      matches?.results[map]?.rounds?.forEach(round => {
        round?.stats[team]?.forEach(player => {
          if (players.filter(p => p.id === player.p).length === 0) {
            players.push({ id: player.p, rating: 0 })
          }
          players.filter(p => p.id === player.p)[0].rating = matches?.results[
            map
          ]?.ratings.filter(r => r.id === player.p)[0]?.rating
        })
      })
      return players.sort((a, b) => b.rating - a.rating)
    } else {
      matches?.results?.forEach(m => {
        m?.rounds?.forEach(round => {
          round?.stats[team]?.forEach(player => {
            if (players.filter(p => p.id === player.p).length === 0) {
              players.push({ id: player.p, rating: 0 })
            }
            players.filter(p => p.id === player.p)[0].rating =
              matches?.ratings?.filter(r => r.id === player.p)[0]?.rating
          })
        })
      })
      return players.sort((a, b) => b.rating - a.rating)
    }
  }

  const getPlayerStatsFromMap = (map, team, p) => {
    let stats = {
      kills: 0,
      deaths: 0,
      diff: 0,
      kas: 0,
      ent: 0,
      cap: 0,
      rating: 0,
      rounds: 0
    }
    if (map > -1) {
      matches?.results[map]?.rounds?.forEach(round => {
        round?.stats[team]?.forEach(player => {
          if (player.p === p) {
            stats.rounds += 1
            stats.kills += player.k
            stats.deaths += player.d
            if (player.k > 0 || player.d === 0 || player.a > 0) {
              stats.kas += 1
            }
            stats.ent += player.e
            stats.cap += player.c
            stats.diff = stats.kills - stats.deaths
            stats.rating = matches?.results[map]?.ratings.filter(
              r => r.id === p
            )[0].rating
          }
        })
      })
      return stats
    } else {
      matches?.results?.forEach(m => {
        m.rounds?.forEach(round => {
          round?.stats[team]?.forEach(player => {
            if (player.p === p) {
              stats.rounds += 1
              stats.kills += player.k
              stats.deaths += player.d
              if (player.k > 0 || player.d === 0 || player.a > 0) {
                stats.kas += 1
              }
              stats.ent += player.e
              stats.cap += player.c
              stats.diff = stats.kills - stats.deaths
              stats.rating = matches?.ratings?.filter(r => r.id === p)[0].rating
            }
          })
        })
      })
      return stats
    }
  }

  return (
    <div>
      <div className="aside-header aside-news">
        <span className="events">
          {teams?.length > 0
            ? `${
                teams?.filter(t => t.id === matches?.teams[0]?.id)[0]
                  ?.display_name
              } 
            vs. 
            ${
              teams?.filter(t => t.id === matches?.teams[1]?.id)[0]
                ?.display_name
            }`
            : 'Loading Match...'}
        </span>
      </div>
      {isLoading ? (
        <div className="content-panel">
          <div className="content-loader">
            <CircularProgress
              style={{
                color: '#7ab903',
                height: 55,
                width: 55
              }}
            />
          </div>
        </div>
      ) : (
        <div>
          {tournament?.length > 0 &&
          teams?.length > 0 &&
          matches?.teams?.length > 0 ? (
            <div>
              <div className="match-header">
                <Link
                  to={`/event/${tournament[0]?.display_name
                    ?.replace(/ /g, '-')
                    ?.toLowerCase()}/${matches?.event}`}
                  className="match-header-title">
                  {tournament[0]?.display_name}
                </Link>
                <div className="match-header-info">
                  <Link
                    to={`/team/${teams
                      ?.filter(t => t.id === matches?.teams[0]?.id)[0]
                      ?.display_name.replace(' ', '-')
                      .toLowerCase()}/${matches?.teams[0]?.id}`}
                    className="match-header-team">
                    <TeamAvatar
                      className={'match-header-team-logo'}
                      team={
                        teams?.filter(t => t.id === matches?.teams[0]?.id)[0]
                      }
                    />
                    <span className="match-header-team-name">
                      {
                        teams?.filter(t => t.id === matches?.teams[0]?.id)[0]
                          ?.display_name
                      }
                    </span>
                  </Link>
                  <span className="match-header-results">
                    <span className="match-header-score">
                      <span
                        className={
                          matches?.teams[0]?.winner === true
                            ? 'winner'
                            : 'loser'
                        }>
                        {matches?.teams[0]?.score}
                      </span>
                      <span className="divider">-</span>
                      <span
                        className={
                          matches?.teams[1]?.winner === true
                            ? 'winner'
                            : 'loser'
                        }>
                        {matches?.teams[1]?.score}
                      </span>
                    </span>
                    <span className="match-header-round">
                      {matches?.roundLabel}, Best of {matches?.bestOf}
                    </span>
                    <span className="match-header-datetime">
                      {moment(matches?.starts?._seconds * 1000).format(
                        'MMMM DD, YYYY h:mm A'
                      )}
                    </span>
                  </span>
                  <Link
                    to={`/team/${teams
                      ?.filter(t => t.id === matches?.teams[1]?.id)[0]
                      ?.display_name.replace(' ', '-')
                      .toLowerCase()}/${matches?.teams[1]?.id}`}
                    className="match-header-team">
                    <TeamAvatar
                      className={'match-header-team-logo'}
                      team={
                        teams?.filter(t => t.id === matches?.teams[1]?.id)[0]
                      }
                    />
                    <span className="match-header-team-name">
                      {
                        teams?.filter(t => t.id === matches?.teams[1]?.id)[0]
                          ?.display_name
                      }
                    </span>
                  </Link>
                </div>
              </div>
              <div className="content-panel">
                <div className="match-two-up">
                  <div className="match-completed">Match Ended</div>
                  <a
                    className="match-vod-link"
                    href={matches.vodURL}
                    target="_blank">
                    Watch Replay
                  </a>
                </div>
                <div className="events-box events-standings">
                  <div className="events-box-header">
                    <span className="events-title">Match Summary</span>
                  </div>
                  {matches?.results?.map(result => {
                    return (
                      <div className="round-map">
                        <div className={`round-map-img ${result.map}`}>
                          {result.map === 'bay5' && <span>B</span>}
                          {result.map === 'equator' && <span>E</span>}
                          {result.map === 'airpit' && <span>A</span>}
                          {result.map === 'sawmill' && <span>S</span>}
                        </div>
                        <div className="round-map-details">
                          <div className="round-map-round">
                            <div className="round-map-team">
                              <TeamAvatar
                                className="round-map-team-img"
                                team={
                                  teams?.filter(
                                    t => t.id === matches?.teams[0]?.id
                                  )[0]
                                }
                              />
                            </div>
                            <div className="round-map-team">
                              <TeamAvatar
                                className="round-map-team-img"
                                team={
                                  teams?.filter(
                                    t => t.id === matches?.teams[1]?.id
                                  )[0]
                                }
                              />
                            </div>
                          </div>
                          {result?.rounds?.map((round, i) => {
                            return (
                              <div className="round-map-round">
                                <span className="round-map-number">
                                  {i + 1}
                                </span>
                                <div className="round-map-team">
                                  {round?.winner?.team ===
                                  matches?.teams[0]?.id ? (
                                    round?.winner?.type === 'kill' ? (
                                      <img src={SkullIcon} />
                                    ) : (
                                      <img src={FlagIcon} />
                                    )
                                  ) : (
                                    <img src={RoundLoss} />
                                  )}
                                </div>
                                <div className="round-map-team">
                                  {round?.winner?.team ===
                                  matches?.teams[1]?.id ? (
                                    round?.winner?.type === 'kill' ? (
                                      <img src={SkullIcon} />
                                    ) : (
                                      <img src={FlagIcon} />
                                    )
                                  ) : (
                                    <img src={RoundLoss} />
                                  )}
                                </div>
                              </div>
                            )
                          })}
                          <div className="round-map-round">
                            <span className="round-map-number">Final</span>
                            <div
                              className={`round-map-team round-map-final ${
                                result.winner.team === matches?.teams[0]?.id
                                  ? 'winner'
                                  : 'loser'
                              }`}>
                              <span>
                                {getTotalMapRoundsWon(
                                  result.map,
                                  matches?.teams[0]?.id
                                )}
                              </span>
                            </div>
                            <div
                              className={`round-map-team round-map-final ${
                                result.winner.team === matches?.teams[1]?.id
                                  ? 'winner'
                                  : 'loser'
                              }`}>
                              <span>
                                {getTotalMapRoundsWon(
                                  result.map,
                                  matches?.teams[1]?.id
                                )}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    )
                  })}
                </div>
                <div className="events-box events-standings">
                  <div className="events-box-header">
                    <span className="events-title">Match Stats</span>
                  </div>
                  <div className="events-stats-filters">
                    <Chip
                      className="events-stats-filter"
                      label="All"
                      variant={statsFilter !== -1 ? 'outlined' : 'filled'}
                      onClick={() => handleStatsFilter(-1)}
                    />
                    {matches?.results?.map((result, i) => {
                      return (
                        <Chip
                          className="events-stats-filter"
                          label={result.map}
                          variant={statsFilter !== i ? 'outlined' : 'filled'}
                          onClick={() => handleStatsFilter(i)}
                        />
                      )
                    })}
                  </div>
                  {matches?.teams?.map((team, ti) => {
                    return (
                      <div>
                        <div className="match-stats-header">
                          <span className="match-stats-label">
                            {displayTeamBasedOnFilter(statsFilter, ti)}
                          </span>
                          <span className="match-stats-label">K-D</span>
                          <span className="match-stats-label">+/-</span>
                          <span className="match-stats-label">KAS</span>
                          <span className="match-stats-label">SR</span>
                          <span className="match-stats-label">ENT</span>
                          <span className="match-stats-label">CAP</span>
                          <span className="match-stats-label">Rating</span>
                        </div>
                        <div>
                          {getPlayersFromMap(
                            statsFilter,
                            displayTeamBasedOnFilter(statsFilter, ti, true)
                          )?.length > 0 ? (
                            getPlayersFromMap(
                              statsFilter,
                              displayTeamBasedOnFilter(statsFilter, ti, true)
                            )?.map(player => {
                              return (
                                <div className="match-stats-row">
                                  <span className="match-stats-label">
                                    <div className="match-stats-user">
                                      <PlayerLink player={player.id} />
                                    </div>
                                  </span>
                                  <span className="match-stats-label">
                                    {
                                      getPlayerStatsFromMap(
                                        statsFilter,
                                        displayTeamBasedOnFilter(
                                          statsFilter,
                                          ti,
                                          true
                                        ),
                                        player.id
                                      )?.kills
                                    }
                                    -
                                    {
                                      getPlayerStatsFromMap(
                                        statsFilter,
                                        displayTeamBasedOnFilter(
                                          statsFilter,
                                          ti,
                                          true
                                        ),
                                        player.id
                                      )?.deaths
                                    }
                                  </span>
                                  <span
                                    className={`match-stats-label ${
                                      getPlayerStatsFromMap(
                                        statsFilter,
                                        displayTeamBasedOnFilter(
                                          statsFilter,
                                          ti,
                                          true
                                        ),
                                        player.id
                                      )?.diff > 0
                                        ? 'winner'
                                        : getPlayerStatsFromMap(
                                            statsFilter,
                                            displayTeamBasedOnFilter(
                                              statsFilter,
                                              ti,
                                              true
                                            ),
                                            player.id
                                          )?.diff < 0
                                        ? 'loser'
                                        : ''
                                    }`}>
                                    {getPlayerStatsFromMap(
                                      statsFilter,
                                      displayTeamBasedOnFilter(
                                        statsFilter,
                                        ti,
                                        true
                                      ),
                                      player.id
                                    )?.diff > 0
                                      ? '+'
                                      : ''}
                                    {
                                      getPlayerStatsFromMap(
                                        statsFilter,
                                        displayTeamBasedOnFilter(
                                          statsFilter,
                                          ti,
                                          true
                                        ),
                                        player.id
                                      )?.diff
                                    }
                                  </span>
                                  <span className="match-stats-label">
                                    {(
                                      (getPlayerStatsFromMap(
                                        statsFilter,
                                        displayTeamBasedOnFilter(
                                          statsFilter,
                                          ti,
                                          true
                                        ),
                                        player.id
                                      )?.kas /
                                        getPlayerStatsFromMap(
                                          statsFilter,
                                          displayTeamBasedOnFilter(
                                            statsFilter,
                                            ti,
                                            true
                                          ),
                                          player.id
                                        )?.rounds) *
                                      100
                                    ).toFixed(0)}
                                    %
                                  </span>
                                  <span className="match-stats-label">
                                    {(
                                      ((getPlayerStatsFromMap(
                                        statsFilter,
                                        displayTeamBasedOnFilter(
                                          statsFilter,
                                          ti,
                                          true
                                        ),
                                        player.id
                                      )?.rounds -
                                        getPlayerStatsFromMap(
                                          statsFilter,
                                          displayTeamBasedOnFilter(
                                            statsFilter,
                                            ti,
                                            true
                                          ),
                                          player.id
                                        )?.deaths) /
                                        getPlayerStatsFromMap(
                                          statsFilter,
                                          displayTeamBasedOnFilter(
                                            statsFilter,
                                            ti,
                                            true
                                          ),
                                          player.id
                                        )?.rounds) *
                                      100
                                    ).toFixed(0)}
                                    %
                                  </span>
                                  <span className="match-stats-label">
                                    {
                                      getPlayerStatsFromMap(
                                        statsFilter,
                                        displayTeamBasedOnFilter(
                                          statsFilter,
                                          ti,
                                          true
                                        ),
                                        player.id
                                      )?.ent
                                    }
                                  </span>
                                  <span className="match-stats-label">
                                    {
                                      getPlayerStatsFromMap(
                                        statsFilter,
                                        displayTeamBasedOnFilter(
                                          statsFilter,
                                          ti,
                                          true
                                        ),
                                        player.id
                                      )?.cap
                                    }
                                  </span>
                                  <span className="match-stats-label">
                                    {getPlayerStatsFromMap(
                                      statsFilter,
                                      displayTeamBasedOnFilter(
                                        statsFilter,
                                        ti,
                                        true
                                      ),
                                      player.id
                                    )?.rating.toFixed(2)}
                                  </span>
                                </div>
                              )
                            })
                          ) : (
                            <span className="match-stats-no-results">
                              Match stats unavailable.
                            </span>
                          )}
                        </div>
                      </div>
                    )
                  })}
                </div>
                <div className="events-box events-head2head">
                  <div className="events-box-header">
                    <span className="events-title">Head to Head</span>
                  </div>
                  <HeadToHead
                    team1={teams?.length > 0 ? teams[0] : {}}
                    team2={teams?.length > 0 ? teams[1] : {}}
                  />
                </div>
                <div className="events-box events-history">
                  <div className="events-box-header">
                    <span className="events-title">Match History</span>
                  </div>
                  <MatchHistory
                    team1={teams?.length > 0 ? teams[0] : {}}
                    team2={teams?.length > 0 ? teams[1] : {}}
                  />
                </div>
                {matches?.highlightsURL && (
                  <div className="events-box events-highlights">
                    <div className="events-box-header">
                      <span className="events-title">Highlights</span>
                    </div>
                    <div className="events-embed-container">
                      <div className="events-embed">
                        <iframe
                          src={`${matches?.highlightsURL}&parent=${window.location.hostname}`}
                          frameborder="0"
                          allowfullscreen="true"
                          scrolling="no"></iframe>
                      </div>
                    </div>
                  </div>
                )}
                <div className="events-box events-comments">
                  <div className="events-box-header">
                    <span className="events-title">Comments</span>
                  </div>
                  <div className="news-comments">
                    {postCommentsLoading ? (
                      <div className="loader-centered">
                        <CircularProgress
                          style={{
                            color: '#7ab903',
                            height: 55,
                            width: 55,
                            marginRight: 10
                          }}
                        />
                      </div>
                    ) : storyComments.length === 0 ? (
                      <div className="no-competitors">
                        Be the first to post a commment.
                      </div>
                    ) : (
                      <div className="story-comments">
                        {[...storyComments]
                          .sort((a, b) => a.createdAt - b.createdAt)
                          .map((comment, i) => (
                            <CommentDisplay comment={comment} i={i} key={i} />
                          ))}
                      </div>
                    )}

                    <div className="post-comment">
                      {isLoggedIn ? (
                        <SubmitComment
                          storyProps={{
                            id: params.id,
                            category: 'tarkovarena',
                            type: 'match'
                          }}
                        />
                      ) : (
                        <div className="no-competitors">
                          Please login to post a comment.
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="content-panel">
              <div className="content-loader">
                <CircularProgress
                  style={{
                    color: '#7ab903',
                    height: 55,
                    width: 55
                  }}
                />
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  )
}

export default MatchPage
