import React from 'react'
import { Link } from 'react-router-dom'
import TeamAvatar from './TeamAvatar'
import TeamName from './TeamName'
import CenteredLoader from './CenteredLoader'

const EventBracket = ({ tournament, matches, teams }) => {
  const getMatchRounds = () => {
    let results = []
    matches?.forEach(match => {
      if (results.filter(r => r === match.round).length === 0) {
        results.push(match.round)
      }
    })
    results.sort((a, b) => a - b)
    return results
  }

  const isQuarterFinals = r => {
    let results = []
    matches?.forEach(match => {
      if (results.filter(r => r === match.round).length === 0) {
        results.push(match.round)
      }
    })
    results.sort((a, b) => b - a)
    if (r === results[1]) {
      return true
    }
  }

  const isSemiFinals = r => {
    let results = []
    matches?.forEach(match => {
      if (results.filter(r => r === match.round).length === 0) {
        results.push(match.round)
      }
    })
    results.sort((a, b) => b - a)
    if (r === results[0]) {
      return true
    }
  }

  const hasShowMatch = () => {
    let results = false
    matches?.forEach(match => {
      if (match?.consolation === true) {
        results = true
      }
    })
    return results
  }

  const isShowMatchRound = r => {
    let results = false
    matches?.forEach(match => {
      if (match?.consolation === true && r === match?.round) {
        results = true
      }
    })
    return results
  }

  return (
    tournament?.matches?.length > 0 && (
      <div className="events-box events-bracket">
        <div className="events-box-header">
          <span className="events-title">Tournament Bracket</span>
        </div>
        {matches?.length > 0 && teams?.length > 0 ? (
          <div className="bracket-container">
            <div className={`bracket ${hasShowMatch() ? 'showmatch' : ''}`}>
              {matches?.length > 0 &&
                getMatchRounds().map(round => {
                  return (
                    <section
                      key={round}
                      className={`round ${
                        isQuarterFinals(round)
                          ? 'quarterfinals'
                          : isSemiFinals(round)
                          ? 'semifinals'
                          : ''
                      } ${isShowMatchRound(round) ? 'showmatch-round' : ''}`}>
                      <div className="winners">
                        <div className="matchups">
                          {[...matches]
                            ?.filter(match => match?.round === round)
                            .sort((a, b) => a.order - b.order)
                            .map(match => {
                              return (
                                <div
                                  key={match.id}
                                  className={`matchup ${
                                    match?.consolation === true
                                      ? 'showmatch-matchup'
                                      : ''
                                  }`}>
                                  <Link
                                    className="participants"
                                    to={`/match/${teams
                                      ?.filter(
                                        t => t.id === match?.teams[0]?.id
                                      )[0]
                                      ?.display_name?.replace(/ /g, '-')
                                      ?.toLowerCase()}-vs-${teams
                                      ?.filter(
                                        t => t.id === match?.teams[1]?.id
                                      )[0]
                                      ?.display_name?.replace(/ /g, '-')
                                      ?.toLowerCase()}-${tournament?.display_name
                                      ?.replace(/ /g, '-')
                                      ?.toLowerCase()}/${match?.id}`}>
                                    <div
                                      className={`participant ${
                                        match?.teams[0]?.winner === true
                                          ? 'winner'
                                          : 'loser'
                                      }`}>
                                      <span>
                                        <TeamAvatar
                                          team={
                                            teams?.filter(
                                              t => t.id === match?.teams[0]?.id
                                            )[0]
                                          }
                                        />
                                        <TeamName
                                          team={
                                            teams?.filter(
                                              t => t.id === match?.teams[0]?.id
                                            )[0]
                                          }
                                        />
                                      </span>
                                      <span
                                        className={
                                          match?.teams[0]?.winner === true
                                            ? 'winner'
                                            : 'loser'
                                        }>
                                        {match?.teams[0]?.score}
                                      </span>
                                    </div>
                                    <div
                                      className={`participant ${
                                        match?.teams[1]?.winner === true
                                          ? 'winner'
                                          : 'loser'
                                      }`}>
                                      <span>
                                        <TeamAvatar
                                          team={
                                            teams?.filter(
                                              t => t.id === match?.teams[1]?.id
                                            )[0]
                                          }
                                        />
                                        <TeamName
                                          team={
                                            teams?.filter(
                                              t => t.id === match?.teams[1]?.id
                                            )[0]
                                          }
                                        />
                                      </span>
                                      <span
                                        className={
                                          match?.teams[1]?.winner === true
                                            ? 'winner'
                                            : 'loser'
                                        }>
                                        {match?.teams[1]?.score}
                                      </span>
                                    </div>
                                  </Link>
                                </div>
                              )
                            })}
                        </div>
                        {!isShowMatchRound(round) && (
                          <div className="connector">
                            <div className="merger"></div>
                            <div className="line"></div>
                          </div>
                        )}
                      </div>
                    </section>
                  )
                })}
            </div>
          </div>
        ) : (
          <CenteredLoader />
        )}
      </div>
    )
  )
}

export default EventBracket
