import React from 'react'
import { useAppIsLoggedIn } from 'hooks'
import Banner from 'components/Banner'
import ReportGoons from 'components/ReportGoons'

const GoonsReport = () => {
  const isLoggedIn = useAppIsLoggedIn()
  return (
    <>
      <Banner />
      <ReportGoons />
    </>
  )
}

export default GoonsReport
