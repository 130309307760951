import Firebase from 'utils/Firebase'
import { getFirestore } from 'firebase/firestore'
import { collection, onSnapshot, doc, query, where } from 'firebase/firestore'
import { getHostFunctions } from 'utils/GetHost'

const db = getFirestore(Firebase)

async function getChatbotSettings(id, callback) {
  const unsubscribe = onSnapshot(doc(db, 'chatbots', id), doc => {
    if (typeof callback === 'function') {
      callback(doc)
    }
  })
  return { unsubscribe }
}

async function connectChatbot(uid, callback) {
  const result = await fetch(getHostFunctions() + `/connectChatbot/?uid=${uid}`)
  if (result.status === 200) {
    result.json().then(res => {
      if (typeof callback === 'function') {
        callback(res)
      }
    })
  }
}

async function disconnectChatbot(uid, callback) {
  const result = await fetch(
    getHostFunctions() + `/disconnectChatbot/?uid=${uid}`
  )
  if (result.status === 200) {
    result.json().then(res => {
      if (typeof callback === 'function') {
        callback(res)
      }
    })
  }
}

async function getConnectedBots(callback) {
  const q = query(collection(db, 'chatbots'), where('connected', '==', true))
  return onSnapshot(q, querySnapshot => {
    const docs = []
    querySnapshot.forEach(doc => {
      docs.push({ ...doc.data(), id: doc.id })
    })
    if (typeof callback === 'function') {
      callback(docs)
    }
  })
}

const ChatbotService = {
  getChatbotSettings,
  connectChatbot,
  disconnectChatbot,
  getConnectedBots
}

export default ChatbotService
