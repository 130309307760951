import React, { useState, useEffect } from 'react'
import { useAppLeagues } from 'hooks'
import Banner from 'components/Banner'
import { Calendar, momentLocalizer, Views } from 'react-big-calendar'
import moment from 'moment'
import { styled } from '@mui/material/styles'
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip'
import 'react-big-calendar/lib/css/react-big-calendar.css'
import LeagueService from 'services/LeagueService'
import { CircularProgress } from '@mui/material'

const localizer = momentLocalizer(moment)

const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#000',
    color: '#fff',
    padding: 15,
    maxWidth: 300,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #707070'
  }
}))

const Event = ({ event }) => {
  const getWeekItems = items => {
    let itemsString = ''
    items.forEach((item, index) => {
      if (index === items.length - 1) {
        itemsString += ` and ${item.display_name}`
      } else {
        itemsString += `${item.display_name}, `
      }
    })
    return itemsString
  }

  return (
    <div className="event-wrapper">
      <HtmlTooltip
        title={
          <div>
            <span className="subhead">{event.title}</span>
            {event.description && (
              <div className="event-description">{event.description}</div>
            )}

            {event.prizes && (
              <div className="league-prizes-placement">
                {event.prizes.map((prize, i) => {
                  return (
                    <div
                      key={i}
                      className={
                        'league-prizes-place place-' +
                        (i === 0 ? 'first' : i === 1 ? 'second' : 'third')
                      }>
                      {prize.indexOf('Gifted' > 0) ? prize : `$${prize}`}
                    </div>
                  )
                })}
              </div>
            )}
            <div className="event-times">
              <span>
                Map: <span>{event.tarkovMap}</span>
              </span>
              <span>
                Bonus Items: <span>{getWeekItems(event.bonusItems)}</span>
              </span>
              <span>
                Starts: <span>{moment(event.start).toString()}</span>
              </span>
              <span>
                Ends: <span>{moment(event.end).toString()}</span>
              </span>
            </div>
            <div className="event-cta">{event.cta}</div>
          </div>
        }>
        <div className="event-title">{event.title}</div>
      </HtmlTooltip>
    </div>
  )
}
const Events = () => {
  const leagues = useAppLeagues()
  const [leaguesLoaded, setLeaguesLoaded] = useState(false)
  const [seasonsLoaded, setSeasonsLoaded] = useState(false)
  const [weeksLoaded, setWeeksLoaded] = useState(false)
  const [leagueData, setLeagueData] = useState([])
  const [activeWeeks, setActiveWeeks] = useState([])
  const [upcomingWeeks, setUpcomingWeeks] = useState([])
  const [seasonsData, setSeasonsData] = useState([])
  const [events, setEvents] = useState([])
  const [initialEvents, setInitialEvents] = useState([
    // {
    //   id: 0,
    //   title: 'Fence.gg Kickoff Stream',
    //   start: moment(
    //     `Sun Dec 18 2022 20:00:00 GMT-0600 (Central Standard Time)`
    //   ).toDate(),
    //   end: moment(
    //     `Sun Dec 18 2022 21:00:00 GMT-0600 (Central Standard Time)`
    //   ).toDate(),
    //   resourceId: 1,
    //   type: 'stream',
    //   description:
    //     'Watch as DanBergundy and Sawski21 kickoff the closed beta Fence.gg Scav League.',
    //   cta: (
    //     <div>
    //       Watch live on{' '}
    //       <a href="https://www.twitch.tv/fencegg" target="_blank">
    //         twitch.tv/fencegg
    //       </a>
    //     </div>
    //   )
    // },
    // {
    //   id: 1,
    //   title: 'Fence.gg Closed Beta Recap Stream',
    //   start: moment(
    //     `Sun Dec 25 2022 20:00:00 GMT-0600 (Central Standard Time)`
    //   ).toDate(),
    //   end: moment(
    //     `Sun Dec 25 2022 21:00:00 GMT-0600 (Central Standard Time)`
    //   ).toDate(),
    //   resourceId: 2,
    //   type: 'stream',
    //   description:
    //     'Watch as DanBergundy and Sawski21 recap the Closed Beta Fence.gg Scav League.',
    //   cta: (
    //     <div>
    //       Watch live on{' '}
    //       <a href="https://www.twitch.tv/fencegg" target="_blank">
    //         twitch.tv/fencegg
    //       </a>
    //     </div>
    //   )
    // }
    {
      id: 0,
      title: 'Drops Stream - DanBergundy',
      start: moment(
        `Fri Dec 30 2022 05:00:00 GMT-0600 (Central Standard Time)`
      ).toDate(),
      end: moment(
        `Fri Dec 30 2022 19:00:00 GMT-0600 (Central Standard Time)`
      ).toDate(),
      resourceId: 1,
      type: 'stream',
      description:
        'Watch DanBergundy and get drops in Escape From Tarkov. Ask questions about Fence.gg and watch as he completes Scav League runs.',
      cta: (
        <div>
          Watch live on{' '}
          <a href="https://www.twitch.tv/danbergundy" target="_blank">
            twitch.tv/danbergundy
          </a>
        </div>
      )
    },
    {
      id: 1,
      title: 'Drops Stream - DanBergundy',
      start: moment(
        `Tue Jan 03 2023 17:00:00 GMT-0600 (Central Standard Time)`
      ).toDate(),
      end: moment(
        `Wed Jan 04 2023 07:00:00 GMT-0600 (Central Standard Time)`
      ).toDate(),
      resourceId: 1,
      type: 'stream',
      description:
        'Watch DanBergundy and get drops in Escape From Tarkov. Ask questions about Fence.gg and watch as he completes Scav League runs.',
      cta: (
        <div>
          Watch live on{' '}
          <a href="https://www.twitch.tv/danbergundy" target="_blank">
            twitch.tv/danbergundy
          </a>
        </div>
      )
    }
  ])
  const [resourceMap, setResourceMap] = useState([])
  const [initialResourceMap, setInitialResourceMap] = useState([
    {
      resourceId: 1,
      resourceTitle: 'Drops Stream - DanBergundy'
    },
    {
      resourceId: 2,
      resourceTitle: 'Drops Stream - DanBergundy'
    }
  ])

  useEffect(() => {
    if (leaguesLoaded === false && leagues.length > 0) {
      setLeaguesLoaded(true)
      let totalData = []

      async function getLeagueSeasons(leaguesData) {
        await Promise.all(
          leaguesData
            .filter(league => league.locked !== true)
            .map(async league => {
              let totalLeagueData = { ...league }
              await LeagueService.getLeagueSeasons(league.id, seasons => {
                let seasonData = []
                seasons.forEach(async season => {
                  seasonData.push({ id: season.id, ...season.data() })
                })
                totalLeagueData.seasons = seasonData
                totalLeagueData.seasons.forEach(async (season, index) => {
                  await LeagueService.getSeasonWeeks(
                    league.id,
                    season.id,
                    weeks => {
                      let weeksData = []
                      weeks.forEach(async week => {
                        weeksData.push({ id: week.id, ...week.data() })
                      })
                      totalLeagueData.seasons[index].weeks = weeksData
                    }
                  )
                })
              })
              totalData.push(totalLeagueData)
            })
        )
        setLeagueData(totalData)
      }
      getLeagueSeasons(leagues)
    }
  }, [leagues])

  useEffect(() => {
    //figure out race condition and remove this later :(
    setTimeout(() => {
      let activeArr = []
      let upcomingArr = []
      let weekEvents = [...initialEvents]
      let resources = [...initialResourceMap]
      leagueData?.forEach(league => {
        league?.seasons?.forEach(season => {
          season?.weeks?.forEach(week => {
            weekEvents.push({
              id: weekEvents.length,
              title: season.display_name
                ? season.display_name
                : `${league.display_name} League - Season ${season.id.replace(
                    'season-',
                    ''
                  )} - ${week.display_name} - ${week.map}`,
              start: moment(week.starts.seconds * 1000),
              end: moment(week.ends.seconds * 1000),
              prizes: season.prizes['yellow'],
              bonusItems: week.items,
              tarkovMap: week.map,
              resourceId: weekEvents.length + 1,
              type: 'league'
            })
            resources.push({
              resourceId: weekEvents.length,
              resourceTitle: season.display_name
                ? season.display_name
                : `${league.display_name} League - Season ${season.id.replace(
                    'season-',
                    ''
                  )} - ${week.display_name} - ${week.map}`
            })
            setEvents(weekEvents)
            setResourceMap(resources)
            setWeeksLoaded(true)
          })
        })
      })
      setActiveWeeks(activeArr)
      setUpcomingWeeks(upcomingArr)
      setWeeksLoaded(true)
    }, 1000)
  }, [leagueData])

  return (
    <>
      <Banner />
      <div className="aside-header aside-leagues">
        <span className="events">Events</span>
      </div>
      <div className="content-panel">
        {weeksLoaded ? (
          <Calendar
            localizer={localizer}
            events={events}
            startAccessor="start"
            endAccessor="end"
            resourceIdAccessor="resourceId"
            resources={resourceMap}
            resourceTitleAccessor="resourceTitle"
            defaultView={Views.MONTH}
            style={{ minHeight: 600 }}
            views={['month']}
            popup
            components={{
              event: Event
            }}
            eventPropGetter={(event, start, end, isSelected) => {
              if (event.type === 'stream') {
                return {
                  className: 'event-stream'
                }
              } else if (event.type === 'league') {
                return {
                  className: `event-league`
                }
              } else {
                return {}
              }
            }}
          />
        ) : (
          <div className="loader-centered">
            <CircularProgress
              style={{
                color: '#7ab903',
                height: 75,
                width: 75
              }}
            />
          </div>
        )}
      </div>
    </>
  )
}

export default Events
