import { Button, CircularProgress } from '@mui/material'
import { UserServiceApi } from 'api/UserServiceApi'
import Username from 'components/Username'
import UsernameAvatar from 'components/UsernameDisplay/UsernameAvatar'
import { useEffect } from 'react'

const DuoInvite = ({
  invite,
  inviteLoading,
  acceptDuoPartner,
  declineDuoPartner
}) => {
  const [getUsername, { data: username, isLoading }] =
    UserServiceApi.useLazyGetUsernameByIdCacheQuery()

  useEffect(() => {
    if (invite) {
      getUsername({ id: invite }, true)
    }
  }, [getUsername, invite])

  if (isLoading) {
    return (
      <div className="invite-row">
        <CircularProgress size={'15px'} />
      </div>
    )
  }

  return (
    <div className="invite-row">
      <span className="invite-desc">
        A player has invited you to their duos team:
      </span>
      <div className="pending-invite">
        <div className="invite-user">
          <div className="invite-avatar">
            <UsernameAvatar username={username} />
          </div>
          <div className="invite-username">
            <span className="invite-display-name">
              <Username username={username} />
            </span>
            <span className="invite-status">(Pending)</span>
          </div>
        </div>
        <div className="invite-button">
          <Button
            className="btn btn-primary"
            disabled={inviteLoading}
            onClick={e => {
              e.preventDefault()
              acceptDuoPartner(invite)
            }}>
            {inviteLoading && (
              <CircularProgress
                style={{
                  color: '#000',
                  height: 15,
                  width: 15,
                  marginRight: 10
                }}
              />
            )}
            Accept
          </Button>
          <Button
            className="btn btn-primary btn-cancel"
            disabled={inviteLoading}
            onClick={e => {
              e.preventDefault()
              declineDuoPartner(invite)
            }}>
            {inviteLoading && (
              <CircularProgress
                style={{
                  color: '#000',
                  height: 15,
                  width: 15,
                  marginRight: 10
                }}
              />
            )}
            Decline
          </Button>
        </div>
      </div>
    </div>
  )
}

export default DuoInvite
