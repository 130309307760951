import Username from 'components/Username'
import Countdown from 'react-countdown'
import moment from 'moment'
import { UserServiceApi } from 'api/UserServiceApi'
import { Box, CircularProgress } from '@mui/material'
import { useEffect } from 'react'
import UsernameStreamLink from 'components/UsernameDisplay/UsernameStreamLink'

const LeagueSidebar = ({ run, getRank }) => {
  const [getUsername, { data: username, isLoading }] =
    UserServiceApi.useLazyGetUsernameByIdCacheQuery({ id: run.id })

  useEffect(() => {
    if (run?.id) {
      getUsername({ id: run.id }, true)
    }
  }, [getUsername, run.id])

  const renderer = ({ hours, minutes, seconds }) => {
    const needsPad = value => {
      if (value > 9) {
        return false
      }
      return true
    }
    return (
      <span className="timer">
        {needsPad(hours) ? '0' + hours : hours}
        <span className="label">H</span>
        {needsPad(minutes) ? '0' + minutes : minutes}
        <span className="label">M</span>
        {needsPad(seconds) ? '0' + seconds : seconds}
        <span className="label">S</span>
      </span>
    )
  }
  if (isLoading) {
    return (
      <Box textAlign={'center'}>
        <CircularProgress size={'15px'} />
      </Box>
    )
  }

  if (run && username) {
    return (
      <UsernameStreamLink className="competitors-link" username={username}>
        <div className="rank-badge">
          <div className={`badge-outer badge-${run?.tier} badge-32`}>
            <div className="badge-inner"></div>
            <div className="badge-rank">
              <span>{getRank(run.id, run.league, run.tier)}</span>
            </div>
          </div>
        </div>
        <div className="rank-details">
          <span className="username">
            <Username username={username} />
          </span>
          <span className={`league league-${run.tier}`}>
            {run.league === 'duo' ? 'Duos' : run.league} League
          </span>
          {run.ended === true ? (
            <span className="timer ended">Ending...</span>
          ) : (
            <Countdown
              renderer={renderer}
              overtime={true}
              date={moment(run.started.seconds * 1000).toDate()}
            />
          )}

          <span className="cta">Watch Live</span>
        </div>
      </UsernameStreamLink>
    )
  }
}

export default LeagueSidebar
