import { Link } from 'react-router-dom'
import TimeAgo from 'react-timeago'
import { convertFromRaw, EditorState } from 'draft-js'
import Username from '../Username'
import { UserServiceApi } from 'api/UserServiceApi'
import { Box, CircularProgress } from '@mui/material'
import { useEffect } from 'react'
import UsernameAvatar from 'components/UsernameDisplay/UsernameAvatar'
import CenteredLoader from 'components/CenteredLoader'

const ArticleItem = ({ story }) => {
  const [getUsername, { data: username, isLoading }] =
    UserServiceApi.useLazyGetUsernameByIdCacheQuery()

  useEffect(() => {
    if (story?.postedBy) {
      getUsername({ id: story.postedBy }, true)
    }
  }, [getUsername, story.postedBy])

  if (isLoading) {
    return (
      <li className="story">
        <Box textAlign={'center'}>
          <CenteredLoader />
        </Box>
      </li>
    )
  }

  return (
    <li className="story">
      {story.published !== true && <span className="draft">DRAFT</span>}
      <Link to={`/news/post/${story.slug}`}>
        <div
          className="news-thumb"
          style={{
            backgroundImage: `url(${story.image})`
          }}></div>
        <span className="news-text">
          <span className="title">
            {EditorState.createWithContent(
              convertFromRaw(JSON.parse(decodeURIComponent(story.title)))
            )
              .getCurrentContent()
              .getPlainText()}
          </span>
          <span className="desc">
            {EditorState.createWithContent(
              convertFromRaw(JSON.parse(decodeURIComponent(story.content)))
            )
              .getCurrentContent()
              .getPlainText()
              .split(' ')
              .splice(0, 16)
              .join(' ') + '...'}
          </span>

          <span className="date">
            {story.published === true ? (
              <span>
                <TimeAgo date={story.createdAt} />
              </span>
            ) : (
              <span>Not published</span>
            )}
          </span>
          <div className="news-user">
            <div
              onClick={e => {
                e.stopPropagation()
                e.nativeEvent.stopImmediatePropagation()
                window.open(
                  `${
                    username?.usingKick
                      ? `https://www.kick.com/${username?.kickUsername}`
                      : `https://www.twitch.tv/${username?.display_name}`
                  }`,
                  '_blank'
                )
              }}>
              <UsernameAvatar username={username} className={'avatar'} />
              <span>
                <Username username={username} />
              </span>
            </div>
          </div>
        </span>
      </Link>
      <div className="divider">
        <div></div>
      </div>
    </li>
  )
}

export default ArticleItem
