import { UserServiceApi } from 'api/UserServiceApi'
import { useEffect } from 'react'
import TimeAgo from 'react-timeago'

const DeniedBy = ({ run }) => {
  const [getUsername, { data: username }] =
    UserServiceApi.useLazyGetUsernameByIdCacheQuery()

  useEffect(() => {
    if (run.approvedBy) {
      getUsername({ id: run.approvedBy }, true)
    }
  }, [getUsername, run.approvedBy])
  return (
    <span>
      <span>
        Denied <TimeAgo date={run.updatedAt.seconds * 1000} /> by{' '}
        {username?.display_name}
      </span>
    </span>
  )
}

export default DeniedBy
