import Username from 'components/Username'
import UsernameAvatar from 'components/UsernameDisplay/UsernameAvatar'
import UsernameStreamLink from 'components/UsernameDisplay/UsernameStreamLink'

const SidebarDisplay = ({ stream }) => {
  return (
    <li className="twitch" key={stream?.id}>
      <UsernameStreamLink username={stream?.username}>
        <UsernameAvatar username={stream?.username} className={'avatar'} />
        <span
          className={`username ${
            stream?.username?.premium === true ? 'premium-badge' : ''
          }`}>
          <Username username={stream.username} />
        </span>
        <span className="viewers">{stream?.viewer_count}</span>
      </UsernameStreamLink>
    </li>
  )
}

export default SidebarDisplay
