import { useState, useEffect } from 'react'
import {
  Box,
  ListItem,
  ToggleButton,
  ListItemAvatar,
  ListItemText,
  Avatar,
  Divider,
  Stack,
  Switch,
  FormGroup,
  FormControlLabel,
  Button
} from '@mui/material'
import { styled } from '@mui/material/styles'
import OwnerService from 'services/OwnerService'
import { useUserIsAdmin, useUserIsOwner } from 'hooks'

const StyledToggleButton = styled(ToggleButton)(({ theme }) => ({
  '&.Mui-selected': {
    color: '#b72824',
    backgroundColor: '#181818'
  },
  ['@media (max-width:410px)']: {
    '& > .MuiTypography-root': {
      display: 'none'
    }
  }
}))

const StyledListItem = styled(ListItem)(({ theme }) => ({
  ['@media (max-width:768px)']: {
    'padding': '20px',
    'display': 'block',
    'textAlign': 'center',
    '& .MuiAvatar-circular': {
      margin: '0 auto',
      width: 80,
      height: 80
    },
    '& > .MuiListItemText-root .MuiTypography-root': {
      fontWeight: 500
    }
  }
}))

const SetUserRoles = ({ user, userId, resetNewUser }) => {
  const [userIsAdmin, setUserIsAdmin] = useState(false)
  const [userIsEditor, setUserIsEditor] = useState(false)
  const [userIsBanned, setUserIsBanned] = useState(false)
  const [userIsVodder, setUserIsVodder] = useState(false)
  const [userIsBeta, setUserIsBeta] = useState(false)
  const [userIsStripeTest, setUserIsStripeTest] = useState(false)
  const [userIsPromotion, setUserIsPromotion] = useState(false)
  const isOwner = useUserIsOwner()
  const isAdmin = useUserIsAdmin()

  useEffect(() => {
    setUserIsAdmin(false)
    setUserIsEditor(false)
    setUserIsBanned(false)
    setUserIsVodder(false)
    setUserIsBeta(false)
    setUserIsStripeTest(false)
    setUserIsPromotion(false)
  }, [userId])

  const setAdmin = event => {
    const { checked = false } = event.target
    setUserIsAdmin(checked)
    if (checked) {
      OwnerService.removeAdmin(userId)
    } else {
      OwnerService.addAdmin(userId)
    }
  }

  const setEditor = event => {
    const { checked = false } = event.target
    setUserIsEditor(checked)
    if (checked) {
      OwnerService.removeEditor(userId)
    } else {
      OwnerService.addEditor(userId)
    }
  }

  const setBanned = event => {
    const { checked = false } = event.target
    setUserIsBanned(checked)
    if (checked) {
      OwnerService.removeBanned(userId)
    } else {
      OwnerService.addBanned(userId)
    }
  }

  const setVodder = event => {
    const { checked = false } = event.target
    setUserIsVodder(checked)
    if (checked) {
      OwnerService.removeVodder(userId)
    } else {
      OwnerService.addVodder(userId)
    }
  }

  const setBeta = event => {
    const { checked = false } = event.target
    setUserIsBeta(checked)
    if (checked) {
      OwnerService.removeBeta(userId)
    } else {
      OwnerService.addBeta(userId)
    }
  }

  const setStripeTest = event => {
    const { checked = false } = event.target
    setUserIsStripeTest(checked)
    if (checked) {
      OwnerService.removeStripeTest(userId)
    } else {
      OwnerService.addStripeTest(userId)
    }
  }

  const setPromotion = event => {
    const { checked = false } = event.target
    setUserIsPromotion(checked)
    if (checked) {
      OwnerService.removePromotionRole(userId)
    } else {
      OwnerService.addPromotionRole(userId)
    }
  }

  return (
    <Box>
      <StyledListItem alignItems="center">
        <ListItemAvatar>
          <Avatar alt={user.display_name} src={user.profile_image_url} />
        </ListItemAvatar>
        <ListItemText primary={user.display_name} />
      </StyledListItem>

      <StyledListItem sx={{ paddingBottom: '15px' }}>
        <Stack spacing={2}>
          <FormGroup>
            {(isOwner || isAdmin) && (
              <>
                {isOwner && (
                  <FormControlLabel
                    control={
                      <Switch checked={userIsAdmin} onChange={setAdmin} />
                    }
                    label="Admin"
                  />
                )}
                {isAdmin && (
                  <FormControlLabel
                    control={
                      <Switch checked={userIsBanned} onChange={setBanned} />
                    }
                    label="Banned"
                  />
                )}
                <FormControlLabel
                  control={
                    <Switch checked={userIsEditor} onChange={setEditor} />
                  }
                  label="Editor"
                />
                <FormControlLabel
                  control={
                    <Switch checked={userIsVodder} onChange={setVodder} />
                  }
                  label="Vodder"
                />
                <FormControlLabel
                  control={<Switch checked={userIsBeta} onChange={setBeta} />}
                  label="Beta"
                />
                <FormControlLabel
                  control={
                    <Switch
                      checked={userIsStripeTest}
                      onChange={setStripeTest}
                    />
                  }
                  label="Stripe Test"
                />
                <FormControlLabel
                  control={
                    <Switch checked={userIsPromotion} onChange={setPromotion} />
                  }
                  label="Promotional User"
                />
              </>
            )}
          </FormGroup>
          <Button variant="contained" onClick={resetNewUser}>
            Close
          </Button>
        </Stack>
      </StyledListItem>
      <Divider />
    </Box>
  )
}

export default SetUserRoles
