import { Link } from 'react-router-dom'
import LeagueSidebar from './Leagues/LeagueSidebar'
import { useGetLeagueRunsSidebar } from './Leagues/hooks/LeagueRuns'

const SidebarCompetitors = () => {
  const { getRank, leagueRuns } = useGetLeagueRunsSidebar()

  return (
    <div>
      <div className="aside-header aside-competitors">
        <span className="rankings">Competitors</span>
      </div>
      <nav className="rankings-nav">
        <ul>
          {leagueRuns.length > 0 ? (
            <li>
              {leagueRuns.map(run => (
                <LeagueSidebar key={run.id} run={run} getRank={getRank} />
              ))}
            </li>
          ) : (
            <li>
              <span className="no-competitors">
                There are no active league runs at this time.
              </span>
            </li>
          )}
        </ul>
      </nav>
      <Link className="all-link" to="/rankings">
        {leagueRuns.length > 5 ? 'View All' : 'View Rankings'}
      </Link>
    </div>
  )
}

export default SidebarCompetitors
