import React from 'react'

const EventMapPool = ({ tournament }) => {
  return (
    tournament?.mappool && (
      <div className="events-box events-standings-map">
        <div className="events-box-header">
          <span className="events-title">Map Pool</span>
        </div>
        <div className="events-maps">
          {tournament?.mappool?.map(map => {
            return (
              <div key={map} className={`events-map ${map}`}>
                {map === 'airpit' && <span>Airpit</span>}
                {map === 'equator' && <span>Equator</span>}
                {map === 'bay5' && <span>Bay 5</span>}
                {map === 'sawmill' && <span>Sawmill</span>}
              </div>
            )
          })}
        </div>
      </div>
    )
  )
}

export default EventMapPool
