import Firebase from 'utils/Firebase'
import { collection, getFirestore } from 'firebase/firestore'
import {
  onSnapshot,
  doc,
  updateDoc,
  where,
  limit,
  query
} from 'firebase/firestore'
import { getHostFunctions } from 'utils/GetHost'
import { getAuth } from 'firebase/auth'

const db = getFirestore(Firebase)
const auth = getAuth()

async function getOverlaySettings(id, callback) {
  onSnapshot(doc(db, 'overlays', id), doc => {
    if (typeof callback === 'function') {
      callback(doc)
    }
  })
}

async function getStreamerChallengeDoc(letter, callback) {
  onSnapshot(doc(db, 'streamerChallenge2023', letter), doc => {
    if (typeof callback === 'function') {
      callback(doc)
    }
  })
}

async function getAmpedMajorDoc(username, callback) {
  // onSnapshot(
  //   collection(
  //     db,
  //     'tournaments',
  //     'c5Jy6Jp1N52e7eWivTJ0',
  //     'points',
  //     'all',
  //     'users'
  //   ),
  //   where('username', '==', username),
  //   limit(1),
  //   doc => {
  //     if (typeof callback === 'function') {
  //       console.log(doc)
  //       callback(doc)
  //     }
  //   }
  // )
  const q = query(
    collection(
      db,
      'tournaments',
      'RNKLTPJvbWlH5nVxQSui',
      'points',
      'all',
      'users'
    ),
    where('username', '==', username.toLowerCase())
  )
  return onSnapshot(q, querySnapshot => {
    const docs = []
    querySnapshot.forEach(doc => {
      docs.push(doc)
    })
    if (typeof callback === 'function') {
      callback(docs[0])
    }
  })
}

async function setOverlaySettings(overlay, data, id, callback) {
  const docRef = await updateDoc(doc(db, 'overlays', id), { [overlay]: data })
  if (typeof callback === 'function') {
    callback(docRef)
  }
}

async function regenerateOverlay(callback) {
  const token = await auth.currentUser?.getIdToken()
  const result = await fetch(getHostFunctions() + `/regenerateOverlay`, {
    headers: {
      authorization: `Bearer ${token}`
    }
  })
  if (result.status === 200) {
    result.json().then(res => {
      if (typeof callback === 'function') {
        callback(res)
      }
    })
  }
}

const OverlayService = {
  getStreamerChallengeDoc,
  getOverlaySettings,
  setOverlaySettings,
  regenerateOverlay,
  getAmpedMajorDoc
}

export default OverlayService
