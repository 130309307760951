import Firebase from 'utils/Firebase'
import { getFirestore, orderBy } from 'firebase/firestore'
import { collection, onSnapshot, query, where } from 'firebase/firestore'
import { getHostFunctions } from 'utils/GetHost'
import { getAuth } from 'firebase/auth'

const auth = getAuth()
const db = getFirestore(Firebase)

async function postComment(id, comment, category, type, callback) {
  const token = await auth.currentUser?.getIdToken()
  const result = await fetch(
    getHostFunctions() +
      `/postComment?id=${id}&comment=${encodeURIComponent(
        comment
      )}&category=${category}&type=${type}`,
    {
      headers: {
        authorization: `Bearer ${token}`
      }
    }
  )
  if (result.status === 200) {
    result.json().then(res => {
      if (typeof callback === 'function') {
        callback(res)
      }
    })
  }
}

async function getCommentsByEventId(id, callback) {
  const q = query(
    collection(db, 'tarkovarena', 'settings', 'comments'),
    where('eventId', '==', parseInt(id, 10)),
    orderBy('createdAt', 'desc')
  )
  const unsubscribe = onSnapshot(q, querySnapshot => {
    const docs = []
    querySnapshot.forEach(doc => {
      docs.push({ ...doc.data(), id: doc.id })
    })
    if (typeof callback === 'function') {
      callback(docs)
    }
  })
  return { unsubscribe }
}

async function getCommentsByMatchId(id, callback) {
  const q = query(
    collection(db, 'tarkovarena', 'settings', 'comments'),
    where('matchId', '==', parseInt(id, 10)),
    orderBy('createdAt', 'desc')
  )
  const unsubscribe = onSnapshot(q, querySnapshot => {
    const docs = []
    querySnapshot.forEach(doc => {
      docs.push({ ...doc.data(), id: doc.id })
    })
    if (typeof callback === 'function') {
      callback(docs)
    }
  })
  return { unsubscribe }
}

const TournamentService = {
  postComment,
  getCommentsByEventId,
  getCommentsByMatchId
}

export default TournamentService
