import React, { useEffect, useState } from 'react'
import {
  useAppCommunityStreams,
  useAppRankingsModal,
  useAppRankingsModalLeague
} from 'hooks'
import { useDispatch } from 'react-redux'
import { setRankingsModal, setRankingsModalLeague } from 'store/slice/appSlice'
import Modal from '@mui/material/Modal'
import CircularProgress from '@mui/material/CircularProgress'
import PropTypes from 'prop-types'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { useAppLeagues } from 'hooks'
import LeagueService from 'services/LeagueService'
import { getTarkovMaps } from 'utils/tarkovMaps'
import Grid from '@mui/material/Grid'
import TimeAgo from 'react-timeago'
import { NavLink, useNavigate } from 'react-router-dom'
import Username from './Username'
import { UserServiceApi } from 'api/UserServiceApi'
import PartnerStats from './Elo/PartnerStats'
import UsernameAvatar from './UsernameDisplay/UsernameAvatar'
import UsernameStreamLink from './UsernameDisplay/UsernameStreamLink'

function TabPanel(props) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}>
      {value === index && (
        <div sx={{ p: 3 }}>
          <span>{children}</span>
        </div>
      )}
    </div>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired
}

function a11yProps(index) {
  return {
    'id': `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`
  }
}

const EloSummary = ({ league, tier, season }) => {
  const dispatch = useDispatch()
  const communityStreams = useAppCommunityStreams()
  const rankingsModal = useAppRankingsModal()
  const leagueModal = useAppRankingsModalLeague()
  const navigate = useNavigate()
  const leagues = useAppLeagues()
  const [leaguesLoaded, setLeaguesLoaded] = useState(false)
  const tarkovMaps = getTarkovMaps()
  const [detailsLoading, setDetailsLoading] = useState(true)
  const [leagueData, setLeagueData] = useState([])
  const [rankData, setRankData] = useState({})
  const [streamData, setStreamData] = useState({})
  const [lowestRank, setLowestRank] = useState(0)
  const [runData, setRunData] = useState([])
  const [runsLoading, setRunsLoading] = useState(true)
  const [statsTab, setStatsTab] = useState(0)
  const [expanded, setExpanded] = useState(false)
  const [badgeData, setBadgeData] = useState([])
  const [getUsername, { data: username, isLoading }] =
    UserServiceApi.useLazyGetUsernameByIdCacheQuery()

  useEffect(() => {
    if (rankingsModal) {
      getUsername({ id: rankingsModal }, true)
    }
  }, [getUsername, rankingsModal])

  const handleAccChange = panel => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false)
  }

  const handleChange = (event, newValue) => {
    setStatsTab(newValue)
  }

  const handleClose = (e, r) => {
    dispatch(setRankingsModal(''))
    dispatch(setRankingsModalLeague(''))
  }

  const ordinal_suffix_of = i => {
    var j = i % 10,
      k = i % 100
    if (j == 1 && k != 11) {
      return 'st'
    }
    if (j == 2 && k != 12) {
      return 'nd'
    }
    if (j == 3 && k != 13) {
      return 'rd'
    }
    return 'th'
  }

  const getTmpWeeksArr = league => {
    return league === 'scav'
      ? [1, 2, 3, 4]
      : league === 'solo'
      ? [1]
      : [1, 2, 3]
  }

  useEffect(() => {
    // LeagueService.getLeagueSeasonRankings(league.id, season, tier.id, data => {
    //   let ranks =
    //     data.data() && data.data().rankings ? data.data().rankings : []
    //   let userRank =
    //     ranks.filter(r => r.uid === rankingsModal).length > 0
    //       ? ranks.filter(r => r.uid === rankingsModal)[0]
    //       : {}
    //   if (ranks.length > 0) {
    //     setLowestRank(ranks[ranks.length - 1].rank)
    //   }
    //   setRankData(userRank)
    // })
    if (leaguesLoaded === false && leagues.length > 0) {
      setLeaguesLoaded(true)
      let totalData = []

      async function getLeagueSeasons(leaguesData) {
        await Promise.all(
          leaguesData.map(async league => {
            let totalLeagueData = { ...league }
            await LeagueService.getLeagueSeasonRankings(
              league.id,
              league.currentSeason,
              'yellow'
            ).then(data => {
              let ranks = data || []

              let userRank = null
              if (league.id !== 'duo') {
                userRank =
                  ranks.filter(r => r.uid === rankingsModal).length > 0
                    ? ranks.filter(r => r.uid === rankingsModal)[0]
                    : {
                        points: 0.0,
                        rank:
                          ranks.length > 0
                            ? ranks[ranks.length - 1].rank + 1
                            : 1,
                        weeks: []
                      }
              } else {
                userRank =
                  ranks.filter(r => r.members.includes(rankingsModal)).length >
                  0
                    ? ranks.filter(r => r.members.includes(rankingsModal))[0]
                    : {
                        points: 0.0,
                        rank:
                          ranks.length > 0
                            ? ranks[ranks.length - 1].rank + 1
                            : 1,
                        weeks: []
                      }
              }

              totalLeagueData.myRank = userRank
            })
            totalData.push(totalLeagueData)
          })
        )
        setLeagueData(totalData)
      }
      getLeagueSeasons(leagues)
    }
  }, [leagues, leaguesLoaded, rankingsModal])

  useEffect(() => {
    if (rankingsModal.length > 0) {
      let streamInfo = communityStreams.filter(s => s.id === rankingsModal)
      if (streamInfo.length > 0) {
        setStreamData(streamInfo[0])
      }
    }
  }, [communityStreams, rankingsModal])

  useEffect(() => {
    if (leagueModal.length > 0) {
      if (leagueModal === 'scav') {
        setStatsTab(0)
      } else if (leagueModal === 'solo') {
        setStatsTab(1)
      } else if (leagueModal === 'duo') {
        setStatsTab(2)
      }
    }
  }, [leagueModal])

  useEffect(() => {
    let listener = null
    let listenerBadges = null
    if (rankingsModal.length > 0) {
      listener = LeagueService.getLeagueRunsFromUID(rankingsModal, data => {
        setRunsLoading(false)
        setRunData(data)
      })
      listenerBadges = LeagueService.getBadges(rankingsModal, data => {
        if (
          data &&
          data.data() &&
          data.data().badges &&
          data.data().badges.length
        ) {
          setBadgeData(data.data().badges)
        }
      })
    }
    return () => {
      if (typeof listener === 'function') {
        listener()
      }
      if (typeof listenerBadges === 'function') {
        listenerBadges()
      }
    }
  }, [rankingsModal])

  const getRunData = param => {
    let result = 0
    runData.forEach(run => {
      result = result + (isNaN(run[param]) ? 0 : run[param])
    })
    return `${result}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  }

  const getRunDataFromID = (id, param) => {
    if (runData?.filter(r => r.id === id).length > 0) {
      let data = runData?.filter(r => r.id === id)[0][param]
      if (typeof data === 'string') {
        return runData?.filter(r => r.id === id)[0][param]
      } else if (typeof data === 'number') {
        if (isNaN(data)) {
          return 0
        } else {
          return runData?.filter(r => r.id === id)[0][param]
        }
      }
    }
    return ''
  }

  const isPremium = () => {
    return username?.premium === true
  }

  return (
    <Modal
      sx={{ overflowY: 'scroll' }}
      open={true}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description">
      <Box className="run-modal rankings-modal">
        <div className="aside-header aside-run">
          <span className="leagues summary-title">
            <span className="username">
              {username?.display_name}
              's Profile
            </span>
          </span>
        </div>
        <div className="modal-content">
          <span className="header">
            <span className="username">
              <UsernameAvatar username={username} className={'avatar'} />
              <span className="username-details">
                <UsernameStreamLink username={username}>
                  <span
                    className={
                      'displayname' +
                      (streamData && streamData.id ? ' is-live' : '')
                    }>
                    <span
                      className={
                        'displayname-inner' +
                        (username?.broadcaster_type === 'partner'
                          ? ' is-partner'
                          : '')
                      }>
                      <Username username={username} />
                    </span>
                  </span>
                </UsernameStreamLink>
                <span className="joined">
                  Joined{' '}
                  <TimeAgo
                    date={username?.joined ? username?.joined : '12/21/2022'}
                  />
                </span>
                {isPremium() && (
                  <span className="is-premium">FenceGG Premium</span>
                )}
              </span>
            </span>
            <div className="league-ranks">
              <div className="league-rank league-solo">
                <div className="rankings-badge">
                  <div className="rank-badge">
                    <div
                      className={`badge-outer badge-48 ${
                        isPremium() ? 'badge-yellow' : 'badge-grey'
                      }`}>
                      <div className="badge-inner"></div>
                      <div className="badge-rank">
                        <span>
                          {isPremium()
                            ? leagueData.filter(league => league.id === 'solo')
                                .length > 0
                              ? leagueData.filter(
                                  league => league.id === 'solo'
                                )[0]?.myRank?.rank
                              : '--'
                            : '--'}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <span className="badge-label">Solo</span>
              </div>
              <div className="league-rank league-solo">
                <div className="rankings-badge">
                  <div className="rank-badge">
                    <div
                      className={`badge-outer badge-48 ${
                        isPremium() ? 'badge-yellow' : 'badge-grey'
                      }`}>
                      <div className="badge-inner"></div>
                      <div className="badge-rank">
                        <span>
                          {isPremium()
                            ? leagueData.filter(league => league.id === 'duo')
                                .length > 0
                              ? leagueData.filter(
                                  league => league.id === 'duo'
                                )[0]?.myRank?.rank
                              : '--'
                            : '--'}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <span className="badge-label">Duo</span>
              </div>
              <div className="league-rank league-solo">
                <div className="rankings-badge">
                  <div className="rank-badge">
                    <div className={`badge-outer badge-yellow badge-48`}>
                      <div className="badge-inner"></div>
                      <div className="badge-rank">
                        <span>
                          {leagueData.filter(league => league.id === 'scav')
                            .length > 0
                            ? leagueData.filter(
                                league => league.id === 'scav'
                              )[0]?.myRank?.rank
                            : '--'}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <span className="badge-label">Scav</span>
              </div>
            </div>
          </span>
          <div className="overall-stats">
            <span className="overall-stats-title">Lifetime FTL Stats</span>
            <div className="overall-stats-inner">
              {runsLoading ? (
                <div className="loader-centered">
                  <CircularProgress
                    style={{
                      color: '#7ab903',
                      height: 75,
                      width: 75
                    }}
                  />
                </div>
              ) : (
                <Grid container spacing={5}>
                  <Grid item xs={12} md={4}>
                    <span className="stats-row">
                      <span className="stats-label">Total Runs</span>
                      <span className="stats-value">{runData.length}</span>
                    </span>
                    <span className="stats-row">
                      <span className="stats-label">Prizes Earned</span>
                      <span className="stats-value">0</span>
                    </span>
                    <span className="stats-row">
                      <span className="stats-label">Bounties Won</span>
                      <span className="stats-value">0</span>
                    </span>
                    <span className="stats-row">
                      <span className="stats-label">Roubles</span>
                      <span className="stats-value">
                        {getRunData('costItems')}
                      </span>
                    </span>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <span className="stats-row">
                      <span className="stats-label">XP</span>
                      <span className="stats-value">{getRunData('xp')}</span>
                    </span>
                    <span className="stats-row">
                      <span className="stats-label">Bonus Items</span>
                      <span className="stats-value">
                        {getRunData('bonusItems')}
                      </span>
                    </span>
                    <span className="stats-row">
                      <span className="stats-label">Damage</span>
                      <span className="stats-value">
                        {getRunData('damage')}
                      </span>
                    </span>
                    <span className="stats-row">
                      <span className="stats-label">PMCs</span>
                      <span className="stats-value">
                        {getRunData('killsPmc')}
                      </span>
                    </span>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <span className="stats-row">
                      <span className="stats-label">Raiders/Rogues</span>
                      <span className="stats-value">
                        {getRunData('killsRogue')}
                      </span>
                    </span>
                    <span className="stats-row">
                      <span className="stats-label">Scavs</span>
                      <span className="stats-value">
                        {getRunData('killsScav')}
                      </span>
                    </span>
                    <span className="stats-row">
                      <span className="stats-label">Bosses</span>
                      <span className="stats-value">
                        {getRunData('killsBoss')}
                      </span>
                    </span>
                    <span className="stats-row">
                      <span className="stats-label">Guards</span>
                      <span className="stats-value">
                        {getRunData('killsGuard')}
                      </span>
                    </span>
                  </Grid>
                </Grid>
              )}
            </div>
          </div>
          <span className="title">Badges Earned</span>
          {badgeData.length > 0 ? (
            <div className="badges">
              {badgeData.map((badge, bi) => {
                return (
                  <div className="badge" key={bi}>
                    <div className="rankings-badge">
                      <div className="rank-badge">
                        <NavLink
                          to={`/history/${badge.league}/${badge.season}`}
                          onClick={e => {
                            handleClose()
                          }}
                          className={`badge-outer badge-${badge.tier} badge-48`}>
                          <div className="badge-inner"></div>
                          <div className="badge-rank">
                            <span className="badge-league">{badge.league}</span>
                            <span className="badge-place">
                              {badge.rank}
                              <sup>{ordinal_suffix_of(badge.rank)}</sup>
                            </span>
                            <span className="badge-season">
                              Season
                              <br />
                              {badge.season}
                            </span>
                          </div>
                        </NavLink>
                      </div>
                    </div>
                  </div>
                )
              })}
            </div>
          ) : (
            <span className="no-badges">No badges earned yet.</span>
          )}

          <span className="title">Current Season Stats</span>
          {runsLoading ? (
            <div className="loader-centered">
              <CircularProgress
                style={{
                  color: '#7ab903',
                  height: 75,
                  width: 75
                }}
              />
            </div>
          ) : (
            <div className="current-season-stats">
              <div className="league-tabs">
                <Tabs
                  value={statsTab}
                  onChange={handleChange}
                  aria-label="basic tabs example">
                  {leagueData
                    .sort((a, b) => a.order - b.order)
                    .map((leg, li) => {
                      return <Tab label={leg.id} key={li} {...a11yProps(li)} />
                    })}
                </Tabs>
              </div>
              {leagueData
                .sort((a, b) => a.order - b.order)
                .map((leg, li) => {
                  return (
                    <TabPanel value={statsTab} key={li} index={li}>
                      <div>
                        <div className="total-points">
                          <span>Total Points:</span>{' '}
                          {leagueData.filter(league => league.id === leg.id)
                            .length > 0
                            ? leagueData.filter(
                                league => league.id === leg.id
                              )[0]?.myRank?.points
                            : '0.00'}
                        </div>
                        {getTmpWeeksArr(leg.id).map(a => {
                          return (
                            <Accordion
                              key={a}
                              expanded={expanded === `panel${a}`}
                              onChange={handleAccChange(`panel${a}`)}>
                              <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls={`panel${a}bh-content`}
                                id={`panel${a}bh-header`}>
                                <span>
                                  Week {a} &ndash;{' '}
                                  {leagueData
                                    .filter(league => league.id === leg.id)[0]
                                    ?.myRank?.weeks?.filter(w => w.week === a)
                                    .length > 0
                                    ? leagueData
                                        .filter(
                                          league => league.id === leg.id
                                        )[0]
                                        ?.myRank?.weeks?.filter(
                                          w => w.week === a
                                        )[0]?.points
                                    : 0.0}{' '}
                                  PTS{' '}
                                  {leagueData
                                    .filter(league => league.id === leg.id)[0]
                                    ?.myRank?.weeks?.filter(w => w.week === a)
                                    .length > 0
                                    ? leagueData
                                        .filter(
                                          league => league.id === leg.id
                                        )[0]
                                        ?.myRank?.weeks?.filter(
                                          w => w.week === a
                                        )[0].dropped === true
                                      ? ' (Dropped Lowest Week)'
                                      : ''
                                    : ''}
                                </span>
                              </AccordionSummary>
                              <AccordionDetails>
                                {leagueData
                                  .filter(league => league.id === leg.id)[0]
                                  ?.myRank?.weeks?.filter(w => w.week === a)
                                  ?.length > 0 ? (
                                  leagueData
                                    .filter(league => league.id === leg.id)[0]
                                    ?.myRank?.weeks?.filter(w => w.week === a)
                                    ?.map((week, wi) => {
                                      return (
                                        <div
                                          key={wi}
                                          className="stats-summary-accordion">
                                          {week?.runs?.length > 0 ? (
                                            week?.runs?.map((run, ri) => {
                                              return (
                                                <div key={ri}>
                                                  <span className="subhead">
                                                    Run {ri + 1} &ndash;{' '}
                                                    {run.points} PTS &ndash;{' '}
                                                    <a
                                                      className="vod-link"
                                                      href={getRunDataFromID(
                                                        run.id,
                                                        'twitchLink'
                                                      )}
                                                      target="_blank"
                                                      rel={'noreferrer'}>
                                                      Watch VOD
                                                    </a>
                                                    {leg.id === 'duo' && (
                                                      <a
                                                        className="vod-link"
                                                        href={getRunDataFromID(
                                                          run.id,
                                                          'twitchLink2'
                                                        )}
                                                        target="_blank"
                                                        rel={'noreferrer'}>
                                                        Watch VOD2
                                                      </a>
                                                    )}
                                                  </span>
                                                  {leg.id === 'duo' && (
                                                    <PartnerStats
                                                      getRunDataFromID={
                                                        getRunDataFromID
                                                      }
                                                      run={run}
                                                      user={'submittedBy'}
                                                      points={'duoPoints'}
                                                    />
                                                  )}
                                                  <Grid container spacing={2}>
                                                    <Grid
                                                      item
                                                      xs={12}
                                                      md={4}
                                                      sx={{
                                                        pointerEvents: 'none'
                                                      }}>
                                                      <div className="league-week-banner-text">
                                                        <span className="ends">
                                                          Bonus Items:{' '}
                                                          <span className="items-value">
                                                            {getRunDataFromID(
                                                              run.id,
                                                              'bonusItems'
                                                            )}
                                                          </span>
                                                        </span>
                                                        {leg.id === 'duo' ? (
                                                          <span className="ends">
                                                            Survived:{' '}
                                                            <span className="items-value">
                                                              {`${getRunDataFromID(
                                                                run.id,
                                                                'survive'
                                                              )}`.replace(
                                                                /\B(?=(\d{3})+(?!\d))/g,
                                                                ','
                                                              )}
                                                            </span>
                                                          </span>
                                                        ) : (
                                                          <span className="ends">
                                                            Cost of Items:{' '}
                                                            <span className="items-value">
                                                              {`${getRunDataFromID(
                                                                run.id,
                                                                'costItems'
                                                              )}`.replace(
                                                                /\B(?=(\d{3})+(?!\d))/g,
                                                                ','
                                                              )}
                                                            </span>
                                                          </span>
                                                        )}
                                                        <span className="ends">
                                                          Raid XP:{' '}
                                                          <span className="items-value">
                                                            {`${getRunDataFromID(
                                                              run.id,
                                                              'xp'
                                                            )}`.replace(
                                                              /\B(?=(\d{3})+(?!\d))/g,
                                                              ','
                                                            )}
                                                          </span>
                                                        </span>
                                                      </div>
                                                    </Grid>
                                                    <Grid
                                                      item
                                                      xs={12}
                                                      md={4}
                                                      sx={{
                                                        pointerEvents: 'none'
                                                      }}>
                                                      <div className="league-week-banner-text">
                                                        <span className="ends">
                                                          Raid Damage:{' '}
                                                          <span className="items-value">
                                                            {`${getRunDataFromID(
                                                              run.id,
                                                              'damage'
                                                            )}`.replace(
                                                              /\B(?=(\d{3})+(?!\d))/g,
                                                              ','
                                                            )}
                                                          </span>
                                                        </span>
                                                        <span className="ends">
                                                          PMC Kills:{' '}
                                                          <span className="items-value">
                                                            {getRunDataFromID(
                                                              run.id,
                                                              'killsPmc'
                                                            )}
                                                          </span>
                                                        </span>
                                                        <span className="ends">
                                                          Rogue/Raider Kills:{' '}
                                                          <span className="items-value">
                                                            {getRunDataFromID(
                                                              run.id,
                                                              'killsRogue'
                                                            )}
                                                          </span>
                                                        </span>
                                                      </div>
                                                    </Grid>
                                                    <Grid
                                                      item
                                                      xs={12}
                                                      md={4}
                                                      sx={{
                                                        pointerEvents: 'none'
                                                      }}>
                                                      <div className="league-week-banner-text">
                                                        <span className="ends">
                                                          Scav Kills:{' '}
                                                          <span className="items-value">
                                                            {getRunDataFromID(
                                                              run.id,
                                                              'killsScav'
                                                            )}
                                                          </span>
                                                        </span>
                                                        <span className="ends">
                                                          Guard Kills:{' '}
                                                          <span className="items-value">
                                                            {getRunDataFromID(
                                                              run.id,
                                                              'killsGuard'
                                                            )}
                                                          </span>
                                                        </span>
                                                        <span className="ends">
                                                          Boss Kills:{' '}
                                                          <span className="items-value">
                                                            {getRunDataFromID(
                                                              run.id,
                                                              'killsBoss'
                                                            )}
                                                          </span>
                                                        </span>
                                                      </div>
                                                    </Grid>
                                                  </Grid>
                                                  {leg.id === 'duo' && (
                                                    <div>
                                                      <PartnerStats
                                                        getRunDataFromID={
                                                          getRunDataFromID
                                                        }
                                                        run={run}
                                                        user={'duoPartner'}
                                                        points={'duoPoints2'}
                                                      />

                                                      <Grid
                                                        container
                                                        spacing={2}>
                                                        <Grid
                                                          item
                                                          xs={12}
                                                          md={4}
                                                          sx={{
                                                            pointerEvents:
                                                              'none'
                                                          }}>
                                                          <div className="league-week-banner-text">
                                                            <span className="ends">
                                                              Bonus Items:{' '}
                                                              <span className="items-value">
                                                                {getRunDataFromID(
                                                                  run.id,
                                                                  'bonusItems2'
                                                                )}
                                                              </span>
                                                            </span>
                                                            <span className="ends">
                                                              Survived:{' '}
                                                              <span className="items-value">
                                                                {`${getRunDataFromID(
                                                                  run.id,
                                                                  'survive2'
                                                                )}`.replace(
                                                                  /\B(?=(\d{3})+(?!\d))/g,
                                                                  ','
                                                                )}
                                                              </span>
                                                            </span>
                                                            <span className="ends">
                                                              Raid XP:{' '}
                                                              <span className="items-value">
                                                                {`${getRunDataFromID(
                                                                  run.id,
                                                                  'xp2'
                                                                )}`.replace(
                                                                  /\B(?=(\d{3})+(?!\d))/g,
                                                                  ','
                                                                )}
                                                              </span>
                                                            </span>
                                                          </div>
                                                        </Grid>
                                                        <Grid
                                                          item
                                                          xs={12}
                                                          md={4}
                                                          sx={{
                                                            pointerEvents:
                                                              'none'
                                                          }}>
                                                          <div className="league-week-banner-text">
                                                            <span className="ends">
                                                              Raid Damage:{' '}
                                                              <span className="items-value">
                                                                {`${getRunDataFromID(
                                                                  run.id,
                                                                  'damage2'
                                                                )}`.replace(
                                                                  /\B(?=(\d{3})+(?!\d))/g,
                                                                  ','
                                                                )}
                                                              </span>
                                                            </span>
                                                            <span className="ends">
                                                              PMC Kills:{' '}
                                                              <span className="items-value">
                                                                {getRunDataFromID(
                                                                  run.id,
                                                                  'killsPmc2'
                                                                )}
                                                              </span>
                                                            </span>
                                                            <span className="ends">
                                                              Rogue/Raider
                                                              Kills:{' '}
                                                              <span className="items-value">
                                                                {getRunDataFromID(
                                                                  run.id,
                                                                  'killsRogue2'
                                                                )}
                                                              </span>
                                                            </span>
                                                          </div>
                                                        </Grid>
                                                        <Grid
                                                          item
                                                          xs={12}
                                                          md={4}
                                                          sx={{
                                                            pointerEvents:
                                                              'none'
                                                          }}>
                                                          <div className="league-week-banner-text">
                                                            <span className="ends">
                                                              Scav Kills:{' '}
                                                              <span className="items-value">
                                                                {getRunDataFromID(
                                                                  run.id,
                                                                  'killsScav2'
                                                                )}
                                                              </span>
                                                            </span>
                                                            <span className="ends">
                                                              Guard Kills:{' '}
                                                              <span className="items-value">
                                                                {getRunDataFromID(
                                                                  run.id,
                                                                  'killsGuard2'
                                                                )}
                                                              </span>
                                                            </span>
                                                            <span className="ends">
                                                              Boss Kills:{' '}
                                                              <span className="items-value">
                                                                {getRunDataFromID(
                                                                  run.id,
                                                                  'killsBoss2'
                                                                )}
                                                              </span>
                                                            </span>
                                                          </div>
                                                        </Grid>
                                                      </Grid>
                                                    </div>
                                                  )}
                                                </div>
                                              )
                                            })
                                          ) : (
                                            <div className="no-badges">
                                              No runs to display for the
                                              selected week.
                                            </div>
                                          )}
                                        </div>
                                      )
                                    })
                                ) : (
                                  <div className="no-badges">
                                    No runs to display for the selected week.
                                  </div>
                                )}
                              </AccordionDetails>
                            </Accordion>
                          )
                        })}
                      </div>
                    </TabPanel>
                  )
                })}
              {/* <TabPanel value={statsTab} index={0}>
                Solo
              </TabPanel>
              <TabPanel value={statsTab} index={1}>
                Duo
              </TabPanel>
              <TabPanel value={statsTab} index={2}>
                
              </TabPanel> */}
            </div>
          )}
          <div className="league-submission-form">
            <div className="modal-controls">
              <Button
                className="btn btn-primary btn-cancel"
                onClick={e => {
                  handleClose()
                }}>
                Close Profile
              </Button>
            </div>
          </div>
        </div>
      </Box>
    </Modal>
  )
}

export default EloSummary
