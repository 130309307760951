import {
  Typography,
  Box,
  CircularProgress,
  Alert,
  Link as MuiLink,
  Grid,
  FormControlLabel,
  Switch
} from '@mui/material'
import { Link, useNavigate } from 'react-router-dom'
import React, { useState } from 'react'
import moment from 'moment'
import Button from '@mui/material/Button'
import SubscriptionCancelModal from './modals/SubscriptionCancelModal'
import SubscriptionChangeCard from './modals/SubscriptionChangeCard'
import { useGetCard } from 'components/Stripe/hooks/Cards'
import { StripeApi } from 'api/Stripe'
import { useUserData } from 'hooks'
import { stripeDisplayAmount } from 'utils/Stripe'
import { useUserIsPromotion } from 'hooks/use-user-isPromotion'

const SubscriptionStatus = ({
  id,
  subscription,
  customerId,
  isPrivacy,
  togglePrivacy
}) => {
  const userData = useUserData()
  const isPromotion = useUserIsPromotion()
  const navigate = useNavigate()
  const { stripe = {} } = userData
  const amount = stripe?.balance
    ? stripeDisplayAmount(stripe?.balance)
    : '$0.00'
  const [modalOpen, setModalOpen] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [hasError, setHasError] = useState(false)
  const [isCardChangeOpen, setIsCardChangeOpen] = useState(false)
  const { cardDetails = {} } = useGetCard(subscription?.default_payment_method)
  const { card = {} } = cardDetails
  const [deleteSubscription] = StripeApi.useDeleteSubscriptionMutation()
  const [updateSubscription] = StripeApi.useUpdateSubscriptionMutation()

  const handleError = () => {
    setHasError(true)
    setModalOpen(false)
    setIsLoading(false)
    setTimeout(() => {
      setHasError(false)
    }, 3000)
  }

  const handleClose = () => setModalOpen(false)
  const handleCardChangeClose = () => setIsCardChangeOpen(false)

  const cancelSubscription = event => {
    event.preventDefault()
    setIsLoading(true)
    deleteSubscription({ subscriptionId: subscription.id })
      .then(() => {
        setModalOpen(false)
        setIsLoading(false)
      })
      .catch(() => {
        handleError()
      })
  }

  const resumeSubscription = event => {
    event.preventDefault()
    setIsLoading(true)
    const data = {
      cancel_at_period_end: false
    }
    updateSubscription({
      subscriptionId: subscription.id,
      subscriptionData: data
    })
      .then(() => {
        setIsLoading(false)
      })
      .catch(() => {
        handleError()
      })
  }

  const nexBillAmount = () => {
    const price = 899
    if (stripe?.balance < 0) {
      if (stripe?.balance < price * -1) {
        return `0.00 (8.99 will be deducted from your referral credit)`
      } else {
        return `${parseFloat((price + stripe?.balance) / 100).toFixed(
          2
        )} ($${parseFloat((stripe?.balance * -1) / 100).toFixed(
          2
        )} will be deducted from your referral credit)`
      }
    } else {
      return `8.99`
    }
  }

  return (
    <div>
      <div className="aside-header aside-premium">
        <span className="premium">Subscription Status</span>
      </div>
      <div className="content-panel sub-status-grid">
        {!subscription ? (
          <div>
            <span className="sub-status">
              <span className="sub-status-label">Status </span>
              <span className="sub-status-value sub-status-inactive">
                Not Active
              </span>
            </span>
            <div>
              <Button
                className="btn btn-primary"
                onClick={e => {
                  e.preventDefault()
                  navigate('/subscribe')
                }}>
                Join Fence Premium
              </Button>
            </div>
          </div>
        ) : (
          <React.Fragment>
            {hasError && (
              <Alert severity="error">
                There was an error processing your request, please try again.
              </Alert>
            )}
            <Grid container spacing={2} sx={{ marginTop: 0 }}>
              <Grid item xs={12} md={6} sx={{ marginTop: 0 }}>
                <span className="sub-status">
                  <span className="sub-status-label">Status </span>
                  <span
                    className={`sub-status-value ${
                      subscription.status === 'past_due' ? 'sub-past-due' : ''
                    }`}>
                    {isPromotion
                      ? 'Active'
                      : subscription.status === 'past_due'
                      ? 'Past Due'
                      : subscription.status}
                  </span>
                </span>
                {!isPromotion ? (
                  <>
                    <span className="sub-joined is-private">
                      <span className="sub-joined-label">
                        Referral Credit:{' '}
                      </span>
                      <span className="sub-joined-value">{amount}</span>
                    </span>
                    <span className="sub-joined is-private">
                      <span className="sub-joined-label">Premium Since: </span>
                      <span className="sub-joined-value">
                        {moment(subscription.created).format('MMMM D, YYYY')}
                      </span>
                    </span>
                    {subscription.cancel_at_period_end ? (
                      <Typography
                        variant="p"
                        component="p"
                        className="is-private"
                        sx={{ color: '#e41a1a' }}>
                        Subscription will be canceled on{' '}
                        {moment
                          .unix(subscription.current_period_end)
                          .format('MMMM D, YYYY')}
                        .
                      </Typography>
                    ) : (
                      <React.Fragment>
                        <span className="sub-joined is-private">
                          <span className="sub-joined-label">
                            Billing Period:{' '}
                          </span>
                          <span className="sub-joined-value">
                            {moment
                              .unix(subscription.current_period_start)
                              .format('MMM D')}
                            {' \u2013 '}
                            {moment
                              .unix(subscription.current_period_end)
                              .format('MMM D, YYYY')}
                          </span>
                        </span>
                        {subscription.status === 'active' && (
                          <span className="disclaimer sub-disclaimer is-private">
                            {`You will be automatically charged $${nexBillAmount()} on the next billing
                  cycle.`}
                          </span>
                        )}
                      </React.Fragment>
                    )}
                  </>
                ) : (
                  <span className="disclaimer sub-disclaimer">
                    You have been granted a promotional subscription of Fence
                    Premium by the admins.
                  </span>
                )}
              </Grid>
              {!isPromotion ? (
                <Grid item xs={12} md={6} sx={{ marginTop: 0 }}>
                  <Box className="sub-ctas">
                    <div className="sub-cta">
                      <FormControlLabel
                        control={
                          <Switch
                            checked={isPrivacy}
                            onChange={togglePrivacy}
                          />
                        }
                        label="Privacy Mode"
                      />
                    </div>
                    {subscription.status === 'active' &&
                      !subscription.cancel_at_period_end && (
                        <div className="sub-cta">
                          <button
                            className="btn btn-primary btn-grey"
                            onClick={() => setModalOpen(true)}>
                            Cancel Subscription
                          </button>
                        </div>
                      )}
                    {subscription.status === 'active' &&
                      subscription.cancel_at_period_end && (
                        <div className="sub-cta">
                          <Button
                            variant="contained"
                            disabled={isLoading}
                            onClick={resumeSubscription}>
                            {isLoading && (
                              <CircularProgress
                                style={{
                                  color: '#000',
                                  height: 20,
                                  width: 20,
                                  marginRight: 10
                                }}
                              />
                            )}
                            {isLoading ? (
                              <span>Resuming...</span>
                            ) : (
                              <span>Resume Subscription</span>
                            )}
                          </Button>
                        </div>
                      )}
                    {subscription.status !== 'active' && (
                      <div className="sub-cta">
                        <Link
                          className="btn btn-primary"
                          to="/subscribe?skipPromo=true">
                          Subscribe Now
                        </Link>
                      </div>
                    )}
                    {subscription.status === 'active' &&
                      !subscription.cancel_at_period_end &&
                      card && (
                        <div className="sub-cta">
                          <Typography
                            variant="p"
                            component="p"
                            sx={{ marginTop: '5px' }}>
                            {/* {card.brand} &bull;&bull;&bull;&bull; {card.last4}{' '}
                    {card.exp_month}/{card.exp_year}{' '} */}
                            <MuiLink
                              href="#"
                              onClick={() => setIsCardChangeOpen(true)}>
                              Change Payment Method
                            </MuiLink>
                          </Typography>
                        </div>
                      )}
                  </Box>
                </Grid>
              ) : (
                <Grid item xs={12} md={6} sx={{ marginTop: 0 }}>
                  <Box className="sub-ctas">
                    <div className="sub-cta">
                      <FormControlLabel
                        control={
                          <Switch
                            checked={isPrivacy}
                            onChange={togglePrivacy}
                          />
                        }
                        label="Privacy Mode"
                      />
                    </div>
                  </Box>
                </Grid>
              )}
            </Grid>

            <SubscriptionChangeCard
              subscriptionId={subscription.id}
              isCardChangeOpen={isCardChangeOpen}
              handleCardChangeClose={handleCardChangeClose}
              customerId={customerId}
              currentDefault={subscription?.default_payment_method}
            />

            <SubscriptionCancelModal
              isLoading={isLoading}
              setModalOpen={setModalOpen}
              modalOpen={modalOpen}
              handleClose={handleClose}
              cancelSubscription={cancelSubscription}
            />
          </React.Fragment>
        )}
      </div>
    </div>
  )
}

export default SubscriptionStatus
