import { TableRow, TableCell } from '@mui/material'
import IconInfo from '../../../assets/img/icon-info.png'
import { useDispatch } from 'react-redux'
import Countdown from 'react-countdown'
import moment from 'moment'
import { setRankingsModal, setRankingsModalLeague } from 'store/slice/appSlice'
import Username from 'components/Username'
import { useCallback, useEffect, useState } from 'react'
import CommunityService from 'services/CommunityService'
import UsernameAvatar from 'components/UsernameDisplay/UsernameAvatar'
import UsernameStreamLink from 'components/UsernameDisplay/UsernameStreamLink'

const LeagueParticipant = ({ league, tier, pendingRuns, username }) => {
  const dispatch = useDispatch()
  const [userIsLive, setUserIsLive] = useState(false)

  const rendererQualify = ({ hours, minutes, seconds }) => {
    const needsPad = value => {
      if (value > 9) {
        return false
      }
      return true
    }
    return (
      <span className="timer">
        {needsPad(hours) ? '0' + hours : hours}
        <span className="label">H</span>
        {needsPad(minutes) ? '0' + minutes : minutes}
        <span className="label">M</span>
        {needsPad(seconds) ? '0' + seconds : seconds}
        <span className="label">S</span>
      </span>
    )
  }

  const isQualifying = (uid, league) => {
    let result = '--'
    pendingRuns.forEach(run => {
      if (run.id === uid && run.league === league) {
        if (run.ended === true) {
          result = 'Ended...'
        } else {
          result = (
            <Countdown
              renderer={rendererQualify}
              overtime={true}
              date={moment(run.started.seconds * 1000).toDate()}
            />
          )
        }
      }
    })
    return result
  }

  const isLive = useCallback(async login => {
    const response = await CommunityService.checkUserIsLive(login).then(
      response => {
        if (response.length > 0) {
          setUserIsLive(true)
        } else {
          setUserIsLive(false)
        }
      }
    )
    return response
  }, [])

  useEffect(() => {
    if (username) {
      isLive(username?.login)
    }
  }, [username, isLive])

  return (
    <TableRow
      className="ranks-row"
      sx={{
        '&:last-child td, &:last-child th': { border: 0 }
      }}>
      <TableCell component="td" scope="row" align="left" width={18}>
        <div className="rankings-rank">
          <div className="rankings-trophy"></div>
          <div className="rankings-badge">
            <div className="rank-badge">
              <div className={`badge-outer badge-${tier.id} badge-32`}>
                <div className="badge-inner"></div>
                <div className="badge-rank">
                  <span>
                    {'--'}
                    {/* <sup>th</sup> */}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </TableCell>
      <TableCell>
        <UsernameStreamLink username={username}>
          <UsernameAvatar
            username={username}
            style={{
              width: 24,
              height: 24,
              marginRight: 10
            }}
          />
          <span className={userIsLive ? 'is-live' : ''}>
            <Username username={username} />
          </span>
        </UsernameStreamLink>
      </TableCell>
      <TableCell align="center" width={90}>
        {isQualifying(username.id, league.id)}
      </TableCell>
      <TableCell align="right" className="rankings-points" width={110}>
        <div
          className="rankings-points-inner"
          style={{
            cursor: league.id !== 'duo' ? 'pointer' : 'default'
          }}
          onClick={() => {
            if (league.id !== 'duo') {
              dispatch(setRankingsModal(username.id))
              dispatch(setRankingsModalLeague(league.id))
            }
          }}>
          {parseFloat(0).toFixed(2)} <span>PTS</span>
          {league.id !== 'duo' && <img src={IconInfo} alt="Info Icon" />}
        </div>
      </TableCell>
    </TableRow>
  )
}

export default LeagueParticipant
