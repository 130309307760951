import { useState } from 'react'
import Username from '../Username'
import {
  Button,
  CircularProgress,
  FormControl,
  TextareaAutosize
} from '@mui/material'
import { useUserData } from 'hooks'
import NewsService from 'services/NewsService'
import dompurify from 'dompurify'
import UserAvatar from '../UserDisplay/UserAvatar'
import TournamentService from 'services/TournamentService'

const SubmitComment = ({ storyProps }) => {
  const [commentLoading, setCommentLoading] = useState(false)
  const [newsComment, setNewsComment] = useState('')
  const handleCommentChange = (e, v) => {
    setNewsComment(e.target.value)
  }
  const postComment = () => {
    if (newsComment !== '') {
      setCommentLoading(true)
      if (storyProps.category === 'tarkovarena') {
        TournamentService.postComment(
          storyProps.id,
          dompurify.sanitize(newsComment),
          storyProps.category,
          storyProps.type,
          result => {
            setCommentLoading(false)
            setNewsComment('')
            //alert success
          }
        )
      } else {
        NewsService.postComment(
          storyProps.id,
          dompurify.sanitize(newsComment),
          result => {
            setCommentLoading(false)
            setNewsComment('')
            //alert success
          }
        )
      }
    }
  }

  const userData = useUserData()
  return (
    <div>
      <FormControl className="input-news-comment">
        <TextareaAutosize
          minRows={2}
          id="news-story-comment"
          onChange={(e, v) => {
            e.persist()
            handleCommentChange(e, v)
          }}
          value={newsComment}
          placeholder="Write a comment..."
          variant="outlined"
        />
      </FormControl>
      <div className="comment-controls">
        <div className="comment-username">
          <UserAvatar user={userData} className="avatar" />
          <span className="username">
            <Username username={userData} />
          </span>
        </div>
        <Button
          className="btn btn-primary"
          disabled={commentLoading}
          onClick={e => {
            e.preventDefault()
            postComment()
          }}>
          {commentLoading && (
            <CircularProgress
              style={{
                color: '#000',
                height: 15,
                width: 15,
                marginRight: 10
              }}
            />
          )}
          Post
        </Button>
      </div>
    </div>
  )
}

export default SubmitComment
