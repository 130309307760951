import { useState } from 'react'
import {
  Autocomplete,
  Alert,
  TextField,
  Button,
  CircularProgress
} from '@mui/material'
import { useAppSettings, useUser } from 'hooks'
import { LeagueServiceApi } from 'api/LeagueServiceApi'
import * as ga4 from 'utils/ga4'
import { UserServiceApi } from 'api/UserServiceApi'

const LeagueDuoForm = () => {
  const appSettings = useAppSettings()
  const [inviteLoading, setInviteLoading] = useState(false)
  const [inviteError, setInviteError] = useState('')
  const [duoInviteUser, setDuoInviteUser] = useState({})
  const user = useUser()
  const [inviteDuo] = LeagueServiceApi.useInviteDuoPartnerMutation()
  const [searchUsername, { data: usernames = [], isLoading }] =
    UserServiceApi.useSearchUsernameMutation()

  const handleUserSelect = (e, v) => {
    setDuoInviteUser(v)
  }

  const inviteDuoPartner = async invite => {
    setInviteLoading(true)
    await inviteDuo({ uid: invite.id })
      .unwrap()
      .then(data => {
        if (data.error) {
          setInviteError(data.error)
        }
        setTimeout(() => {
          setInviteError('')
        }, 5000)
        setInviteLoading(false)
        ga4.sendEvent({
          category: 'Duo Partner',
          action: 'Invite',
          nonInteraction: false
        })
      })
  }

  return (
    <div>
      <span className="helper-text">
        Make sure your duo partner has logged into the Fence.gg website first
        before inviting them. Both members of the team must have Fence premium.
      </span>
      {inviteError !== '' && (
        <div className="invite-error">
          <Alert severity="error">{inviteError}</Alert>
        </div>
      )}
      <Autocomplete
        disablePortal
        getOptionLabel={option => option.display_name}
        freeSolo
        noOptionsText={'No Users Found'}
        loading={isLoading}
        onChange={(e, v) => {
          handleUserSelect(e, v)
        }}
        onInputChange={(e, value) => {
          if (value.length >= 3) {
            searchUsername({ username: value })
          }
        }}
        options={usernames.filter(
          u => u.uid !== user.uid && u.uid !== appSettings.owner
        )}
        renderInput={params => (
          <TextField {...params} label="Search for Duo Partner..." />
        )}
      />
      <div className="buttons-right">
        <Button
          className="btn btn-primary"
          disabled={inviteLoading}
          onClick={e => {
            e.preventDefault()
            inviteDuoPartner(duoInviteUser)
          }}>
          {inviteLoading && (
            <CircularProgress
              style={{
                color: '#000',
                height: 15,
                width: 15,
                marginRight: 10
              }}
            />
          )}
          Invite Duo Partner
        </Button>
      </div>
    </div>
  )
}

export default LeagueDuoForm
