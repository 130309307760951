import Firebase from 'utils/Firebase'
import { getCountFromServer, getFirestore, query } from 'firebase/firestore'
import { collection, onSnapshot, getDoc, doc } from 'firebase/firestore'

const db = getFirestore(Firebase)

async function getUsernames(callback) {
  onSnapshot(collection(db, 'usernames'), docs => {
    callback(docs)
  })
}

async function getUsernameCount() {
  const getCount = await getCountFromServer(
    query(collection(db, 'usernames'))
  ).then(docs => docs.data().count)
  return getCount
}

async function getUsernameByID(id, callback) {
  // onSnapshot(doc(db, 'usernames', id), doc => {
  //   if (typeof callback === 'function') {
  //     callback(doc)
  //   }
  // })
  const docRef = await getDoc(doc(db, 'usernames', id))
  if (typeof callback === 'function') {
    callback(docRef.data())
  }
}

const UsernameService = {
  getUsernames,
  getUsernameCount,
  getUsernameByID
}

export default UsernameService
