import { UserServiceApi } from 'api/UserServiceApi'
import { useCallback, useEffect, useState } from 'react'
import Username from 'components/Username'
import CommunityService from 'services/CommunityService'
import UsernameAvatar from 'components/UsernameDisplay/UsernameAvatar'

const SubmittedBy = ({ className, uid, pts }) => {
  const [userIsLive, setUserIsLive] = useState(false)
  const [getUsername, { data: username }] =
    UserServiceApi.useLazyGetUsernameByIdCacheQuery()

  useEffect(() => {
    if (uid) {
      getUsername({ id: uid }, true)
    }
  }, [getUsername, uid])

  const isLive = useCallback(async login => {
    const response = await CommunityService.checkUserIsLive(login).then(
      response => {
        if (response.length > 0) {
          setUserIsLive(true)
        } else {
          setUserIsLive(false)
        }
      }
    )
    return response
  }, [])

  useEffect(() => {
    if (username) {
      isLive(username?.login)
    }
  }, [username, isLive])

  return (
    <span className={className}>
      <UsernameAvatar username={username} className={'avatar'} />
      <span
        className={`username 
                      ${userIsLive ? 'is-live' : ''}
                    `}>
        <Username username={username} />
      </span>
      {pts && <span className="summary-points">{pts} PTS</span>}
    </span>
  )
}

export default SubmittedBy
