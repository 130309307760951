import React from 'react'
import { useAppIsLoggedIn } from 'hooks'
import Banner from 'components/Banner'
import TournamentsList from 'components/TournamentsList'

const Tournaments = () => {
  const isLoggedIn = useAppIsLoggedIn()
  return (
    <>
      <Banner />
      <TournamentsList />
    </>
  )
}

export default Tournaments
