import React from 'react'

import TwitterSocial from '../assets/img/social-twitter.png'
import DiscordSocial from '../assets/img/social-discord.png'
import TiktokSocial from '../assets/img/social-tiktok.png'
import YoutubeSocial from '../assets/img/social-youtube.png'
import FacebookSocial from '../assets/img/social-facebook.png'
import NightMode from '../assets/img/night-mode.png'
import EnglishFlag from '../assets/img/flag-english.png'

const SocialLinks = () => {
  return (
    <div className="social-links">
      <div className="social-link">
        <a
          href="https://twitter.com/fence_gg"
          target="_blank"
          rel={'noreferrer'}>
          <img src={TwitterSocial} alt={'Twitter'} />
        </a>
      </div>
      <div className="social-link">
        <a
          href="https://discord.gg/wBaSq8Nvjf"
          target="_blank"
          rel={'noreferrer'}>
          <img src={DiscordSocial} alt={'Discord'} />
        </a>
      </div>
      <div className="social-link">
        <a
          href="https://www.tiktok.com/@fencegg"
          target="_blank"
          rel={'noreferrer'}>
          <img src={TiktokSocial} alt={'TikTok'} />
        </a>
      </div>
      <div className="social-link">
        <a
          href="https://www.youtube.com/channel/UC3yTL9yuDQ2Gk6doU_ahxGw"
          target="_blank"
          rel={'noreferrer'}>
          <img src={YoutubeSocial} alt={'Youtube'} />
        </a>
      </div>
      <div className="social-link">
        <a
          href="https://www.facebook.com/profile.php?id=100087352035143"
          target="_blank"
          rel={'noreferrer'}>
          <img src={FacebookSocial} alt={'Facebook'} />
        </a>
      </div>
      <div className="divider"></div>
      <div className="social-link">
        <a
          href="#"
          onClick={e => {
            e.preventDefault()
          }}>
          <img src={NightMode} alt={'Night Mode'} />
        </a>
      </div>
      <div className="social-link">
        <a
          href="#"
          onClick={e => {
            e.preventDefault()
          }}>
          <img src={EnglishFlag} alt={'English Flag'} />
        </a>
      </div>
    </div>
  )
}

export default SocialLinks
