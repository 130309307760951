import { UserServiceApi } from 'api/UserServiceApi'
import Username from 'components/Username'
import { useUserIsVodder } from 'hooks'
import { useCallback, useEffect, useState } from 'react'
import TimeAgo from 'react-timeago'
import CommunityService from 'services/CommunityService'
import IconOpen from '../../assets/img/icon-open.png'
import { Box, CircularProgress } from '@mui/material'
import UsernameAvatar from 'components/UsernameDisplay/UsernameAvatar'
import UsernameStreamLink from 'components/UsernameDisplay/UsernameStreamLink'

const DisplayEntry = ({ entry, bounty, setOpenReviewModal, key }) => {
  const isVodder = useUserIsVodder()
  const [userIsLive, setUserIsLive] = useState(false)
  const [getUsername, { data: username, isLoading }] =
    UserServiceApi.useLazyGetUsernameByIdCacheQuery()

  useEffect(() => {
    if (entry?.submittedBy) {
      getUsername({ id: entry?.submittedBy }, true)
    }
  }, [getUsername, entry.submittedBy])

  const isLive = useCallback(async login => {
    const response = await CommunityService.checkUserIsLive(login).then(
      response => {
        if (response.length > 0) {
          setUserIsLive(true)
        } else {
          setUserIsLive(false)
        }
      }
    )
    return response
  }, [])

  useEffect(() => {
    if (username) {
      isLive(username?.login)
    }
  }, [username, isLive])

  if (isLoading) {
    return (
      <div
        className={`bounty-entry ${
          entry.winner === true ? 'entry-winner' : ''
        }`}>
        <div className="entry-user">
          <Box textAlign={'center'}>
            <CircularProgress size={'20px'} />
          </Box>
        </div>
      </div>
    )
  }

  return (
    <div
      className={`bounty-entry ${entry.winner === true ? 'entry-winner' : ''}`}>
      <div className="entry-user">
        <span className="entry-position">{key + 1}</span>
        <UsernameStreamLink username={username}>
          <UsernameAvatar username={username} />
          <span className={userIsLive ? 'is-live' : ''}>
            <Username username={username} />
          </span>
        </UsernameStreamLink>
      </div>
      <div className="entry-time">
        <TimeAgo date={entry.createdAt.seconds * 1000} />
      </div>
      <div className="entry-link">
        <a href={entry.twitchLink} target="_blank" rel="noreferrer">
          VOO/Clip
        </a>
        {bounty.status !== 'completed' && isVodder && (
          <div
            className="entry-vodder"
            onClick={() => {
              setOpenReviewModal(true)
            }}>
            <img src={IconOpen} alt={'Open Icon'} />
          </div>
        )}
      </div>
    </div>
  )
}

export default DisplayEntry
