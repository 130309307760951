import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { auth } from 'configs/firebaseConfig'
import { getHostFunctions } from 'utils/GetHost'

const base = getHostFunctions()

const getUserToken = async () => {
  const token = await auth.currentUser?.getIdToken()
  return token
}

export const LeagueServiceApi = createApi({
  reducerPath: 'LeagueService',
  baseQuery: fetchBaseQuery({
    baseUrl: `${base}`,
    prepareHeaders: async headers => {
      headers.set('Content-Type', 'application/json')
      const token = await getUserToken()
      if (token) headers.set('Authorization', `Bearer ${token}`)
      return headers
    }
  }),
  refetchOnFocus: true,
  tagTypes: ['LeagueTierParticipants', 'LeagueRanks'],
  endpoints: build => ({
    getLeagueTierParticipants: build.mutation({
      query: ({ league, tier }) => ({
        url: `/getLeagueTierParticipants?league=${league}&tier=${tier}`
      }),
      transformResponse: response => response.success
    }),
    startLeagueRun: build.mutation({
      query: ({ leagueId, tierId, season, week }) => ({
        url: `/startLeagueRun?leagueId=${leagueId}&tierId=${tierId}&season=${season}&week=${week}`
      })
    }),
    endLeagueRun: build.mutation({
      query: () => ({
        url: `/endLeagueRun`
      })
    }),
    reviewLeagueRun: build.mutation({
      query: ({ id, callback }) => ({
        url: `/reviewLeagueRun?id=${id}`
      })
    }),
    unReviewLeagueRun: build.mutation({
      query: ({ id }) => ({
        url: `/unReviewLeagueRun?id=${id}`
      }),
      invalidatesTags: ['LeagueTierParticipants']
    }),
    denyReviewLeagueRun: build.mutation({
      query: ({ id, reason }) => ({
        url: `/denyReviewLeagueRun?id=${id}&reason=${reason}`
      })
    }),
    approveReviewLeagueRun: build.mutation({
      query: ({ id, run }) => ({
        url: `/approveReviewLeagueRun?id=${id}&${Object.keys(run)
          .map(key => key + '=' + run[key])
          .join('&')}`
      }),
      invalidatesTags: ['LeagueTierParticipants']
    }),
    deleteLeagueRun: build.mutation({
      query: () => ({
        url: `/deleteLeagueRun`
      })
    }),
    submitLeagueRun: build.mutation({
      query: ({ twitchLink, twitchLink2 }) => ({
        url: `/submitLeagueRun?twitchLink=${encodeURIComponent(
          twitchLink
        )}&twitchLink2=${encodeURIComponent(twitchLink2)}`
      })
    }),
    getRanks: build.query({
      query: ({ league, season }) => ({
        url: `/getRanks?league=${league}&season=${season}`
      }),
      providesTags: result =>
        result
          ? [
              ...result.map(({ id }) => ({
                type: 'LeagueRanks',
                id
              })),
              'LeagueRanks'
            ]
          : ['LeagueRanks']
    }),
    reCalcScavRanks: build.mutation({
      query: () => ({
        url: `/reCalcScavRanks`
      }),
      invalidatesTags: ['LeagueRanks', 'LeagueTierParticipants']
    }),
    reCalcDuosRanks: build.mutation({
      query: () => ({
        url: `/reCalcDuosRanks`
      }),
      invalidatesTags: ['LeagueRanks', 'LeagueTierParticipants']
    }),
    testDuoRanks: build.mutation({
      query: () => ({
        url: `/testDuosRanks`
      }),
      invalidatesTags: ['LeagueRanks', 'LeagueTierParticipants']
    }),
    issueBadges: build.mutation({
      query: ({ league }) => ({
        url: `/issueBadges?league=${league}`
      }),
      invalidatesTags: ['LeagueRanks', 'LeagueTierParticipants']
    }),
    testRanks: build.mutation({
      query: () => ({
        url: `/testRanks`
      })
    }),
    markMyRunsAsRead: build.mutation({
      query: () => ({
        url: `/markMyRunsAsRead`
      })
    }),
    getRunsByIds: build.mutation({
      query: ({ runs }) => ({
        url: `/getRunsByIds?runs=${runs}`
      })
    }),
    inviteDuoPartner: build.mutation({
      query: ({ uid }) => ({
        url: `/inviteDuoPartner?invitee=${uid}`
      })
    }),
    cancelInviteDuoPartner: build.mutation({
      query: ({ uid }) => ({
        url: `/cancelInviteDuoPartner?invitee=${uid}`
      })
    }),
    declineDuoPartner: build.mutation({
      query: ({ uid }) => ({
        url: `/declineDuoPartner?inviter=${uid}`
      })
    }),
    acceptDuoPartner: build.mutation({
      query: ({ uid }) => ({
        url: `/acceptDuoPartner?inviter=${uid}`
      })
    }),
    disbandDuos: build.mutation({
      query: () => ({
        url: `/disbandDuos`
      })
    })
  })
})
