import { UserServiceApi } from 'api/UserServiceApi'
import { useEffect } from 'react'

const InReview = ({ run }) => {
  const [getUsername, { data: username }] =
    UserServiceApi.useLazyGetUsernameByIdCacheQuery()

  useEffect(() => {
    if (run.lockedBy) {
      getUsername({ id: run.lockedBy }, true)
    }
  }, [getUsername, run.lockedBy])

  return (
    <span>
      <span>In Review By {username?.display_name}</span>
    </span>
  )
}

export default InReview
