import React from 'react'

const SiteTagline = () => {
  return (
    <span className="tagline">
      Competitive leagues and streaming overlay tools for Escape From Tarkov
      creators
    </span>
  )
}

export default SiteTagline
