import Banner from 'components/Banner'
import LatestNews from 'components/LatestNews'

const Home = () => {
  return (
    <>
      <Banner />
      <LatestNews />
    </>
  )
}

export default Home
