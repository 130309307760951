import React, { useCallback, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import PropTypes from 'prop-types'
import { Tab, Tabs } from '@mui/material'
import { TournamentServiceApi } from 'api/TournamentServiceApi'
import moment from 'moment'
import EventComments from 'components/EventComments'
import CenteredLoader from 'components/CenteredLoader'
import EventPlayerStats from 'components/EventPlayerStats'
import EventHighlights from 'components/EventHighlights'
import EventMapPool from 'components/EventMapPool'
import EventBracket from 'components/EventBracket'
import EventsResults from 'components/EventsResults'
import EventMVP from 'components/EventMVP'
import EventStandings from 'components/EventStandings'
import EventChallengeStandings from 'components/EventChallengeStandings'
import IconSocialX from '../assets/img/icon-social-x.jpg'
import IconSocialTwitch from '../assets/img/icon-social-twitch.jpg'
import IconSocialInstagram from '../assets/img/icon-social-instagram.jpg'
import IconSocialYoutube from '../assets/img/icon-social-youtube.jpg'
import IconSocialTiktok from '../assets/img/icon-social-tiktok.jpg'
import { TabContext, TabList, TabPanel } from '@mui/lab'
import { styled } from '@mui/material/styles'

function a11yTabProps(index) {
  return {
    'id': `event-tab-${index}`,
    'aria-controls': `event-tabpanel-${index}`
  }
}

const EventPage = () => {
  const params = useParams()
  const { tab = 'overview' } = params
  const navigate = useNavigate()
  const [teams, setTeams] = React.useState([])
  const [matches, setMatches] = React.useState([])
  const [participantCount, setParticipantCount] = React.useState('--')
  const [organizer, setOrganizer] = useState({})

  const TabPanelStyled = styled(TabPanel)(({ theme }) => ({
    padding: 0
  }))

  const { tournament, isLoading } = TournamentServiceApi.useGetEventsQuery(
    { ids: parseInt(params.id, 10) },
    {
      selectFromResult: ({ data, isLoading, isSuccess }) => ({
        tournament: data?.find(
          tournament => tournament.id === parseInt(params.id, 10)
        ),
        isLoading
      })
    }
  )
  const [getOrganizersApi] =
    TournamentServiceApi.useLazyGetOrganizersByIdsQuery()

  const getOrganizersAsync = useCallback(async () => {
    const asyncOrganizers = await getOrganizersApi(
      { ids: tournament?.organizer },
      true
    ).unwrap()
    setOrganizer(asyncOrganizers)
  }, [tournament])

  useEffect(() => {
    if (tournament?.organizer !== 'undefined') {
      getOrganizersAsync()
    }
  }, [getOrganizersAsync, tournament])

  const [getParticipantCountApi] =
    TournamentServiceApi.useLazyGetParticipantCountQuery()

  const getParticipantCountAsync = useCallback(async () => {
    const asyncParticipantCount = await getParticipantCountApi(
      { docId: tournament?.docId },
      true
    ).unwrap()
    setParticipantCount(asyncParticipantCount)
  }, [tournament])

  useEffect(() => {
    if (
      tournament?.matchType === 'challenge' &&
      typeof tournament?.docId !== 'undefined'
    ) {
      getParticipantCountAsync()
    }
  }, [getParticipantCountAsync, tournament])

  const [getTeamsApi] = TournamentServiceApi.useLazyGetTeamsByIdsQuery()

  const getTeamsAsync = useCallback(async () => {
    const asyncTeams = await Promise.all(
      tournament?.teams.map(async team => {
        return await getTeamsApi({ ids: team.id }, true).unwrap()
      })
    )
    setTeams(asyncTeams)
  }, [tournament?.teams])

  useEffect(() => {
    if (tournament?.teams?.length > 0) {
      getTeamsAsync()
    }
  }, [getTeamsAsync, tournament])

  const [getMatchesApi] = TournamentServiceApi.useLazyGetMatchesByIdsQuery()

  const getMatchesAsync = useCallback(async () => {
    const asyncMatches = await Promise.all(
      tournament?.matches.map(async team => {
        return await getMatchesApi({ ids: team.id }, true).unwrap()
      })
    )
    setMatches(asyncMatches)
  }, [tournament?.matches])

  useEffect(() => {
    if (tournament?.matches?.length > 0) {
      getMatchesAsync()
    }
  }, [getMatchesAsync, tournament])

  const handleChangeTabs = (event, newValue) => {
    navigate(`/event/${params.slug}/${params.id}/${newValue}`)
  }

  return (
    <div>
      <div className="aside-header aside-news">
        <span className="events">
          {tournament?.display_name
            ? tournament?.display_name
            : 'Loading Event...'}
        </span>
      </div>
      {isLoading ? (
        <CenteredLoader />
      ) : (
        <div>
          <div
            className="event-header"
            style={{
              background: `url(${tournament?.poster}) no-repeat center center`
            }}>
            {tournament?.eventList === 'past' ? (
              <span className="event-status">Event Ended</span>
            ) : tournament?.eventList === 'ongoing' ? (
              <span className="event-status event-status-green">
                Ongoing Event
              </span>
            ) : tournament?.eventList === 'upcoming' ? (
              <span className="event-status event-status-green">
                Upcoming Event
              </span>
            ) : (
              ''
            )}
          </div>
          <div className="event-page-details">
            <div className="event-detail">
              <span className="event-detail-label">Dates</span>
              <span className="event-detail-value">
                {moment(tournament?.starts).isSame(
                  moment(tournament?.ends),
                  'day'
                )
                  ? `${moment(tournament?.starts).format('MMM D, yyy')}`
                  : `${moment(tournament?.starts).format('MMM D')}—${moment(
                      tournament?.ends
                    ).format('D, YYYY')}`}
              </span>
            </div>
            <div className="event-detail">
              <span className="event-detail-label">Prize Pool</span>
              <span className="event-detail-value">
                {`${
                  tournament?.prizepool?.currency === 'USD'
                    ? '$'
                    : tournament?.prizepool?.currency === 'EUR'
                    ? '€'
                    : tournament?.prizepool?.currency === 'GBP'
                    ? '£'
                    : ''
                }${tournament?.prizepool?.amount
                  ?.toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`}
              </span>
            </div>
            <div className="event-detail">
              <span className="event-detail-label">Game</span>
              <span className="event-detail-value">
                {tournament?.game === 'tarkovarena'
                  ? 'Tarkov Arena'
                  : tournament?.game === 'tarkov'
                  ? 'Escape From Tarkov'
                  : ''}
              </span>
            </div>
            <div className="event-detail">
              <span className="event-detail-label">
                {tournament?.matchType === 'challenge'
                  ? 'Participants'
                  : 'Teams'}
              </span>
              <span className="event-detail-value">
                {tournament?.matchType === 'challenge'
                  ? participantCount?.participants
                      ?.toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                  : tournament?.teams?.length}
              </span>
            </div>
            <div className="event-detail">
              <span className="event-detail-label">Location</span>
              <span className="event-detail-value">
                {tournament?.location?.display_name}
              </span>
            </div>
          </div>
          <div className="content-panel">
            {tournament?.matchType === 'challenge' ? (
              <div>
                {/* <Tabs
                  value={tab}
                  className="event-tabs event-challenge-tabs"
                  onChange={handleChangeTabs}
                  aria-label="basic tabs example">
                  <Tab
                    className="event-tab event-challenge-tab"
                    label={<span className="league-label">Standings</span>}
                    {...a11yTabProps(0)}
                  />
                  <Tab
                    className="event-tab event-challenge-tab"
                    label={<span className="league-label">Rules</span>}
                    {...a11yTabProps(1)}
                  />
                </Tabs>
                <TabPanel value={tab} index={0}> */}
                {typeof organizer?.display_name !== 'undefined' && (
                  <div className="event-organizer">
                    <span className="event-organizer-title">
                      <span>Hosted by {organizer.display_name}</span>
                      <span className="player-socials">
                        {organizer?.socials?.map(social => {
                          return (
                            <a
                              key={social.id}
                              href={social.url}
                              target="_blank">
                              {social.id === 'twitter' && (
                                <img src={IconSocialX} />
                              )}
                              {social.id === 'twitch' && (
                                <img src={IconSocialTwitch} />
                              )}
                              {social.id === 'instagram' && (
                                <img src={IconSocialInstagram} />
                              )}
                              {social.id === 'youtube' && (
                                <img src={IconSocialYoutube} />
                              )}
                              {social.id === 'tiktok' && (
                                <img src={IconSocialTiktok} />
                              )}
                            </a>
                          )
                        })}
                      </span>
                    </span>
                    <span className="event-organizer-desc">
                      {organizer.desc}
                    </span>
                  </div>
                )}
                {tournament?.teams?.length > 0 ? (
                  <div style={{ paddingTop: 20 }}>
                    <TabContext value={tab}>
                      <TabList
                        value={tab}
                        className="event-tabs event-challenge-tabs"
                        onChange={handleChangeTabs}
                        aria-label="basic tabs example">
                        <Tab
                          className="event-tab event-challenge-tab"
                          value={'overview'}
                          label={
                            <span className="league-label">Standings</span>
                          }
                          {...a11yTabProps(0)}
                        />
                        <Tab
                          className="event-tab event-challenge-tab"
                          value={'teams'}
                          label={<span className="league-label">Teams</span>}
                          {...a11yTabProps(1)}
                        />
                      </TabList>
                      <TabPanelStyled value={'overview'} index={0}>
                        <EventChallengeStandings
                          tournament={tournament}
                          organizer={organizer}
                        />
                      </TabPanelStyled>
                      <TabPanelStyled value={'teams'} index={1}>
                        <div className="events-box events-standings">
                          <div className="events-box-header">
                            <span className="events-title">Teams</span>
                          </div>
                          <div className="teams-list">
                            {tournament?.teams?.map(team => {
                              return (
                                <div className="teams-list-team">
                                  <span className="teams-list-team-name">
                                    {team.display_name}
                                  </span>
                                  <div className="teams-list-team-members">
                                    {team.members.map(m => {
                                      return (
                                        <span className="teams-list-team-member">
                                          {m.display_name}
                                        </span>
                                      )
                                    })}
                                  </div>
                                </div>
                              )
                            })}
                          </div>
                        </div>
                      </TabPanelStyled>
                    </TabContext>
                  </div>
                ) : (
                  <EventChallengeStandings
                    tournament={tournament}
                    organizer={organizer}
                  />
                )}

                {/* </TabPanel>
                <TabPanel value={tab} index={1}>
                  <div className="events-box events-standings">
                    <div className="events-box-header">
                      <span className="events-title">Event Rules</span>
                    </div>
                    <CenteredLoader />
                  </div>
                </TabPanel> */}
              </div>
            ) : (
              <div>
                <TabContext value={tab}>
                  <Tabs
                    value={tab}
                    className="event-tabs"
                    onChange={handleChangeTabs}
                    aria-label="basic tabs example">
                    <Tab
                      className="event-tab"
                      value={'overview'}
                      label={<span className="league-label">Overview</span>}
                      {...a11yTabProps(0)}
                    />
                    <Tab
                      className="event-tab"
                      value={'results'}
                      label={<span className="league-label">Results</span>}
                      {...a11yTabProps(1)}
                    />
                    <Tab
                      className="event-tab"
                      value={'stats'}
                      label={<span className="league-label">Stats</span>}
                      {...a11yTabProps(2)}
                    />
                    <Tab
                      className="event-tab"
                      value={'news'}
                      label={<span className="league-label">News</span>}
                      {...a11yTabProps(3)}
                    />
                  </Tabs>
                  <TabPanelStyled value={'overview'} index={0}>
                    <EventStandings
                      tournament={tournament}
                      matches={matches}
                      teams={teams}
                    />
                    {tournament?.id === 34920 && (
                      <EventMVP tournament={tournament} />
                    )}
                    <EventBracket
                      tournament={tournament}
                      matches={matches}
                      teams={teams}
                    />
                    <EventMapPool tournament={tournament} />
                    <EventHighlights tournament={tournament} />
                    {/* <div className="events-box events-photos">
                <div className="events-box-header">
                  <span className="events-title">Photo Gallery</span>
                </div>
                <span className="no-results">Loading...</span>
              </div> */}
                  </TabPanelStyled>
                  <TabPanelStyled value={'results'} index={1}>
                    <EventsResults
                      tournament={tournament}
                      matches={matches}
                      teams={teams}
                    />
                  </TabPanelStyled>
                  <TabPanelStyled value={'stats'} index={2}>
                    <EventPlayerStats tournament={tournament} />
                  </TabPanelStyled>
                </TabContext>
              </div>
            )}
            <EventComments />
          </div>
        </div>
      )}
    </div>
  )
}

export default EventPage
