import React from 'react'
import Box from '@mui/material/Box'
import { useAppIsLoggedIn } from 'hooks'
import SidebarNavigation from 'components/SidebarNavigation'
import SidebarSponsors from 'components/SidebarSponsors'
import SidebarTeams from 'components/SidebarTeams'
import Banner from 'components/Banner'
import SocialLinks from 'components/SocialLinks'
import CurrentLeagues from 'components/CurrentLeagues'
import SiteTagline from 'components/SiteTagline'
import SidebarStreams from 'components/SidebarStreams'
import SidebarGoons from 'components/SidebarGoons'
import SidebarEvents from 'components/SidebarEvents'
import Footer from 'components/Footer'
import MenuMobile from 'components/MenuMobile'

const Maintenance = () => {
  const isLoggedIn = useAppIsLoggedIn()
  return (
    <Box>
      <div className="main-container">
        <div className="wrapper tagline-social">
          <SiteTagline />
          <SocialLinks />
        </div>
        <div className="main wrapper clearfix">
          <div className="main-col main-col-full middle-content">
            <div>
              <div className="aside-header aside-maintenance">
                <span className="admin">Maintenance Mode</span>
              </div>
              <div className="content-panel">
                <span className="paragraph">
                  The site is currently in maintenance mode. Please try again
                  later.
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
      <MenuMobile />
    </Box>
  )
}

export default Maintenance
