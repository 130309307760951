import { CircularProgress } from '@mui/material'

const CenteredLoader = () => {
  return (
    <div className="content-panel">
      <div className="content-loader">
        <CircularProgress
          style={{
            color: '#7ab903',
            height: 55,
            width: 55
          }}
        />
      </div>
    </div>
  )
}

export default CenteredLoader
