import { MenuItem } from '@mui/material'
import { UserServiceApi } from 'api/UserServiceApi'
import Username from 'components/Username'
import UsernameAvatar from 'components/UsernameDisplay/UsernameAvatar'
import { useEffect } from 'react'

const SelectWinner = ({ user, key }) => {
  const [getUsername, { data: username }] =
    UserServiceApi.useLazyGetUsernameByIdCacheQuery()

  useEffect(() => {
    if (user?.submittedBy) {
      getUsername({ id: user?.submittedBy }, true)
    }
  }, [getUsername, user.submittedBy])

  return (
    <MenuItem key={user.submittedBy} value={user.submittedBy}>
      <div className="select-winner">
        <span className="winner-position">{key + 1}</span>
        <UsernameAvatar username={username} />
        <span className="winner-username">
          <Username username={username} />
        </span>
      </div>
    </MenuItem>
  )
}

export default SelectWinner
