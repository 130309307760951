import React, { useEffect, useRef } from 'react'
import { useParams, useSearchParams } from 'react-router-dom'
import OverlayService from 'services/OverlayService'
import GoonsService from 'services/GoonsService'
import { getGoonsMaps } from 'utils/GoonsMaps'
import { getTarkovMaps } from 'utils/tarkovMaps'
import TimeAgo from 'react-timeago'
import { CSSTransition } from 'react-transition-group'
import LeagueService from 'services/LeagueService'
import moment from 'moment'
import Countdown from 'react-countdown'
import UsernameService from 'services/UsernameService'
import FenceLogo from '../assets/img/fence-logo.png'
import OverlayRank from 'components/OverlayRank'
import RunNotifications from 'components/RunNotifications'
import { useAppVersion } from 'hooks/use-app-settings'
import BountyOverlay from 'components/BountyOverlay'
import StreamerChallenge from 'components/StreamerChallenge'
import AmpedMajor from 'components/AmpedMajor'

const Overlay = () => {
  const params = useParams()
  const goonsMaps = getGoonsMaps()
  const tarkovMaps = getTarkovMaps()
  const [settings, setSettings] = React.useState({})
  const [leagueRun, setLeagueRun] = React.useState({})
  const [leagueRunLoaded, setLeagueRunLoaded] = React.useState(false)
  const [timeoutSet, setTimeoutSet] = React.useState(false)
  const [usernameLoaded, setUsernameLoaded] = React.useState(false)
  const [username, setUsername] = React.useState({})
  const [goonsSightings, setGoonsSightings] = React.useState([])
  const overlayGoonsRef = useRef(null)
  const overlayLeagueRunRef = useRef(null)
  const [rankingsLoaded, setRankingsLoaded] = React.useState(false)
  const [rankings, setRankings] = React.useState([])
  const [weekData, setWeekData] = React.useState({})
  const version = useAppVersion()
  const [searchParams] = useSearchParams()

  const css = `
    body, html { background: none transparent !important; }
    .speech-bubble {
      position: absolute;
      z-index: 3;
      bottom: 250px;
      width: 280px;
      background: rgba(0,0,0,0.7);
      border-radius: 20px;
      padding: 20px 30px;
      margin: 0;
    }
    .overlay-box {
      width: 1920px;
      height: 1080px;
      position: relative;
      z-index: 1;
      font-family: 'Bender Bold', sans-serif;
      text-transform: uppercase;
      font-weight: 700;
      line-height: 1em;
      color: #000;
      overflow:hidden;
    }
  `

  useEffect(() => {
    if (params && params.overlay) {
      OverlayService.getOverlaySettings(params.overlay, data => {
        setSettings(data.data())
      })
    }
  }, [params])

  useEffect(() => {
    if (searchParams.get('cache') !== version) {
      window.location.href = `/overlay/${params.overlay}?cache=${version}`
    }
  }, [version, params, searchParams])

  useEffect(() => {
    if (settings && settings.owner && leagueRunLoaded === false) {
      setLeagueRunLoaded(true)
      LeagueService.getUserPendingRun(settings.owner, data => {
        setLeagueRun(data.data())
      })
    }
    if (settings && settings.owner && usernameLoaded === false) {
      setUsernameLoaded(true)
      UsernameService.getUsernameByID(settings.owner, data => {
        setUsername(data)
      })
    }
  }, [settings])

  useEffect(() => {
    GoonsService.getLatestSightings(data => {
      setGoonsSightings(data)
    })
  }, [])

  useEffect(() => {
    let listenerRankings = null
    let listenerWeek = null
    if (leagueRun && leagueRun.league) {
      if (rankingsLoaded === false) {
        setRankingsLoaded(true)
        if (typeof listenerRankings === 'function') {
          listenerRankings()
        }
        listenerRankings = LeagueService.getLeagueSeasonRankingsLive(
          leagueRun.league,
          leagueRun.season,
          leagueRun.tier,
          data => {
            if (typeof listenerWeek === 'function') {
              listenerWeek()
            }
            setRankings(
              data.data() && data.data().rankings ? data.data().rankings : []
            )
            listenerWeek = LeagueService.getLeagueSeasonWeek(
              leagueRun.league,
              leagueRun.season,
              leagueRun.week,
              result => {
                setWeekData(result.data())
              }
            )
          }
        )
      }
    } else {
      if (rankingsLoaded === true) {
        setRankingsLoaded(false)
        setRankings([])
        return () => {
          if (typeof listenerRankings === 'function') {
            listenerRankings()
          }
          if (typeof listenerWeek === 'function') {
            listenerWeek()
          }
        }
      }
    }
  }, [leagueRun])

  const renderer = ({ hours, minutes, seconds }) => {
    const needsPad = value => {
      if (value > 9) {
        return false
      }
      return true
    }
    return (
      <span className="timer">
        {needsPad(hours) ? '0' + hours : hours}
        <span className="label">H</span>
        {needsPad(minutes) ? '0' + minutes : minutes}
        <span className="label">M</span>
        {needsPad(seconds) ? '0' + seconds : seconds}
        <span className="label">S</span>
      </span>
    )
  }

  const getRank = uid => {
    if (leagueRun && leagueRun.league === 'duo') {
      return rankings.filter(rank => rank.members.includes(uid))?.length &&
        rankings.filter(rank => rank.members.includes(uid))[0]?.rank
        ? rankings.filter(rank => rank.members.includes(uid))[0]?.rank
        : '--'
    } else {
      return rankings.filter(rank => rank.uid === uid).length &&
        rankings.filter(rank => rank.uid === uid)[0].rank
        ? rankings.filter(rank => rank.uid === uid)[0].rank
        : '--'
    }
  }

  const getPoints = uid => {
    if (leagueRun && leagueRun.league === 'duo') {
      return rankings.filter(rank => rank.members.includes(uid))?.length > 0
        ? parseFloat(
            rankings.filter(rank => rank.members.includes(uid))[0]?.points
          ).toFixed(2)
        : '0.00'
    } else {
      return rankings.filter(rank => rank.uid === uid).length > 0
        ? parseFloat(
            rankings.filter(rank => rank.uid === uid)[0].points
          ).toFixed(2)
        : '0.00'
    }
  }

  return (
    <div>
      <style>{css}</style>
      <div className="overlay-box">
        {settings && settings.goons && goonsSightings && goonsSightings.length && (
          <CSSTransition
            nodeRef={overlayGoonsRef}
            in={settings.goons.show}
            timeout={0}
            classNames="animate-overlay-goons">
            <div
              ref={overlayGoonsRef}
              className={
                `overlay overlay-goons overlay-align-${settings.goons.align}` +
                (settings.goons.show ? ' animate-overlay-goons-enter-done' : '')
              }
              style={{
                top:
                  settings.goons && typeof settings.goons.offset !== 'undefined'
                    ? settings.goons.offset
                    : 600
              }}>
              <div className="overlay-badge">
                <img src={FenceLogo} />
                <span>
                  Goons
                  <br />
                  Sightings
                </span>
              </div>
              <div
                className="overlay-goons-map"
                style={{
                  backgroundImage: `url(${
                    goonsMaps[goonsSightings[0].map].discordImg
                  })`
                }}>
                <div className="overlay-goons-map-inner">
                  <div className="overlay-goons-map-goons"></div>
                </div>
              </div>
              <div className="overlay-goons-timeago">
                <span>
                  <TimeAgo date={goonsSightings[0].datetime.seconds * 1000} />
                </span>
              </div>
              <div className="overlay-goons-textbox">
                <span>{goonsMaps[goonsSightings[0].map].name}</span>
              </div>
            </div>
          </CSSTransition>
        )}
        {settings && (
          <CSSTransition
            nodeRef={overlayLeagueRunRef}
            in={
              leagueRun &&
              typeof leagueRun.league !== 'undefined' &&
              typeof weekData.map !== 'undefined' &&
              rankings.length > 0
            }
            timeout={0}
            classNames="animate-overlay-goons">
            <div
              ref={overlayLeagueRunRef}
              className={
                `overlay overlay-goons overlay-league-run overlay-align-${
                  settings.leagues && settings.leagues.align
                    ? settings.leagues.align
                    : 'right'
                }` +
                (leagueRun && typeof leagueRun.league !== 'undefined'
                  ? ' animate-overlay-goons-enter-done'
                  : '')
              }
              style={{
                top:
                  settings.leagues &&
                  typeof settings.leagues.offset !== 'undefined'
                    ? settings.leagues.offset
                    : 800
              }}>
              <div className="overlay-badge">
                <img src={FenceLogo} />
              </div>
              <div
                className="overlay-league-map"
                style={{
                  backgroundImage:
                    weekData && weekData.map && tarkovMaps[weekData.map].img
                      ? `url(${tarkovMaps[weekData.map].img})`
                      : `url(${tarkovMaps['customs'].img})`
                }}>
                <div className="overlay-league-map-inner"></div>
                <div className="overlay-league-map-content">
                  <div className="rank-badge">
                    <div
                      className={`badge-outer badge-${
                        leagueRun && leagueRun.tier ? leagueRun.tier : 'yellow'
                      } badge-32`}>
                      <div className="badge-inner"></div>
                      <div className="badge-rank">
                        <span>
                          {getRank(settings.owner)}
                          {/* <sup>st</sup> */}
                        </span>
                      </div>
                    </div>
                  </div>
                  <span className="overlay-league-text">
                    <span className="overlay-league-username">
                      {username &&
                        username.display_name &&
                        (username.usingKick === true
                          ? username.kickUsername
                          : username.display_name)}
                    </span>
                    {leagueRun?.league === 'solo' ? (
                      <span
                        className={`overlay-league-tier league-${
                          leagueRun && leagueRun.tier
                            ? leagueRun.tier
                            : 'yellow'
                        }`}>
                        Trio Battles Qualifier
                      </span>
                    ) : (
                      <span
                        className={`overlay-league-tier league-${
                          leagueRun && leagueRun.tier
                            ? leagueRun.tier
                            : 'yellow'
                        }`}>
                        {leagueRun && leagueRun.tier
                          ? leagueRun.tier
                          : 'Yellow'}{' '}
                        {leagueRun && leagueRun.league
                          ? leagueRun.league === 'duo'
                            ? 'Duos'
                            : leagueRun.league
                          : 'Scav'}{' '}
                        League
                      </span>
                    )}
                  </span>
                </div>
              </div>
              <div className="overlay-goons-timeago">
                <span className="overlay-league-points">
                  {getPoints(settings.owner)}{' '}
                  <span className="overlay-league-points-label">PTS</span>
                </span>
              </div>
              <div className="overlay-goons-textbox">
                {leagueRun && leagueRun.ended !== true ? (
                  <Countdown
                    renderer={renderer}
                    overtime={true}
                    date={
                      leagueRun && leagueRun.started
                        ? moment(leagueRun.started.seconds * 1000).toDate()
                        : new Date(Date.now())
                    }
                  />
                ) : (
                  <span className="league-red">Ending...</span>
                )}
              </div>
            </div>
          </CSSTransition>
        )}
        {settings && username && (
          <OverlayRank settings={settings} username={username} />
        )}
        {settings && username && (
          <RunNotifications settings={settings} username={username} />
        )}
        {/* {settings && username && (
          <StreamerChallenge settings={settings} username={username} />
        )} */}
        {settings && username && (
          <AmpedMajor settings={settings} username={username} />
        )}
        {/* {settings && settings.owner && <BountyOverlay owner={settings.owner} />} */}
      </div>
    </div>
  )
}

export default Overlay
