import { StripeApi } from 'api/Stripe'
import { useEffect, useState } from 'react'
import StripeService from 'services/StripeService'

export const useGetCharges = userId => {
  const [charges, setCharges] = useState([])
  const [getCard] = StripeApi.useGetCardMutation()

  useEffect(() => {
    const getCharges = async userId => {
      const charges = await StripeService.getChargesFirestore(userId).then(
        docs => {
          const rows = []
          docs.forEach(doc => {
            rows.push({ ...doc.data() })
          })
          return rows
        }
      )
      const rows = await Promise.all(
        charges.map(async charge => {
          if (charge.payment_method) {
            const card = await getCard({
              paymentMethodId: charge.payment_method
            }).then(response => {
              return response.data?.card
            })
            return { ...charge, card }
          }
        })
      )
      return rows
    }

    getCharges(userId).then(data => {
      setCharges(data)
    })
    return () => {}
  }, [userId, getCard])

  return { charges }
}

export const useGetInvoices = userId => {
  const [invoices, setInvoices] = useState([])

  const getInvoices = async userId => {
    return await StripeService.getInvoicesFirestore(userId).then(docs => {
      const rows = []
      docs.forEach(doc => {
        rows.push({ ...doc.data() })
      })
      return rows
    })
  }

  useEffect(() => {
    getInvoices(userId).then(data => {
      setInvoices(data)
    })
    return () => {}
  }, [userId])

  return { invoices }
}
