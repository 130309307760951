import React from 'react'

import Box from '@mui/material/Box'
import SubscriptionStatus from './account/SubscriptionStatus'
import PaymentMethods from './account/PaymentMethods'
import Transactions from './account/Transactions'
import Invoices from './account/Invoices'
import { useUserStripe, useUserSubscription } from 'hooks/use-user-data'
import AccountDisplay from './account/AccountDisplay'

const AccountComponent = ({ user, user_id, isPrivacy, togglePrivacy }) => {
  const stripe = useUserStripe()
  const subscription = useUserSubscription()

  return (
    <Box>
      <SubscriptionStatus
        id={user.id}
        customerId={stripe?.customer}
        subscription={subscription}
        isPrivacy={isPrivacy}
        togglePrivacy={togglePrivacy}
      />
      <Box mt="15px">
        <AccountDisplay />
      </Box>
      <Box>
        <PaymentMethods
          customerId={stripe?.customer}
          defaultMethod={stripe?.default_payment_method}
          subscription={subscription}
        />
      </Box>
      <Invoices userId={user_id} />
      <Box mt="15px">
        <Transactions userId={user_id} />
      </Box>
    </Box>
  )
}

export default AccountComponent
