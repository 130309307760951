import React from 'react'
import { useAppIsLoggedIn } from 'hooks'
import Banner from 'components/Banner'
import CurrentTeams from 'components/CurrentTeams'

const Teams = () => {
  const isLoggedIn = useAppIsLoggedIn()
  return (
    <>
      <Banner />
      <CurrentTeams />
    </>
  )
}

export default Teams
