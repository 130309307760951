import React from 'react'
import { Link } from 'react-router-dom'

const SidebarTeams = () => {
  return (
    <div>
      <div className="aside-header aside-teams">
        <span className="teams">Team Standings</span>
      </div>
      <nav className="rankings-nav">
        <ul>
          {/* <li>
            <a href="#">
              <div className="rank-badge">
                <div className="badge-outer badge-brown badge-32">
                  <div className="badge-inner"></div>
                  <div className="badge-rank">
                    <span>
                      1<sup>st</sup>
                    </span>
                  </div>
                </div>
              </div>
              <div className="rank-details">
                <span className="username">Evasion</span>
                <span className="points">
                  <span>1000</span>
                  <span className="label">PTS</span>
                </span>
              </div>
            </a>
          </li>
          <li className="divider">
            <div></div>
          </li> */}
          <li>
            <span className="no-competitors">
              More information on team events to be announced soon.
            </span>
          </li>
        </ul>
      </nav>
      <Link className="all-link" to="/teams">
        View Standings
      </Link>
    </div>
  )
}

export default SidebarTeams
