import React, { useCallback, useEffect, useState } from 'react'
import Alert from '@mui/material/Alert'
import { StripeApi } from 'api/Stripe'

import { useUserData } from 'hooks'
import { NavLink } from 'react-router-dom'
import BountyService from 'services/BountyService'
import StripeService from 'services/StripeService'
import {
  Button,
  CircularProgress,
  FormControl,
  FormLabel,
  Modal,
  TextField
} from '@mui/material'
import BountyDetails from './BountyDetails'

const BountyList = () => {
  const user = useUserData()
  const [availableBounties, setAvailableBounties] = useState([])
  const [activeBounties, setActiveBounties] = useState([])
  const [completedBounties, setCompletedBounties] = useState([])
  const [affiliateData, setAffiliateData] = useState({})
  const [success, setSuccess] = useState(false)
  const [selectedBounty, setSelectedBounty] = useState({})
  const [twitchLink, setTwitchLink] = useState('')
  const [twitchLinkError, setTwitchLinkError] = useState(false)
  const [submitModalOpen, setSubmitModalOpen] = useState(false)
  const [submitLoading, setSubmitLoading] = useState(false)
  const [getPaymentStatus] = StripeApi.useGetPaymentStatusMutation()

  const showSuccess = () => {
    setSuccess(true)
    setTimeout(() => {
      setSuccess(false)
    }, 10000)
  }

  const handleClose = () => {
    setSelectedBounty({})
    setSubmitModalOpen(false)
  }

  const submitBountyEntry = id => {
    BountyService.submitBountyEntry(
      id,
      encodeURIComponent(twitchLink),
      data => {
        setSelectedBounty({})
        setTwitchLink('')
        setTwitchLinkError(false)
        setSubmitModalOpen(false)
        setSubmitLoading(false)
      }
    )
  }

  useEffect(() => {
    BountyService.getEnabledBounties(data => {
      setAvailableBounties(data)
    })
    BountyService.getActivatedBounties(data => {
      setActiveBounties(data)
    })
    BountyService.getCompletedBounties(data => {
      setCompletedBounties(data)
    })
  }, [])

  useEffect(() => {
    if (user?.stripe?.account) {
      StripeService.getAffiliateFirestore(user.stripe.account, data => {
        setAffiliateData(data)
      })
    }
  }, [user])

  const checkStatus = useCallback(async () => {
    const urlParams = new URLSearchParams(window.location.search)
    const paymentIntent = urlParams.get('payment_intent')
    if (paymentIntent) {
      getPaymentStatus({ paymentSecret: paymentIntent }).then(response => {
        if (response.data.status === 'succeeded') {
          showSuccess()
        }
      })
    }
  }, [getPaymentStatus])

  useEffect(() => {
    checkStatus()
  }, [checkStatus])

  const isEligible = () => {
    if (user?.subscription?.status !== 'active') {
      return false
    }
    if (affiliateData?.payouts_enabled !== true) {
      return false
    }
    return true
  }

  return (
    <div>
      <div className="aside-header aside-leagues">
        <span className="bounty">Bounty Challenges</span>
      </div>
      <div className="content-panel">
        {!isEligible() && (
          <NavLink to={'/account'}>
            <Alert
              severity="error"
              sx={{ marginBottom: '10px', marginTop: '10px' }}>
              To be eligible to enter and win bounties, you must be a Fence
              Premium subscriber and complete the FenceGG payout onboarding in
              your account page.
            </Alert>
          </NavLink>
        )}
        {success && (
          <Alert
            severity="success"
            sx={{ marginBottom: '10px', marginTop: '10px' }}>
            Your new bounty will be activated shortly.
          </Alert>
        )}
        {user.usingKick === true && (
          <Alert
            severity="error"
            sx={{ marginBottom: '10px', marginTop: '10px' }}>
            Sorry, Kick streamers are not currently eligible to win bounties.
          </Alert>
        )}
        <span className="paragraph">
          Anyone can create a bounty! After a bounty is created and available,
          an alert will go out to eligible streamers and the first streamer to
          submit a VOD/Clip with proof of completing the bounty task will win
          the prize.
        </span>
        <span className="disclaimer">
          *For now, only Twitch streamers are eligble to win bounties until Kick
          improves their clip/VOD/chatbot functionality.
        </span>
        <div className="bounty-buttons">
          <NavLink className="btn btn-primary" to={'/bounty/create'}>
            Create a Bounty
          </NavLink>
        </div>
        <span className="subhead green">Active Bounties</span>
        <div className="bounty-list">
          {activeBounties.length > 0 ? (
            activeBounties
              .sort((a, b) => b.opened.seconds - a.opened.seconds)
              .map(bounty => {
                return (
                  <BountyDetails
                    key={bounty.id}
                    isEligible={isEligible}
                    bounty={bounty}
                    availableBounties={availableBounties}
                    setSelectedBounty={setSelectedBounty}
                    submitModalOpen={submitModalOpen}
                    setSubmitModalOpen={setSubmitModalOpen}
                  />
                )
              })
          ) : (
            <span className="no-competitors">
              There are no active bounties at this time.
            </span>
          )}
        </div>
        <span className="subhead green">Completed Bounties</span>
        <div className="bounty-list">
          {completedBounties.length > 0 ? (
            completedBounties
              .sort((a, b) => b.opened.seconds - a.opened.seconds)
              .map(bounty => {
                return (
                  <BountyDetails
                    key={bounty.id}
                    isEligible={isEligible}
                    bounty={bounty}
                    availableBounties={availableBounties}
                    setSelectedBounty={setSelectedBounty}
                    submitModalOpen={submitModalOpen}
                    setSubmitModalOpen={setSubmitModalOpen}
                  />
                )
              })
          ) : (
            <span className="no-competitors">
              There are no completed bounties yet, be the first to create one.
            </span>
          )}
        </div>
      </div>
      <Modal
        className="start-run-modal"
        disableEscapeKeyDown={false}
        open={submitModalOpen}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description">
        <div className="run-modal">
          <div className="aside-header aside-run">
            <span className="bounty">Submit Entry For Bounty</span>
          </div>
          <div className="modal-content">
            <span className="paragraph" style={{ paddingTop: 10 }}>
              Complete the bounty task and submit a VOD/Clip link proving that
              you completed it. If you are the first one to submit the proof and
              there is no funny business, you win the prize.
            </span>
            <span className="subhead green">Bounty Task:</span>
            <span className="bounty-modal-task">{selectedBounty.desc}</span>
            <div>
              <FormControl sx={{ paddingBottom: 3 }}>
                <FormLabel id="twitch-link">
                  <span className="subhead green">
                    Twitch Clip or Timestamped VOD Link
                  </span>
                  <span className="paragraph">
                    Please paste in proof of you completing the bounty task in
                    the form of a Twitch clip link or timestamped VOD link.
                  </span>
                </FormLabel>
                <TextField
                  id="twitch-link"
                  onChange={e => {
                    let v = e.target.value
                    let hasError = true
                    if (
                      (v.indexOf('/clip/') > -1 &&
                        (v.indexOf('https://www.twitch.tv/') === 0 ||
                          v.indexOf('https://twitch.tv/') === 0)) ||
                      v.indexOf('https://clips.twitch.tv/') === 0
                    ) {
                      hasError = false
                    }
                    if (hasError) {
                      setTwitchLinkError(true)
                      return false
                    }
                    setTwitchLinkError(false)
                    setTwitchLink(v)
                  }}
                  error={twitchLinkError}
                  helperText={
                    'Provide a Twitch Clip or VOD link WITH a ?t= timestamp. ex. https://www.twitch.tv/danbergundy/clip/SilkySpunkyTirePoooound-hepAPZge1zLQid22'
                  }
                  value={twitchLink}
                  placeholder={
                    'ex. https://www.twitch.tv/danbergundy/clip/SilkySpunkyTirePoooound-hepAPZge1zLQid22'
                  }
                  variant="outlined"
                />
              </FormControl>
            </div>
            <div className="modal-controls">
              <Button
                className="btn btn-primary btn-cancel"
                disabled={submitLoading}
                onClick={e => {
                  e.preventDefault()
                  setSubmitModalOpen(false)
                }}>
                Cancel
              </Button>
              <Button
                className={`btn btn-primary ${
                  twitchLinkError ? 'btn-grey' : ''
                }`}
                disabled={twitchLinkError || submitLoading}
                onClick={e => {
                  e.preventDefault()
                  setSubmitLoading(true)
                  submitBountyEntry(selectedBounty.id)
                }}>
                {submitLoading && (
                  <CircularProgress
                    style={{
                      color: '#000',
                      height: 15,
                      width: 15,
                      marginRight: 10
                    }}
                  />
                )}
                Submit Entry
              </Button>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  )
}

export default BountyList
