import { useSelector } from 'react-redux'
import {
  selectUserData,
  selectUserStripe,
  selectUserSubscription
} from 'store/slice/authSlice'

export const useUserData = () => useSelector(selectUserData)
export const useUserStripe = () => useSelector(selectUserStripe)
export const useUserSubscription = () => useSelector(selectUserSubscription)
