import Firebase from 'utils/Firebase'
import { getFirestore, orderBy } from 'firebase/firestore'
import {
  collection,
  onSnapshot,
  doc,
  updateDoc,
  addDoc,
  query,
  where
} from 'firebase/firestore'
import { getHostFunctions } from 'utils/GetHost'
import { getAuth } from 'firebase/auth'

const auth = getAuth()
const db = getFirestore(Firebase)

const string_to_slug = str => {
  str = str.replace(/^\s+|\s+$/g, '')
  str = str.toLowerCase()

  var from = 'àáäâèéëêìíïîòóöôùúüûñç·/_,:;'
  var to = 'aaaaeeeeiiiioooouuuunc------'
  for (var i = 0, l = from.length; i < l; i++) {
    str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i))
  }

  str = str
    .replace(/[^a-z0-9 -]/g, '')
    .replace(/\s+/g, '-')
    .replace(/-+/g, '-')

  return str
}

async function getPublishedStories(callback) {
  const q = query(
    collection(db, 'stories'),
    where('published', '==', true),
    orderBy('createdAt', 'desc')
  )
  const unsubscribe = onSnapshot(q, querySnapshot => {
    const docs = []
    querySnapshot.forEach(doc => {
      docs.push({ ...doc.data(), id: doc.id })
    })
    if (typeof callback === 'function') {
      callback(docs)
    }
  })
  return { unsubscribe }
}

async function getAllStories(callback) {
  const unsubscribe = onSnapshot(
    query(collection(db, 'stories'), orderBy('createdAt', 'desc')),
    docs => {
      const stories = []
      docs.forEach(doc => {
        stories.push({ ...doc.data(), id: doc.id })
      })
      if (typeof callback === 'function') {
        callback(stories)
      }
    }
  )
  return { unsubscribe }
}

async function getNewsStoryById(id, callback) {
  onSnapshot(doc(db, 'stories', id), doc => {
    if (typeof callback === 'function') {
      callback(doc)
    }
  })
}

async function getNewsStoryBySlug(slug, callback) {
  const q = query(collection(db, 'stories'), where('slug', '==', slug))
  const unsubscribe = onSnapshot(q, querySnapshot => {
    const docs = []
    querySnapshot.forEach(doc => {
      docs.push({ ...doc.data(), id: doc.id })
    })
    if (typeof callback === 'function') {
      callback(docs[0])
    }
  })
  return { unsubscribe }
}

async function addNewsStory(
  uid,
  slug,
  title,
  content,
  image,
  published,
  callback
) {
  //check for duplicate titles
  //discord webhook for new stories
  //twitter post for new stories
  const now = new Date(Date.now())
  const docRef = await addDoc(collection(db, 'stories'), {
    title: encodeURIComponent(title),
    slug: string_to_slug(slug),
    content: encodeURIComponent(content),
    image: image,
    postedBy: uid,
    createdAt: now,
    updatedAt: now,
    published: published
  })
  if (typeof callback === 'function') {
    callback(docRef, string_to_slug(slug))
  }
}

async function updateNewsStory(id, title, content, image, callback) {
  const docRef = await updateDoc(
    doc(db, 'stories', id),
    {
      title: encodeURIComponent(title),
      content: encodeURIComponent(content),
      image: image,
      updatedAt: new Date(Date.now())
    },
    { merge: true }
  )
  if (typeof callback === 'function') {
    callback(docRef)
  }
}

async function publishNewsStory(id, callback) {
  const docRef = await updateDoc(
    doc(db, 'stories', id),
    {
      published: true
    },
    { merge: true }
  )
  if (typeof callback === 'function') {
    callback(docRef)
  }
}

async function unpublishNewsStory(id, callback) {
  const docRef = await updateDoc(
    doc(db, 'stories', id),
    {
      published: false
    },
    { merge: true }
  )
  if (typeof callback === 'function') {
    callback(docRef)
  }
}

async function postComment(id, comment, callback) {
  const token = await auth.currentUser?.getIdToken()
  const result = await fetch(
    getHostFunctions() +
      `/postComment?id=${id}&comment=${encodeURIComponent(comment)}`,
    {
      headers: {
        authorization: `Bearer ${token}`
      }
    }
  )
  if (result.status === 200) {
    result.json().then(res => {
      if (typeof callback === 'function') {
        callback(res)
      }
    })
  }
}

async function getCommentsByPostId(id, callback) {
  const q = query(
    collection(db, 'comments'),
    where('postId', '==', id),
    orderBy('createdAt', 'desc')
  )
  const unsubscribe = onSnapshot(q, querySnapshot => {
    const docs = []
    querySnapshot.forEach(doc => {
      docs.push({ ...doc.data(), id: doc.id })
    })
    if (typeof callback === 'function') {
      callback(docs)
    }
  })
  return { unsubscribe }
}

const NewsService = {
  getPublishedStories,
  getAllStories,
  getNewsStoryById,
  getNewsStoryBySlug,
  addNewsStory,
  updateNewsStory,
  publishNewsStory,
  unpublishNewsStory,
  postComment,
  getCommentsByPostId
}

export default NewsService
