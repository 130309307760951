import React, { useEffect, useCallback, useState } from 'react'
import USAFlag from '../assets/img/icon-flag-usa.jpg'
import OnlineIcon from '../assets/img/icon-online.png'
import { Link } from 'react-router-dom'
import moment from 'moment'
import { TournamentServiceApi } from 'api/TournamentServiceApi'

const TournamentsListItem = ({ tournament, type }) => {
  const [organizer, setOrganizer] = useState({})
  const [getOrganizersApi] =
    TournamentServiceApi.useLazyGetOrganizersByIdsQuery()

  const getOrganizersAsync = useCallback(async () => {
    const asyncOrganizers = await getOrganizersApi(
      { ids: tournament?.organizer },
      true
    ).unwrap()
    setOrganizer(asyncOrganizers)
  }, [tournament])

  useEffect(() => {
    if (tournament?.organizer !== 'undefined') {
      getOrganizersAsync()
    }
  }, [getOrganizersAsync, tournament])

  return (
    <Link
      className="events-row"
      to={
        tournament?.eventURL
          ? tournament?.eventURL
          : `/event/${tournament?.slug}/${tournament?.id}`
      }>
      {tournament?.status !== 'approved' && (
        <span className="draft">DRAFT</span>
      )}
      <div className="events-row-desc">
        <div
          className="events-row-thumb"
          style={{
            backgroundImage: `url(${tournament?.poster})`
          }}></div>
        <div className="events-row-desc-content">
          <span className="events-row-title">{tournament?.display_name}</span>
          {organizer?.display_name && (
            <span className="events-row-organizer">
              By {organizer.display_name}
            </span>
          )}
          {tournament?.location && (
            <span className="events-row-location">
              {tournament?.type === 'Online' ? (
                <img className="online-icon" src={OnlineIcon} alt={'Online'} />
              ) : (
                <img className="flag-icon" src={USAFlag} alt={'US Flag'} />
              )}
              <span>
                {tournament?.location?.display_name} ({tournament?.type})
              </span>
            </span>
          )}
          <span></span>
        </div>
      </div>
      <div className="events-row-info">
        <div className={`events-row-game game-${tournament?.game}`}></div>
        <div className="events-row-prize">
          <span className="events-row-info-label">Prize</span>
          <span className="events-row-info-value">
            {`${
              tournament?.prizepool?.currency === 'USD'
                ? '$'
                : tournament?.prizepool?.currency === 'EUR'
                ? '€'
                : tournament?.prizepool?.currency === 'GBP'
                ? '£'
                : ''
            }${tournament?.prizepool?.amount
              ?.toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`}
          </span>
        </div>
        <span className="calendar">
          <span className={`label ${type}`}>
            {type === 'ended'
              ? 'Ended'
              : type === 'upcoming'
              ? 'Upcoming'
              : 'Ends'}
          </span>
          <span className="day">
            {moment(
              tournament?.eventList === 'upcoming'
                ? tournament?.starts
                : tournament?.ends
            ).format('ddd')}
          </span>
          <span className="date">
            {moment(
              tournament?.eventList === 'upcoming'
                ? tournament?.starts
                : tournament?.ends
            ).format('MMM DD YY')}
          </span>
        </span>
      </div>
    </Link>
  )
}

export default TournamentsListItem
