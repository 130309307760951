import { useState } from 'react'
import { Alert } from '@mui/material'
import { LeagueServiceApi } from 'api/LeagueServiceApi'
import * as ga4 from 'utils/ga4'
import InviteDisplay from './Duo/InviteDisplay'

const LeagueDuoInvites = ({ invites }) => {
  const [inviteLoading, setInviteLoading] = useState(false)
  const [declineDuo] = LeagueServiceApi.useDeclineDuoPartnerMutation()
  const [acceptDuo] = LeagueServiceApi.useAcceptDuoPartnerMutation()
  const [inviteError, setInviteError] = useState('')

  const declineDuoPartner = async uid => {
    setInviteLoading(true)
    await declineDuo({ uid })
      .unwrap()
      .then(data => {
        if (data.error) {
          setInviteError(data.error)
        }
        setTimeout(() => {
          setInviteError('')
        }, 5000)
        setInviteLoading(false)
        ga4.sendEvent({
          category: 'Duo Partner',
          action: 'Decline',
          nonInteraction: false
        })
      })
  }

  const acceptDuoPartner = async uid => {
    setInviteLoading(true)
    await acceptDuo({ uid })
      .unwrap()
      .then(data => {
        if (data.error) {
          setInviteError(data.error)
        }
        setTimeout(() => {
          setInviteError('')
        }, 5000)
        setInviteLoading(false)
        ga4.sendEvent({
          category: 'Duo Partner',
          action: 'Accept',
          nonInteraction: false
        })
      })
  }

  return (
    <>
      <Alert severity="info">
        You don't have a duos team yet, please set one up first.
      </Alert>
      {inviteError !== '' && (
        <div className="invite-error">
          <Alert severity="error">{inviteError}</Alert>
        </div>
      )}
      {invites.incoming.length > 0 && (
        <div className="inline-pending-invites">
          <span className="invite-desc">You have pending invites:</span>
          {invites.incoming.map((invite, ii) => (
            <InviteDisplay
              key={ii}
              invite={invite}
              acceptDuoPartner={acceptDuoPartner}
              declineDuoPartner={declineDuoPartner}
              inviteLoading={inviteLoading}
            />
          ))}
        </div>
      )}
    </>
  )
}

export default LeagueDuoInvites
