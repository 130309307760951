import React, { useState } from 'react'
import { useStripe, useElements, PaymentElement } from '@stripe/react-stripe-js'
import Button from '@mui/material/Button'
import { CircularProgress, Alert } from '@mui/material'

import { getHostDomain } from '../../../utils/GetHost'

const AddCardForm = ({ route }) => {
  const [isSaving, setIsSaving] = useState(false)
  const [error, setError] = useState(false)
  const stripe = useStripe()
  const elements = useElements()

  const showError = () => {
    setError(true)
    setTimeout(() => {
      setError(false)
    }, 3000)
  }

  const handleSubmit = async event => {
    event.preventDefault()
    setIsSaving(true)

    if (!stripe || !elements) {
      console.log('Stripe or Elements not initialized.')
      setIsSaving(false)
      return
    }

    await stripe
      .confirmSetup({
        elements,
        confirmParams: {
          return_url: `${getHostDomain()}${route}`
        }
      })
      .catch(() => {
        setIsSaving(false)
        showError()
      })
  }

  return (
    <form onSubmit={handleSubmit}>
      {error && (
        <Alert severity="error" sx={{ marginBottom: '15px' }}>
          There was an issue saving your card, please try again.
        </Alert>
      )}
      <Alert severity="info" sx={{ marginBottom: '15px' }}>
        Do not show this screen on livestream.
      </Alert>
      <span className="disclaimer">
        Fence.gg does not store any of your card information but uses a Stripe
        integration &ndash; an industry leading payment provider.
      </span>
      <PaymentElement />
      <Button
        variant="contained"
        type="submit"
        disabled={isSaving}
        sx={{ marginTop: '10px' }}>
        {isSaving && (
          <CircularProgress
            style={{
              color: '#000',
              height: 20,
              width: 20,
              marginRight: 10
            }}
          />
        )}
        {isSaving ? <span>Adding...</span> : <span>Add Payment Method</span>}
      </Button>
    </form>
  )
}

export default AddCardForm
