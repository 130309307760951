import React, { useEffect, useCallback, useState } from 'react'
import CenteredLoader from './CenteredLoader'
import { TournamentServiceApi } from 'api/TournamentServiceApi'
import {
  Button,
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Select
} from '@mui/material'
import IconInfo from '../assets/img/icon-info.png'

const EventChallengeStandings = ({ tournament }) => {
  const [participants, setParticipants] = React.useState([])
  const [lastParticipant, setLastParticipant] = useState()
  const [hasNext, setHasNext] = useState(false)
  const [selectorIsLoading, setSelectorIsLoading] = useState(false)
  const [pagingLoading, setPagingLoading] = useState(false)
  const [scoreBreakdown, setScoreBreakdown] = useState('')
  const [payouts, setPayouts] = React.useState([])
  const [dividers, setDividers] = React.useState([])
  const [scoreColumns, setScoreColumns] = React.useState([])
  const [getTournamentDayApi, { isLoading }] =
    TournamentServiceApi.useLazyGetTournamentDayQuery()
  const [getTournamentDayPageApi] =
    TournamentServiceApi.useLazyGetTournamentDayPageQuery()
  const [selectedDay, setSelectedDay] = useState('all')

  const size = tournament?.playersToShow || 100

  const getTournamentDayAsync = useCallback(
    async (day, size) => {
      const asyncTournamentDay = await getTournamentDayApi(
        { id: tournament?.id, day, size },
        true
      ).unwrap()
      setPayouts(
        asyncTournamentDay?.day?.payouts?.length > 0
          ? asyncTournamentDay.day.payouts
          : []
      )
      setDividers(
        asyncTournamentDay?.day?.dividers?.length > 0
          ? asyncTournamentDay.day.dividers
          : []
      )
      setScoreColumns(
        asyncTournamentDay?.day?.scoreColumns?.length > 0
          ? asyncTournamentDay.day.scoreColumns
          : []
      )
      setParticipants(asyncTournamentDay.users)
      setHasNext(asyncTournamentDay.hasNext)
      setLastParticipant(
        asyncTournamentDay?.users[asyncTournamentDay?.users?.length - 1]
      )
      setSelectorIsLoading(false)
    },
    [tournament]
  )

  useEffect(() => {
    if (tournament?.docId !== 'undefined') {
      getTournamentDayAsync('all', size)
    }
  }, [getTournamentDayAsync, tournament])

  const loadMore = async () => {
    setPagingLoading(true)
    getTournamentDayPageApi({
      id: tournament?.id,
      day: selectedDay,
      login: lastParticipant?.login,
      rank: lastParticipant?.rank,
      size: 20
    })
      .unwrap()
      .then(data => {
        setPagingLoading(false)
        setParticipants([...participants, ...data.users])
        setLastParticipant(data?.users[data?.users?.length - 1])
        setHasNext(data.hasNext)
      })
  }

  const openScoreBreakdown = player => {
    if (player === scoreBreakdown) {
      setScoreBreakdown('')
    } else {
      setScoreBreakdown(player)
    }
  }

  const handleDayChange = e => {
    setSelectedDay(e.target.value)
    setSelectorIsLoading(true)
    getTournamentDayAsync(e.target.value, size)
  }

  return (
    <div>
      <div className="events-box events-standings">
        <div className="events-box-header">
          <span className="events-title">
            {tournament?.eventList === 'ongoing'
              ? 'Current Standings'
              : tournament?.eventList === 'upcoming'
              ? 'Event Participants'
              : 'Final Standings'}
          </span>
        </div>
        {tournament?.maxDays > 0 && (
          <div className="multi-day-selector">
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">
                Showing Results For:
              </InputLabel>
              <Select
                labelId="multi-day-label"
                id="multi-day-select"
                value={selectedDay}
                label="Showing Results For"
                onChange={handleDayChange}>
                {Array.from(Array(tournament?.maxDays + 1).keys()).map(day => {
                  return (
                    <MenuItem key={day} value={day === 0 ? 'all' : `day${day}`}>
                      {typeof tournament?.dayLabels !== 'undefined'
                        ? day === 0
                          ? tournament?.dayLabels['all']
                          : tournament?.dayLabels[`day${day}`]
                        : day === 0
                        ? 'Total Event'
                        : `Day ${day}`}
                    </MenuItem>
                  )
                })}
              </Select>
            </FormControl>
          </div>
        )}
        <div className="event-standings-header event-challenge-standings-header">
          <span className="event-standings-label">Place</span>
          <span className="event-standings-label">Participant</span>
          <span className="event-standings-label">Score</span>
          <span className="event-standings-label">Prize</span>
        </div>
        {!isLoading && selectorIsLoading === false ? (
          <div>
            {participants?.length > 0 ? (
              [...participants]
                .sort((a, b) => parseInt(a.rank, 10) - parseInt(b.rank, 10))
                ?.map(player => {
                  return (
                    <div
                      key={player.login}
                      className="event-challenge-standings-row">
                      <div className="event-challenge-standings-row-inner">
                        <span className="event-standings-label">
                          {player.rank}
                        </span>
                        {player.streamLink === '' ||
                        typeof player.streamLink === 'undefined' ? (
                          <div className="event-standings-label event-standings-label-team">
                            {player?.avatar !== '' && (
                              <img src={player.avatar} />
                            )}
                            <span>{player.login}</span>
                          </div>
                        ) : (
                          <a
                            href={player.streamLink}
                            target="_blank"
                            className="event-standings-label event-standings-label-team">
                            {player?.avatar !== '' && (
                              <img src={player.avatar} />
                            )}
                            <span>{player.login}</span>
                          </a>
                        )}
                        <span className="event-standings-label event-challenge-score">
                          <span>
                            {player.points
                              .toString()
                              .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                          </span>
                          {typeof player?.scores !== 'undefined' && (
                            <img
                              onClick={() => {
                                openScoreBreakdown(player.login)
                              }}
                              className="score-breakdown"
                              src={IconInfo}
                            />
                          )}
                        </span>
                        {tournament?.prizepool?.type === 'money' && (
                          <span className="event-standings-label">
                            {payouts?.length > 0 && payouts[player.rank - 1] > 0
                              ? `${
                                  tournament?.prizepool?.currency === 'USD'
                                    ? '$'
                                    : tournament?.prizepool?.currency === 'EUR'
                                    ? '€'
                                    : tournament?.prizepool?.currency === 'GBP'
                                    ? '£'
                                    : ''
                                }${payouts[player.rank - 1]
                                  ?.toString()
                                  .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`
                              : '--'}
                          </span>
                        )}
                        {tournament?.prizepool?.type === 'item' && (
                          <span className="event-standings-label">
                            {payouts?.length > 0 &&
                            typeof payouts[player.rank - 1] !== 'undefined'
                              ? payouts[player.rank - 1]
                              : '--'}
                          </span>
                        )}
                      </div>
                      {scoreBreakdown === player?.login && (
                        <div className="event-challenge-scores">
                          {Object.keys(player?.scores).map((key, i) => {
                            return (
                              <span
                                className="event-challenge-scores-item"
                                key={i}>
                                <span className="event-challenge-score-label">
                                  {
                                    scoreColumns?.filter(s => s.key === key)[0]
                                      ?.title
                                  }
                                </span>
                                <span className="event-challenge-score-value">
                                  {`${player.scores[key]
                                    ?.toString()
                                    .replace(
                                      /\B(?=(\d{3})+(?!\d))/g,
                                      ','
                                    )} Pts`}
                                </span>
                              </span>
                            )
                          })}
                        </div>
                      )}
                      {dividers?.filter(d => d.rank === player.rank)?.length >
                        0 && (
                        <div className="event-challenge-divider">
                          {
                            dividers?.filter(d => d.rank === player.rank)[0]
                              .label
                          }
                        </div>
                      )}
                    </div>
                  )
                })
            ) : (
              <span className="no-results">
                There are no participants listed at this time.
              </span>
            )}
            {hasNext === true && (
              <Button
                variant={'contained'}
                className="all-link"
                style={{ display: 'flex', alignItems: 'center' }}
                onClick={loadMore}
                fullWidth>
                {pagingLoading && (
                  <CircularProgress
                    style={{
                      color: '#7ab903',
                      height: 16,
                      width: 16,
                      marginRight: 8
                    }}
                  />
                )}
                <span>Load More</span>
              </Button>
            )}
          </div>
        ) : (
          <CenteredLoader />
        )}
      </div>
    </div>
  )
}

export default EventChallengeStandings
