import React from 'react'
import { useParams } from 'react-router-dom'
import { useAppIsLoggedIn } from 'hooks'
import Banner from 'components/Banner'
import CurrentRankings from 'components/CurrentRankings'

const History = () => {
  const isLoggedIn = useAppIsLoggedIn()
  const params = useParams()
  return (
    <>
      <Banner />
      <CurrentRankings historical={true} params={params} />
    </>
  )
}

export default History
