import React, { useState } from 'react'
import CardsList from '../cards/CardsList'

import Button from '@mui/material/Button'
import CircularProgress from '@mui/material/CircularProgress'
import Box from '@mui/material/Box'
import Alert from '@mui/material/Alert'
import { useGetCustomerCards } from '../hooks/Cards'
import { StripeApi } from 'api/Stripe'
import { useUserIsStripeTest } from 'hooks/use-user-isStripeTest'
import { useUserStripe } from 'hooks/use-user-data'
import AddCardModal from 'components/account/modals/AddCardModal'

const SubscribeForm = ({ user }) => {
  const isStripeTest = useUserIsStripeTest()
  const premiumProductKey = isStripeTest
    ? process.env.REACT_APP_STRIPE_TEST_PREMIUM_KEY
    : process.env.REACT_APP_STRIPE_PREMIUM_KEY

  const [paymentMethod, setPaymentMethod] = useState('new')
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [error, setError] = useState(false)
  const { id, referredBy = null } = user
  const stripe = useUserStripe()
  const { data: cards = [], isLoading } = useGetCustomerCards(stripe?.customer)
  const [createSubscription] = StripeApi.useCreateSubscriptionMutation()

  const showError = () => {
    setError(true)
    setTimeout(() => {
      setError(false)
    }, 3000)
  }

  const paymentOnChange = paymentMethod => {
    setPaymentMethod(paymentMethod)
  }

  const subscribeUser = async event => {
    event.preventDefault()
    if (!stripe.customer) {
      return
    }
    setIsSubmitting(true)

    const items = [{ price: premiumProductKey }]

    createSubscription({
      userId: id,
      customerId: stripe?.customer,
      items,
      defaultPaymentMethod: paymentMethod,
      metadata: { referredBy }
    })
      .then(() => {
        setIsSubmitting(false)
      })
      .catch(() => {
        showError()
        setIsSubmitting(false)
      })
  }

  if (isLoading) {
    return (
      <Box sx={{ marginTop: '10px', textAlign: 'center' }}>
        <CircularProgress
          style={{
            color: '#7ab903',
            height: 75,
            width: 75
          }}
        />
      </Box>
    )
  }

  return (
    <>
      {error && (
        <Alert
          severity="error"
          sx={{ marginBottom: '10px', marginTop: '10px' }}>
          Whoops, we ran into an issue. Please try again.
        </Alert>
      )}
      <span className="heading-text">
        Step 1 &ndash; Select Your Payment Method
      </span>
      <CardsList
        cards={cards}
        paymentMethod={paymentMethod}
        paymentOnChange={paymentOnChange}
      />

      <AddCardModal
        route={'/subscribe?addCard=true'}
        customerId={stripe?.customer}
        button={cards.length === 0}
      />

      {paymentMethod && cards.length > 0 && (
        <Button
          variant="contained"
          sx={{ marginTop: '10px' }}
          onClick={subscribeUser}
          disabled={isSubmitting}>
          {isSubmitting && (
            <CircularProgress
              style={{
                color: '#000',
                height: 20,
                width: 20,
                marginRight: 10
              }}
            />
          )}
          {isSubmitting ? (
            <span>Subscribing...</span>
          ) : (
            <span>Subscribe for $8.99/mo</span>
          )}
        </Button>
      )}
    </>
  )
}

export default SubscribeForm
