import Firebase from 'utils/Firebase'
import { getFirestore, doc, onSnapshot } from 'firebase/firestore'
import { getHostFunctions } from 'utils/GetHost'
import { getAuth } from 'firebase/auth'

const db = getFirestore(Firebase)
const auth = getAuth()

async function getAppSettings(callback) {
  onSnapshot(doc(db, 'settings', 'app'), doc => {
    callback(doc.data())
  })
}

async function toggleMaintenanceMode(callback) {
  const token = await auth.currentUser?.getIdToken()
  const result = await fetch(getHostFunctions() + '/toggleMaintenanceMode', {
    headers: {
      authorization: `Bearer ${token}`
    }
  })
  if (result.status === 200) {
    result.json().then(res => {
      if (typeof callback === 'function') {
        callback(res)
      }
    })
  }
}

async function overwriteMatchStats(callback) {
  const token = await auth.currentUser?.getIdToken()
  const result = await fetch(getHostFunctions() + '/setMatchStats', {
    headers: {
      authorization: `Bearer ${token}`
    }
  })
  if (result.status === 200) {
    result.json().then(res => {
      if (typeof callback === 'function') {
        callback(res)
      }
    })
  }
}

async function overwriteEventStats(callback) {
  const token = await auth.currentUser?.getIdToken()
  const result = await fetch(getHostFunctions() + '/setEventStats', {
    headers: {
      authorization: `Bearer ${token}`
    }
  })
  if (result.status === 200) {
    result.json().then(res => {
      if (typeof callback === 'function') {
        callback(res)
      }
    })
  }
}

const SettingsService = {
  getAppSettings,
  toggleMaintenanceMode,
  overwriteMatchStats,
  overwriteEventStats
}

export default SettingsService
