import React from 'react'
import { useAppIsLoggedIn } from 'hooks'
import { Link } from 'react-router-dom'
import AdImg from '../assets/img/ad-goodys.jpg'

const SidebarSponsors = () => {
  const isLoggedIn = useAppIsLoggedIn()
  return (
    <div>
      <div className="sponsors desktop">
        <span className="title">Support our sponsors</span>
        <div className="logos">
          {/* <a href="#">
            <img src="/assets/img/logo-space-force.png" />
          </a> */}
          {/* <span className="no-competitors">Get your logo here!</span> */}
          <a
            href="https://goodys-granola.myshopify.com?ref=fencegg"
            target="_blank">
            <img src={AdImg} />
          </a>
        </div>
      </div>
      <Link className="all-link desktop" to="/contact">
        Contact Us
      </Link>
    </div>
  )
}

export default SidebarSponsors
