import React from 'react'
import { Modal, Box, Typography, Button, CircularProgress } from '@mui/material'
import { Warning } from '@mui/icons-material'

const SubscriptionCancelModal = ({
  setModalOpen,
  modalOpen,
  handleClose,
  isLoading,
  cancelSubscription
}) => {
  return (
    <Modal
      open={modalOpen}
      onClose={handleClose}
      aria-labelledby="subscription-modal-title"
      aria-describedby="subscription-modal-description">
      <Box className="run-modal">
        <Box className="aside-header">
          <Typography
            className="aside-header"
            id="subscription-modal-title"
            variant="h6"
            component="span">
            <Warning /> Cancel Subscription
          </Typography>
        </Box>
        <Box className="modal-content">
          <Typography id="subscription-modal-description" variant="p">
            Your subscription will become in-active at the end of the current
            period. Are you sure you want to cancel?
          </Typography>
          <Box className="modal-controls" sx={{ marginTop: '8px' }}>
            <Button variant="contained" onClick={() => setModalOpen(false)}>
              Close
            </Button>
            <Button
              variant="contained"
              color="error"
              disabled={isLoading}
              onClick={cancelSubscription}
              sx={{ marginLeft: '10px' }}>
              {isLoading && (
                <CircularProgress
                  style={{
                    color: '#000',
                    height: 20,
                    width: 20,
                    marginRight: 10
                  }}
                />
              )}
              {isLoading ? (
                <span>Canceling...</span>
              ) : (
                <span>Cancel Subscription</span>
              )}
            </Button>
          </Box>
        </Box>
      </Box>
    </Modal>
  )
}

export default SubscriptionCancelModal
