import { Box } from '@mui/material'
import SiteTagline from 'components/SiteTagline'
import SocialLinks from 'components/SocialLinks'
import SidebarNavigation from 'components/SidebarNavigation'
import SidebarSponsors from 'components/SidebarSponsors'
import SidebarCompetitors from 'components/SidebarCompetitors'
import SidebarTeams from 'components/SidebarTeams'
import SidebarStreams from 'components/SidebarStreams'
import SidebarGoons from 'components/SidebarGoons'
import SidebarEvents from 'components/SidebarEvents'
import Footer from 'components/Footer'
import MenuMobile from 'components/MenuMobile'
import { Outlet } from 'react-router-dom'

const DefaultLayout = ({ children }) => {
  return (
    <Box>
      <div className="main-container">
        <div className="wrapper tagline-social">
          <SiteTagline />
          <SocialLinks />
        </div>
        <div className="main wrapper clearfix">
          <div className="main-col sidebar-left">
            <div className="desktop">
              <SidebarNavigation />
            </div>
            <SidebarSponsors />
            <SidebarCompetitors />
            <SidebarTeams />
          </div>
          <div className="main-col middle-content">
            <Outlet />
          </div>
          <div className="main-col sidebar-right">
            <SidebarStreams />
            <SidebarGoons />
            <SidebarEvents />
          </div>
        </div>
      </div>

      <Footer />
      <MenuMobile />
    </Box>
  )
}

export default DefaultLayout
